import React, { useCallback, useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  Spinner,
  Text,
  Box,
  Flex,
  Link,
  Image,
  Button,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Icon,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Stack,
} from "@chakra-ui/react";
import { FaRobot, FaPlayCircle } from "react-icons/fa";
import { MdAttachment, MdPictureAsPdf } from "react-icons/md";
import { BiChevronDown } from "react-icons/bi";
import AppContainer from "../store/app";
import MessengerContainer from "../store/messenger";
import InternalSpliter from "./InternalSpliter";
import AsyncImage from "./AsyncImage";
import PreviewImage from "./PreviewImage";
import TeamworkVoiceDecoder from "@projectg/utils/utils/voice/TeamworkVoiceDecoder";
import { getClientType, getHeader } from "@projectg/utils/utils/api";
import {
  AudioPlayer,
  InlineInputMessage,
} from "../../console-app/src/components/components";
import {
  downloadBlobUrl,
  fetchAssetToObjectUrl,
  getBoundedImageSize,
  createTextMessage,
  humanifyDate,
} from "@projectg/utils/utils/helpers";
import {
  BotActionContentType,
  MessageMetaFileSubTypes,
  MessageMetaTypes,
  urlRegex,
} from "@projectg/utils/utils/constants";
import ReplyMessage from "./ReplyMessage";

const ActionMenuWrapper = ({
  children,
  message,
  sdk,
  conversationId,
  ...props
}) => {
  const { session, getTexts } = AppContainer.useContainer();
  const { setReplyMeta, deleteMessage } = MessengerContainer.useContainer();
  const [renderMenu, setRenderMenu] = useState(false);
  const [deferUnrenderMenu, setDeferUnrenderMenu] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const alertCancelRef = useRef();

  useEffect(() => {
    if (deferUnrenderMenu && !isOpen) {
      setRenderMenu(false);
      setDeferUnrenderMenu(false);
    }
  }, [deferUnrenderMenu, isOpen]);

  return (
    <>
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        leastDestructiveRef={alertCancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{getTexts("DeleteMessage")}</AlertDialogHeader>
            <AlertDialogBody>
              {getTexts("AreYouSureToDeleteThisInformation")}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={alertCancelRef} onClick={alertOnClose}>
                {getTexts("Cancel")}
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  deleteMessage(conversationId, message.id);
                  alertOnClose();
                }}
              >
                {getTexts("Delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex
        borderRadius={4}
        onMouseMove={() => {
          if (isOpen) setDeferUnrenderMenu(false);
          else setRenderMenu(true);
        }}
        onMouseLeave={() => {
          if (isOpen) setDeferUnrenderMenu(true);
          else setRenderMenu(false);
        }}
        pos="relative"
        {...props}
      >
        {renderMenu && (
          <Box position="absolute" top={0} right={0} zIndex={2}>
            <Menu
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              fixed
              placement="bottom-end"
            >
              <MenuButton
                as={Button}
                pos="absolute"
                right="0"
                borderRadius={4}
                fontSize="lg"
                w="5px"
                variant="ghost"
                focusBorderColor="transparent"
                _focus={{ boxShadow: "none" }}
                _hover={{}}
                _active={{}}
                size="xs"
                mt={1}
                mr={1}
                zIndex={100}
              >
                <Icon as={BiChevronDown} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setReplyMeta({ ...message })}>
                  {getTexts("Response")}
                </MenuItem>
                {!sdk && message?.senderId === session?.user?.userId && (
                  <MenuItem
                    onClick={() => {
                      alertOnOpen();
                      setDeferUnrenderMenu(false);
                      setRenderMenu(false);
                    }}
                  >
                    {getTexts("Delete")}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        )}
        {children}
      </Flex>
    </>
  );
};

const Message = React.forwardRef(
  (
    {
      conversationId,
      messageDetail,
      sdk,
      internalVisible,
      index,
      style,
      onLoad,
      clearCellMeasurerCache = () => {},
      isReadOnly = false,
    },
    ref
  ) => {
    const { appConfig, getTextByLang, session, getTexts, currentLangKey } =
      AppContainer.useContainer();
    const {
      upsertMessage,
      upsertConversation,
      agents,
      customers,
      sendQueue: appendToSendQueue,
    } = MessengerContainer.useContainer();
    const { m, prevMessage = null, nextMessage = null } = messageDetail;
    //   const prevMessage = _messages?.[index - 1] ?? null;
    //   const nextMessage = _messages?.[index + 1] ?? null;

    const outgoing =
      sdk || m?.internal
        ? m?.senderId === session?.user?.userId
        : m?.senderType !== "customer";

    const showHeader =
      !prevMessage || !dayjs(prevMessage.timestamp).isSame(m.timestamp, "day");
    const showUser =
      // changes
      sdk
        ? !outgoing
        : showHeader ||
          prevMessage?.senderId !== m.senderId ||
          (internalVisible && prevMessage.internal !== m.internal);
    const showTimestamp =
      m?.selfId ||
      nextMessage?.senderId !== m?.senderId ||
      dayjs(nextMessage?.timestamp).format("h:mm a") !==
        dayjs(m?.timestamp).format("h:mm a");

    const sender = (message => {
      if (!message) {
        return <Text display="inline">{getTexts("Users")}</Text>;
      }
      const { senderType, senderId } = message;
      switch (senderType) {
        case "agent":
          return (
            <Text as="span" display="inline">
              {agents[senderId]?.displayName ?? getTexts("Agents")}
            </Text>
          );
        case "customer":
          return (
            <Text as="span" display="inline">
              {customers[senderId]?.displayName ?? getTexts("Users")}
            </Text>
          );
        case "autoMessage":
          return (
            <Flex as="span" align="center">
              <Text as="span" display="inline-block" mr={1}>
                <FaRobot />
              </Text>
              <Text as="span" d="inline">
                {sdk
                  ? getTextByLang(
                      appConfig?.landingPageDescriptio,
                      `${getTexts("ChatBot")}`
                    )
                  : `${getTexts("ChatBot")}`}
              </Text>
            </Flex>
          );
        case "system":
          return (
            <Text as="span" display="inline" color="secondary">
              {getTexts("System")}
            </Text>
          );
        default:
      }
    })(m);

    const onPlayButtonClick = useCallback(
      async m => {
        try {
          upsertMessage(conversationId, m.id, m => ({
            ...m,
            ___voiceFile: { downloading: true, blob: null },
          }));

          const blobMp3 = await (() =>
            new Promise(async resolve => {
              if (typeof window === "undefined") return;
              new TeamworkVoiceDecoder(
                `/api/message/download?messageId=${
                  m.id
                }&clientType=${getClientType()}`,
                getHeader()
              ).toMp3(resolve);
            }))();

          upsertMessage(conversationId, m.id, m => ({
            ...m,
            ___voiceFile: { downloading: false, blob: blobMp3 },
          }));
        } catch (error) {
          upsertMessage(conversationId, m.id, m => ({
            ...m,
            ___voiceFile: { downloading: false, error: true, blob: null },
          }));
        }
      },
      [conversationId, upsertMessage]
    );

    const onButtonClick = useCallback(
      ({ action }) => {
        switch (action.actionType) {
          case BotActionContentType.TEXT_MESSAGE: {
            const { text } = action.actionContent;
            const m = createTextMessage(
              conversationId,
              session.user.type,
              session.user.userId,
              text.trim(),
              false
            );
            upsertMessage(conversationId, m.id, m);
            upsertConversation(conversationId, { orderTimestamp: m.timestamp });
            appendToSendQueue(m);
            break;
          }

          case BotActionContentType.URL: {
            const { url } = action.actionContent;
            if (url) {
              window.open(url, "_blank");
              // const { useNewWindow = false } = action.actionProperties || {};
              // if (useNewWindow) {
              //   window.open(url, "_blank");
              // } else {
              //   window.location.href = url;
              // }
            }
            break;
          }

          default:
        }
      },
      [
        appendToSendQueue,
        conversationId,
        session.user.type,
        session.user.userId,
        upsertConversation,
        upsertMessage,
      ]
    );

    const getDisplayContent = useCallback(
      m => {
        let content = null;

        const outgoing =
          sdk || m?.internal
            ? m?.senderId === session?.user?.userId
            : m?.senderType !== "customer";

        const bg = m?.internal
          ? "secondary.100"
          : outgoing
            ? "primary.100"
            : "gray.100";

        const color = m?.internal
          ? "secondary._100"
          : outgoing
            ? "primary._100"
            : "black";

        switch (m.meta.type) {
          case MessageMetaTypes.TEXT:
            const urls = [];
            const text = m?.meta?.content?.replaceAll(urlRegex, x => {
              urls.push(x);
              return `___{url}___`;
            });

            content = (
              <Box
                borderRadius={4}
                maxW="100%"
                borderWidth={!outgoing ? 0 : 0}
                color={color}
                whiteSpace="pre-line"
                overflowWrap="break-word"
                wordBreak="break-word"
                py={2}
                px={4}
              >
                {text?.split("___{url}___").map((content, index) => {
                  return (
                    <Box d="inline" key={index}>
                      <Text as="span" d="inline" wordBreak="break-word">
                        {content}
                      </Text>
                      {urls?.[index] && (
                        <Link
                          d="inline"
                          color="primary.900"
                          textDecoration="underline"
                          key={`url_${index}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={urls[index]}
                        >
                          {urls[index]}
                        </Link>
                      )}
                    </Box>
                  );
                })}
              </Box>
            );

            break;

          case MessageMetaTypes.ELEMENT:
            content = (
              <Box p={2} color={color}>
                {(m?.meta?.element?.elements ?? []).map((element, index) => {
                  return (
                    <Box py={2} bg="white" border={1} key={index}>
                      {element.title && (
                        <Text mt={2} px={2} fontSize="lg" fontWeight="bold">
                          {element.title}
                        </Text>
                      )}
                      {element.description && (
                        <Text mt={2} px={2} color="#666" whiteSpace="pre-line">
                          {element.description}
                        </Text>
                      )}
                      {element?.thumbnailUrl && (
                        <Image
                          mt={2}
                          px={2}
                          alt={element.title}
                          src={element?.thumbnailUrl}
                        />
                      )}
                      <Box textAlign="center" px={2}>
                        {(element.actions || []).map((action, key) => {
                          return (
                            <Button
                              maxW="90%"
                              mt={2}
                              size="sm"
                              variant="outline"
                              colorScheme={outgoing ? "primary" : "gray"}
                              key={key}
                              w="100%"
                              isDisabled={outgoing || isReadOnly}
                              onClick={() => onButtonClick({ action })}
                            >
                              {action.title}
                            </Button>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
            break;

          case MessageMetaTypes.VOICE: {
            const blob = m?.___voiceFile?.blob;
            const error = m?.__voiceFile?.error;
            const downloading = m?.___voiceFile?.downloading;

            if (blob) {
              content = <audio src={blob} controls={true} />;
            } else {
              if (error) {
                content = (
                  <IconButton
                    icon={<FaPlayCircle />}
                    onClick={() => onPlayButtonClick(m)}
                  />
                );
                break;
              }
              if (!downloading) {
                onPlayButtonClick(m);
              }
              content = (
                <Box m={2}>
                  <Spinner />
                </Box>
              );
            }
            break;
          }

          case MessageMetaTypes.FILE:
            const file = m.meta.file;
            switch (m.meta.subtype) {
              case MessageMetaFileSubTypes.IMAGE: {
                const isLocalImage = file instanceof Blob;
                const size = getBoundedImageSize(
                  file?.previewDimension?.width,
                  file?.previewDimension?.height,
                  250,
                  250
                );
                content = (
                  <Box
                    borderRadius={4}
                    m={2}
                    h={`${size.height}px`}
                    w={`${size.width}px`}
                  >
                    <AsyncImage
                      {...(!isLocalImage
                        ? {
                            srcType: "url",
                            src: file.fileUrl,
                          }
                        : {
                            srcType: "file",
                            src: file,
                          })}
                      fallback={
                        <Flex {...size} align="center" justify="center">
                          <Spinner />
                        </Flex>
                      }
                    >
                      {({ src }) => (
                        <PreviewImage
                          src={src}
                          file={file}
                          size={size}
                          message={m}
                          onLoad={onLoad}
                          boxShadow="none"
                        />
                      )}
                    </AsyncImage>
                  </Box>
                );
                break;
              }
              default:
                const type = (type => {
                  if (type?.match(/(pdf)/)) {
                    return "attachment";
                  } else if (type?.match(/(ogg)/)) {
                    return "audio";
                  } else if (type?.match(/(mp4)/)) {
                    return "audio";
                  }
                })(file?.contentType);

                switch (type) {
                  case "audio":
                    content = <AudioPlayer url={file?.fileUrl} color={color} />;
                    break;
                  default:
                    content = (
                      <Box p={4}>
                        <Flex justify="center">
                          {(type => {
                            if (type?.match(/(pdf)/)) {
                              return <MdPictureAsPdf mx="auto" size={30} />;
                            } else {
                              return <MdAttachment mx="auto" size={30} />;
                            }
                          })(file?.contentType)}
                        </Flex>
                        <Button
                          whiteSpace="break-spaces"
                          onClick={async () => {
                            if (file?.fileUrl) {
                              const fileBlobUrl = await fetchAssetToObjectUrl(
                                file?.fileUrl
                              );
                              downloadBlobUrl(fileBlobUrl, file?.name ?? null);
                            }
                          }}
                          variant="link"
                          colorScheme="gray.900"
                          mt={2}
                          fontSize="md"
                          fontWeight="md"
                        >
                          {file?.name}
                        </Button>
                      </Box>
                    );
                }
            }
            break;

          case MessageMetaTypes.INLINE_INPUT:
            content = (
              <InlineInputMessage
                {...{
                  clearCellMeasurerCache: () =>
                    clearCellMeasurerCache(index, 0),
                  conversationId: conversationId,
                  bg,
                  color,
                  sdk,
                  message: m,
                  colorScheme: !m?.internal ? "primary" : "secondary",
                  isReadOnly,
                }}
              />
            );
            break;

          case MessageMetaTypes.TEMPLATE_MESSAGE:
            const template = m.meta.template;
            const renderParams = () =>
              template?.displayParams?.map(param => (
                <Text>{`${param?.name}: ${param?.value}`}</Text>
              ));
            content = (
              <Box
                whiteSpace="pre-line"
                overflowWrap="break-word"
                wordBreak="break-word"
                py={2}
                px={4}
              >
                <Text mb={1} size="lg" fontWeight="bold" color={`primary.800`}>
                  {getTexts("TemplateMessage")}
                </Text>
                <Text>{template?.name}</Text>
                <Text mb={2} fontSize="sm" color="#666">
                  {template?.description}
                </Text>
                <Stack>{renderParams()}</Stack>
              </Box>
            );
            break;

          default:
            content = "other";
        }

        const messageBox = (
          <Flex flexDir="column" bg={bg} borderRadius={4}>
            {m?.meta?.reply && (
              <Box p={2} pb={0} borderRadius={4} minW="100px">
                <ReplyMessage replyMeta={m?.meta?.reply} onImgLoad={onLoad} />
              </Box>
            )}
            <Box borderRadius={4}>{content}</Box>
          </Flex>
        );

        return isReadOnly ? (
          messageBox
        ) : (
          <ActionMenuWrapper
            message={m}
            bg={bg}
            sdk={sdk}
            conversationId={conversationId}
          >
            {messageBox}
          </ActionMenuWrapper>
        );
      },
      [
        sdk,
        session?.user?.userId,
        onLoad,
        isReadOnly,
        conversationId,
        getTexts,
        onButtonClick,
        onPlayButtonClick,
        clearCellMeasurerCache,
        index,
      ]
    );

    return (
      <InternalSpliter
        ref={ref}
        style={style}
        key={m?.id}
        internalVisible={internalVisible}
        external={
          <Box px={4}>
            {showHeader && (
              <Text pt={2} fontSize="sm" color="#aaa" textAlign="center">
                {humanifyDate(m.timestamp, currentLangKey, false)}
              </Text>
            )}
            <Box {...(m?.internal && internalVisible && { display: "none" })}>
              <Flex
                w="100%"
                my={1}
                flexDir={outgoing ? "row-reverse" : "row"}
                key={m?.id}
              >
                <Flex
                  w="100%"
                  flexDir="column"
                  align={outgoing ? "flex-end" : "flex-start"}
                >
                  {showUser && (
                    <Text
                      mx={1}
                      fontSize="sm"
                      fontWeight="bold"
                      fontFamily="Roboto Slab"
                      color="#666"
                    >
                      {sender}
                    </Text>
                  )}
                  <Flex
                    borderRadius={4}
                    overflow="hidden"
                    maxW="min(90%, 300px)"
                    w="100%"
                    justify={outgoing ? "flex-end" : "flex-start"}
                  >
                    {getDisplayContent(m)}
                  </Flex>

                  {showTimestamp && (
                    <Flex alignItems="center" fontSize="xs" color="gray.400">
                      {m?.selfId && <Spinner mr={2} size="xs" />}
                      <Text>{dayjs(m.timestamp).format("h:mm A")}</Text>
                    </Flex>
                  )}
                </Flex>
                <Box flex={1} />
              </Flex>
            </Box>
          </Box>
        }
        internal={
          <Box px={4}>
            {showHeader && (
              <Text my={2} fontSize="sm" color="#aaa" textAlign="center">
                {humanifyDate(m.timestamp, currentLangKey, false)}
              </Text>
            )}
            <Box {...(!m?.internal && internalVisible && { display: "none" })}>
              <Flex
                w="100%"
                my={1}
                flexDir={outgoing ? "row-reverse" : "row"}
                key={m?.id}
              >
                <Flex
                  w="100%"
                  flexDir="column"
                  align={outgoing ? "flex-end" : "flex-start"}
                >
                  {outgoing && showUser && (
                    <Text
                      mx={1}
                      fontSize="sm"
                      fontWeight="bold"
                      fontFamily="Roboto Slab"
                      color="#666"
                    >
                      {sender}
                    </Text>
                  )}
                  <Flex
                    overflow="hidden"
                    borderRadius={4}
                    maxW="min(90%, 300px)"
                    w="100%"
                    justify={outgoing ? "flex-end" : "flex-start"}
                  >
                    {getDisplayContent(m)}
                  </Flex>
                  {showTimestamp && (
                    <Flex alignItems="center" fontSize="xs" color="gray.400">
                      {m?.selfId && <Spinner mr={2} size="xs" />}
                      <Text>{dayjs(m.timestamp).format("h:mm A")}</Text>
                    </Flex>
                  )}
                </Flex>
                <Box flex={1} />
              </Flex>
            </Box>
          </Box>
        }
      />
    );
  }
);

export default Message;
