import React from "react";
import { Text, Flex, Box, Spinner } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FaRobot } from "react-icons/fa";
import MessengerContainer from "../store/messenger";
import AppContainer from "@projectg/utils/store/app";

const MessageListItem = ({ children, m, style, onClick = () => {} }) => {
  const { agents, customers } = MessengerContainer.useContainer();
  const { getTexts } = AppContainer.useContainer();

  const colorScheme = m?.internal ? "secondary" : "primary";

  const color = m?.internal ? "secondary._100" : "primary._100";

  const sender = message => {
    if (!message) {
      return <Text display="inline">{getTexts("Users")}</Text>;
    }
    const { senderType, senderId } = message;
    switch (senderType) {
      case "agent":
        return (
          <Text as="span" display="inline">
            {agents[senderId]?.displayName ?? getTexts("Agents")}
          </Text>
        );
      case "customer":
        return (
          <Text as="span" display="inline">
            {customers[senderId]?.displayName ?? getTexts("Customers")}
          </Text>
        );
      case "autoMessage":
        return (
          <Flex as="span" align="center">
            <Text as="span" display="inline-block" mr={1}>
              <FaRobot />
            </Text>
            <Text as="span" d="inline">
              {getTexts("ChatBot")}
            </Text>
          </Flex>
        );
      case "system":
        return (
          <Text as="span" display="inline" color="secondary">
            {getTexts("System")}
          </Text>
        );
      default:
    }
  };

  return (
    <Box css={style} userSelect="none">
      <Box
        my={2}
        mx={2}
        borderRadius={4}
        cursor="pointer"
        transition="all 0.2s"
        _hover={{ bg: "rgba(0,0,0,0.05)" }}
        onClick={onClick}
      >
        <Box
          borderLeft="4px solid"
          borderColor={`${colorScheme}.700`}
          p={1}
          px={2}
        >
          <Flex>
            <Box flex={1} minW="0" w="100%">
              <Text
                fontSize="sm"
                fontWeight="bold"
                fontFamily="Roboto Slab"
                color={`${colorScheme}.700`}
                mb={1}
              >
                {sender(m)}
              </Text>
            </Box>
            <Flex alignItems="center" fontSize="xs" color="gray.400">
              {m?.selfId && <Spinner mr={2} size="xs" />}
              <Text>{dayjs(m.timestamp).format("D MMM YYYY h:mm A")}</Text>
            </Flex>
          </Flex>
          <Box color={color}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageListItem;
