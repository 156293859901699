import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIdle } from "react-use";
import { createContainer } from "unstated-next";

import { Permission } from "../../console-app/src/utils/constants";
import i18n from "../i18n.json";
import { LangKey } from "../utils/constants";
import { interpolate, permitted } from "../utils/helpers";
import { useMemoRef } from "../utils/hooks";

const AppContainer = createContainer(() => {
  const [session, setSession] = useState(null);
  const [appConfig, setAppConfig] = useState(null);
  const [selectedLangKey, setSelectedLangKey] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [externalList, setExternalList] = useState(null);

  // store the conversation id which is currently
  const activeConversationRef = useRef(null);
  const isIdleRef = useMemoRef(useIdle(10e3));

  const [texts, setTexts] = useState({});

  const currentLangKey = useMemo(
    () => selectedLangKey || (appConfig && appConfig.defaultLang) || LangKey.EN,
    [appConfig, selectedLangKey]
  );

  const thresholds = [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 6, d: "day" },
    { l: "w", r: 13, d: "day" },
    { l: "ww", r: 27, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y" },
    { l: "yy", d: "year" },
  ];

  const relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime, { thresholds });
  const updateLocale = require("dayjs/plugin/updateLocale");
  dayjs.extend(updateLocale);

  useEffect(() => {
    let locale = require("dayjs/locale/en");
    let relativeTime = {
      future: "in %s",
      past: "%s",
      s: "%ss",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      w: "1w",
      ww: d => `${Math.floor(d / 7)}w`,
      M: "1m",
      MM: "%dm",
      y: "1y",
      yy: "%dy",
    };

    switch (currentLangKey) {
      case LangKey.EN:
        break;
      case LangKey.ZH:
        locale = require("dayjs/locale/zh-tw");
        relativeTime = {
          ...locale.relativeTime,
          w: "1 星期",
          ww: d => `${Math.floor(d / 7)} 星期`,
        };
        break;
      case LangKey.ZH_CN:
        locale = require("dayjs/locale/zh-cn");
        relativeTime = {
          ...locale.relativeTime,
          w: "1 星期",
          ww: d => `${Math.floor(d / 7)} 星期`,
        };
        break;
      default:
        break;
    }

    dayjs.locale(locale);
    dayjs.updateLocale(locale.name, {
      relativeTime,
    });
  }, [currentLangKey]);

  const getTexts = useCallback(
    (key, vars) => {
      try {
        if (vars) {
          return interpolate(texts[key][currentLangKey], vars);
        } else {
          return texts[key][currentLangKey];
        }
      } catch (error) {
        return key;
      }
    },
    [texts, currentLangKey]
  );

  const getTextByLang = useCallback(
    (obj, fallback = "") => {
      try {
        return (
          obj?.[currentLangKey] ??
          obj?.[appConfig.defaultLang] ??
          obj?.["en"] ??
          fallback
        );
      } catch (error) {
        return fallback;
      }
    },
    [currentLangKey, appConfig]
  );

  const resetApp = useCallback(() => {
    setAppConfig(null);
    setSession(null);
    setSelectedLangKey(null);
  }, []);

  useEffect(() => {
    const fetchTexts = async () => {
      //note: fetching multiple request because maxRecords of airtable api is 100 per request, shall delete this logic when i18n is done and using the table offline
      //note: there is key of offset and it's changing everyday :(, if there is some part of i18n din't show up, it's mosts likely because of that.
      let fullRecords = [];

      let fetchRecordsOffset = 0;
      do {
        let fetchRecords = await fetch(
          `https://api.airtable.com/v0/appvVWnfUTcKprXKl/Table%201?maxRecords=1200&view=Grid%20view&api_key=key30iKJgHYfCYi4y&offset=${fetchRecordsOffset}`
        ).then(r => r.json());
        if (fetchRecords.offset) {
          fetchRecordsOffset = fetchRecords.offset;
        } else {
          fetchRecordsOffset = 0;
        }
        fullRecords.push(...fetchRecords?.records);
      } while (fetchRecordsOffset !== 0);

      setTexts(
        fullRecords.reduce(
          (texts, { fields: { key, ...rest } }) => ({
            ...texts,
            [key]: rest,
          }),
          {}
        )
      );
    };

    // if (process.env.NODE_ENV === "development") {
    //   fetchTexts();
    // } else {
    //   setTexts(i18n);
    // }
    setTexts(i18n);
  }, []);

  //i18n note:
  // 1-40 is login, forget reset password page
  // 40-67 configuration
  // 68-99  customization
  // 100-118 developer
  // 119-145 report
  // 146-155 customers
  // 156-178 customer
  // 179-180 agent
  // 181-188 agents
  // 189  dashboard
  // 190-206 detail
  // 207-253 main
  // 254-263 teams
  // 264-298 components
  // 299-327 conversation
  // 328-334 main ConversationComp
  // 335-345 invitation
  // 346 AsyncImage
  // 347-353 mediaDrawer
  // 354-359 message, messageListItem
  // 340 MessageModal
  // 361-365 ReplyMessage
  // 366-369 SearchConversationDrawer
  // 370-375 permissionOptions

  const upsertConfiguration = useCallback(async (key, updater) => {
    setConfiguration(_ => ({
      ..._,
      [key]:
        typeof updater === "function" ? updater(_?.[key] ?? null) : updater,
    }));
  }, []);

  const {
    hasReportPermission,
    hasSystemPermission,
    hasManageSettingsPermission,
    hasManageUserPermission,
    hasOwnConversationsPermission,
    hasAllConversionsPermission,
  } = useMemo(() => {
    const permissions = session?.user?.permissions;
    if (!Array.isArray(permissions))
      return {
        hasReportPermission: false,
        hasSystemPermission: false,
        hasManageSettingsPermission: false,
        hasManageUserPermission: false,
        hasOwnConversationsPermission: false,
        hasAllConversionsPermission: false,
      };

    return {
      hasReportPermission: permitted(permissions, Permission.report),
      hasSystemPermission: permitted(permissions, Permission.system),
      hasManageSettingsPermission: permitted(
        permissions,
        Permission.manageSettings
      ),
      hasManageUserPermission: permitted(permissions, Permission.manageUser),
      hasOwnConversationsPermission: permitted(
        permissions,
        Permission.ownConversations
      ),
      hasAllConversionsPermission: permitted(
        permissions,
        Permission.allConversations
      ),
    };
  }, [session?.user?.permissions]);

  return {
    session,
    sessionRef: useMemoRef(session),
    setSession,
    appConfig,
    setAppConfig,
    getTexts,
    getTextByLang,
    currentLangKey,
    setSelectedLangKey,
    resetApp,
    activeConversationRef,
    isIdleRef,

    configuration,
    upsertConfiguration,

    externalList,
    setExternalList,

    hasReportPermission,
    hasSystemPermission,
    hasManageSettingsPermission,
    hasManageUserPermission,
    hasOwnConversationsPermission,
    hasAllConversionsPermission,
  };
});

export default AppContainer;
