import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Spinner,
  Input,
  FormLabel as Label,
  Select,
} from "@chakra-ui/react";
import landing from "../../images/landing.png";
import logo from "@projectg/utils/assets/logo.png";
import { Controller, useForm } from "react-hook-form";
import api, { setClientType, setToken } from "@projectg/utils/utils/api";
import { useHistory, useLocation, Link } from "react-router-dom";
import Langs from "../../components/LanguagesPicker";

import AppContainer from "@projectg/utils/store/app";
import { useCookie } from "react-use";

import LogRocket from "logrocket";

const Login = () => {
  const [authCookie, updateCookie, deleteCookie] =
    useCookie("grick-console-auth");
  const { setSession, getTexts } = AppContainer.useContainer();
  const [loginError, setLoginError] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    watch,
    reset,
  } = useForm({});

  useEffect(() => {
    (async () => {
      setClientType(26);
      try {
        const { token = null, udId = null } = (() => {
          try {
            return JSON.parse(authCookie) ?? {};
          } catch {
            return {};
          }
        })();
        if (token) {
          setToken(token);
        }
        const { data: user } = await api.get({
          url: "/api/me",
        });
        setTimeout(() => {
          setSession({
            user: { ...user, udId },
            token,
          });

          if (process.env.NODE_ENV === "production") {
            LogRocket.init("ue77xa/grick", {
              dom: {
                textSanitizer: true,
                inputSanitizer: true,
              },
            });
            LogRocket.identify(user.userId, {
              name: user.displayName,
              email: user.email,
              username: user.username,
            });
          }
        }, 1000);
      } catch (error) {
        deleteCookie();
        setToken(null);
        setSession(null);
        setLoading(false);
      }
    })();
  }, [history, location, setSession, deleteCookie, authCookie]);

  const onLogin = useCallback(
    async ({ username, password, preferredAccount }) => {
      try {
        const { data: session } = await api.post({
          url: "/api/init",
          body: {
            username,
            password,
            getAuthToken: true,
            lang: "zh_HK",
            version: "0.0.1",
            appVersion: "0.0.1",
            type: "agent",
            deviceName: "Browser",
            ...(preferredAccount
              ? { preferredAccount: preferredAccount }
              : { supportMultipleAccounts: true }),
          },
        });

        updateCookie(
          JSON.stringify({
            token: session?.token,
            udId: session?.user?.udId,
          })
        );
        setToken(session?.token);

        setSession(session);

        if (location) {
          history.push(location?.state?.location);
        }
      } catch (error) {
        if (error?.msgCode === 253 && error?.extraInfo?.accounts?.length > 0) {
          setAccounts(error?.extraInfo?.accounts);
          return;
        } else {
          deleteCookie();
          setToken(null);
          setLoginError(error);
        }
      }
    },
    [history, location, setSession, deleteCookie, updateCookie]
  );

  const isLoggingIn = isSubmitting || accounts;

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onLogin)}
      h="100%"
      position="relative"
    >
      <svg
        viewBox="0 0 450 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%", position: "absolute" }}
      >
        <path
          d="M213.19,0.00 C152.69,70.06 270.03,70.06 202.98,150.00 L500.00,150.00 L500.00,0.00 Z"
          style={{ stroke: "none", fill: "#FFE0A1" }}
        ></path>
      </svg>

      <Flex h="100%" w="100%" position="absolute" zIndex={1}>
        <div style={{ top: "8px", right: "8px", position: "absolute" }}>
          <Langs />
        </div>
        <Flex
          flexDirection="column"
          justify="center"
          w="50%"
          p={[12, 20]}
          sx={{ zIndex: 10 }}
        >
          <Image alignSelf="center" src={logo} w={150} />
          <Text
            as="h2"
            fontWeight="bold"
            fontSize="xl"
            alignSelf="center"
            fontFamily="Roboto Slab"
          >
            {getTexts("login_title")}
          </Text>

          {loading ? (
            <Box textAlign="center" mt={20}>
              <Spinner color="primary.200" />
              <Text
                mt={3}
                as="h3"
                fontWeight="bold"
                fontSize="lg"
                alignSelf="center"
                color="primary.500"
                fontFamily="Roboto Slab"
              >
                {getTexts("login_welcome")}
              </Text>
            </Box>
          ) : (
            <>
              {accounts && (
                <Flex
                  // {...(!accounts && { display: "none" })}
                  maxw={600}
                  minw={300}
                  w="100%"
                  flexDirection="column"
                  bg="white"
                  p={4}
                >
                  <Box flex={1} fontSize="md">
                    <Text lineHeight={1.5}>
                      {getTexts("login_multiple_registered_workspaces_select")}
                    </Text>
                    <Box my={4} px={4}>
                      <Controller
                        control={control}
                        name="preferredAccount"
                        defaultValue={accounts[0].id}
                        render={({ onChange, onBlur, value, name }) => (
                          <Select
                            name={name}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {(accounts ?? []).map(({ id, organization }) => (
                              <option value={id} key={id}>
                                {organization}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Box>
                  <Flex w="100%" alignSelf="stretch">
                    <Button
                      fontSize="sm"
                      variant="link"
                      colorScheme="secondary"
                      onClick={() => {
                        setAccounts(null);
                        reset({});
                      }}
                    >
                      {getTexts("login_this_isnt_my_account")}
                    </Button>
                    <Box flex={1} />
                    <Button
                      ml={1}
                      colorScheme="secondary"
                      {...(accounts && { type: "submit" })}
                      disabled={!watch("preferredAccount") || isSubmitting}
                    >
                      {isSubmitting
                        ? getTexts("Initializing...")
                        : getTexts("Select")}
                    </Button>
                  </Flex>
                </Flex>
              )}
              <Box {...(accounts && { display: "none" })}>
                <Box mt={4}>
                  <Label>{getTexts("Email")}</Label>
                  <Input
                    fontWeight="bold"
                    borderColor="secondary.500"
                    size="lg"
                    focusBorderColor="secondary.500"
                    name="username"
                    ref={register({
                      required: getTexts("Incorrect_email_format"),
                    })}
                    placeholder="demo@example.com"
                  />
                  <Text color="error">{errors?.username?.message}</Text>
                </Box>
                <Box mt={4}>
                  <Label>{getTexts("Password")}</Label>
                  <Input
                    fontWeight="bold"
                    borderColor="secondary.500"
                    focusBorderColor="secondary.500"
                    size="lg"
                    name="password"
                    ref={register({
                      required: getTexts("login_please_enter_password"),
                    })}
                    type="password"
                    placeholder={getTexts("Password")}
                  />
                  <Text mt={1} color="error">
                    {errors?.password?.message}
                  </Text>
                </Box>
                <Box mt={3} alignSelf="flex-end" textAlign="right">
                  <Button
                    as={Link}
                    variant="link"
                    colorScheme="secondary"
                    to="/forget"
                  >
                    {getTexts("login_forget_password")}
                  </Button>
                </Box>
                <Box mt={4} alignSelf="center" textAlign="center">
                  <Text mb={2} color="error">
                    {loginError?.message}
                  </Text>
                  <Button
                    px={8}
                    borderRadius={30}
                    colorScheme="secondary"
                    variant="solid"
                    {...(!accounts && { type: "submit" })}
                    disabled={isLoggingIn}
                  >
                    {isLoggingIn
                      ? getTexts("login_logging_in")
                      : getTexts("login")}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Flex>
        <Flex
          align="center"
          flexDirection="column"
          justify="center"
          w={1 / 2}
          p={[12, 16]}
        >
          <Image src={landing} borderRadius={8} />
          <Text mt={3} fontSize="xl" alignSelf="flex-start">
            {getTexts("login_quote")}
          </Text>
          <Text
            alignSelf="flex-end"
            variant="heading"
            textAlign="right"
            mt={2}
            color="secondary.500"
            fontSize="md"
          >
            {getTexts("login_quote_by")}
          </Text>

          <Button
            as="a"
            mt={3}
            borderRadius={30}
            variant="outline"
            borderWidth={2}
            colorScheme="secondary"
            px={4}
            lineHeight={1.5}
            href="https://grick.io"
            target="_blank"
          >
            {getTexts("login_how_they_use")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Login;
