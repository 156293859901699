import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Input,
  Button,
  IconButton,
  Tag,
  Tooltip,
  Center,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Card from "../card";
import UpsertSanukerTokenModal from "./upsertSanukerTokenModal";
import UpsertSanukerDrawer from "./upsertSanukerDrawer";
import UpsertSanukerChannelDrawer from "./upsertSanukerChannelDrawer";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import {
  CopyClipboardButton,
  AlertModal,
} from "../../../../components/components";
import { MdClose, MdDelete } from "react-icons/md";
import { useHistory } from "react-router";

const SanukerDetail = ({ platform }) => {
  const history = useHistory();
  const toast = useToast();
  const cancelRef = React.useRef();

  const { getTexts, setExternalList } = AppContainer.useContainer();

  const removeSanukerAlert = useDisclosure();
  const [removeSanukerIsLoading, setRemoveSanukerIsLoading] = useState(false);
  const onRemoveSanuker = async () => {
    try {
      setRemoveSanukerIsLoading(true);
      const { data } = await api.post({
        url: "/api/admin/setting/externalPlatform/sanuker/delete",
        body: {
          id: platform.id,
        },
      });
      setExternalList(data);
      removeSanukerAlert.onClose();
      toast({
        title: getTexts("SanukerConnectionRemoved"),
        status: "success",
      });
      history.replace("/externalPlatform");
    } catch (error) {
    } finally {
      setRemoveSanukerIsLoading(false);
    }
  };

  const upsertSanukerTokenModal = useDisclosure();

  const upsertSanukerDrawer = useDisclosure();

  const upsertSanukerChannelDrawer = useDisclosure();
  const [
    selectedSanukerChannelParams,
    setSelectedSanukerChannelParams,
  ] = useState({ platform });

  const removeSanukerChannelAlert = useDisclosure();
  const [
    removeSanukerChannelIsLoading,
    setRemoveSanukerChannelIsLoading,
  ] = useState(false);
  const onRemoveSanukerChannel = async () => {
    try {
      setRemoveSanukerChannelIsLoading(true);
      const { data } = await api.post({
        url: "/api/admin/setting/externalPlatform/sanuker/channel/delete",
        body: {
          id: platform?.id,
          channelId: selectedSanukerChannelParams?.channel?.id,
        },
      });
      setExternalList(data);
      removeSanukerChannelAlert.onClose();
      toast({
        title: getTexts("SanukerChannelRemoved"),
        status: "success",
      });
    } catch (error) {
    } finally {
      setRemoveSanukerChannelIsLoading(false);
    }
  };

  return (
    <>
      <AlertModal
        isOpen={removeSanukerAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeSanukerAlert.onClose}
        isLoading={removeSanukerIsLoading}
        onSubmit={onRemoveSanuker}
        title={getTexts("SanukerConnectionRemove")}
      />

      <AlertModal
        isOpen={removeSanukerChannelAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeSanukerChannelAlert.onClose}
        isLoading={removeSanukerChannelIsLoading}
        onSubmit={onRemoveSanukerChannel}
        title={getTexts("SanukerChannelRemove")}
      />

      <UpsertSanukerTokenModal
        title={getTexts("SanukerAccessTokenUpdate")}
        isOpen={upsertSanukerTokenModal.isOpen}
        onClose={() => {
          upsertSanukerTokenModal.onClose();
        }}
        platform={platform}
      />

      {upsertSanukerDrawer.isOpen && (
        <UpsertSanukerDrawer
          isOpen={upsertSanukerDrawer.isOpen}
          onClose={upsertSanukerDrawer.onClose}
          platform={platform}
        />
      )}

      {upsertSanukerChannelDrawer.isOpen && (
        <UpsertSanukerChannelDrawer
          isOpen={upsertSanukerChannelDrawer.isOpen}
          onClose={upsertSanukerChannelDrawer.onClose}
          params={selectedSanukerChannelParams}
        />
      )}

      <Flex alignItems="flex-end">
        <Flex w="100%" minW={0} flex={1}>
          <Heading size="lg">{getTexts("ExternalPlatformDetail")}</Heading>
        </Flex>
        <Stack direction="row" spacing={4}>
          <Button
            leftIcon={<MdDelete />}
            colorScheme="red"
            onClick={removeSanukerAlert.onOpen}
            isLoading={removeSanukerIsLoading}
          >
            {getTexts("SanukerConnectionRemove")}
          </Button>
          <Button onClick={upsertSanukerTokenModal.onOpen}>
            {getTexts("SanukerAccessTokenUpdate")}
          </Button>
          <Button colorScheme="primary" onClick={upsertSanukerDrawer.onOpen}>
            {getTexts("Edit")}
          </Button>
        </Stack>
      </Flex>

      <Box as={Card}>
        <Stack spacing={4}>
          {platform?.organizationId && (
            <Stack spacing={2}>
              <Text w="100%" fontWeight="bold">
                {getTexts("OrganizationId")}
              </Text>
              <Box fontSize="sm" color="gray.500">
                <Flex alignItems="center">
                  <Text fontSize="md">{platform?.organizationId}</Text>
                </Flex>
              </Box>
            </Stack>
          )}
          {platform?.projectGChannelId && (
            <Stack spacing={2}>
              <Text w="100%" fontWeight="bold">
                {getTexts("ProjectGChannelId")}
              </Text>
              <Box fontSize="sm" color="gray.500">
                <Flex alignItems="center">
                  <Text fontSize="md">{platform?.projectGChannelId}</Text>
                </Flex>
              </Box>
            </Stack>
          )}
          <Stack spacing={2}>
            <Text w="100%" fontWeight="bold">
              {getTexts("LiveChatWebhook")}
            </Text>
            <Box fontSize="sm" color="gray.500">
              <Flex alignItems="center">
                <Input value={platform?.liveChatWebhook} readOnly />
                {platform?.liveChatWebhook && (
                  <CopyClipboardButton content={platform?.liveChatWebhook} />
                )}
              </Flex>
            </Box>
          </Stack>
          <Stack spacing={2}>
            <Text w="100%" fontWeight="bold">
              {getTexts("RelayWebhook")}
            </Text>
            <Box fontSize="sm" color="gray.500">
              <Flex alignItems="center">
                <Input value={platform?.relayWebhook} readOnly />
                {platform?.relayWebhook && (
                  <CopyClipboardButton content={platform?.relayWebhook} />
                )}
              </Flex>
            </Box>
          </Stack>
          <Stack spacing={2}>
            <Text w="100%" fontWeight="bold">
              {getTexts("SendWebhook")}
            </Text>
            <Box fontSize="sm" color="gray.500">
              <Flex alignItems="center">
                <Input value={platform?.sendWebhook} readOnly />
                {platform?.sendWebhook && (
                  <CopyClipboardButton content={platform?.sendWebhook} />
                )}
              </Flex>
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Flex alignItems="flex-end" mt={8}>
        <Flex w="100%" minW={0} flex={1}>
          <Heading size="lg">{getTexts("SanukerChannels")}</Heading>
        </Flex>
        <Button
          colorScheme="primary"
          onClick={() => {
            setSelectedSanukerChannelParams({ platform });
            upsertSanukerChannelDrawer.onOpen();
          }}
        >
          {getTexts("SanukerChannelCreate")}
        </Button>
      </Flex>
      {platform?.channels?.length ? (
        platform?.channels?.map((channel, i) => (
          <Box
            as={Card}
            key={i}
            py={4}
            cursor="pointer"
            onClick={() => history.push(`${platform.id}/${channel.id}`)}
          >
            <Box pos="relative">
              <IconButton
                pos="absolute"
                top="0px"
                right="0px"
                icon={<MdClose />}
                variant="ghost"
                size="sm"
                fontSize="lg"
                isRound
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedSanukerChannelParams(_ => ({ ..._, channel }));
                  removeSanukerChannelAlert.onOpen();
                }}
              />
            </Box>
            <Stack flex={1} minW={0} w="100%" spacing={1}>
              <Text w="100%" fontWeight="bold">
                {channel?.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {`${getTexts("ChannelId")}: ${channel?.id}`}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {`${getTexts("ResponseWindowHours")}: ${
                  channel?.responseWindowHours
                }`}
              </Text>
              <Box flex={1}>
                {channel?.templates?.map(({ name, description, key }) => (
                  <Tag colorScheme="secondary" mr={2} size="sm" key={key}>
                    <Tooltip label={description}>{name}</Tooltip>
                  </Tag>
                ))}
              </Box>
            </Stack>
          </Box>
        ))
      ) : (
        <Card>
          <Center>
            <Text fontSize="xl" color="gray.400">
              {getTexts("ListEmpty")}
            </Text>
          </Center>
        </Card>
      )}
    </>
  );
};

export default SanukerDetail;
