import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from "@chakra-ui/react";
import Message from "./Message";
import AppContainer from "@projectg/utils/store/app";

const MessageModal = ({ isOpen, onClose, sdk = false, messageDetail }) => {
  const { getTexts } = AppContainer.useContainer();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getTexts("InformationContext")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Message isReadOnly={true} sdk={sdk} messageDetail={messageDetail} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MessageModal;
