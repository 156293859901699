import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Spinner,
  FormControl,
  FormLabel,
  useToast
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import logo from "@projectg/utils/assets/logo.png";
import api from "@projectg/utils/utils/api";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { strongPasswordRegex } from "@projectg/utils/utils/constants";
import Langs from "../../components/LanguagesPicker";
import AppContainer from "@projectg/utils/store/app";

const ResetPassword = () => {
  const { getTexts } = AppContainer.useContainer();
  const { handleSubmit, errors, getValues, watch, control } = useForm({
    reValidateMode: "onSubmit"
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [accepting, setAccepting] = useState(false);
  const [email, setEmail] = useState("");
  const { token } = useParams();
  // const token = "YuNwE5tJuZX58ZgC99utRy8DLYhAodWvHZKF";
  const toast = useToast();

  //logg
  const { log } = console;
  log("errors", errors);
  log("watch", watch());

  //email init
  useEffect(() => {
    (async () => {
      try {
        const res = await api.get({
          url: `/api/forgetPassword/${token}`
        });
        if (res.data) {
          setEmail(res.data.email);
          log("res.data", res);
          setIsLoading(false);
        }
      } catch (error) {
        toast({
          title: getTexts("resetPassword_getEmail_error_title"),
          description: getTexts("resetPassword_getEmail_error_desciption"),
          status: "error",
          duration: 7500,
          isClosable: false
        });
        history.push("/");
      }
    })();
  }, [getTexts, history, log, toast, token]);

  //reset password post api
  const onSubmit = useCallback(
    async value => {
      setAccepting(true);
      try {
        const res = await api.post({
          url: `/api/forgetPassword/${token}/submit`,
          body: {
            password: value.password
          }
        });
        if (res) {
          log("res", res);
          toast({
            title: getTexts("resetPassword_success"),
            status: "success",
            duration: 2500,
            isClosable: false
          });
        }
        history.push("/");
      } catch (error) {
        setAccepting(false);
      } finally {
        return false;
      }
    },
    [getTexts, history, log, toast, token]
  );

  console.log("errors", errors);
  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      h="100%"
      position="relative"
    >
      <svg
        viewBox="0 0 450 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%", position: "absolute" }}
      >
        <path
          d="M213.19,0.00 C152.69,70.06 270.03,70.06 202.98,150.00 L500.00,150.00 L500.00,0.00 Z"
          style={{ stroke: "none", fill: "#FFE0A1" }}
        ></path>
      </svg>
      <div style={{ top: "8px", right: "8px", position: "absolute" }}>
        <Langs />
      </div>
      <Flex
        h="100%"
        w="100%"
        position="absolute"
        zIndex={1}
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Flex
          flexDir="column"
          alignItems="stretch"
          justifyContent="center"
          maxW={400}
          w="100%"
          px={12}
          py={6}
          boxShadow="md"
          minH="60%"
          bg="white"
        >
          <Image mx="auto" src={logo} w={100} />
          <Text
            mt={3}
            as="h2"
            fontWeight="bold"
            fontSize="2xl"
            alignSelf="center"
            fontFamily="Roboto Slab"
          >
            {getTexts("ResetPassword")}
          </Text>
          {isLoading ? (
            <Spinner alignSelf="center" mt={12} color="primary.200"></Spinner>
          ) : (
            <>
              <Text pt={6}>
                {getTexts("EmailAddress")}
                <br />
                {email}
              </Text>
              <FormControl>
                <FormLabel pt={3}>{getTexts("NewPassword")}</FormLabel>
                <Controller
                  control={control}
                  name="password"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      fontSize="2l"
                      mb={-1}
                      w="100%"
                      focusBorderColor="primary"
                      type="password"
                    />
                  )}
                  rules={{
                    required: getTexts("resetPassword_did_not_fill_passwords"),
                    pattern: {
                      value: strongPasswordRegex,
                      message: getTexts("resetPassword_strongPassword_error")
                    }
                  }}
                />

                <FormLabel pt={3}>{getTexts("ConfirmPassword")}</FormLabel>
                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      type="password"
                      fontSize="2l"
                      mb={-1}
                      w="100%"
                      focusBorderColor="primary"
                    />
                  )}
                  rules={{
                    required: getTexts("resetPassword_did_not_fill_passwords"),
                    pattern: {
                      value: strongPasswordRegex,
                      message: getTexts("resetPassword_strongPassword_error")
                    },
                    validate: value =>
                      value === getValues("password") ||
                      getTexts("resetPassword_confirm_password_not_match")
                  }}
                />
              </FormControl>
              <Text mt={3} color="error">
                {errors?.password?.message || errors?.confirmPassword?.message}
              </Text>
              <Button
                mt={3}
                size="lg"
                fontSize="xl"
                colorScheme="secondary"
                variant="solid"
                isLoading={accepting}
                type="submit"
              >
                {getTexts("Submit")}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ResetPassword;
