import {
  Flex,
  Text,
  IconButton,
  Image,
  Box,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import Conversation from "@projectg/utils/components/conversation";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { useIsOfficeHour } from "./utils/hooks";
const ConversationWrapper = () => {
  const { id } = useParams();
  const history = useHistory();
  const { getTexts } = AppContainer.useContainer();

  const { agents, conversations } = MessengerContainer.useContainer();
  const conversation = conversations?.[id];

  const isOfficeHour = useIsOfficeHour();

  return (
    <Flex w="100%" h="100%" flexDir="column">
      <Flex
        h={150}
        w="100%"
        position="relative"
        flexDir="column"
        justifyContent="flex-end"
      >
        <Flex px={2} alignItems="center" color="primary._200">
          <IconButton
            icon={<MdArrowBack />}
            variant="ghost"
            zIndex={400}
            fontSize="2xl"
            colorScheme="primary"
            color="primary._200"
            onClick={() => history.push("/")}
          />
          <Text fontSize="2xl" fontWeight="bold" fontFamily="Roboto Slab" p={2}>
            {conversation?.name}
          </Text>
          <Box flex={1} minW={0} w="100%" />
          <Flex my={2} px={2} alignSelf="center" textAlign="right">
            {Object.values(conversation?.assignedAgent).map(({ id }, index) => {
              const u = agents?.[id];
              return (
                <Tooltip
                  key={u?.userId}
                  label={u?.displayName ?? "Staff"}
                  placement="top"
                >
                  <Flex
                    key={u?.userId}
                    mx={-1}
                    w={12}
                    h={12}
                    borderRadius="50%"
                    bg="primary.500"
                    color="primary._500"
                    alignItems="center"
                    justifyContent="center"
                    boxShadow="md"
                    overflow="hidden"
                  >
                    {u?.profilePic ? (
                      <Image src={u?.profilePic} />
                    ) : (
                      <Text fontWeight="bole" fontSize="lg">
                        {u?.displayName
                          .split(" ")
                          .map(x => x?.[0])
                          .filter(_ => !!_)
                          .join("")}
                      </Text>
                    )}
                  </Flex>
                </Tooltip>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      {!isOfficeHour && (
        <VStack pt={2} pb={1} bg="rgba(0,0,0,0.05)">
          <Text fontSize="sm" color="#515151">
            {getTexts("NonOfficeHourMessage")}
          </Text>
        </VStack>
      )}
      <Flex
        flex={1}
        minH={0}
        h="100%"
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Conversation id={id} sdk={true} />
      </Flex>
    </Flex>
  );
};

export default ConversationWrapper;
