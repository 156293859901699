import React, { useCallback } from "react";
import { Text, useTheme } from "@chakra-ui/react";
import { MessageMetaTypes } from "@projectg/utils/utils/constants";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";

export const useGetMessageContent = () => {
  const { getTexts } = AppContainer.useContainer();

  const getMessageContent = useCallback(
    message => {
      let content;
      switch (message?.meta?.type ?? null) {
        case MessageMetaTypes.TEXT:
          content = message?.meta?.content ?? getTexts("SentAMessage");
          break;
        case MessageMetaTypes.VOICE:
          content = getTexts("SentAnAudio");
          break;
        case MessageMetaTypes.FILE:
          content = getTexts("SentAFile");
          break;
        case MessageMetaTypes.ELEMENT:
          const elements = message?.meta?.element?.elements;
          content = elements?.[0]?.description
            ? `: ${elements?.[0]?.description}`
            : getTexts("SentAMultimedia");
          break;
        default:
          content = getTexts("SentAMessage");
      }
      return content;
    },
    [getTexts]
  );

  return getMessageContent;
};

export const useGetLastMessageDisplay = () => {
  const { getTexts } = AppContainer.useContainer();
  const { getLastMessage, customers, agents } =
    MessengerContainer.useContainer();
  const theme = useTheme();

  return useCallback(
    (conversation, userId = null) => {
      const message = getLastMessage(conversation);

      const sender = (message => {
        if (!message) {
          return { displayName: "Someone" };
        }
        const { senderType, senderId } = message;
        switch (senderType) {
          case "agent":
            return (
              <Text as="span" display="inline" fontWeight="bold">
                {senderId === userId
                  ? getTexts("SenderUser")
                  : agents[senderId]?.displayName ?? getTexts("Agents")}
              </Text>
            );
          case "customer":
            return (
              <Text as="span" display="inline" fontWeight="bold">
                {senderId === userId
                  ? getTexts("SenderUser")
                  : customers[senderId]?.displayName ?? getTexts("Customers")}
              </Text>
            );
          case "autoMessage":
            return (
              <Text as="span" display="inline" fontWeight="bold">
                {getTexts("Chatbot")}
              </Text>
            );
          case "system":
            return (
              <Text
                as="span"
                display="inline"
                fontWeight="bold"
                color="primary"
              >
                {getTexts("System")}
              </Text>
            );
          default:
        }
      })(message);

      let content = null;

      const typingCustomers = (conversation?.customer ?? [])
        .filter(({ ___isTyping }) => !!___isTyping)
        .map(({ id }) => customers?.[id]);

      const typingAgents = (conversation?.agent ?? [])
        .filter(({ ___isTyping }) => !!___isTyping)
        .map(({ id }) => agents?.[id]);

      const typingUsers = [...typingCustomers, ...typingAgents];

      if ((typingUsers?.length ?? 0) > 0) {
        const maxCount = 1;
        return (
          <Text
            color="secondary.500"
            fontWeight="bold"
            minWidth={0}
            flex={1}
            fontSize="90%"
            mx={4}
          >
            {typingUsers
              .slice(0, Math.min(maxCount, typingUsers.length))
              .map((c, i) => (
                <Text key={i} d="inline">
                  {i > 0 && (
                    <Text d="inline" mx={1}>
                      {i === typingUsers.length - 1 &&
                      typingUsers.length <= maxCount
                        ? getTexts("UserTypingAnd")
                        : "、"}
                    </Text>
                  )}
                  <Text d="inline" color="secondary.500">
                    {c?.displayName}
                  </Text>
                </Text>
              ))}{" "}
            {typingUsers.length > maxCount &&
              getTexts("UserTypingMultiple", {
                count: typingUsers.length - maxCount,
              })}{" "}
            {getTexts("UserTyping")}
          </Text>
        );
      } else {
        switch (message?.meta?.type ?? null) {
          case MessageMetaTypes.TEXT:
            content = (
              <>
                {sender}:{" "}
                {message?.meta?.content ??
                  getTexts("SentAMessage").toLowerCase()}
              </>
            );
            break;
          case MessageMetaTypes.VOICE:
            content = (
              <>
                {sender} {getTexts("SentAnAudio").toLowerCase()}
              </>
            );
            break;
          case MessageMetaTypes.FILE:
            content = (
              <>
                {sender} {getTexts("SentAFile").toLowerCase()}
              </>
            );
            break;
          case MessageMetaTypes.ELEMENT:
            const elements = message?.meta?.element?.elements;
            content = (
              <>
                {sender}$
                {elements?.[0]?.description
                  ? `: ${elements?.[0]?.description}`
                  : getTexts("SentAMultimedia").toLowerCase()}
              </>
            );
            break;
          default:
            content = (
              <>
                {sender} {getTexts("SentAMessage").toLowerCase()}
              </>
            );
        }
      }

      if (message) {
        switch (message.dataType) {
          case 51:
            return (
              <Text
                minWidth={0}
                flex={1}
                color="#999"
                fontSize="90%"
                mx={4}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                {...(message.internal && {
                  borderLeft: `2px solid ${theme.colors.primary[700]}`,
                  color: "primary.900",
                  px: 1,
                })}
              >
                {content}
              </Text>
            );

          default:
        }
      }
    },
    [agents, customers, getLastMessage, getTexts, theme.colors.primary]
  );
};
