import { useState } from "react";
import { createContainer } from "unstated-next";

const SdkContainer = createContainer(() => {
  const [workspaceVisible, setWorkspaceVisible] = useState(false);
  const [sdkVisible, setSdkVisible] = useState(true);
  const [preview, setPreview] = useState(null);
  return {
    workspaceVisible,
    setWorkspaceVisible,
    sdkVisible,
    setSdkVisible,
    preview,
    setPreview
  };
});

export default SdkContainer;
