import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import landing from "../../images/landing.png";
import AppContainer from "@projectg/utils/store/app";

const Dashboard = () => {
  const { getTexts } = AppContainer.useContainer();
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      w="100%"
      h="100%"
    >
      <Image src={landing} borderRadius={8} w="50%" />
      <Text
        fontWeight="bold"
        fontSize="2xl"
        color="primary.500"
        fontFamily="Roboto Slab"
      >
        {getTexts("WelcomeToGrick")}
      </Text>
    </Flex>
  );
};

export default Dashboard;
