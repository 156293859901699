import React from "react";
import { Box } from "@chakra-ui/react";

const Card = ({ children, ...props }) => (
  <Box p={8} my={4} boxShadow="md" borderRadius={8} {...props}>
    {children}
  </Box>
);

export default Card;
