import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Spinner,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";
import api from "@projectg/utils/utils/api";
import {
  humanifyDate,
  isMyIssue,
  mergeFields,
} from "@projectg/utils/utils/helpers";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { FaTags, FaUser, FaUserEdit, FaUsers } from "react-icons/fa";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { BadgeIndicator, SourceTag } from "../../components/components";
import { DatePickerInput } from "../../components/DatePicker";
import { emailRegex, urlRegex } from "@projectg/utils/utils/constants";
import {
  getSelectStyle,
  getSelectTheme,
  mergeSearchParams,
} from "@projectg/utils/utils/helpers";
import dayjs from "dayjs";
import { useGetLastMessageDisplay } from "../../utils/hooks";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";

const Detail = ({ conversationId, customerId }) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { getTextByLang, session, appConfig, getTexts, currentLangKey } =
    AppContainer.useContainer();
  const {
    hasUnread,
    customerTags,
    conversationTags,
    agents,
    teams,
    customers,
    conversations,
    upsertConversation,
    upsertCustomer,
    upsertAgent,
  } = MessengerContainer.useContainer();

  const currEditRef = useRef();

  const profileForm = useMemo(
    () => [
      {
        name: "email",
        label: getTexts("File"),
        type: "email",
      },
      {
        name: "subtitle",
        label: getTexts("Subtitle"),
        type: "text",
      },
      {
        name: "bio",
        label: getTexts("Bio"),
        type: "text",
      },
      {
        name: "tags",
        label: getTexts("Tags"),
        type: "select",
      },
    ],
    [getTexts]
  );

  const conversation = useMemo(
    () => conversations[conversationId],
    [conversationId, conversations]
  );

  const id = customerId ?? conversation?.customer?.[0]?.id;
  const customer = useMemo(() => customers?.[id] ?? null, [customers, id]);
  const agentId = isMyIssue(conversation, session)
    ? session?.user?.userId
    : conversation?.assignedAgent?.[0]?.id;
  const agent = useMemo(() => agents?.[agentId] ?? null, [agentId, agents]);

  const { reset, register, control, trigger, errors } = useForm({});

  useEffect(() => {
    reset({
      conversation,
      customer: customer
        ? {
            ...customer,
            fields: mergeFields(appConfig?.customerFields, customer?.fields),
          }
        : null,

      agent: agent
        ? {
            ...agent,
            fields: mergeFields(appConfig?.agentFields, agent?.fields),
          }
        : null,
    });
  }, [appConfig, conversation, customer, agent, reset]);

  const relatedConversations = Object.values(conversations).filter(c =>
    c?.customer?.find(x => x?.id === id)
  );

  const addNote = useCallback(
    async e => {
      try {
        const note = e.target.value;
        e.target.value = "";
        if (note) {
          const params = {
            userId: id,
            content: note,
          };
          const { data: updated } = await api.post({
            url: "/api/admin/customer/note/add",
            body: params,
          });
          upsertCustomer(id, updated);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );

  const updateCustomerTag = useCallback(
    async (
      tags,

      { action, option, removedValue }
    ) => {
      try {
        setUpdatingCustomer(true);
        upsertCustomer(id, { tags: tags?.map(x => x.value) ?? [] });
        await (() => {
          switch (action) {
            case "create-option":
              return api.post({
                url: `/api/tag/customer/assign`,
                body: {
                  userId: id,
                  tag: tags?.filter(x => x.__isNew__).map(x => x.value),
                },
              });
            case "select-option":
              return api.post({
                url: `/api/tag/customer/assign`,
                body: {
                  userId: id,
                  tag: [option.value],
                },
              });
            case "remove-value":
              return api.post({
                url: `/api/tag/customer/unassign`,
                body: {
                  userId: id,
                  tag: [removedValue.value],
                },
              });
            default:
          }
        })();
        setUpdatingCustomer(false);
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );

  const updateConversationTag = useCallback(
    async (tags = [], { action, option, removedValue }) => {
      try {
        setUpdatingConversation(true);
        upsertConversation(conversationId, {
          tag: tags?.map(x => x.value) ?? [],
        });
        await (() => {
          switch (action) {
            case "create-option":
              return api.post({
                url: `/api/tag/conversation/assign`,
                body: {
                  conversationId,
                  tag: tags?.filter(x => x.__isNew__).map(x => x.value),
                },
              });
            case "select-option":
              return api.post({
                url: `/api/tag/conversation/assign`,
                body: {
                  conversationId,
                  tag: [option.value],
                },
              });
            case "remove-value":
              return api.post({
                url: `/api/tag/conversation/unassign`,
                body: {
                  conversationId,
                  tag: [removedValue.value],
                },
              });
            default:
          }
        })();
        setUpdatingConversation(false);
      } catch (error) {
        console.error(error);
      }
    },
    [conversationId, upsertConversation]
  );

  const updateCustomer = useCallback(
    async updater => {
      try {
        setUpdatingCustomer(true);
        upsertCustomer(id, updater);
        await api.post({
          url: "/api/admin/customer/update",
          body: {
            userId: id,
            ...updater,
          },
        });
        setUpdatingCustomer(false);
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );

  const updateAgent = useCallback(
    async updater => {
      try {
        upsertAgent(agentId, updater);
        await api.post({
          url: "/api/admin/agent/update",
          body: {
            userId: agentId,
            ...updater,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [agentId, upsertAgent]
  );

  const updateConversationUser = useCallback(
    async (finalValue, { option, removedValue }) => {
      try {
        setUpdatingConversation(true);
        const updater = {
          ...(removedValue?.id && { removeTeam: [removedValue?.id] }),
          ...(removedValue?.userId && { removeAgent: [removedValue?.userId] }),
          ...(option?.id && { addTeam: [option?.id] }),
          ...(option?.userId && { addAgent: [option?.userId] }),
        };

        upsertConversation(conversationId, c => ({
          ...c,
          assignedAgent: [
            ...(c?.assignedAgent ?? []).filter(
              ({ id }) => id !== updater.removeAgent?.[0]
            ),
            ...(updater.addAgent?.map(id => ({ id })) ?? []),
          ],
          team: [
            ...(c?.team ?? []).filter(id => id !== updater.removeTeam?.[0]),
            ...(updater.addTeam ?? []),
          ],
          ...((updater.removeAgent || updater.addAgent) && {
            _assignedAgent: {
              value: c?.assignedAgent,
              timestamp: dayjs().valueOf(),
              ...c?._assignedAgent,
            },
          }),
          ...((updater.removeTeam || updater.addTeam) && {
            _team: {
              value: c?.team,
              timestamp: dayjs().valueOf(),
              ...c?._team,
            },
          }),
        }));

        await api.post({
          url: "/api/conversation/update",
          body: {
            id: conversationId,
            ...updater,
          },
        });
        setUpdatingConversation(false);
      } catch (error) {
        console.error(error);
      }
    },
    [conversationId, upsertConversation]
  );

  const [showCustomerCustomField, setShowCustomerCustomField] = useState(false);
  const [showAgentCustomField, setShowAgentCustomField] = useState(false);
  const [updatingCustomer, setUpdatingCustomer] = useState(false);
  const [updatingConversation, setUpdatingConversation] = useState(false);

  const getLastMessageDisplay = useGetLastMessageDisplay();

  const conversationTagOptions = [
    ...new Set([...conversationTags, ...(conversation?.tag ?? [])]),
  ]?.map(tag => ({
    label: tag,
    value: tag,
  }));

  const ConversationAccordion = useMemo(
    () => (
      <AccordionItem borderWidth={0}>
        <AccordionButton
          pt={4}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <Text
            textAlign="left"
            flex={1}
            fontWeight="bold"
            fontSize="lg"
            fontFamily="Roboto Slab"
          >
            {getTexts("ThisConversation")}
          </Text>
          {updatingConversation && (
            <Spinner mx={2} size="sm" color="primary.500" />
          )}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {customer?.whatsappId && (
            <FormControl d="flex">
              <Flex w="100%" mt={2} mr={2}>
                <SourceTag source={{ type: "whatsapp" }} />
                <Box w="100%" minW={0} flex={1} textAlign="right">
                  <Text color="primary.500">+{customer?.whatsappId}</Text>
                </Box>
              </Flex>
            </FormControl>
          )}
          <FormControl d="flex">
            <Tooltip label={getTexts("ConversationTags")}>
              <Box>
                <Box
                  mt={2}
                  mr={2}
                  as={FaTags}
                  fontSize="lg"
                  fontWeight="bold"
                  color="primary.500"
                />
              </Box>
            </Tooltip>
            <CreatableSelect
              isMulti={true}
              placeholder={getTexts("Tags")}
              options={conversationTagOptions}
              onChange={updateConversationTag}
              value={conversationTagOptions.filter(x => {
                return (conversation?.tag ?? []).includes(x.value);
              })}
              styles={{
                ...getSelectStyle({ theme }),
                indicatorsContainer: _ => ({
                  display: "none",
                }),
                placeholder: _ => ({ width: "90%" }),
                control: _ => ({
                  ..._,
                  textAlign: "right",
                  borderColor: "transparent",
                  minHeight: 28,
                  padding: 0,
                  borderWidth: 0,
                  "&:hover, &:active": {
                    borderWidth: 0,
                    boxShadow: "none",
                    background: theme.colors.gray[50],
                  },
                }),
                valueContainer: (_, state) => ({
                  ..._,

                  color: state.hasValue ? theme.colors.primary[700] : "#aaa",
                  padding: 0,
                  fontSize: "1.000rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }),
              }}
              theme={getSelectTheme({ theme })}
              size={28}
            />
          </FormControl>
          <FormControl d="flex">
            <Tooltip label={getTexts("AssignedTo")}>
              <Box>
                <Box
                  mt={2}
                  mr={2}
                  as={FaUser}
                  fontSize="md"
                  fontWeight="bold"
                  color="primary.500"
                />
              </Box>
            </Tooltip>
            <Select
              placeholder={getTexts("AssignedTo")}
              isMulti={true}
              options={[
                {
                  label: getTexts("Agents"),
                  options: Object.values(agents),
                },
                {
                  label: getTexts("Team"),
                  options: Object.values(teams),
                },
              ]}
              components={{
                MultiValueLabel: props => (
                  <Flex pl={2} alignItems="center">
                    {props?.data?.userId ? (
                      <FaUser mr={1} />
                    ) : (
                      <FaUsers mr={1} />
                    )}
                    <components.MultiValueLabel {...props} />
                  </Flex>
                ),
              }}
              getOptionValue={({ userId, id }) => userId ?? id}
              getOptionLabel={({ displayName, name }) => displayName ?? name}
              styles={{
                ...getSelectStyle({ theme }),
                indicatorsContainer: _ => ({
                  display: "none",
                }),
                placeholder: _ => ({ width: "90%" }),
                control: _ => ({
                  ..._,
                  textAlign: "right",
                  borderWidth: 0,
                  minHeight: 28,
                  padding: 0,
                  "&:hover, &:active": {
                    borderWidth: 0,
                    background: theme.colors.gray[50],
                  },
                }),
                valueContainer: (_, state) => ({
                  ..._,

                  color: state.hasValue ? theme.colors.primary[700] : "#aaa",
                  padding: 0,
                  fontSize: "1.000rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }),
              }}
              theme={getSelectTheme({ theme })}
              value={[
                ...(conversation?.assignedAgent ?? []).map(
                  ({ id }) => agents?.[id]
                ),
                ...(conversation?.team ?? []).map(id => teams?.[id]),
              ]}
              size={28}
              onChange={updateConversationUser}
            ></Select>
          </FormControl>
          {(!!conversation?.customerRating?.score ||
            !!conversation?.customerRating?.comment ||
            !!conversation?.agentRating?.score ||
            !!conversation?.agentRating?.comment) && (
            <Stat mt={4} bg="secondary.50" px={4} py={2} borderRadius={4}>
              <StatLabel mb={2} fontWeight="bold">
                {getTexts("ConversationEvaluation")}
              </StatLabel>
              <Stack spacing={2}>
                {(conversation?.customerRating?.score ||
                  conversation?.customerRating?.comment) && (
                  <Box>
                    <Flex alignItems="center">
                      <StatLabel flex={1}>
                        {getTexts("CustomerRatings")}
                      </StatLabel>
                      {conversation?.customerRating?.score && (
                        <StatNumber color="secondary.500">
                          {conversation?.customerRating?.score}分
                        </StatNumber>
                      )}
                    </Flex>
                    {conversation?.customerRating?.comment && (
                      <StatHelpText mt={2}>
                        {conversation?.customerRating?.comment}
                      </StatHelpText>
                    )}
                  </Box>
                )}
                {(conversation?.agentRating?.score ||
                  conversation?.agentRating?.comment) && (
                  <Box>
                    <Flex alignItems="center">
                      <StatLabel flex={1}>{getTexts("AgentsScore")}</StatLabel>
                      {conversation?.agentRating?.score && (
                        <StatNumber color="secondary.500">
                          {conversation?.agentRating?.score}分
                        </StatNumber>
                      )}
                    </Flex>
                    {conversation?.agentRating?.comment && (
                      <StatHelpText mt={2}>
                        {conversation?.agentRating?.comment}
                      </StatHelpText>
                    )}
                  </Box>
                )}
              </Stack>
            </Stat>
          )}
        </AccordionPanel>
      </AccordionItem>
    ),
    [
      agents,
      conversation?.agentRating?.comment,
      conversation?.agentRating?.score,
      conversation?.assignedAgent,
      conversation?.customerRating?.comment,
      conversation?.customerRating?.score,
      conversation?.tag,
      conversation?.team,
      conversationTagOptions,
      customer?.whatsappId,
      getTexts,
      teams,
      theme,
      updateConversationTag,
      updateConversationUser,
      updatingConversation,
    ]
  );

  const CustomerAccordion = useMemo(
    () => (
      <AccordionItem>
        <AccordionButton
          pt={4}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
          align="center"
        >
          <Box
            as={FaUserEdit}
            fontSize="lg"
            fontWeight="bold"
            color="primary.500"
          />
          <Input
            mx={2}
            pl={2}
            flex={1}
            minW={0}
            w="100%"
            fontWeight="bold"
            fontSize="lg"
            fontFamily="Roboto Slab"
            focusBorderColor="transparent"
            borderColor="transparent"
            _hover={{ bg: "gray.50" }}
            size="md"
            name="customer.displayName"
            ref={register}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onBlur={e => updateCustomer({ displayName: e.target.value })}
          />
          {updatingCustomer && <Spinner mx={2} size="sm" color="primary.500" />}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {profileForm?.map(({ name, label, type }) => (
            <FormControl key={name} d="flex" alignItems="center">
              <Tooltip
                bg="error"
                isOpen={!!errors?.customer?.[name]?.message}
                placement="left"
                label={errors?.customer?.[name]?.message}
              >
                <Text mr={1} fontSize="md" color="#999" fontWeight="bold">
                  {label}
                </Text>
              </Tooltip>
              <Box flex={1}>
                {(() => {
                  switch (type) {
                    case "select":
                      return (
                        <Controller
                          control={control}
                          name={`customer.${name}`}
                          defaultValue={[]}
                          render={({ value, name }) => {
                            const options = [
                              ...new Set([...customerTags, ...(value ?? [])]),
                            ]?.map(tag => ({
                              label: tag,
                              value: tag,
                            }));
                            return (
                              <CreatableSelect
                                isMulti={true}
                                placeholder={getTexts("Tags")}
                                options={options}
                                onChange={updateCustomerTag}
                                value={options.filter(x =>
                                  (value ?? []).includes(x.value)
                                )}
                                name={name}
                                styles={{
                                  ...getSelectStyle({ theme }),
                                  indicatorsContainer: _ => ({
                                    display: "none",
                                  }),
                                  placeholder: _ => ({ width: "90%" }),
                                  control: _ => ({
                                    ..._,
                                    textAlign: "right",
                                    borderColor: "transparent",
                                    minHeight: 28,
                                    padding: 0,
                                    borderWidth: 0,
                                    "&:hover, &:active": {
                                      borderWidth: 0,
                                      boxShadow: "none",
                                      background: theme.colors.gray[50],
                                    },
                                  }),
                                  valueContainer: (_, state) => ({
                                    ..._,

                                    color: state.hasValue
                                      ? theme.colors.primary[700]
                                      : "#aaa",
                                    padding: 0,
                                    fontSize: "1.000rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }),
                                }}
                                theme={getSelectTheme({ theme })}
                                size={28}
                              />
                            );
                          }}
                        />
                      );
                    default:
                      return (
                        <Input
                          px={1}
                          name={`customer.${name}`}
                          ref={register({
                            pattern: (() => {
                              switch (type) {
                                case "email":
                                  return {
                                    value: emailRegex,
                                    message: getTexts("PleaseEnterAValidEmail"),
                                  };
                                case "url":
                                  return {
                                    value: urlRegex,
                                    message: getTexts("PleaseEnterAValidUrl"),
                                  };
                                case "text":
                                case "longText":
                                case "phone":
                                case "address":
                                case "anniversary":
                                case "date":
                                  return {};
                                default:
                              }
                            })(),
                          })}
                          onFocus={e => (currEditRef.current = e.target)}
                          onBlur={async () => {
                            const result = await trigger(`customer.${name}`);
                            if (result) {
                              updateCustomer({
                                [name]: currEditRef.current.value,
                              });
                              currEditRef.current = null;
                            } else {
                              currEditRef.current.focus();
                            }
                          }}
                          placeholder={getTexts("Unfilled")}
                          fontSize="md"
                          size="sm"
                          _placeholder={{ textAlign: "right" }}
                          textAlign="right"
                          _hover={{
                            borderColor: "transparent",
                            bg: "gray.50",
                          }}
                          focusBorderColor="transparent"
                          borderColor="transparent"
                        />
                      );
                  }
                })()}
              </Box>
            </FormControl>
          ))}
          {showCustomerCustomField &&
            (appConfig?.customerFields ?? [])?.map(({ id, name, type }) => (
              <FormControl key={id} d="flex">
                <Tooltip
                  bg="error"
                  isOpen={!!errors?.customer?.fields?.[id]?.value?.message}
                  placement="left"
                  label={errors?.customer?.fields?.[id]?.value?.message}
                >
                  <Text
                    mt={1}
                    mr={1}
                    fontSize="md"
                    color="#999"
                    fontWeight="bold"
                  >
                    {getTextByLang(name)}
                  </Text>
                </Tooltip>
                <Box flex={1}>
                  {(() => {
                    switch (type) {
                      case "date":
                        return (
                          <Box paddingLeft="30px">
                            <Controller
                              control={control}
                              name={`customer.fields.${id}.value`}
                              render={({ onChange, value, name, ref }) => (
                                <DatePickerInput
                                  placement="bottom-start"
                                  showSelectableDays={false}
                                  name={name}
                                  placeholder={getTexts("SelectDate")}
                                  size="sm"
                                  format="YYYY-MM-DD"
                                  value={value ? dayjs(value) : null}
                                  onChange={(date, dateString) =>
                                    onChange(dateString)
                                  }
                                  ref={ref}
                                  onFocus={e =>
                                    (currEditRef.current = e.target)
                                  }
                                  onBlur={async () => {
                                    const result = await trigger(
                                      `customer.fields.${id}.value`
                                    );
                                    if (result) {
                                      updateCustomer({
                                        fields: {
                                          ...customer?.fields,
                                          [id]: {
                                            value: currEditRef.current.value,
                                          },
                                        },
                                      });
                                      currEditRef.current = null;
                                    } else {
                                      currEditRef.current.focus();
                                    }
                                  }}
                                  onClear={(date, dateString) =>
                                    updateCustomer({
                                      fields: {
                                        ...customer?.fields,
                                        [id]: {
                                          value: dateString,
                                        },
                                      },
                                    })
                                  }
                                />
                              )}
                            />
                          </Box>
                        );
                      case "anniversary":
                        return (
                          <Box paddingLeft="30px">
                            <Controller
                              control={control}
                              name={`customer.fields.${id}.value`}
                              render={({ onChange, value, name, ref }) => (
                                <DatePickerInput
                                  placement="bottom-start"
                                  picker="anniversary"
                                  showSelectableDays={false}
                                  name={name}
                                  placeholder={getTexts("SelectDate")}
                                  size="sm"
                                  format="MM-DD"
                                  value={value ? dayjs(value) : null}
                                  onChange={(date, dateString) =>
                                    onChange(dateString)
                                  }
                                  ref={ref}
                                  onFocus={e =>
                                    (currEditRef.current = e.target)
                                  }
                                  onBlur={async () => {
                                    const result = await trigger(
                                      `customer.fields.${id}.value`
                                    );
                                    if (result) {
                                      updateCustomer({
                                        fields: {
                                          ...customer?.fields,
                                          [id]: {
                                            value: currEditRef.current.value,
                                          },
                                        },
                                      });
                                      currEditRef.current = null;
                                    } else {
                                      currEditRef.current.focus();
                                    }
                                  }}
                                  onClear={(date, dateString) =>
                                    updateCustomer({
                                      fields: {
                                        ...customer?.fields,
                                        [id]: {
                                          value: dateString,
                                        },
                                      },
                                    })
                                  }
                                />
                              )}
                            />
                          </Box>
                        );

                      default:
                        return (
                          <Input
                            name={`customer.fields.${id}.value`}
                            px={1}
                            placeholder={getTexts("Unfilled")}
                            fontSize="md"
                            size="sm"
                            _placeholder={{ textAlign: "right" }}
                            _hover={{
                              borderColor: "transparent",
                              bg: "gray.50",
                            }}
                            textAlign="right"
                            focusBorderColor="transparent"
                            borderColor="transparent"
                            ref={register({
                              pattern: (() => {
                                switch (type) {
                                  case "email":
                                    return {
                                      value: emailRegex,
                                      message: getTexts(
                                        "PleaseEnterAValidEmail"
                                      ),
                                    };
                                  case "url":
                                    return {
                                      value: urlRegex,
                                      message: getTexts("PleaseEnterAValidUrl"),
                                    };
                                  case "text":
                                  case "longText":
                                  case "phone":
                                  case "address":
                                  case "anniversary":
                                  case "date":
                                    return {};
                                  default:
                                }
                              })(),
                            })}
                            onFocus={e => (currEditRef.current = e.target)}
                            onBlur={async () => {
                              const result = await trigger(
                                `customer.fields.${id}.value`
                              );
                              if (result) {
                                updateCustomer({
                                  fields: {
                                    ...customer?.fields,
                                    [id]: {
                                      value: currEditRef.current.value,
                                    },
                                  },
                                });
                                currEditRef.current = null;
                              } else {
                                currEditRef.current.focus();
                              }
                            }}
                          />
                        );
                    }
                  })()}
                </Box>
              </FormControl>
            ))}
          <Flex alignItems="center" mt={3}>
            <Checkbox
              flex={1}
              minW={0}
              w="100%"
              colorScheme="primary"
              value={showCustomerCustomField}
              onChange={e => setShowCustomerCustomField(e.target.checked)}
            >
              {getTexts("ShowCustomFields")}
            </Checkbox>
            <Link to={`/customers/${id}`}>
              <Button variant="ghost" colorScheme="secondary" size="sm">
                {getTexts("ViewCustomerProfile")}
              </Button>
            </Link>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    ),
    [
      appConfig?.customerFields,
      control,
      customer?.fields,
      customerTags,
      errors?.customer,
      getTextByLang,
      getTexts,
      id,
      profileForm,
      register,
      showCustomerCustomField,
      theme,
      trigger,
      updateCustomer,
      updateCustomerTag,
      updatingCustomer,
    ]
  );

  const AgentAccordion = useMemo(
    () => (
      <AccordionItem>
        <AccordionButton
          pt={4}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
          align="center"
        >
          <Box
            as={FaUserEdit}
            fontSize="lg"
            fontWeight="bold"
            color="primary.500"
          />
          <Input
            mx={2}
            pl={2}
            flex={1}
            minW={0}
            w="100%"
            fontWeight="bold"
            fontSize="lg"
            fontFamily="Roboto Slab"
            focusBorderColor="transparent"
            borderColor="transparent"
            _hover={{ bg: "gray.50" }}
            size="md"
            name="agent.displayName"
            ref={register}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onBlur={e => updateAgent({ displayName: e.target.value })}
          />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {profileForm?.map(({ name, label, value, type }) => (
            <FormControl key={name} d="flex" alignItems="center">
              <Text mr={1} fontSize="md" color="#999" fontWeight="bold">
                {label}
              </Text>
              <Box flex={1}>
                {(() => {
                  switch (type) {
                    case "select":
                      return;
                    default:
                      return (
                        <Input
                          px={1}
                          name={`agent.${name}`}
                          ref={register}
                          onBlur={e => {
                            updateAgent({ [name]: e.target.value });
                          }}
                          placeholder={getTexts("Unfilled")}
                          fontSize="md"
                          size="sm"
                          _placeholder={{ textAlign: "right" }}
                          textAlign="right"
                          _hover={{
                            borderColor: "transparent",
                            bg: "gray.50",
                          }}
                          focusBorderColor="transparent"
                          borderColor="transparent"
                          value={value}
                        />
                      );
                  }
                })()}
              </Box>
            </FormControl>
          ))}
          {showAgentCustomField &&
            (appConfig?.agentFields ?? [])?.map(({ id, name, type }) => (
              <FormControl key={id} d="flex">
                <Tooltip
                  bg="error"
                  isOpen={!!errors?.agent?.fields?.[id]?.value?.message}
                  placement="left"
                  label={errors?.agent?.fields?.[id]?.value?.message}
                >
                  <Text
                    mt={1}
                    mr={1}
                    fontSize="md"
                    color="#999"
                    fontWeight="bold"
                  >
                    {getTextByLang(name)}
                  </Text>
                </Tooltip>
                <Box flex={1}>
                  {(() => {
                    switch (type) {
                      case "date":
                        return (
                          <Box paddingLeft="30px">
                            <Controller
                              control={control}
                              name={`agent.fields.${id}.value`}
                              render={({ onChange, value, name, ref }) => (
                                <DatePickerInput
                                  showSelectableDays={false}
                                  name={name}
                                  placeholder={getTexts("SelectDate")}
                                  size="sm"
                                  format="YYYY-MM-DD"
                                  value={value ? dayjs(value) : null}
                                  onChange={(date, dateString) =>
                                    onChange(dateString)
                                  }
                                  ref={ref}
                                  onFocus={e =>
                                    (currEditRef.current = e.target)
                                  }
                                  onBlur={async () => {
                                    const result = await trigger(
                                      `agent.fields.${id}.value`
                                    );
                                    if (result) {
                                      updateAgent({
                                        fields: {
                                          ...agent?.fields,
                                          [id]: {
                                            value: currEditRef.current.value,
                                          },
                                        },
                                      });
                                      currEditRef.current = null;
                                    } else {
                                      currEditRef.current.focus();
                                    }
                                  }}
                                  onClear={(date, dateString) =>
                                    updateAgent({
                                      fields: {
                                        ...agent?.fields,
                                        [id]: {
                                          value: dateString,
                                        },
                                      },
                                    })
                                  }
                                />
                              )}
                            />
                          </Box>
                        );
                      case "anniversary":
                        return (
                          <Box paddingLeft="30px">
                            <Controller
                              control={control}
                              name={`agent.fields.${id}.value`}
                              render={({ onChange, value, name, ref }) => (
                                <DatePickerInput
                                  picker="anniversary"
                                  showSelectableDays={false}
                                  name={name}
                                  placeholder={getTexts("SelectDate")}
                                  size="sm"
                                  format="MM-DD"
                                  value={value ? dayjs(value) : null}
                                  onChange={(date, dateString) =>
                                    onChange(dateString)
                                  }
                                  ref={ref}
                                  onFocus={e =>
                                    (currEditRef.current = e.target)
                                  }
                                  onBlur={async () => {
                                    const result = await trigger(
                                      `agent.fields.${id}.value`
                                    );
                                    if (result) {
                                      updateAgent({
                                        fields: {
                                          ...agent?.fields,
                                          [id]: {
                                            value: currEditRef.current.value,
                                          },
                                        },
                                      });
                                      currEditRef.current = null;
                                    } else {
                                      currEditRef.current.focus();
                                    }
                                  }}
                                  onClear={(date, dateString) =>
                                    updateAgent({
                                      fields: {
                                        ...agent?.fields,
                                        [id]: {
                                          value: dateString,
                                        },
                                      },
                                    })
                                  }
                                />
                              )}
                            />
                          </Box>
                        );

                      default:
                        return (
                          <Input
                            name={`agent.fields.${id}.value`}
                            px={1}
                            placeholder={getTexts("Unfilled")}
                            fontSize="md"
                            size="sm"
                            _placeholder={{ textAlign: "right" }}
                            _hover={{
                              borderColor: "transparent",
                              bg: "gray.50",
                            }}
                            textAlign="right"
                            focusBorderColor="transparent"
                            borderColor="transparent"
                            ref={register({
                              pattern: (() => {
                                switch (type) {
                                  case "email":
                                    return {
                                      value: emailRegex,
                                      message: getTexts(
                                        "PleaseEnterAValidEmail"
                                      ),
                                    };
                                  case "url":
                                    return {
                                      value: urlRegex,
                                      message: getTexts("PleaseEnterAValidUrl"),
                                    };
                                  case "text":
                                  case "longText":
                                  case "phone":
                                  case "address":
                                  case "anniversary":
                                  case "date":
                                    return {};
                                  default:
                                }
                              })(),
                            })}
                            onFocus={e => (currEditRef.current = e.target)}
                            onBlur={async () => {
                              const result = await trigger(
                                `agent.fields.${id}.value`
                              );
                              if (result) {
                                updateAgent({
                                  fields: {
                                    ...agent?.fields,
                                    [id]: {
                                      value: currEditRef.current.value,
                                    },
                                  },
                                });
                                currEditRef.current = null;
                              } else {
                                currEditRef.current.focus();
                              }
                            }}
                          />
                        );
                    }
                  })()}
                </Box>
              </FormControl>
            ))}
          <Checkbox
            mt={3}
            colorScheme="primary"
            value={showAgentCustomField}
            onChange={e => setShowAgentCustomField(e.target.checked)}
          >
            {getTexts("ShowCustomFields")}
          </Checkbox>
        </AccordionPanel>
      </AccordionItem>
    ),
    [
      agent?.fields,
      appConfig?.agentFields,
      control,
      errors?.agent?.fields,
      getTextByLang,
      register,
      showAgentCustomField,
      trigger,
      updateAgent,
      getTexts,
      profileForm,
    ]
  );

  const RecentConversationAccordion = useMemo(
    () => (
      <AccordionItem>
        <AccordionButton
          pt={4}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <Text
            textAlign="left"
            flex={1}
            fontWeight="bold"
            fontSize="lg"
            fontFamily="Roboto Slab"
          >
            {currentLangKey === "en"
              ? `${getTexts("Recents")} ${customer?.displayName}`
              : `${getTexts("Recents")} ${customer?.displayName} ${getTexts(
                  "Conversation"
                )}`}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {relatedConversations.length === 0 ? (
            <Box
              m={2}
              color="#999"
              alignSelf="center"
              justify="center"
              textAlign="center"
            >
              {getTexts("NoRelatedConversation")}
            </Box>
          ) : (
            relatedConversations
              .slice(0, Math.min(3, relatedConversations?.length))
              .map(({ id, ...conversation }) => {
                return (
                  <Box
                    key={id}
                    _hover={{
                      bg: conversationId === id ? "primary.50" : "#f1f1f1",
                    }}
                    bg={conversationId === id ? "primary.50" : "transparent"}
                  >
                    <Link to={`/conversation/${id}`}>
                      <Flex
                        cursor="pointer"
                        alignSelf="stretch"
                        p={4}
                        flexDirection="column"
                        key={id}
                      >
                        <Flex align="center">
                          <BadgeIndicator
                            ml={-2}
                            enable={conversation?.online}
                            onColor="#2aa92a"
                            offColor="gray.300"
                            onLabel={getTexts("Online")}
                            offLabel={getTexts("Offline")}
                          />
                          <Flex flex={1} align="center">
                            <Text
                              fontWeight="bold"
                              color="gray.700"
                              mr={1}
                              fontSize="md"
                              fontFamily="Roboto"
                            >
                              {conversation?.name}
                            </Text>
                            <SourceTag source={conversation?.source} />
                          </Flex>
                          <Box color="#bbb" fontSize="80%">
                            {humanifyDate(
                              conversation.orderTimestamp,
                              currentLangKey
                            )}
                          </Box>
                        </Flex>
                        <Flex mr={1} mt={1} align="center">
                          {getLastMessageDisplay(
                            conversation,
                            session?.user?.userId
                          )}

                          <BadgeIndicator
                            ml={-2}
                            enable={hasUnread(
                              conversation,
                              session?.user?.userId
                            )}
                            onColor="secondary.500"
                            offColor="transparent"
                            onLabel={getTexts("Unread")}
                          />
                        </Flex>
                      </Flex>
                    </Link>
                  </Box>
                );
              })
          )}
          <Flex w="100%" justify="center">
            <Button
              onClick={() =>
                history.replace({
                  ...location,
                  state: {
                    searchParams: mergeSearchParams({ customerId: id }),
                  },
                })
              }
              mt={3}
              colorScheme="primary"
              variant="link"
            >
              {getTexts("FindMore")}
            </Button>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    ),
    [
      conversationId,
      customer?.displayName,
      getLastMessageDisplay,
      hasUnread,
      history,
      id,
      location,
      currentLangKey,
      getTexts,
      relatedConversations,
      session?.user?.userId,
    ]
  );

  const NotesAccordion = useMemo(
    () => (
      <AccordionItem>
        <AccordionButton
          pt={4}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <Text
            textAlign="left"
            flex={1}
            fontWeight="bold"
            fontSize="lg"
            fontFamily="Roboto Slab"
          >
            {currentLangKey === "en"
              ? `${getTexts("Related")} ${customer?.displayName}`
              : `${getTexts("Related")} ${customer?.displayName} ${getTexts(
                  "SNotes"
                )}`}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Input
            w="100%"
            focusBorderColor="primary.400"
            p={2}
            variant="flushed"
            placeholder={getTexts("FillInTheMemo")}
            onKeyDown={e => {
              if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
                addNote(e);
                e.preventDefault();
              }
            }}
          ></Input>
          {(customer?.notes ?? []).length === 0 ? (
            <Box
              m={2}
              color="#999"
              alignSelf="center"
              justify="center"
              textAlign="center"
            >
              {getTexts("NoMemos")}
            </Box>
          ) : (
            (customer?.notes ?? [])
              .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
              .map(note => {
                return (
                  <Box mt={2} key={note?.id} bg="secondary.50">
                    <Flex
                      alignSelf="stretch"
                      p={4}
                      flexDirection="column"
                      key={id}
                    >
                      <Flex align="center">
                        <Flex flex={1} align="center">
                          <Text
                            fontWeight="bold"
                            color="gray.700"
                            mr={1}
                            fontSize="md"
                            fontFamily="Roboto"
                          >
                            {agents?.[note?.userId]?.displayName}
                          </Text>
                        </Flex>
                        <Box color="#bbb" fontSize="80%">
                          {humanifyDate(note?.timestamp, currentLangKey)}
                        </Box>
                      </Flex>
                      <Flex mr={1} mt={1} align="center">
                        {note?.content}
                      </Flex>
                    </Flex>
                  </Box>
                );
              })
          )}
        </AccordionPanel>
      </AccordionItem>
    ),
    [
      addNote,
      agents,
      currentLangKey,
      customer?.displayName,
      customer?.notes,
      getTexts,
      id,
    ]
  );

  return (
    <Box
      minW={280}
      maxW={280}
      w={280}
      h="100%"
      overflow="auto"
      borderLeftStyle="solid"
      borderLeftWidth={1}
      borderLeftColor="gray.100"
      {...(!id && { display: "none" })}
    >
      <Accordion reduceMotion allowMultiple defaultIndex={[0, 1, 2, 3]}>
        {ConversationAccordion}
        {customer && CustomerAccordion}
        {agent && AgentAccordion}
        {RecentConversationAccordion}
        {NotesAccordion}
      </Accordion>
    </Box>
  );
};

export default Detail;
