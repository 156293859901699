import { useCallback, useRef, useState } from "react";
import { createContainer } from "unstated-next";

import api from "@projectg/utils/utils/api";

export const ConnectionStatusTypes = {
  INITIAL: "initial",
  PROCESSING: "processing",
  ERROR: "error",
  SUCCESS: "success",
  PENDING_RETRY: "pending_retry",
};

const useWebSocket = () => {
  const _socket = useRef(null);
  const _observers = useRef({});
  const connect = useCallback((url, options) => {
    return new Promise((resolve, reject) => {
      const socket = new window.WebSocket(url, options);
      socket.onopen = function (event) {
        resolve(_socket.current);
        _socket.current = socket;
      };
      socket.onclose = function (event) {
        reject(event);
      };
      socket.onerror = function (event) {
        reject(event);
      };
      socket.onmessage = function (event) {
        Object.values(_observers.current).forEach(receiver => receiver(event));
      };
    });
  }, []);

  const send = useCallback(data => {
    if (_socket.current && _socket.current.readyState === WebSocket.OPEN) {
      _socket.current.send(
        typeof data === "string" ? data : JSON.stringify(data)
      );
    }
  }, []);

  const addEventListener = useCallback((name, callback) => {
    _observers.current[name] = callback;
  }, []);

  const removeEventListener = useCallback(name => {
    delete _observers.current[name];
  }, []);

  const sendAndWait = useCallback(
    (data, waitFor, timeout = 10000) => {
      return new Promise((resolve, reject) => {
        const _timeoutHandler = () => {
          removeEventListener("handshake");
          clearTimeout(_timeoutHandler);
          reject("No response");
        };
        addEventListener("handshake", event => {
          if (waitFor(event)) {
            resolve(event);
            removeEventListener("handshake");
          }
        });
        setTimeout(_timeoutHandler, timeout);
        send(data);
      });
    },
    [removeEventListener, addEventListener, send]
  );

  const disconnect = useCallback(() => {
    if (_socket.current && _socket.current.readyState === WebSocket.OPEN) {
      _socket.current.close();
    }
  }, []);

  return {
    connect,
    addEventListener,
    removeEventListener,
    send,
    sendAndWait,
    disconnect,
  };
};

const SocketContainer = createContainer(() => {
  const {
    addEventListener,
    removeEventListener,
    sendAndWait,
    send,
    connect,
    disconnect,
  } = useWebSocket();

  const [connectionStatus, setConnectionStatus] = useState(
    ConnectionStatusTypes.INITIAL
  );

  const destroy = useCallback(
    (passive = true) => {
      setConnectionStatus(
        passive
          ? ConnectionStatusTypes.INITIAL
          : ConnectionStatusTypes.PENDING_RETRY
      );
      if (pingTimer.current) {
        clearInterval(pingTimer.current);
        pingTimer.current = null;
      }
      disconnect();
    },
    [disconnect, setConnectionStatus]
  );

  const pingTimer = useRef(null);
  // const _mockCount = useRef(0);
  const startPingPong = useCallback(() => {
    const _ping = async () => {
      try {
        if (pingTimer.current) {
          await sendAndWait("ping", event => {
            return event.data === "pong";
          });
          pingTimer.current = setTimeout(_triggerPong, 20000);
        }
      } catch (error) {
        destroy(false);
      }
    };

    const _triggerPong = () => {
      pingTimer.current = setTimeout(_ping, 20000);
    };

    _triggerPong();
  }, [sendAndWait, destroy]);

  const init = useCallback(
    async ({ udId, credential, clientType }) => {
      try {
        setConnectionStatus(ConnectionStatusTypes.PROCESSING);

        const {
          data: { connectionList },
        } = await api.get({
          url: `/api/connector`,
          params: {
            clientType,
          },
        });

        const connectors = connectionList.map(
          x => `wss://${x.host}:${x.port}/websocket/connect`
        );

        if (connectors && connectors.length > 0) {
          let index = 0;

          let connected = false;
          const _handshakeWaitFor = event => {
            try {
              const { handshake = 1 } = JSON.parse(event.data);
              return handshake.status === 0;
            } catch (error) {
              return false;
            }
          };
          while (!connected && index < connectors.length) {
            const url = connectors[index];
            try {
              await connect(url);
              const handshakeData = {
                handshake: {
                  appKey: credential.apiKey,
                  token: credential.token,
                  udId: udId,
                  clientType,
                  version: "0.0.1",
                  lang: "zh_HK",
                  getUpdates: false,
                },
              };
              await sendAndWait(handshakeData, _handshakeWaitFor);
              connected = true;
              setConnectionStatus(ConnectionStatusTypes.SUCCESS);
            } catch (error) {
              index++;
            }
          }
          if (connected) {
            startPingPong();
            return true;
          } else {
            throw new Error("Failed to connect socket");
          }
        }
      } catch (error) {
        setConnectionStatus(ConnectionStatusTypes.ERROR);
      }
    },
    [connect, sendAndWait, startPingPong]
  );

  return {
    init,
    send,
    sendAndWait,
    destroy,
    connectionStatus,
    subscribe: addEventListener,
    unsubscribe: removeEventListener,
  };
});

export default SocketContainer;
