import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { BiUnlink } from "react-icons/bi";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import { AlertModal } from "../../../../components/components";

const ImportantText = chakra("span", {
  baseStyle: {
    fontWeight: "bold",
  },
});

const facebookTagsOptions = [
  {
    value: "CONFIRMED_EVENT_UPDATE",
    data: {
      tag: "CONFIRMED_EVENT_UPDATE",
      name: "Confirmed Event Update",
      description:
        "Send the user reminders or updates for an event they have registered for",
    },
    label: "Confirmed Event Update",
  },
  {
    value: "POST_PURCHASE_UPDATE",
    data: {
      tag: "POST_PURCHASE_UPDATE",
      name: "Post Purchase Update",
      description: "Notify the user of an update on a recent purchase",
    },
    label: "Post Purchase Update",
  },
  {
    value: "ACCOUNT_UPDATE",
    data: {
      tag: "ACCOUNT_UPDATE",
      name: "Account Update",
      description:
        "Notify the user of a non-recurring change to their application or account",
    },
    label: "Account Update",
  },
  {
    value: "HUMAN_AGENT",
    data: {
      tag: "HUMAN_AGENT",
      name: "Human Agent",
      description: "Allows human agents to respond to user inquiries",
    },
    label: "Human Agent",
  },
];

const UpsertFacebookPageDrawer = ({ isOpen, onClose, params }) => {
  const toast = useToast();
  const { getTexts, setExternalList } = AppContainer.useContainer();

  const isEdit = params?.page?.id ? true : false;

  const removeFacebookPageAlert = useDisclosure();
  const [
    removeFacebookPageIsLoading,
    setRemoveFacebookPageIsLoading,
  ] = useState(false);

  const [
    upsertFacebookPageIsLoading,
    setUpsertFacebookPageIsLoading,
  ] = useState(false);

  const cancelRef = React.useRef();

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...params.page,
      tags: params.page?.tags?.map(item => ({
        value: item.tag,
        label: item.name,
        data: item,
      })),
    },
  });

  const onSubmit = async data => {
    try {
      setUpsertFacebookPageIsLoading(true);
      const { data: resData } = await api.post({
        url: "/api/admin/setting/externalPlatform/facebook/page/updateOrCreate",
        body: {
          id: params.platform.id,
          pageId: params?.page?.id,
          ...data,
          tags: data.tags.map(option => ({ ...option.data })),
        },
      });
      setExternalList(resData);
      toast({
        title: isEdit
          ? getTexts("FacebookPageUpdated")
          : getTexts("FacebookPageConnected"),
        status: "success",
      });
      onClose();
    } catch (err) {
    } finally {
      setUpsertFacebookPageIsLoading(false);
    }
  };

  const onRemoveFacebookPage = async () => {
    if (params?.page?.id)
      try {
        setRemoveFacebookPageIsLoading(true);
        const { data } = await api.post({
          url: "/api/admin/setting/externalPlatform/facebook/page/delete",
          body: {
            id: params.platform.id,
            pageId: params.page.id,
          },
        });
        setExternalList(data);
        removeFacebookPageAlert.onClose();
        toast({
          title: getTexts("FacebookPageRemoved"),
          status: "success",
        });
        onClose();
      } catch (err) {
      } finally {
        setRemoveFacebookPageIsLoading(false);
      }
  };

  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <AlertModal
        isOpen={removeFacebookPageAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeFacebookPageAlert.onClose}
        isLoading={removeFacebookPageIsLoading}
        onSubmit={onRemoveFacebookPage}
        title={getTexts("FacebookPageRemove")}
      />

      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEdit
              ? getTexts("FacebookPageUpdate")
              : getTexts("FacebookPageConnect")}
          </DrawerHeader>

          <DrawerBody>
            <List spacing={8}>
              <ListItem>
                <FormControl isRequired isInvalid={errors.name?.message}>
                  <FormLabel htmlFor="name">{getTexts("PageName")}</FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "PageName"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
              {!isEdit && (
                <ListItem>
                  <FormControl isRequired isInvalid={errors.pageId?.message}>
                    <FormLabel htmlFor="pageId">{getTexts("PageId")}</FormLabel>
                    <Controller
                      as={<Input />}
                      control={control}
                      name="pageId"
                      defaultValue=""
                      rules={{
                        required: `${getTexts("PleaseInput")} ${getTexts(
                          "PageId"
                        )}`,
                      }}
                    />
                    <FormErrorMessage>
                      {errors.pageId?.message}
                    </FormErrorMessage>
                  </FormControl>
                </ListItem>
              )}
              <ListItem>
                <FormControl isRequired isInvalid={errors.accessToken?.message}>
                  <FormLabel htmlFor="name">
                    {getTexts("AccessToken")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="accessToken"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "AccessToken"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.accessToken?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isInvalid={errors.responseWindowHours?.message}>
                  <FormLabel htmlFor="responseWindowHours">
                    {getTexts("ResponseWindowHours")}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="responseWindowHours"
                    defaultValue={24}
                    render={({ name, value, onChange }) => (
                      <NumberInput
                        name={name}
                        value={value}
                        onChange={v => onChange(Math.round(v))}
                        inputMode="numeric"
                        precision={0}
                        min={0}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  <FormErrorMessage>
                    {errors.responseWindowHours?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isInvalid={errors.tags?.message}>
                  <FormLabel htmlFor="name">{getTexts("Tags")}</FormLabel>
                  <Controller
                    as={
                      <Select
                        isMulti
                        options={facebookTagsOptions}
                        placeholder=""
                      />
                    }
                    control={control}
                    name="tags"
                    defaultValue={[]}
                  />

                  <FormErrorMessage>{errors.tags?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
            </List>
            <Text mt={4} fontSize="sm" fontStyle="italic" color="gray.400">
              {`${getTexts("FacebookSetupS4b7")} `}
              <ImportantText>{`${getTexts(
                "ResponseWindowHours"
              )}`}</ImportantText>
              {` ${getTexts("FacebookSetupS4b8")} `}
              <ImportantText>{`${getTexts("Tags")}`}</ImportantText>
              {` ${getTexts("FacebookSetupS4b9")}`}
            </Text>
          </DrawerBody>

          <DrawerFooter>
            <Flex mt={8} w="100%">
              <Box flex={1} minW={0} w="100%">
                {params?.page && (
                  <Button
                    leftIcon={<BiUnlink />}
                    isLoading={removeFacebookPageIsLoading}
                    colorScheme="red"
                    onClick={removeFacebookPageAlert.onOpen}
                  >
                    {getTexts("FacebookPageRemove")}
                  </Button>
                )}
              </Box>
              <Stack direction="row">
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {getTexts("Cancel")}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={upsertFacebookPageIsLoading}
                >
                  {isEdit ? getTexts("Update") : getTexts("Create")}
                </Button>
              </Stack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UpsertFacebookPageDrawer;
