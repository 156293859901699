import {
  Box,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import AppContainer from "@projectg/utils/store/app";
import dayjs from "dayjs";
import React from "react";

import MessengerContainer from "../store/messenger";

const transparentCheckboardBg = {
  // background:
  //   "-webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white",
  // background:
  //   "-moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 00980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white",
  background:
    "linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white",
  backgroundRepeat: "repeat, repeat",
  backgroundPosition: "0px 0px, 5px 5px",
  WebkitTransformOrigin: "0 0 0",
  transformOrigin: "0 0 0",
  WebkitBackgroundOrigin: "padding-box, padding-box",
  backgroundOrigin: "padding-box, padding-box",
  WebkitBackgroundClip: "border-box, border-box",
  backgroundClip: "border-box, border-box",
  WebkitBackgroundSize: "10px 10px, 10px 10px",
  backgroundSize: "10px 10px, 10px 10px",
  WebkitBoxShadow: "none",
  boxShadow: "none",
  textShadow: "none",
  WebkitTransition: "none",
  MozTransition: "none",
  OTransition: "none",
  transition: "none",
  WebkitTransform: "scaleX(1) scaleY(1) scaleZ(1)",
  transform: "scaleX(1) scaleY(1) scaleZ(1)",
};

const PreviewImage = ({
  src,
  size,
  file,
  message,
  onLoad,
  imgStyles,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { agents, customers } = MessengerContainer.useContainer();
  const { getTexts, currentLangKey } = AppContainer.useContainer();

  const imageProps = {
    borderRadius: 4,
    src,
    name: file?.name || "image",
  };

  const colorScheme = message?.internal ? "secondary" : "primary";

  const sender = message => {
    if (!message) {
      return getTexts("Users");
    }
    const { senderType, senderId } = message;
    switch (senderType) {
      case "agent":
        return agents[senderId]?.displayName ?? getTexts("Agents");
      case "customer":
        return customers[senderId]?.displayName ?? getTexts("Customers");
      default:
        return "";
    }
  };
  return (
    <>
      <Modal isCentered {...{ isOpen, onClose }}>
        <ModalOverlay bg="rgba(0,0,0,0.8)" />
        <ModalContent
          minW="25%"
          maxW="unset"
          w="unset"
          p={2}
          bg="rgba(0,0,0,0)"
          boxShadow="0"
        >
          <Flex flexDir="column" borderRadius={4} h="auto">
            <Box alignSelf="flex-end">
              <CloseButton color="white" onClick={onClose} />
            </Box>
            <Box mt={2} borderRadius={4}>
              <Image css={transparentCheckboardBg} w="100%" {...imageProps} />
            </Box>
          </Flex>
          {message && (
            <Flex
              flexDir="column"
              mt={1}
              p={1}
              borderRadius={4}
              color={`${colorScheme}.300`}
            >
              <Box w="100%" minW={0} flex={1}>
                {sender(message)}
              </Box>
              <Box fontSize="sm">
                {dayjs(message?.timestamp).format(
                  currentLangKey === "en"
                    ? "YYYY-MM-DD HH:mm:ss"
                    : "YYYY年MM月DD日 HH時mm分ss秒"
                )}
              </Box>
            </Flex>
          )}
        </ModalContent>
      </Modal>
      <Box
        position="relative"
        borderRadius={4}
        h="auto"
        boxShadow="sm"
        w={`min(100%, ${size?.width}px)`}
        cursor="pointer"
        onClick={onOpen}
        {...props}
      >
        <Box
          position="absolute"
          borderRadius={4}
          transition="all 0.4s"
          _hover={{ bg: "rgba(0,0,0,0.15)" }}
          zIndex={1}
          w="100%"
          h="100%"
        />
        <Image
          css={transparentCheckboardBg}
          onLoad={onLoad}
          {...imgStyles}
          {...imageProps}
        />
      </Box>
    </>
  );
};

export default PreviewImage;
