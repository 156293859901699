import Axios from "axios";

const _headers = {
  "g-token": null,
  "g-apiKey": null,
};
const _params = {
  clientType: null,
};

let _baseUrl = null;

export const setClientType = type => (_params["clientType"] = type);
export const getClientType = () => _params["clientType"];
export const setToken = token => (_headers["g-token"] = token);
export const getHeader = () => _headers;
export const setApiKey = apiKey => (_headers["g-apiKey"] = apiKey);
export const setBaseUrl = baseUrl => (_baseUrl = baseUrl);

const base = ({ url, params, headers }) => {
  return Axios.get(url, {
    ...(_baseUrl && { baseURL: _baseUrl }),
    params: {
      ..._params,
      ...params,
    },
    headers: {
      ...(_headers?.["g-token"] && { "g-token": _headers?.["g-token"] }),
      ...(_headers?.["g-apiKey"] && { "g-apiKey": _headers?.["g-apiKey"] }),
      ...headers,
    },
  });
};

const get = ({ url, params, headers, responseType }, meta = {}) => {
  return Axios.get(url, {
    ...(_baseUrl && { baseURL: _baseUrl }),
    params: {
      ..._params,
      ...params,
    },
    responseType,
    headers: {
      ...(_headers?.["g-token"] && { "g-token": _headers?.["g-token"] }),
      ...(_headers?.["g-apiKey"] && { "g-apiKey": _headers?.["g-apiKey"] }),
      ...headers,
    },
  })
    .then(r => (meta.getResponse ? r : r.data))
    .catch(error => {
      if (error.response && error.response.data.error) {
        throw error.response.data.error;
      } else {
        throw error;
      }
    });
};

const post = ({ url, body = {}, headers, responseType }, meta = {}) => {
  if (!(body instanceof FormData)) {
    body = Object.entries(body).reduce((formData, [name, value]) => {
      if (value instanceof Blob) {
        formData.set(name, value);
      } else if (typeof value === "object") {
        formData.set(name, JSON.stringify(value));
      } else if (Array.isArray(value)) {
        // TODO: handle if not string array
        formData.set(name, `["${JSON.stringify(value).join("", "")}"]`);
      } else {
        formData.set(name, value);
      }
      return formData;
    }, new FormData());
  }

  Object.entries(_params).forEach(([key, value]) => body.set(key, value));

  return Axios.post(url, body, {
    ...(_baseUrl && { baseURL: _baseUrl }),
    responseType,
    headers: {
      ...(_headers?.["g-token"] && { "g-token": _headers?.["g-token"] }),
      ...(_headers?.["g-apiKey"] && { "g-apiKey": _headers?.["g-apiKey"] }),
      ...headers,
    },
  })
    .then(r => (meta.getResponse ? r : r.data))
    .catch(error => {
      if (error.response && error.response.data.error) {
        throw error.response.data.error;
      } else {
        throw error;
      }
    });
};

const api = {
  base,
  get,
  post,
};

export default api;
