import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import {
  Spinner,
  Link,
  ButtonGroup,
  IconButton,
  Text,
  Box,
  Flex,
  Textarea,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  GoCommentDiscussion,
  GoFile,
  GoIssueClosed,
  GoIssueOpened,
  GoMention,
  getTexts,
  GoSearch,
  GoFileMedia,
} from "react-icons/go";
import { BiChevronDown, BiAlarmSnooze } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { ImInsertTemplate } from "react-icons/im";
import api from "@projectg/utils/utils/api";
import { unstable_batchedUpdates } from "react-dom";
import {
  MessageDataTypes,
  MessageMetaTypes,
} from "@projectg/utils/utils/constants";
import {
  useGetPixelToBottom,
  useIsMounted,
  useMemoRef,
  useScrollToBottom,
} from "@projectg/utils/utils/hooks";
import {
  downloadBlob,
  createFileMessage,
  createTextMessage,
  createTemplateMessage,
  throttle,
  debounce,
} from "@projectg/utils/utils/helpers";
import SocketContainer from "@projectg/utils/store/socket";
import {
  BadgeIndicator,
  InlineInputList,
  TemplateInputList,
  LiveChatIndicator,
  SourceTag,
} from "../../console-app/src/components/components";
import { DatePicker } from "../../console-app/src/components/DatePicker";
import MessengerContainer from "../store/messenger";
import AppContainer from "../store/app";
import { getSnoozeMessage } from "../../console-app/src/utils/helpers";
import InternalSpliter from "./InternalSpliter";
import Message from "./Message";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
} from "react-virtualized";
import ReplyMessage from "./ReplyMessage";
import { useLocation } from "react-router-dom";
import SearchConversationDrawer from "./SearchConversationDrawer";
import MediaLibraryDrawer from "./MediaLibraryDrawer";

const PAGE_SIZE = 20;
const MAX_PIXELS_ABOVE_LAST_MESSAGE = 100;

const InputBar = ({ sdk, conversationId: id, internal, internalVisible }) => {
  const location = useLocation();
  const inlineInputDisclosure = useDisclosure();
  const textRef = useRef();
  const {
    upsertMessage,
    upsertConversation,
    replyMeta,
    setReplyMeta,
  } = MessengerContainer.useContainer();
  const { session, getTexts } = AppContainer.useContainer();
  const { sendQueue: appendToSendQueue } = MessengerContainer.useContainer();

  const uploadInputRef = useRef(null);
  const isTypingRef = useRef(false);
  const isTypingTimer = useRef(null);
  const [sendAsInternal, setSendAsInternal] = useState(false);

  const onSendButtonClick = useCallback(() => {
    const _text = textRef?.current?.value?.trim() ?? null;
    if (!_text) return;
    const m = createTextMessage(
      id,
      session?.user?.type,
      session?.user?.userId,
      _text,
      sendAsInternal || internal,
      replyMeta
    );
    upsertMessage(id, m.id, m);
    upsertConversation(id, { orderTimestamp: m.timestamp });
    appendToSendQueue(m);
    setReplyMeta({});
    textRef.current.value = "";
  }, [
    appendToSendQueue,
    id,
    internal,
    replyMeta,
    sendAsInternal,
    session?.user?.type,
    session?.user?.userId,
    setReplyMeta,
    upsertConversation,
    upsertMessage,
  ]);

  const sendTemplateMsg = useCallback(
    template => {
      const m = createTemplateMessage(
        id,
        session?.user?.type,
        session?.user?.userId,
        sendAsInternal || internal,
        template
      );
      upsertMessage(id, m.id, m);
      upsertConversation(id, { orderTimestamp: m.timestamp });
      appendToSendQueue(m);
    },
    [
      appendToSendQueue,
      id,
      internal,
      sendAsInternal,
      session?.user?.type,
      session?.user?.userId,
      upsertConversation,
      upsertMessage,
    ]
  );

  const { activeConversationRef } = AppContainer.useContainer();
  useEffect(() => {
    activeConversationRef.current = id;
    return () => {
      activeConversationRef.current = null;
    };
  }, [activeConversationRef, id]);

  const { send: sendPacket } = SocketContainer.useContainer();
  useEffect(() => {
    const sendTypingPacket = () => {
      clearTimeout(isTypingTimer.current);
      if (isTypingRef.current) {
        sendPacket({
          dataType: MessageDataTypes.MESSAGE_DISCRETE_SYNC,
          senderId: session?.user?.userId,
          conversationId: id,
          internal,
          meta: {
            type: MessageMetaTypes.STATUS,
            status: {
              type: 1,
              time: 3000,
            },
          },
        });
      }
      isTypingTimer.current = window.setTimeout(sendTypingPacket, 3000);
    };

    clearTimeout(isTypingTimer.current);
    isTypingTimer.current = window.setTimeout(sendTypingPacket, 3000);

    return () => {
      clearTimeout(isTypingTimer.current);
    };
  }, [id, internal, sendPacket, session]);

  const onSendFileClick = useCallback(
    async e => {
      const file = e?.target?.files?.[0] ?? null;
      if (!file) return;
      const m = await createFileMessage(
        id,
        session?.user?.userId,
        file,
        internal || sendAsInternal,
        replyMeta
      );
      upsertMessage(id, m.id, m);
      upsertConversation(id, { orderTimestamp: m.timestamp });
      appendToSendQueue(m);
      setReplyMeta({});
      uploadInputRef.current.value = "";
    },
    [
      appendToSendQueue,
      id,
      internal,
      replyMeta,
      sendAsInternal,
      session?.user?.userId,
      setReplyMeta,
      upsertConversation,
      upsertMessage,
    ]
  );

  useEffect(() => {
    const textarea = textRef.current;
    const resize = target => {
      target.style.height = "inherit";
      target.style.height = `${Math.min(100, target.scrollHeight)}px`;
    };
    const onInput = e => resize(e.target);
    textarea.addEventListener("input", onInput);
    resize(textarea);
    return () => {
      textarea.removeEventListener("input", onInput);
    };
  }, []);

  useEffect(() => {
    if (location) textRef.current.value = "";
  }, [location]);

  const typingStart = useRef(
    throttle(() => {
      const { type: senderType, userId: senderId } = session?.user;
      upsertConversation(
        id,
        c => ({
          ...c,
          [senderType]: c?.[senderType]?.map(u =>
            u?.id === senderId
              ? {
                  id: senderId,
                  ___isTyping: new Date().valueOf() + 3000,
                }
              : u
          ),
        }),
        false
      );
      isTypingRef.current = true;
    }, 500)
  );
  const typingEnd = useRef(
    debounce(() => {
      const { type: senderType, userId: senderId } = session?.user;
      upsertConversation(
        id,
        c => ({
          ...c,
          [senderType]: c?.[senderType]?.map(u =>
            u?.id === senderId
              ? {
                  id: senderId,
                  ___isTyping: false,
                }
              : u
          ),
        }),
        false
      );
      isTypingRef.current = false;
    }, 500)
  );

  const allowReply = useMemo(
    () =>
      !replyMeta.id ||
      !(replyMeta?.internal === true && internal === false) ||
      sendAsInternal,
    [internal, replyMeta.id, replyMeta?.internal, sendAsInternal]
  );

  useEffect(() => {
    if (!internalVisible && !sendAsInternal && replyMeta.internal)
      setSendAsInternal(true);
  }, [internalVisible, replyMeta.internal, sendAsInternal]);

  return (
    <Box position="relative">
      <Flex
        h="100%"
        w="100%"
        position="absolute"
        bg="rgba(0,0,0,0.2)"
        {...(allowReply && {
          display: "none",
        })}
        zIndex={10}
        userSelect="none"
        pointerEvents="none"
        alignItems="center"
        justifyContent="center"
      >
        {getTexts("CannotRespondToInternalInformation")}
      </Flex>
      <Flex
        {...(!allowReply && {
          pointerEvents: "none",
        })}
        flexDir="column"
        h="100%"
        {...(sendAsInternal && !internalVisible && { bg: "secondary.50" })}
      >
        <Input
          ref={uploadInputRef}
          type="file"
          d="none"
          onChange={onSendFileClick}
        ></Input>
        <Textarea
          minH={0}
          h="100%"
          flex={1}
          bg="transparent"
          ref={textRef}
          onChange={() => {
            typingStart.current();
            typingEnd.current();
          }}
          onKeyDown={e => {
            if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
              onSendButtonClick();
              e.preventDefault();
            }
          }}
          w="100%"
          outline="none"
          border="none"
          resize="none"
          focusBorderColor="transparent"
          placeholder={getTexts("sdkInputPlaceholder")}
        ></Textarea>
        <Flex align="center" px={2} pb={1} w="100%">
          <ButtonGroup spacing={0} fontFamily="Roboto Slab">
            <IconButton
              onClick={() => {
                uploadInputRef.current.click();
              }}
              size="sm"
              fontSize="lg"
              icon={<GoFile />}
              colorScheme="primary"
              variant="ghost"
            />

            <IconButton
              size="sm"
              fontSize="lg"
              icon={<GoMention />}
              colorScheme="primary"
              variant="ghost"
            />
            {!sdk && (
              <Popover isOpen={inlineInputDisclosure.isOpen}>
                <PopoverTrigger>
                  <Box d="inline">
                    <Tooltip label={getTexts("InteractiveInformation")}>
                      <IconButton
                        onClick={inlineInputDisclosure.onOpen}
                        size="sm"
                        fontSize="lg"
                        icon={<ImInsertTemplate />}
                        colorScheme="primary"
                        variant="ghost"
                      />
                    </Tooltip>
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  overflow="auto"
                  zIndex={1}
                  h={300}
                  p={4}
                  onBlur={inlineInputDisclosure.onClose}
                >
                  <InlineInputList
                    internal={internal}
                    onChange={async ({ id: inlineInputId }) => {
                      try {
                        inlineInputDisclosure.onClose();
                        await api.post({
                          url: "/api/admin/inlineInput/trigger",
                          body: {
                            id: inlineInputId,
                            conversationId: id,
                          },
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  {!internal && (
                    <TemplateInputList
                      conversationId={id}
                      onChange={sendTemplateMsg}
                    />
                  )}
                </PopoverContent>
              </Popover>
            )}
          </ButtonGroup>
          <Box flex={1} />

          {!internalVisible && !sdk && (
            <Button
              mx={2}
              onClick={() => setSendAsInternal(v => !v)}
              isActive={sendAsInternal}
              size="sm"
              fontWeight="bold"
              fontFamily="Roboto Slab"
              colorScheme="primary"
              variant="link"
              isDisabled={replyMeta.internal}
            >
              {!sendAsInternal
                ? getTexts("SwitchToInternalCommunication")
                : getTexts("CloseInternalCommunication")}
            </Button>
          )}
          <Button
            onClick={onSendButtonClick}
            size="sm"
            fontWeight="bold"
            fontFamily="Roboto Slab"
            colorScheme="primary"
            variant="solid"
          >
            {getTexts("Send")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

const Conversation = React.memo(({ id, sdk = true }) => {
  const location = useLocation();
  const { session } = AppContainer.useContainer();
  const { messages } = MessengerContainer.useContainer();
  const {
    isOpen: snoozeIsOpen,
    onOpen: snoozeOnOpen,
    onClose: snoozeOnClose,
  } = useDisclosure();

  const {
    isOpen: searchIsOpen,
    onOpen: searchOnOpen,
    onClose: searchOnClose,
  } = useDisclosure();

  const {
    isOpen: mediaIsOpen,
    onOpen: mediaOnOpen,
    onClose: mediaOnClose,
  } = useDisclosure();

  const cache = new CellMeasurerCache({
    fixedWidth: true,
  });

  const {
    upsertMessage,
    conversations,
    upsertCustomer,
    upsertAgent,
    upsertConversation,
    replyMeta,
    setReplyMeta,
  } = MessengerContainer.useContainer();
  const { send: sendPacket } = SocketContainer.useContainer();

  const [beforeHasMore, setBeforeHasMore] = useState(false);
  const messageViewRef = useRef(null);
  const scrollToBottom = useScrollToBottom(messageViewRef);
  const getPixelsAboveLastMessage = useGetPixelToBottom(messageViewRef);

  const mounted = useIsMounted();
  const [fetching, setFetching] = useState(true);
  const [internalVisible, setInternalVisible] = useState(!sdk);

  const sortedMessages = useMemo(
    () =>
      Object.values(messages?.[id] ?? {}).sort((a, b) =>
        a.timestamp > b.timestamp ? 1 : -1
      ),
    [messages, id]
  );

  useEffect(() => {
    if (location) {
      // reset state when page change
      setReplyMeta({});
    }
  }, [location, setReplyMeta]);

  const conversation = conversations?.[id] ?? null;

  const beforeId = useMemo(() => sortedMessages?.[0]?.id ?? null, [
    sortedMessages,
  ]);
  const beforeIdRef = useMemoRef(beforeId);

  const fetchMessages = useCallback(async () => {
    try {
      setFetching(true);
      const { data: conversations } = await api.get({
        url: `/api/conversation/get`,
        params: { id },
      });

      const {
        data: { message: messages = [] },
      } = await api.get({
        url: `/api/conversation/${id}/message`,
        params: { size: PAGE_SIZE, beforeId: beforeIdRef.current },
      });

      const customers = await (async () => {
        if (sdk) return;
        try {
          const { data: _cs = [] } = await api.get({
            url: "/api/customer/get",
            params: {
              userId: conversations
                .reduce((_, c) => [..._, ...c.customer.map(c => c.id)], [])
                .join(","),
              fields: "createDate,conversations,tags,notes",
            },
          });
          return _cs;
        } catch {
          return [];
        }
      })();

      const agents = await (async () => {
        try {
          const { data: _as = [] } = await api.get({
            url: "/api/agent/get",
            params: {
              userId: conversations
                .reduce((_, c) => [..._, ...c.assignedAgent.map(c => c.id)], [])
                .join(","),
              fields: "createDate,conversations,tags,notes",
            },
          });
          return _as;
        } catch {
          return [];
        }
      })();

      unstable_batchedUpdates(() => {
        (customers ?? []).forEach(c => upsertCustomer(c?.userId, c));
        (agents ?? []).forEach(a => upsertAgent(a?.userId, a));
        conversations.forEach(c => upsertConversation(c?.id, c));
        messages.forEach(m => upsertMessage(id, m?.id, m));
        setBeforeHasMore(PAGE_SIZE === (messages?.length ?? 0));
        setFetching(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [
    beforeIdRef,
    id,
    sdk,
    upsertAgent,
    upsertConversation,
    upsertCustomer,
    upsertMessage,
  ]);

  const messageCountRef = useMemoRef(sortedMessages.length);
  useEffect(() => {
    (async () => {
      if (messageCountRef.current < PAGE_SIZE) {
        await fetchMessages();
        scrollToBottom();
      } else {
        setFetching(false);
      }
    })();
  }, [scrollToBottom, fetchMessages, messageCountRef]);

  /* handle read packet and scrolling */
  const prevLastMessage = useRef(null);
  const pixelsAboveLastMessageRef = useRef(0);
  useEffect(() => {
    const handler = () => {
      pixelsAboveLastMessageRef.current = getPixelsAboveLastMessage();
    };
    const scrollView = messageViewRef.current;
    if (scrollView && conversation) {
      scrollView.addEventListener("scroll", handler);
    }
    return () => {
      scrollView && scrollView.removeEventListener("scroll", handler);
    };
  }, [conversation, getPixelsAboveLastMessage]);
  useEffect(() => {
    if (sortedMessages && sortedMessages.length > 0) {
      let shouldSendReadPacket = false;
      let shouldScrollToBottom = false;
      const lastMessage = sortedMessages[sortedMessages.length - 1];
      if (prevLastMessage.current) {
        if (prevLastMessage.current.id !== lastMessage.id) {
          shouldSendReadPacket = true;
          if (
            pixelsAboveLastMessageRef.current < MAX_PIXELS_ABOVE_LAST_MESSAGE
          ) {
            shouldScrollToBottom = true;
          }
          if (lastMessage.senderId === session.user.userId) {
            if (lastMessage.senderType !== "autoMessage") {
              shouldScrollToBottom = true;
            }
          }
        }
      } else {
        shouldScrollToBottom = true;
      }

      if (shouldSendReadPacket) {
        const packet = {
          dataType: MessageDataTypes.MESSAGE_STATE_READ,
          id: lastMessage.id,
          conversationId: id,
        };
        // console.log("[send read packet]", packet);
        upsertConversation(id, conversation => ({
          ...conversation,
          lastRead: {
            id: lastMessage.id,
          },
        }));
        sendPacket(packet);
      }
      if (shouldScrollToBottom) {
        scrollToBottom();
      }
      prevLastMessage.current = lastMessage;
    }
  }, [
    getPixelsAboveLastMessage,
    id,
    scrollToBottom,
    sendPacket,
    session.user.userId,
    sortedMessages,
    upsertConversation,
  ]);
  /* handle read packet and scrolling */

  const onMoreMessageClick = useCallback(async () => {
    const cacheCurrentPosition = getPixelsAboveLastMessage();
    await fetchMessages();
    scrollToBottom(cacheCurrentPosition);
  }, [fetchMessages, getPixelsAboveLastMessage, scrollToBottom]);

  const updateConversation = useCallback(
    async updater => {
      try {
        if (updater.status) {
          upsertConversation(id, c => ({
            ...c,
            ...updater,
            _status: { value: c?.status, timestamp: dayjs().valueOf() },
          }));
        } else {
          upsertConversation(id, updater);
        }
        await api.post({
          url: "/api/conversation/update",
          body: {
            id,
            ...updater,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertConversation]
  );

  const downloadConversationReport = useCallback(async () => {
    try {
      const blob = await api.get({
        url: `/api/admin/conversation/message/export`,
        params: { id },
        responseType: "blob",
      });
      if (blob) {
        downloadBlob(blob);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const startLiveChat = useCallback(async () => {
    try {
      upsertConversation(id, { livechat: true }, false);
      await api.post({
        url: `/api/admin/conversation/livechat/start`,
        body: { id },
      });
    } catch (error) {
      console.error(error);
    }
  }, [id, upsertConversation]);

  const endLiveChat = useCallback(async () => {
    try {
      upsertConversation(id, { livechat: false }, false);
      await api.post({
        url: `/api/admin/conversation/livechat/end`,
        body: { id },
      });
    } catch (error) {
      console.error(error);
    }
  }, [id, upsertConversation]);

  const startSnooze = useCallback(
    async timestamp => {
      try {
        upsertConversation(id, { snooze: timestamp });
        await api.post({
          url: `/api/conversation/snooze`,
          body: {
            id,
            snooze: timestamp,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [id, upsertConversation]
  );

  const endSnooze = useCallback(async () => {
    try {
      upsertConversation(id, { snooze: 0 });
      await api.post({
        url: `/api/conversation/snooze`,
        body: {
          id,
          snooze: 0,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, upsertConversation]);
  const { getTexts, currentLangKey } = AppContainer.useContainer();

  const statusOptions = [
    { value: "open", label: getTexts("Processing"), icon: GoIssueOpened },
    { value: "closed", label: getTexts("Finished"), icon: GoIssueClosed },
    // { value: "terminated", label: getTexts("Terminate"), icon: GoStop }
  ];

  const snoozeIsBefore =
    conversation?.snooze && dayjs(conversation?.snooze).isBefore(dayjs());

  // useEffect(() => {
  //   if (filterPanelIsOpen !== undefined) cache.clearAll();
  // }, [cache, filterPanelIsOpen]);

  const snoozeSelectableDays = [
    {
      text: getTexts("ThreeHours"),
      timestamp: dayjs().add(3, "hour"),
      format: currentLangKey === "en" ? "HH:mm" : "HH時mm分",
    },
    {
      text: getTexts("TomorrowMorning"),
      timestamp: dayjs().add(1, "day").set("hour", 9).startOf("hour"),
      format: currentLangKey === "en" ? "MM/DD HH:mm" : "MM月DD日 HH時mm分",
    },
    {
      text: getTexts("MondayMorning"),
      timestamp: dayjs()
        .add(1, "week")
        .set("day", 1)
        .set("hour", 9)
        .startOf("hour"),
      format: currentLangKey === "en" ? "MM/DD HH:mm" : "MM月DD日 HH時mm分",
    },
    {
      text: getTexts("AWeek"),
      timestamp: dayjs().add(1, "week"),
      format: currentLangKey === "en" ? "MM/DD HH:mm" : "MM月DD日 HH時mm分",
    },
    {
      text: getTexts("AMonth"),
      timestamp: dayjs().add(1, "month"),
      format: currentLangKey === "en" ? "MM/DD HH:mm" : "MM月DD日 HH時mm分",
    },
  ];

  return (
    <>
      {!sdk && searchIsOpen && (
        <SearchConversationDrawer
          isOpen={searchIsOpen}
          onClose={searchOnClose}
          conversationId={id}
        />
      )}
      {!sdk && mediaIsOpen && (
        <MediaLibraryDrawer
          isOpen={mediaIsOpen}
          onClose={mediaOnClose}
          conversationId={id}
        />
      )}
      <Flex w="100%" h="100%" flexDirection="column" align="stretch">
        <Flex
          px={4}
          py={2}
          align="center"
          borderBottomStyle="solid"
          borderBottomWidth={1}
          borderBottomColor="gray.100"
          minH="48px"
          {...(sdk && { display: "none" })}
        >
          <BadgeIndicator
            ml={-2}
            enable={conversation?.online}
            onColor="#2aa92a"
            offColor="gray.300"
            onLabel={getTexts("Online")}
            offLabel={getTexts("Offline")}
          />
          <Text
            fontWeight="bold"
            color="#333"
            mr={1}
            fontSize="xl"
            fontFamily="Roboto Slab"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {conversation?.name}
          </Text>
          <SourceTag source={conversation?.source} />
          <LiveChatIndicator
            ml={4}
            livechat={conversation?.livechat}
            nameVisible={true}
          />
          {!sdk && (
            <Flex
              flex={1}
              justify="flex-end"
              ml={8}
              py={2}
              alignItems="center"
              position="relative"
              zIndex={3}
            >
              <Tooltip
                label={getTexts("SplitConversation")}
                aria-label="separate conversation"
              >
                <IconButton
                  ml={1}
                  icon={<GoCommentDiscussion />}
                  focusBorderColor="transparent"
                  _focus={{ boxShadow: "none" }}
                  variant="ghost"
                  colorScheme="primary"
                  isActive={internalVisible}
                  fontWeight="bold"
                  fontSize="2xl"
                  onClick={() => setInternalVisible(f => !f)}
                />
              </Tooltip>
              <Popover
                isOpen={snoozeIsOpen}
                onClose={snoozeOnClose}
                isLazy={true}
              >
                <PopoverTrigger>
                  <Tooltip label={getSnoozeMessage(conversation?.snooze)}>
                    <IconButton
                      mx={1}
                      icon={<BiAlarmSnooze />}
                      focusBorderColor="transparent"
                      _focus={{ boxShadow: "none" }}
                      {...(conversation?.snooze
                        ? snoozeIsBefore
                          ? { colorScheme: "red", variant: "solid" }
                          : { colorScheme: "green", variant: "solid" }
                        : { colorScheme: "primary", variant: "ghost" })}
                      fontWeight="bold"
                      fontSize="2xl"
                      onClick={snoozeOnOpen}
                    />
                  </Tooltip>
                </PopoverTrigger>
                <PopoverContent zIndex={4} minW="fit-content" w="auto">
                  <PopoverCloseButton />
                  <PopoverHeader>
                    <Text color="primary.500" fontWeight="bold">
                      {getSnoozeMessage(conversation?.snooze)}
                    </Text>
                  </PopoverHeader>
                  <PopoverBody padding="3px">
                    <DatePicker
                      defaultValue={
                        conversation?.snooze ? dayjs(conversation.snooze) : null
                      }
                      format={
                        currentLangKey === "en"
                          ? "YYYY/MM/DD HH:mm"
                          : "YYYY年MM月DD日 HH時mm分"
                      }
                      selectableDays={snoozeSelectableDays}
                      disableTimestampBefore={dayjs()}
                      showTimeSelector={true}
                      showFooterButtons={true}
                      onOk={time => {
                        snoozeOnClose();
                        startSnooze(time.valueOf());
                      }}
                      okText={
                        conversation?.snooze
                          ? getTexts("UpdateExtensionPeriod")
                          : getTexts("Snooze")
                      }
                      showCancelButton={!!conversation?.snooze}
                      onCancel={() => {
                        snoozeOnClose();
                        endSnooze();
                      }}
                      cancelText={getTexts("UnsnoozeTracking")}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <Menu placement="bottom-end">
                <MenuButton
                  as={Button}
                  rightIcon={<BiChevronDown />}
                  variant="ghost"
                  colorScheme="primary"
                  isDisabled={conversation?.status === "terminated"}
                >
                  {statusOptions.find(
                    ({ value }) => conversation?.status === value
                  )?.label ?? getTexts("Status")}
                </MenuButton>
                <MenuList>
                  {(statusOptions ?? []).map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      isDisabled={conversation?.status === value}
                      onClick={() => updateConversation({ status: value })}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  icon={<FaEllipsisV />}
                  variant="ghost"
                  colorScheme="primary"
                  isDisabled={conversation?.status === "terminated"}
                  ml={2}
                >
                  {statusOptions.find(
                    ({ value }) => conversation?.status === value
                  )?.label ?? getTexts("Status")}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={searchOnOpen}>
                    {getTexts("Search")}
                  </MenuItem>
                  <MenuItem onClick={mediaOnOpen}>
                    {getTexts("MediaLibrary")}
                  </MenuItem>
                  <MenuItem onClick={downloadConversationReport}>
                    {getTexts("DownloadTheConversationReport")}
                  </MenuItem>
                  {!conversation?.livechat ? (
                    <MenuItem onClick={startLiveChat}>
                      {getTexts("StartAPersonalConversation")}
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={endLiveChat}>
                      {getTexts("ClosePersonalConversation")}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Flex>
          )}
        </Flex>
        {/* <Flex flexDir="column" minH={0} flex={1} ref={messageViewRef}>
          <AutoSizer onResize={() => cache.clearAll()}>
            {({ height, width }) => (
              <List
                ref={conversationListRef}
                scrollToAlignment="start"
                height={height}
                width={width}
                deferredMeasurementCache={cache}
                overscanRowCount={20}
                rowCount={sortedMessages.length + 1}
                rowHeight={cache.rowHeight}
                // onRowsRendered={onRowsRendered}
                rowRenderer={({ key, parent, index, style }) => (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    rowIndex={index}
                    key={key}
                    parent={parent}
                  >
                    {({ measure, registerChild }) => {
                      const msgIndex = index - 1;
                      if (index === 0)
                        return (
                          <Box>
                            {(fetching || beforeHasMore) && (
                              <InternalSpliter
                                ref={registerChild}
                                internalVisible={internalVisible}
                                external={
                                  fetching ? (
                                    <Flex
                                      pt={4}
                                      mb={2}
                                      justify="center"
                                      minH={30}
                                    >
                                      <Spinner color="primary.400" />
                                    </Flex>
                                  ) : beforeHasMore ? (
                                    <Flex
                                      pt={4}
                                      mb={2}
                                      justify="center"
                                      minH={30}
                                    >
                                      <Link
                                        color="primary.700"
                                        alignSelf="center"
                                        onClick={onMoreMessageClick}
                                      >
                                        {getTexts("LoadMore")}
                                      </Link>
                                    </Flex>
                                  ) : null
                                }
                                internal={
                                  fetching ? (
                                    <Flex
                                      pt={4}
                                      mb={2}
                                      justify="center"
                                      minH={30}
                                    >
                                      <Spinner color="primary.400" />
                                    </Flex>
                                  ) : beforeHasMore ? (
                                    <Flex
                                      pt={4}
                                      mb={2}
                                      justify="center"
                                      minH={30}
                                    >
                                      <Link
                                        color="primary.700"
                                        alignSelf="center"
                                        onClick={onMoreMessageClick}
                                      >
                                        {getTexts("LoadMore")}
                                      </Link>
                                    </Flex>
                                  ) : null
                                }
                              />
                            )}
                          </Box>
                        );
                      else {
                        if (sortedMessages[msgIndex].deleted) return null;
                        else
                          return (
                            <Message
                              clearCellMeasurerCache={(row, col) =>
                                cache.clear(row, col)
                              }
                              ref={registerChild}
                              onLoad={measure}
                              conversationId={id}
                              style={style}
                              key={key}
                              messageDetail={{
                                m: sortedMessages[msgIndex],
                                prevMessage:
                                  sortedMessages?.[msgIndex - 1] ?? null,
                                nextMessage:
                                  sortedMessages?.[msgIndex + 1] ?? null
                              }}
                              sdk={sdk}
                              internalVisible={internalVisible}
                            />
                          );
                      }
                    }}
                  </CellMeasurer>
                )}
              />
            )}
          </AutoSizer>
          <InternalSpliter
            flex={1}
            minH={0}
            h="100%"
            internalVisible={internalVisible}
          />
        </Flex> */}
        <Flex
          flexDir="column"
          minH={0}
          flex={1}
          overflow="auto"
          ref={messageViewRef}
        >
          <Box>
            {(fetching || beforeHasMore) && (
              <InternalSpliter
                internalVisible={internalVisible}
                external={
                  fetching ? (
                    <Flex pt={4} mb={2} justify="center" minH={30}>
                      <Spinner color="primary.400" />
                    </Flex>
                  ) : beforeHasMore ? (
                    <Flex pt={4} mb={2} justify="center" minH={30}>
                      <Link
                        color="primary.700"
                        alignSelf="center"
                        onClick={onMoreMessageClick}
                      >
                        加載更多
                      </Link>
                    </Flex>
                  ) : null
                }
                internal={
                  fetching ? (
                    <Flex pt={4} mb={2} justify="center" minH={30}>
                      <Spinner color="primary.400" />
                    </Flex>
                  ) : beforeHasMore ? (
                    <Flex pt={4} mb={2} justify="center" minH={30}>
                      <Link
                        color="primary.700"
                        alignSelf="center"
                        onClick={onMoreMessageClick}
                      >
                        加載更多
                      </Link>
                    </Flex>
                  ) : null
                }
              />
            )}
            <Box {...(!mounted && { visibility: "hidden" })}>
              {sortedMessages.map((m, i, _messages) => {
                return (
                  <Message
                    key={sortedMessages[i].id}
                    clearCellMeasurerCache={(row, col) => cache.clear(row, col)}
                    conversationId={id}
                    messageDetail={{
                      m: sortedMessages[i],
                      prevMessage: sortedMessages?.[i - 1] ?? null,
                      nextMessage: sortedMessages?.[i + 1] ?? null,
                    }}
                    sdk={sdk}
                    internalVisible={internalVisible}
                  />
                );
              })}
            </Box>
          </Box>
          <InternalSpliter
            flex={1}
            minH={0}
            h="100%"
            internalVisible={internalVisible}
          />
        </Flex>
        {conversation?.status !== "terminated" && (
          <Flex flexDir="column">
            <Box
              px={2}
              pt={1}
              pb={2}
              bg="rgba(0,0,0,0.02)"
              borderY="1px"
              borderColor="gray.200"
              {...(!replyMeta?.id && { ...{ display: "none" } })}
            >
              <Flex alignItems="center" mb={1}>
                <Box flex={1}>{getTexts("Responding")}</Box>
                <IconButton
                  colorScheme="primary"
                  icon={<FaTimes />}
                  variant="ghost"
                  size="sm"
                  onClick={() => setReplyMeta({})}
                />
              </Flex>
              <ReplyMessage replyMeta={replyMeta} sdk={sdk} />
            </Box>
            <InternalSpliter
              borderTopStyle="solid"
              borderTopWidth={1}
              borderTopColor="gray.100"
              internalVisible={internalVisible}
              external={
                <InputBar
                  sdk={sdk}
                  conversationId={id}
                  internal={false}
                  internalVisible={internalVisible}
                />
              }
              internal={
                <InputBar
                  sdk={sdk}
                  conversationId={id}
                  internal={true}
                  internalVisible={internalVisible}
                />
              }
            />
          </Flex>
        )}
      </Flex>
    </>
  );
});

export default Conversation;
