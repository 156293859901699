import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Spinner,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import logo from "@projectg/utils/assets/logo.png";
import api from "@projectg/utils/utils/api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AppContainer from "@projectg/utils/store/app";

const Invitation = () => {
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [accepting, setAccepting] = useState(false);
  const [invitation, setInvitation] = useState(true);
  const [password, setPassword] = useState("");
  const { setSession, getTexts } = AppContainer.useContainer();
  const location = useLocation();
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data: invitation } = await api.get({
          url: `/api/invitation/${token}`
        });
        setInvitation(invitation);
      } catch (error) {
        history.replace("/");
      }
      setLoading(false);
    })();
  }, [history, location, setSession, token]);

  const onAccept = useCallback(async () => {
    setAccepting(true);
    try {
      await api.post({
        url: `/api/invitation/${token}/accept`,
        body: {
          ...(invitation?.requirePassword && { password })
        }
      });
      history.push("/");
      return false;
    } catch (error) {
      setError(error);
      setAccepting(false);
    }
  }, [history, invitation, password, token]);

  return (
    <Box as="form" onSubmit={onAccept} h="100%" position="relative">
      <svg
        viewBox="0 0 425 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%", position: "absolute" }}
      >
        <path
          d="M213.19,0.00 C152.69,70.06 270.03,70.06 202.98,150.00 L500.00,150.00 L500.00,0.00 Z"
          style={{ stroke: "none", fill: "#FFE0A1" }}
        ></path>
      </svg>
      <Flex
        h="100%"
        w="100%"
        position="absolute"
        zIndex={1}
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Flex
          flexDir="column"
          alignItems="stretch"
          justifyContent="center"
          maxW={400}
          w="100%"
          px={12}
          py={6}
          boxShadow="md"
          minH="60%"
          bg="white"
        >
          <Image mx="auto" src={logo} w={100} />
          <Text
            mt={3}
            as="h2"
            fontWeight="bold"
            fontSize="2xl"
            alignSelf="center"
            fontFamily="Roboto Slab"
          >
            {getTexts("InvitationCard")}
          </Text>
          <Flex flex={1} flexDir="column" minH={0} h="100%">
            {loading ? (
              <Spinner alignSelf="center" mt={12} color="primary.200"></Spinner>
            ) : (
              <>
                <Text mt={12} fontSize="lg" alignSelf="center">
                  {getTexts("YouHaveBeenInvitedToJoin")}
                  <Text
                    as="span"
                    px={1}
                    fontSize="xl"
                    fontWeight="bold"
                    color="primary.500"
                  >
                    {invitation?.organization ?? getTexts("ThisWorkspace")}
                  </Text>
                  {invitation?.requirePassword
                    ? getTexts("PleaseSetYourLoginPasswordToCreateAnAccount")
                    : getTexts("PleaseClickTheButtonBelowToJoin")}
                </Text>
                {invitation?.requirePassword && (
                  <FormControl mt={6}>
                    <FormLabel>{getTexts("Password")}</FormLabel>
                    <Input
                      fontSize="4xl"
                      mb={-1}
                      w="100%"
                      type="password"
                      value={password}
                      fontFamily="initial"
                      letterSpacing={3}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </FormControl>
                )}
                <Text mt={12} color="error">
                  {error?.message}
                </Text>
                <Button
                  mt={3}
                  size="lg"
                  fontSize="xl"
                  colorScheme="secondary"
                  variant="solid"
                  isLoading={accepting}
                  htmlType="submit"
                  type="submit"
                >
                  {getTexts("ClickHereToJoin")}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Invitation;
