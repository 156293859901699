import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import { useHistory } from "react-router";

const UpsertSanukerTemplateDrawer = ({ isOpen, onClose, params }) => {
  const toast = useToast();
  const history = useHistory();
  const { getTexts, setExternalList } = AppContainer.useContainer();

  const isEdit = params?.template?.key ? true : false;

  const [
    upsertSanukerTemplateIsLoading,
    setUpsertSanukerTemplateIsLoading,
  ] = useState(false);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...params?.template,
    },
  });

  const onSubmit = async data => {
    try {
      setUpsertSanukerTemplateIsLoading(true);

      let newTemplatesArray = [...(params?.channel?.templates ?? [])];
      const templateIndex = newTemplatesArray.findIndex(
        template => template.key === params?.template?.key
      );

      if (templateIndex === -1)
        newTemplatesArray = [...newTemplatesArray, { ...data }];
      else
        newTemplatesArray[templateIndex] = {
          ...newTemplatesArray[templateIndex],
          ...data,
        };

      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...params?.channel,
          id: params?.platform?.id,
          channelId: params?.channel?.id,
          templates: newTemplatesArray,
        },
      });
      setExternalList(resData);
      toast({
        title: isEdit
          ? getTexts("SanukerTemplateUpdated")
          : getTexts("SanukerTemplateCreated"),
        status: "success",
      });
      if (params?.template?.key && data?.key !== params?.template?.key)
        history.replace(
          `/externalPlatform/${params?.platform.id}/${params?.channel?.id}/${data?.key}`
        );
      onClose();
    } catch (err) {
    } finally {
      setUpsertSanukerTemplateIsLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEdit
              ? getTexts("SanukerTemplateUpdate")
              : getTexts("SanukerTemplateCreate")}
          </DrawerHeader>

          <DrawerBody>
            <List spacing={8}>
              {/* {!isEdit && ( */}
              <ListItem>
                <FormControl isRequired isInvalid={errors.key?.message}>
                  <FormLabel htmlFor="key">{getTexts("TemplateKey")}</FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="key"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "TemplateKey"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.key?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
              {/* )} */}
              <ListItem>
                <FormControl isRequired isInvalid={errors.name?.message}>
                  <FormLabel htmlFor="name">
                    {getTexts("TemplateName")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "TemplateName"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isRequired isInvalid={errors.description?.message}>
                  <FormLabel htmlFor="description">
                    {getTexts("TemplateDescription")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "TemplateDescription"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
            </List>
          </DrawerBody>

          <DrawerFooter>
            <Flex mt={8} w="100%">
              <Box flex={1} minW={0} w="100%"></Box>
              <Stack direction="row">
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {getTexts("Cancel")}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={upsertSanukerTemplateIsLoading}
                >
                  {isEdit ? getTexts("Update") : getTexts("Create")}
                </Button>
              </Stack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UpsertSanukerTemplateDrawer;
