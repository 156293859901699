import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spinner,
  Tag,
  Text,
} from "@chakra-ui/react";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import api from "@projectg/utils/utils/api";
import {
  downloadBlob,
  mergeSearchParams,
  removeIf,
  updateIf,
} from "@projectg/utils/utils/helpers";
import { useGetPixelToBottom, useMemoRef } from "@projectg/utils/utils/hooks";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { MdAdd, MdClose, MdFilterList, MdLink } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import DatePickerInput from "../../components/DatePicker";
import ConsoleContainer from "../../store/console";
import { getCriteriaSchema } from "../../utils/customerQuery";

const Filter = ({ isActive, control, defaultValue = {} }) => {
  const watched = useWatch({
    control,
  });

  const { operator: _operator, field: _key, operand } = isActive
    ? watched
    : defaultValue;

  const {
    appConfig,
    getTextByLang,
    getTexts,
    currentLangKey,
  } = AppContainer.useContainer();
  const criteriaSchema = useMemo(
    () =>
      getCriteriaSchema({
        customFields: appConfig?.customerFields,
        getTextByLang,
      }),
    [appConfig, getTextByLang]
  );
  const field = criteriaSchema?.find(({ value }) => _key === value);
  const operator = (field?.operators ?? []).find(
    ({ value }) => _operator === value
  );
  const operandType = operator?.operand?.type;

  return (
    <Flex alignItems="center">
      <Text>{field?.label}</Text>{" "}
      <Text color="black" mx={1}>
        {operator?.label}
      </Text>
      {operandType &&
        (operand ? (
          <Text mx={1} color="secondary.500">
            {(() => {
              switch (operand) {
                case "date":
                  return dayjs(operand).format(
                    currentLangKey === "en" ? "YYYY-MM-DD" : "YYYY年MM月DD日"
                  );
                case "anniversary":
                  return dayjs(operand).format(
                    currentLangKey === "en" ? "MM-DD" : "MM月DD日"
                  );
                default:
                  return operand;
              }
            })()}
          </Text>
        ) : (
          <Text mx={1} fontStyle="italic" color="gray.300">
            {getTexts("NotSpecified")}
          </Text>
        ))}
    </Flex>
  );
};

const Customers = () => {
  const history = useHistory();
  const { upsertCustomer } = MessengerContainer.useContainer();
  const { ___searchParams } = ConsoleContainer.useContainer();
  const scrollRef = useRef();

  const [fetching, setFetching] = useState(true);
  const [criteria, setCriteria] = useState([]);
  const {
    appConfig,
    getTextByLang,
    getTexts,
    currentLangKey,
  } = AppContainer.useContainer();
  const criteriaSchema = useMemo(
    () =>
      getCriteriaSchema({
        customFields: appConfig?.customerFields,
        getTextByLang,
      }),
    [appConfig, getTextByLang]
  );
  const [customers, setCustomers] = useState([]);
  const offsetRef = useMemoRef(customers?.length ?? 0);
  const hasMoreRef = useRef(true);
  const filteredCustomers = customers;

  const { register, watch, control, reset, getValues } = useForm();

  const [indexOpened, setIndexOpened] = useState(null);

  const fetchCustomers = useCallback(
    async ({ more }) => {
      try {
        setFetching(true);
        if (!more) {
          setCustomers([]);
        }
        const criteriaParams = (_criteria => {
          if (!_criteria || _criteria?.length === 0) {
            return [];
          } else {
            let valid = true;
            const criteria = _criteria.map(({ field, operator, operand }) => {
              valid = !!operand && !!operator && !!field;
              return (
                `${field} ${operator}` +
                (operand
                  ? ` ${!isNaN(operand) ? operand : `"${operand}"`}`
                  : "")
              );
            });
            return valid ? criteria : null;
          }
        })(criteria);

        if (!criteriaParams) return;

        const { data: _customers } = await api.post({
          url: "/api/admin/customer",
          body: {
            size: 20,
            offset: more ? offsetRef.current : 0,
            fields: "createDate,conversations,tags,notes",
            ...(criteriaParams?.length > 0 && {
              criteria: JSON.stringify(criteriaParams),
              operand: "and",
            }),
          },
        });

        unstable_batchedUpdates(() => {
          _customers.forEach(c => upsertCustomer(c?.userId, c));
          hasMoreRef.current = _customers?.length === 20;
          setCustomers(_ => [..._, ..._customers]);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    },
    [criteria, offsetRef, upsertCustomer]
  );

  useEffect(() => {
    fetchCustomers({ more: false });
  }, [fetchCustomers]);

  const getPixelToBottom = useGetPixelToBottom(scrollRef);

  useEffect(() => {
    const handler = () => {
      if (
        !fetching &&
        getPixelToBottom() < 10 &&
        offsetRef.current > 0 &&
        hasMoreRef.current
      ) {
        fetchCustomers({ more: true });
      }
    };
    const scrollView = scrollRef.current;
    if (scrollView) {
      scrollView.addEventListener("scroll", handler);
    }
    return () => {
      scrollView && scrollView.removeEventListener("scroll", handler);
    };
  }, [fetchCustomers, fetching, getPixelToBottom, offsetRef]);

  const downloadCustomerReport = useCallback(async () => {
    try {
      const blob = await api.get({
        url: `/api/admin/report/customer/download`,
        responseType: "blob",
      });
      if (blob) {
        downloadBlob(blob);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Flex flexDir="column" w="100%" h="100%">
      <Box w="100%" zIndex={50} p={8}>
        <Heading as="h2" fontFamily="Roboto Slab" mb={4}>
          {getTexts("Client")}
        </Heading>
        <Flex>
          <Box flex={1} minW={0} w="100%">
            {(criteria ?? []).map(({ field, operator, operand }, index) => {
              const _field = criteriaSchema?.find(
                ({ value }) => watch("field") === value
              );
              return (
                <Popover
                  key={index}
                  closeOnBlur={true}
                  onOpen={() => {
                    reset({ field, operator, operand });
                    setIndexOpened(index);
                  }}
                  onClose={() => {
                    setCriteria(_ => {
                      return updateIf(_, indexOpened, getValues());
                    });
                    setIndexOpened(null);
                  }}
                >
                  <PopoverTrigger key={index}>
                    <Button
                      mr={2}
                      mb={2}
                      variant="outline"
                      colorScheme="primary"
                      p={0}
                    >
                      <Flex alignItems="center">
                        <Box as={MdFilterList} mx={2} />
                        <Filter
                          isActive={indexOpened === index}
                          control={control}
                          defaultValue={{ field, operator, operand }}
                        />
                        <IconButton
                          icon={<MdClose />}
                          colorScheme="red"
                          variant="link"
                          fontSize="lg"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setCriteria(_ => removeIf(_, index));
                            setIndexOpened(null);
                          }}
                        />
                      </Flex>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    placement="bottom-start"
                    zIndex={500}
                    px={2}
                    py={4}
                    minW={600}
                    _focus={{ boxShadow: "none" }}
                  >
                    <FormControl as={Flex}>
                      <Select
                        ref={register}
                        name={`field`}
                        defaultValue={field}
                        maxW={200}
                        variant="filled"
                        bg="transparent"
                      >
                        {criteriaSchema.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Select>

                      <Flex flex={1} alignItems="center">
                        <Select
                          ref={register}
                          name={`operator`}
                          defaultValue={operator}
                          mx={1}
                          maxW={150}
                          variant="filled"
                          bg="transparent"
                        >
                          {(_field?.operators ?? []).map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                        {(() => {
                          const _operator = (_field?.operators ?? []).find(
                            ({ value }) => watch("operator") === value
                          );
                          switch (_operator?.operand?.type) {
                            case "number": {
                              return (
                                <Input
                                  type="number"
                                  ref={register}
                                  name={`operand`}
                                  defaultValue={operand}
                                  variant="outline"
                                  bg="transparent"
                                />
                              );
                            }

                            case "date": {
                              return (
                                <Controller
                                  control={control}
                                  name="operand"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                  }) => (
                                    <DatePickerInput
                                      name={name}
                                      onBlur={onBlur}
                                      onChange={(date, datestring) =>
                                        onChange(datestring)
                                      }
                                      defaultValue={value}
                                      variant="outline"
                                      bg="transparent"
                                      size="md"
                                    />
                                  )}
                                />
                              );
                            }

                            case "anniversary": {
                              return (
                                <Controller
                                  control={control}
                                  name="operand"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                  }) => (
                                    <DatePickerInput
                                      picker="anniversary"
                                      format="MM-DD"
                                      name={name}
                                      onBlur={onBlur}
                                      onChange={(date, datestring) =>
                                        onChange(datestring)
                                      }
                                      defaultValue={value}
                                      variant="outline"
                                      bg="transparent"
                                      size="md"
                                    />
                                  )}
                                />
                              );
                            }
                            case "string": {
                              return (
                                <Input
                                  ref={register}
                                  name={`operand`}
                                  defaultValue={operand}
                                  variant="outline"
                                  bg="transparent"
                                />
                              );
                            }
                            default:
                              return null;
                          }
                        })()}
                      </Flex>
                    </FormControl>
                  </PopoverContent>
                </Popover>
              );
            })}
            <Menu placement="bottom-start">
              <MenuButton
                as={Button}
                variant="ghost"
                leftIcon={<MdAdd />}
                colorScheme="primary"
                mb={2}
              >
                {getTexts("AddFilter")}
              </MenuButton>
              <MenuList>
                {criteriaSchema.map(({ value, label }) => {
                  return (
                    <MenuItem
                      key={value}
                      onClick={() => {
                        setCriteria(_ => {
                          return [..._, { field: value, operator: "=" }];
                        });
                      }}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Box>
          <Box>
            <Button onClick={downloadCustomerReport}>
              {getTexts("ExportCustomerReport")}
            </Button>
          </Box>
        </Flex>
      </Box>
      <Flex
        flexDir="column"
        flex={1}
        h="100%"
        minH={0}
        fontSize="md"
        w="100%"
        overflowX="auto"
      >
        <Flex top={0} left={0} fontWeight="bold" boxShadow="sm" zIndex={1}>
          <Box minW={200} w={200} p={4}>
            {getTexts("Name")}
          </Box>
          <Box minW={80} w={80} p={4}>
            {getTexts("Conversations")}
          </Box>
          <Box minW={200} w={200} p={4}>
            {getTexts("EMail")}
          </Box>
          <Box minW={200} w={200} p={4}>
            {getTexts("CreationDate")}
          </Box>
          <Box minW={200} w={200} p={4}>
            {getTexts("ReferenceNo")}
          </Box>
          <Box flex={1} p={4}></Box>
          {/* {(appConfig?.customerFields ?? []).map(({ id, name }) => {
            return (
              <Box minW={200} w={200} p={4} key={id}>
                {getTextByLang(name)}
              </Box>
            );
          })}
          <Box minW={600} w={600} p={4}>
            {getTexts("Tags")}
          </Box> */}
        </Flex>

        <Flex
          flexDir="column"
          minH={0}
          h="100%"
          flex={1}
          overflowY="auto"
          ref={scrollRef}
        >
          {(filteredCustomers ?? []).map(c => (
            <Box
              as={Flex}
              key={c?.userId}
              _hover={{ bg: "gray.50" }}
              borderBottomWidth={1}
              borderBottomColor="gray.100"
              borderBottomStyle="solid"
            >
              <Box minW={200} w={200} p={4}>
                <Box
                  w="100%"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  {c?.displayName}
                </Box>
              </Box>
              <Box minW={80} w={80} p={4}>
                <Tag
                  size="sm"
                  onClick={() => {
                    ___searchParams.current = mergeSearchParams({
                      customerId: c?.userId,
                    });
                    history.push("/conversation");
                  }}
                >
                  {c?.conversations?.length}
                </Tag>
              </Box>
              <Box minW={200} w={200} p={4}>
                <Link href={`mailto:${c?.email}`}>{c?.email}</Link>
              </Box>
              <Box minW={200} w={200} p={4}>
                {dayjs(c?.createDate).format(
                  currentLangKey === "en"
                    ? "YYYY-MM-DD HH:mm a"
                    : "YYYY年MM月DD日 HH:mm a"
                )}
              </Box>
              <Box minW={200} w={200} p={4}>
                {c?.refId}
              </Box>
              <Box flex={1} p={4} textAlign="right">
                <Button
                  as={Link}
                  leftIcon={<MdLink />}
                  variant="ghost"
                  colorScheme="primary"
                  to={`/customers/${c?.userId}`}
                >
                  {getTexts("File")}
                </Button>
              </Box>
              {/* {(appConfig?.customerFields ?? []).map(({ id }) => {
                return (
                  <Box minW={200} w={200} p={4} key={id}>
                    {c?.fields?.[id]?.value}
                  </Box>
                );
              })}
              <Box minW={600} w={600} p={4}>
                {(c?.tags ?? []).map(tag => (
                  <Tag colorScheme="secondary" size="sm" key={tag}>
                    {tag}
                  </Tag>
                ))}
              </Box> */}
            </Box>
          ))}

          {fetching && (
            <Flex m={6} justifyContent="center">
              <Spinner color="primary.500" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Customers;
