import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useTheme,
  useToast
} from "@chakra-ui/react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import api from "@projectg/utils/utils/api";
import { unstable_batchedUpdates } from "react-dom";
import {
  emailRegex,
  patterns,
  urlRegex
} from "@projectg/utils/utils/constants";
import { MdArchive, MdChatBubble, MdPerson } from "react-icons/md";
import { BadgeIndicator, SourceTag } from "../../components/components";
import { DatePickerInput } from "../../components/DatePicker";
import dayjs from "dayjs";
import {
  border,
  getSelectStyle,
  getSelectTheme,
  humanifyDate
} from "@projectg/utils/utils/helpers";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useGetLastMessageDisplay } from "../../utils/hooks";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";

const Customer = () => {
  const { id } = useParams();
  const history = useHistory();
  const toast = useToast();

  const {
    agents,
    customers,
    conversations,
    hasUnread,
    customerTags,
    upsertCustomer,
    upsertConversation
  } = MessengerContainer.useContainer();
  const {
    session,
    getTextByLang,
    appConfig,
    getTexts,
    currentLangKey
  } = AppContainer.useContainer();
  const getLastMessageDisplay = useGetLastMessageDisplay();
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [archivingCustomer, setArchivingCustomer] = useState(false);
  const [, setUpdatingCustomer] = useState(false);
  const { reset, register, control, trigger, errors } = useForm({});
  const customer = useMemo(() => customers?.[id], [customers, id]);

  const currEditRef = useRef();
  const updateCustomer = useCallback(
    async updater => {
      try {
        setUpdatingCustomer(true);
        upsertCustomer(id, updater);
        await api.post({
          url: "/api/admin/customer/update",
          body: {
            userId: id,
            ...updater
          }
        });
        setUpdatingCustomer(false);
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );
  const profileForm = [
    {
      name: "email",
      label: getTexts("EMail"),
      type: "email"
    },
    {
      name: "subtitle",
      label: getTexts("Subtitle"),
      type: "text"
    },
    {
      name: "bio",
      label: getTexts("Bio"),
      type: "text"
    },
    {
      name: "tags",
      label: getTexts("Tags"),
      type: "select"
    }
  ];

  const archiveCustomer = useCallback(async () => {
    try {
      setArchivingCustomer(true);
      await api.post({
        url: "/api/admin/customer/archive",
        body: {
          userId: customer?.userId
        }
      });
      // upsertConversation(c.id, c);
      toast({
        title: getTexts("Archived"),
        status: "success",
        duration: 2500,
        isClosable: false
      });
      history.push(`/customers`);
    } catch (error) {
      toast({
        title: getTexts("FailedToArchiveCustomer"),
        status: "error",
        duration: 2500,
        isClosable: false
      });
      console.error(error);
    }
    setArchivingCustomer(false);
  }, [customer?.userId, toast, getTexts, history]);

  const createNewConversation = useCallback(async () => {
    try {
      setCreatingConversation(true);
      const { data: c } = await api.post({
        url: "/api/conversation/create",
        body: {
          agent: [session?.user?.userId],
          customer: [customer?.userId]
        }
      });
      upsertConversation(c.id, c);
      toast({
        title: getTexts("ConversationCreated"),
        status: "success",
        duration: 2500,
        isClosable: false
      });
      history.push(`/conversation/${c.id}`);
    } catch (error) {
      setCreatingConversation(false);
      toast({
        title: getTexts("FailedToCreateConversation"),
        status: "error",
        duration: 2500,
        isClosable: false
      });
      console.error(error);
    }
  }, [session, customer, upsertConversation, toast, history, getTexts]);

  const updateCustomerTag = useCallback(
    async (
      tags,

      { action, option, removedValue }
    ) => {
      try {
        setUpdatingCustomer(true);
        upsertCustomer(id, { tags: tags?.map(x => x.value) ?? [] });
        await (() => {
          switch (action) {
            case "create-option":
              return api.post({
                url: `/api/tag/customer/assign`,
                body: {
                  userId: id,
                  tag: tags?.filter(x => x.__isNew__).map(x => x.value)
                }
              });
            case "select-option":
              return api.post({
                url: `/api/tag/customer/assign`,
                body: {
                  userId: id,
                  tag: [option.value]
                }
              });
            case "remove-value":
              return api.post({
                url: `/api/tag/customer/unassign`,
                body: {
                  userId: id,
                  tag: [removedValue.value]
                }
              });
            default:
          }
        })();
        setUpdatingCustomer(false);
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );

  const theme = useTheme();

  useEffect(() => {
    reset({ customer });
  }, [customer, reset]);

  useEffect(() => {
    (async () => {
      try {
        const { data: customers } = await api.get({
          url: "/api/admin/customer/get",
          params: {
            userId: id,
            fields: "createDate,conversations,tags,notes,lastActivity"
          }
        });

        const { data: conversations } = await api.get({
          url: `/api/conversation/get`,
          params: {
            id: customers
              ?.map(c => c?.conversations)
              ?.flat()
              .join(",")
          }
        });

        unstable_batchedUpdates(() => {
          customers.forEach(c => upsertCustomer(c?.userId, c));
          conversations.forEach(c => upsertConversation(c?.id, c));
        });
      } catch (error) {
        toast({
          title: getTexts(
            "UnableToInviteThisPersonPleaseCheckThatTheEmailIsInTheAgentList"
          ),
          status: "error",
          duration: 2500,
          isClosable: false
        });
        history.push("/customers");
      }
    })();
  }, [history, id, reset, toast, upsertConversation, upsertCustomer, getTexts]);

  const relatedConversations = Object.values(conversations).filter(c =>
    c?.customer?.find(x => x?.id === id)
  );

  const addNote = useCallback(
    async e => {
      try {
        const note = e.target.value;
        e.target.value = "";
        if (note) {
          const params = {
            userId: id,
            content: note
          };
          const { data: updated } = await api.post({
            url: "/api/admin/customer/note/add",
            body: params
          });
          upsertCustomer(id, updated);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertCustomer]
  );

  if (!id) {
    return <Redirect to="/customers" />;
  }

  return (
    <Flex w="100%" h="100%" flexDir="column" p={4} overflow="auto">
      <Box colorScheme="primary" mx="auto" maxW={1024} w="100%">
        <Flex
          zIndex={1}
          flexDir="column"
          w="100%"
          px={8}
          mt={4}
          minHeight={120}
          backgroundImage={`url("${patterns.diagram()}")`}
        >
          <Flex px={8}>
            <Box
              as={MdPerson}
              borderRadius="50%"
              bg="primary.300"
              color="white"
              fontSize="xl"
              p={1}
              w={32}
              h={32}
            />

            <Stack ml={8} spacing={2}>
              <Flex alignItems="center">
                <BadgeIndicator
                  ml={-1}
                  enable={customer?.online}
                  onColor="#2aa92a"
                  offColor="gray.300"
                  onLabel={getTexts("Online")}
                  offLabel={getTexts("Offline")}
                />
                <Heading
                  fontFamily="Roboto Slab"
                  flex={1}
                  minW={0}
                  w="100%"
                  as="h2"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {customer?.displayName}
                </Heading>
              </Flex>
              <Stack mt={2} spacing={2}>
                <FormControl
                  d="flex"
                  alignItems="center"
                  color="primary.700"
                  fontSize="sm"
                >
                  <Text w={100}>{getTexts("LastActivityOn")}</Text>
                  <Text ml={2}>
                    {dayjs(customer?.lastActivity).format(
                      currentLangKey === "en"
                        ? "YYYY-MM-DD HH:mm"
                        : "YYYY年MM月DD日 HH:mm"
                    )}
                  </Text>
                </FormControl>
                <FormControl
                  d="flex"
                  alignItems="center"
                  color="primary.700"
                  fontSize="sm"
                >
                  <Text w={100}>{getTexts("EstablishedIn")}</Text>
                  <Text ml={2}>
                    {dayjs(customer?.createDate).format(
                      currentLangKey === "en"
                        ? "YYYY-MM-DD HH:mm"
                        : "YYYY年MM月DD日 HH:mm"
                    )}
                  </Text>
                </FormControl>
              </Stack>
              <Flex mt={2}>
                <Button
                  mr={2}
                  size="sm"
                  lefticon={<MdArchive />}
                  colorScheme="secondary"
                  variant="outline"
                  onClick={archiveCustomer}
                  isLoading={archivingCustomer}
                >
                  {getTexts("Archive")}
                </Button>
                <Button
                  lefticon={<MdChatBubble />}
                  mr={2}
                  size="sm"
                  colorScheme="primary"
                  variant="outline"
                  onClick={createNewConversation}
                  isLoading={creatingConversation}
                >
                  {getTexts("CreateNewConversation")}
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Flex>

        <Flex w="100%" mt={8}>
          <Box w={300} p={4}>
            <Accordion allowMultiple={true} defaultIndex={[0, 1]}>
              <AccordionItem boxShadow="md" p={2} border={0}>
                <AccordionButton fontWeight="bold" p={2} {...border()}>
                  {getTexts("Profile")}
                </AccordionButton>
                <AccordionPanel mx={-2}>
                  {profileForm?.map(({ name, label, type }) => (
                    <FormControl key={name} d="flex" alignItems="center">
                      <Tooltip
                        bg="error"
                        isOpen={!!errors?.customer?.[name]?.message}
                        placement="left"
                        label={errors?.customer?.[name]?.message}
                      >
                        <Text
                          mr={1}
                          fontSize="md"
                          color="#999"
                          fontWeight="bold"
                        >
                          {label}
                        </Text>
                      </Tooltip>
                      <Box flex={1}>
                        {(() => {
                          switch (type) {
                            case "select":
                              return (
                                <Controller
                                  control={control}
                                  name={`customer.${name}`}
                                  defaultValue={[]}
                                  render={({ value, name }) => {
                                    const options = [
                                      ...new Set([
                                        ...customerTags,
                                        ...(value ?? [])
                                      ])
                                    ]?.map(tag => ({
                                      label: tag,
                                      value: tag
                                    }));
                                    return (
                                      <CreatableSelect
                                        isMulti={true}
                                        placeholder={getTexts("Tags")}
                                        options={options}
                                        onChange={updateCustomerTag}
                                        value={options.filter(x =>
                                          (value ?? []).includes(x.value)
                                        )}
                                        name={name}
                                        styles={{
                                          ...getSelectStyle({ theme }),
                                          indicatorsContainer: _ => ({
                                            display: "none"
                                          }),
                                          placeholder: _ => ({ width: "90%" }),
                                          control: _ => ({
                                            ..._,
                                            textAlign: "right",
                                            borderColor: "transparent",
                                            minHeight: 28,
                                            padding: 0,
                                            borderWidth: 0,
                                            "&:hover, &:active": {
                                              borderWidth: 0,
                                              boxShadow: "none",
                                              background: theme.colors.gray[50]
                                            }
                                          }),
                                          valueContainer: (_, state) => ({
                                            ..._,

                                            color: state.hasValue
                                              ? theme.colors.primary[700]
                                              : "#aaa",
                                            padding: 0,
                                            fontSize: "1.000rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end"
                                          })
                                        }}
                                        theme={getSelectTheme({ theme })}
                                        size={28}
                                      />
                                    );
                                  }}
                                />
                              );
                            default:
                              return (
                                <Input
                                  px={1}
                                  name={`customer.${name}`}
                                  ref={register({
                                    pattern: (() => {
                                      switch (type) {
                                        case "email":
                                          return {
                                            value: emailRegex,
                                            message: getTexts(
                                              "PleaseEnterAValidEmail"
                                            )
                                          };
                                        case "url":
                                          return {
                                            value: urlRegex,
                                            message: getTexts(
                                              "PleaseEnterAValidUrl"
                                            )
                                          };
                                        case "text":
                                        case "longText":
                                        case "phone":
                                        case "address":
                                        case "anniversary":
                                        case "date":
                                          return {};
                                        default:
                                      }
                                    })()
                                  })}
                                  onFocus={e =>
                                    (currEditRef.current = e.target)
                                  }
                                  onBlur={async e => {
                                    const result = await trigger(
                                      `customer.${name}`
                                    );
                                    if (result) {
                                      updateCustomer({
                                        [name]: currEditRef.current.value
                                      });
                                      currEditRef.current = null;
                                    } else {
                                      currEditRef.current.focus();
                                    }
                                  }}
                                  placeholder={getTexts("Unfilled")}
                                  fontSize="md"
                                  size="sm"
                                  _placeholder={{ textAlign: "right" }}
                                  textAlign="right"
                                  _hover={{
                                    borderColor: "transparent",
                                    bg: "gray.50"
                                  }}
                                  focusBorderColor="transparent"
                                  borderColor="transparent"
                                />
                              );
                          }
                        })()}
                      </Box>
                    </FormControl>
                  ))}
                  {(appConfig?.customerFields ?? [])?.map(
                    ({ id, name, type }) => (
                      <FormControl key={id} d="flex">
                        <Tooltip
                          bg="error"
                          isOpen={
                            !!errors?.customer?.fields?.[id]?.value?.message
                          }
                          placement="left"
                          label={errors?.customer?.fields?.[id]?.value?.message}
                        >
                          <Text
                            mt={1}
                            mr={1}
                            fontSize="md"
                            color="#999"
                            fontWeight="bold"
                          >
                            {getTextByLang(name)}
                          </Text>
                        </Tooltip>
                        <Box flex={1}>
                          {(() => {
                            switch (type) {
                              case "anniversary":
                                return (
                                  <Box paddingLeft="30px">
                                    <Controller
                                      control={control}
                                      name={`customer.fields.${id}.value`}
                                      render={({
                                        onChange,
                                        value,
                                        name,
                                        ref
                                      }) => (
                                        <DatePickerInput
                                          picker="anniversary"
                                          showSelectableDays={false}
                                          name={name}
                                          placeholder={getTexts("SelectDate")}
                                          size="sm"
                                          format="MM-DD"
                                          value={value ? dayjs(value) : null}
                                          onChange={(date, dateString) =>
                                            onChange(dateString)
                                          }
                                          ref={ref}
                                          onFocus={e =>
                                            (currEditRef.current = e.target)
                                          }
                                          onBlur={async () => {
                                            const result = await trigger(
                                              `customer.fields.${id}.value`
                                            );
                                            if (result) {
                                              updateCustomer({
                                                fields: {
                                                  ...customer?.fields,
                                                  [id]: {
                                                    value:
                                                      currEditRef.current.value
                                                  }
                                                }
                                              });
                                              currEditRef.current = null;
                                            } else {
                                              currEditRef.current.focus();
                                            }
                                          }}
                                          onClear={(date, dateString) =>
                                            updateCustomer({
                                              fields: {
                                                ...customer?.fields,
                                                [id]: {
                                                  value: dateString
                                                }
                                              }
                                            })
                                          }
                                        />
                                      )}
                                    />
                                  </Box>
                                );

                              case "date":
                                return (
                                  <Box paddingLeft="30px">
                                    <Controller
                                      control={control}
                                      name={`customer.fields.${id}.value`}
                                      render={({
                                        onChange,
                                        value,
                                        name,
                                        ref
                                      }) => (
                                        <DatePickerInput
                                          showSelectableDays={false}
                                          name={name}
                                          placeholder={getTexts("SelectDate")}
                                          size="sm"
                                          format="YYYY-MM-DD"
                                          value={value ? dayjs(value) : null}
                                          onChange={(date, dateString) =>
                                            onChange(dateString)
                                          }
                                          ref={ref}
                                          onFocus={e =>
                                            (currEditRef.current = e.target)
                                          }
                                          onBlur={async () => {
                                            const result = await trigger(
                                              `customer.fields.${id}.value`
                                            );
                                            if (result) {
                                              updateCustomer({
                                                fields: {
                                                  ...customer?.fields,
                                                  [id]: {
                                                    value:
                                                      currEditRef.current.value
                                                  }
                                                }
                                              });
                                              currEditRef.current = null;
                                            } else {
                                              currEditRef.current.focus();
                                            }
                                          }}
                                          onClear={(date, dateString) =>
                                            updateCustomer({
                                              fields: {
                                                ...customer?.fields,
                                                [id]: {
                                                  value: dateString
                                                }
                                              }
                                            })
                                          }
                                        />
                                      )}
                                    />
                                  </Box>
                                );

                              default:
                                return (
                                  <Input
                                    name={`customer.fields.${id}.value`}
                                    px={1}
                                    placeholder={getTexts("Unfilled")}
                                    fontSize="md"
                                    size="sm"
                                    _placeholder={{ textAlign: "right" }}
                                    _hover={{
                                      borderColor: "transparent",
                                      bg: "gray.50"
                                    }}
                                    textAlign="right"
                                    focusBorderColor="transparent"
                                    borderColor="transparent"
                                    ref={register({
                                      pattern: (() => {
                                        switch (type) {
                                          case "email":
                                            return {
                                              value: emailRegex,
                                              message: getTexts(
                                                "PleaseEnterAValidEmail"
                                              )
                                            };
                                          case "url":
                                            return {
                                              value: urlRegex,
                                              message: getTexts(
                                                "PleaseEnterAValidUrl"
                                              )
                                            };
                                          case "text":
                                          case "longText":
                                          case "phone":
                                          case "address":
                                          case "anniversary":
                                          case "date":
                                            return {};
                                          default:
                                        }
                                      })()
                                    })}
                                    onFocus={e =>
                                      (currEditRef.current = e.target)
                                    }
                                    onBlur={async () => {
                                      const result = await trigger(
                                        `customer.fields.${id}.value`
                                      );
                                      if (result) {
                                        updateCustomer({
                                          fields: {
                                            ...customer?.fields,
                                            [id]: {
                                              value: currEditRef.current.value
                                            }
                                          }
                                        });
                                        currEditRef.current = null;
                                      } else {
                                        currEditRef.current.focus();
                                      }
                                    }}
                                  />
                                );
                            }
                          })()}
                        </Box>
                      </FormControl>
                    )
                  )}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem boxShadow="md" p={2} mt={4} border={0}>
                <AccordionButton fontWeight="bold" p={2} {...border()}>
                  {getTexts("Memo")}
                </AccordionButton>
                <AccordionPanel>
                  <Input
                    w="100%"
                    focusBorderColor="primary.400"
                    p={2}
                    variant="flushed"
                    placeholder={getTexts("FillInTheMemo")}
                    onKeyDown={e => {
                      if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
                        addNote(e);
                        e.preventDefault();
                      }
                    }}
                  ></Input>
                  {(customer?.notes ?? []).length === 0 ? (
                    <Box
                      m={2}
                      color="#999"
                      alignSelf="center"
                      justify="center"
                      textAlign="center"
                    >
                      {getTexts("NoMemo")}
                    </Box>
                  ) : (
                    (customer?.notes ?? [])
                      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
                      .map(note => {
                        return (
                          <Box mt={2} key={note?.id} bg="secondary.50">
                            <Flex
                              alignSelf="stretch"
                              p={4}
                              flexDirection="column"
                              key={id}
                            >
                              <Flex align="center">
                                <Flex flex={1} align="center">
                                  <Text
                                    fontWeight="bold"
                                    color="gray.700"
                                    mr={1}
                                    fontSize="md"
                                    fontFamily="Roboto"
                                  >
                                    {agents?.[note?.userId]?.displayName}
                                  </Text>
                                </Flex>
                                <Box color="#bbb" fontSize="80%">
                                  {humanifyDate(note?.timestamp, "zh")}
                                </Box>
                              </Flex>
                              <Flex mr={1} mt={1} align="center">
                                {note?.content}
                              </Flex>
                            </Flex>
                          </Box>
                        );
                      })
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Tabs flex={1} minW={0} w="100%" mt={4}>
            <TabList
              borderBottomWidth={1}
              borderStyle="solid"
              borderColor="#eee"
            >
              <Tab>
                {getTexts("Conversations")} ({customer?.conversations?.length})
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel py={8}>
                {relatedConversations.length === 0 ? (
                  <Box
                    m={2}
                    color="#999"
                    alignSelf="center"
                    justify="center"
                    textAlign="center"
                  >
                    {getTexts("NoRelatedConversations")}
                  </Box>
                ) : (
                  relatedConversations.map(({ id, ...conversation }, index) => {
                    return (
                      <Box
                        mb={4}
                        boxShadow="sm"
                        borderRadius="sm"
                        key={id}
                        _hover={{ bg: "gray.50" }}
                      >
                        <Link to={`/conversation/${id}`}>
                          <Flex
                            cursor="pointer"
                            alignSelf="stretch"
                            p={4}
                            flexDirection="column"
                            key={id}
                          >
                            <Flex align="center">
                              <BadgeIndicator
                                ml={-2}
                                enable={conversation?.online}
                                onColor="#2aa92a"
                                offColor="gray.300"
                                onLabel={getTexts("Online")}
                                offLabel={getTexts("Offline")}
                              />
                              <Flex flex={1} align="center">
                                <Text
                                  fontWeight="bold"
                                  color="gray.700"
                                  mr={1}
                                  fontSize="md"
                                  fontFamily="Roboto"
                                >
                                  {conversation?.name}
                                </Text>
                                <SourceTag source={conversation?.source} />
                              </Flex>
                              <Box color="#bbb" fontSize="80%">
                                {humanifyDate(
                                  conversation.orderTimestamp,
                                  currentLangKey
                                )}
                              </Box>
                            </Flex>
                            <Flex mr={1} mt={1} align="center">
                              {getLastMessageDisplay(
                                conversation,
                                session?.user?.userId
                              )}

                              <BadgeIndicator
                                ml={-2}
                                enable={hasUnread(
                                  conversation,
                                  session?.user?.userId
                                )}
                                onColor="secondary.500"
                                offColor="transparent"
                                onLabel={getTexts("Unread")}
                              />
                            </Flex>
                          </Flex>
                        </Link>
                      </Box>
                    );
                  })
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Customer;
