import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import api from "@projectg/utils/utils/api";
import { downloadBlob, permitted } from "@projectg/utils/utils/helpers";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEllipsisV } from "react-icons/fa";
import { MdCardMembership, MdLink, MdPerson } from "react-icons/md";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";

const Agents = () => {
  const { agents, upsertAgent } = MessengerContainer.useContainer();
  const { session, getTexts, currentLangKey } = AppContainer.useContainer();
  const scrollRef = useRef();

  const [fetching, setFetching] = useState(true);
  const toast = useToast();
  const filteredAgents = Object.values(agents);

  const [inviting, setInviting] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const onAgentInvite = useCallback(
    async ({ email, displayName }) => {
      setInviting(true);
      try {
        await api.post({
          url: "/api/admin/agent/invite",
          body: {
            email,
            displayName,
          },
        });

        toast({
          title: getTexts("InvitationEmailSent"),
          status: "success",
          duration: 2500,
          isClosable: false,
        });
      } catch (error) {
        toast({
          title: getTexts(
            "UnableToInviteThisPersonPleaseCheckThatTheEmailIsInTheAgentList"
          ),
          status: "error",
          duration: 2500,
          isClosable: false,
        });
        console.error(error);
      }
      setInviting(false);
    },
    [getTexts, toast]
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: agents } = await api.get({
          url: "/api/admin/agent",
          params: {
            size: 0,
            fields: "createDate",
          },
        });
        unstable_batchedUpdates(() => {
          agents.forEach(c => upsertAgent(c?.userId, c));
        });
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    })();
  }, [upsertAgent]);

  const downloadAgentReport = useCallback(async () => {
    try {
      const blob = await api.get({
        url: `/api/admin/report/agent/download`,
        responseType: "blob",
      });
      if (blob) {
        downloadBlob(blob);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Flex flexDir="column" w="100%" h="100%">
      <Box w="100%" zIndex={50} p={8}>
        <Heading as="h2" fontFamily="Roboto Slab" mb={4}>
          {getTexts("Agents")}
        </Heading>
        <Flex>
          <Box flex={1} minW={0} w="100%" />
          <Popover closeOnBlur={true} as="form">
            <PopoverTrigger>
              <Box>
                <Button
                  variant="ghost"
                  colorScheme="primary"
                  leftIcon={<MdCardMembership />}
                >
                  {getTexts("InviteMembers")}
                </Button>
              </Box>
            </PopoverTrigger>
            <PopoverContent
              placement="bottom-end"
              _focus={{ boxShadow: "md" }}
              onSubmit={handleSubmit(onAgentInvite)}
            >
              <PopoverBody p={4} as="form">
                <Text fontWeight="bold" fontSize="lg">
                  {getTexts("InviteToJoin")}
                </Text>
                <FormControl mt={3}>
                  <Input
                    focusBorderColor="primary.500"
                    w="100%"
                    p={2}
                    placeholder={getTexts("EMail")}
                    ref={register({
                      required: getTexts("PleaseFillInTheEmail"),
                    })}
                    name="email"
                  />
                  {errors?.email && (
                    <Text mt={1} color="red.500">
                      {errors?.email?.message}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={3}>
                  <Input
                    focusBorderColor="primary.500"
                    w="100%"
                    p={2}
                    placeholder={getTexts("AgentName")}
                    ref={register({
                      required: getTexts("PleaseFillInTheAgentName"),
                    })}
                    name="displayName"
                  />
                  {errors?.displayName && (
                    <Text mt={1} color="red.500">
                      {errors?.displayName?.message}
                    </Text>
                  )}
                </FormControl>
                <Button
                  mt={3}
                  float="right"
                  variant="solid"
                  colorScheme="primary"
                  type="submit"
                  isLoading={inviting}
                >
                  {getTexts("main_invite")}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Menu placement="bottom-end">
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="ghost"
              colorScheme="primary"
            />
            <MenuList>
              {permitted(session?.user?.permissions ?? [], ["report"]) && (
                <MenuItem onClick={downloadAgentReport}>
                  {getTexts("ExportAgentReport")}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Flex
        flexDir="column"
        flex={1}
        h="100%"
        minH={0}
        fontSize="md"
        w="100%"
        overflowX="auto"
      >
        <Flex top={0} left={0} fontWeight="bold" boxShadow="sm" zIndex={1}>
          <Box minW={300} w={300} p={4}>
            {getTexts("Name")}
          </Box>
          <Box minW={300} w={300} p={4}>
            {getTexts("EMail")}
          </Box>
          <Box minW={200} w={200} p={4}>
            {getTexts("CreationDate")}
          </Box>
          <Box minW={300} w={300} p={4}>
            {getTexts("ReferenceNo")}
          </Box>
          <Box flex={1} minW={0} w="100%" p={4}></Box>
        </Flex>

        <Flex
          flexDir="column"
          minH={0}
          h="100%"
          flex={1}
          overflowY="auto"
          ref={scrollRef}
        >
          {(filteredAgents ?? []).map(c => (
            <Box
              as={Flex}
              key={c?.userId}
              _hover={{ bg: "gray.50" }}
              borderBottomWidth={1}
              borderBottomColor="gray.100"
              borderBottomStyle="solid"
              alignItems="center"
            >
              <Flex minW={300} w={300} p={4} alignItems="center">
                <Flex
                  minW={12}
                  minH={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <AsyncImage
                    src={c?.profilePic}
                    empty={
                      <Box
                        as={MdPerson}
                        borderRadius="50%"
                        bg="primary.300"
                        color="white"
                        p={2}
                        w={12}
                        h={12}
                      />
                    }
                  >
                    {({ src }) => (
                      <Image
                        w={12}
                        h={12}
                        alt={c?.displayName}
                        src={src}
                        borderRadius="50%"
                      />
                    )}
                  </AsyncImage>
                </Flex>
                <Box
                  ml={4}
                  w="100%"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  {c?.displayName}
                </Box>
              </Flex>
              <Box minW={300} w={300} p={4}>
                <Link href={`mailto:${c?.email}`}>{c?.email}</Link>
              </Box>
              <Box minW={200} w={200} p={4}>
                {dayjs(c?.createDate).format(
                  currentLangKey === "en"
                    ? "YYYY-MM-DD HH:mm a"
                    : "YYYY年MM月DD日 HH:mm a"
                )}
              </Box>
              <Box minW={300} w={300} p={4}>
                {c?.refId}
              </Box>

              <Box flex={1} p={4} textAlign="right">
                <Button
                  as={Link}
                  leftIcon={<MdLink />}
                  variant="ghost"
                  colorScheme="primary"
                  to={`/agents/${c?.userId}`}
                >
                  {getTexts("Profile")}
                </Button>
              </Box>
            </Box>
          ))}

          {fetching && (
            <Flex w="100vw" m={6} justifyContent="center">
              <Spinner color="primary.500" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Agents;
