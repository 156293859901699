import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import SocketContainer from "@projectg/utils/store/socket";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, MemoryRouter } from "react-router-dom";
import App from "./pages/console/App";
import SdkApp from "./pages/sdk/sdkApp";
import * as serviceWorker from "./serviceWorker";
import SdkContainer from "./store/sdk";
import { CookiesProvider } from "react-cookie";

switch (process.env.REACT_APP_BUILD_TARGET) {
  case "sdk":
    ReactDOM.render(
      <CookiesProvider>
        <MemoryRouter>
          <SocketContainer.Provider>
            <MessengerContainer.Provider>
              <SdkContainer.Provider>
                <AppContainer.Provider>
                  <SdkApp />
                </AppContainer.Provider>
              </SdkContainer.Provider>
            </MessengerContainer.Provider>
          </SocketContainer.Provider>
        </MemoryRouter>
      </CookiesProvider>,
      document.getElementById("root")
    );

    break;
  case "console":
  default:
    // const sdk = document.createElement("script");
    // sdk.src = "https://projectg.talkbox.net/console/greet-sdk-v1.js";
    // sdk.async = true;

    // window.Greet = function () {
    //   (window.Greet.q = window.Greet.q || []).push(arguments);
    // };
    // window.Greet("init", {
    //   domain: "https://projectg.talkbox.net",
    //   apiKey: "FrZrDIIADn92JeXTChL8fFzotTfIfa2kekbj"
    // });
    // window.Greet("show");

    // document.body.appendChild(sdk);

    ReactDOM.render(
      <CookiesProvider>
        <HashRouter>
          <SocketContainer.Provider>
            <MessengerContainer.Provider>
              <AppContainer.Provider>
                <App />
              </AppContainer.Provider>
            </MessengerContainer.Provider>
          </SocketContainer.Provider>
        </HashRouter>
      </CookiesProvider>,
      document.getElementById("root")
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
