const getStringTypedOperators = () => [
  {
    label: "欄位存在",
    value: "has_value"
  },
  {
    label: "欄位不存在",
    value: "is_unknown"
  },
  {
    label: "等於",
    value: "=",
    operand: {
      type: "string"
    }
  },
  {
    label: "前綴",
    value: "starts_with",
    operand: {
      type: "string"
    }
  },
  {
    label: "後綴",
    value: "ends_with",
    operand: {
      type: "string"
    }
  },
  {
    label: "包含",
    value: "contains",
    operand: {
      type: "string"
    }
  },
  {
    label: "不包含",
    value: "not_contains",
    operand: {
      type: "string"
    }
  }
];

const getDateTypedOperators = () => [
  {
    label: "等於",
    value: "=",
    operand: {
      type: "date"
    }
  },
  {
    label: "大於",
    value: ">",
    operand: {
      type: "date"
    }
  },
  {
    label: "大於或等於",
    value: ">=",
    operand: {
      type: "date"
    }
  },
  {
    label: "小於",
    value: "<",
    operand: {
      type: "date"
    }
  },
  {
    label: "小於或等於",
    value: "<=",
    operand: {
      type: "date"
    }
  }
];

const getAnniversaryTypedOperators = () => [
  {
    label: "等於",
    value: "=",
    operand: {
      type: "anniversary"
    }
  },
  {
    label: "大於",
    value: ">",
    operand: {
      type: "anniversary"
    }
  },
  {
    label: "大於或等於",
    value: ">=",
    operand: {
      type: "anniversary"
    }
  },
  {
    label: "小於",
    value: "<",
    operand: {
      type: "anniversary"
    }
  },
  {
    label: "小於或等於",
    value: "<=",
    operand: {
      type: "anniversary"
    }
  }
];

export const getCriteriaSchema = ({ customFields, getTextByLang }) => [
  {
    value: "displayName",
    label: "名稱",
    operators: getStringTypedOperators()
  },
  {
    value: "refId",
    label: "參考號",
    operators: getStringTypedOperators()
  },
  {
    value: "email",
    label: "電郵",
    operators: getStringTypedOperators()
  },
  {
    value: "subtitle",
    label: "副題",
    operators: getStringTypedOperators()
  },
  {
    value: "bio",
    label: "座右銘",
    operators: getStringTypedOperators()
  },
  {
    value: "lastActivity",
    label: "最後線上時間",
    operators: getDateTypedOperators()
  },
  {
    value: "createDate",
    label: "建立日期",
    operators: getDateTypedOperators()
  },
  ...(customFields ?? []).map(({ id, name, type }) => ({
    value: `fields.${id}`,
    label: getTextByLang(name),
    operators: (type => {
      switch (type) {
        case "date":
          return getDateTypedOperators();
        case "anniversary":
          return getAnniversaryTypedOperators();
        case "text":
        case "email":
        case "long_text":
        case "url":
        case "phone":
        default:
          return getStringTypedOperators();
      }
    })(type)
  }))
];
