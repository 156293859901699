import { HStack, Text } from "@chakra-ui/react";
import AppContainer from "@projectg/utils/store/app";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const OfficeHourMessage = ({
  label = "",
  officeHour = { enable: false, start: null, end: null },
}) => {
  const { getTexts } = AppContainer.useContainer();

  const getHourText = (time = "00:00") =>
    dayjs(time, "HH:mm").format("HH:mm a");

  const { enable, start, end } = officeHour;

  return enable ? (
    <HStack>
      <Text>{label} :</Text>
      <Text>{getHourText(start || "00:00")}</Text>
      <Text>{getTexts("To")}</Text>
      <Text>{getHourText(end || "23:30")}</Text>
    </HStack>
  ) : null;
};
