import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdArrowForward, MdCheck, MdClose } from "react-icons/md";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import queryString from "querystring";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import PreviewImage from "@projectg/utils/components/PreviewImage";
import {
  AlertModal,
  CopyClipboardButton,
} from "../../../../components/components";

import Card from "../card";

import UpsertFacebookPageDrawer from "./upsertFacebookPageDrawer";

import FB_AppSecret from "@projectg/console-app/src/images/facebook/FB_AppSecret.png";
import FB_RegisterDeveloper from "@projectg/console-app/src/images/facebook/FB_RegisterDeveloper.png";
import FB_CreateApp from "@projectg/console-app/src/images/facebook/FB_CreateApp.png";
import FB_CreateMessenger from "@projectg/console-app/src/images/facebook/FB_CreateMessenger.png";
import FB_MessengerWebhooks from "@projectg/console-app/src/images/facebook/FB_MessengerWebhooks.png";
import FB_MessengerAddCallbackUrl from "@projectg/console-app/src/images/facebook/FB_MessengerAddCallbackUrl.png";
import FB_MessengerAddOrRemovePage from "@projectg/console-app/src/images/facebook/FB_MessengerAddOrRemovePage.png";
import FB_MessengerAddSubscriptions from "@projectg/console-app/src/images/facebook/FB_MessengerAddSubscriptions.png";
import FB_MessengerEditSubscriptions from "@projectg/console-app/src/images/facebook/FB_MessengerEditSubscriptions.png";
import FB_PageConnectGetDetail from "@projectg/console-app/src/images/facebook/FB_PageConnectGetDetail.png";

const ImportantAction = chakra("span", {
  baseStyle: {
    cursor: "default",
    fontWeight: "bold",
    borderRadius: 5,
    bg: "primary.500",
    color: "white",
    px: 2,
    py: 1,
  },
});

const ImportantText = chakra("span", {
  baseStyle: {
    fontWeight: "bold",
  },
});

const FacebookSetupStep = ({
  children,
  title,
  pageText,
  previousBtnIsLoading = false,
  previousBtnOnClick = () => {},
  previousBtnIsVisible = true,
  previousBtnText,
  previousBtnIcon = <MdArrowBack />,
  previousBtnProps,
  cancelBtnIsLoading = false,
  cancelBtnOnClick = () => {},
  cancelBtnIsVisible = true,
  cancelBtnText,
  cancelBtnIcon,
  cancelBtnProps,
  nextBtnIsLoading = false,
  nextBtnOnClick = () => {},
  nextBtnIsVisible = true,
  nextBtnText,
  nextBtnIcon = <MdArrowForward />,
  nextBtnProps,
  skipBtnOnClick = () => {},
  skipBtnIsVisible = false,
  skipBtnText,
  skipBtnIcon,
  skipBtnProps,
}) => {
  const { getTexts } = AppContainer.useContainer();
  const pBtnText = previousBtnText ?? getTexts("PreviousStep");
  const cBtnText = cancelBtnText ?? getTexts("Cancel");
  const nBtnText = nextBtnText ?? getTexts("NextStep");
  const sBtnText = skipBtnText ?? getTexts("SkipStep");

  const actionBar = () => (
    <Flex my={4}>
      <Flex w="33%">
        {previousBtnIsVisible && (
          <Button
            mr={4}
            colorScheme="primary"
            leftIcon={previousBtnIcon}
            isLoading={previousBtnIsLoading}
            onClick={previousBtnOnClick}
            {...previousBtnProps}
          >
            {pBtnText}
          </Button>
        )}
        {cancelBtnIsVisible && (
          <Button
            leftIcon={cancelBtnIcon}
            isLoading={cancelBtnIsLoading}
            onClick={cancelBtnOnClick}
            {...cancelBtnProps}
          >
            {cBtnText}
          </Button>
        )}
      </Flex>
      <Flex
        w="100%"
        minW={0}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        {pageText}
      </Flex>
      <Flex w="33%" justifyContent="flex-end">
        {skipBtnIsVisible && (
          <Button
            rightIcon={skipBtnIcon}
            onClick={skipBtnOnClick}
            mr={4}
            {...skipBtnProps}
          >
            {sBtnText}
          </Button>
        )}
        {nextBtnIsVisible && (
          <Button
            colorScheme="primary"
            rightIcon={nextBtnIcon}
            isLoading={nextBtnIsLoading}
            onClick={nextBtnOnClick}
            {...nextBtnProps}
          >
            {nBtnText}
          </Button>
        )}
      </Flex>
    </Flex>
  );

  return (
    <Box h="100%" color="gray.500">
      {actionBar()}
      <Heading color="black" size="lg">
        {title}
      </Heading>
      <Flex h="100%" flexDir="column" fontSize="lg" p={4}>
        <Box h="100%" minH={0} flex="auto">
          {children}
        </Box>
      </Flex>
      {actionBar()}
    </Box>
  );
};

const FacebookSetup = ({ platform }) => {
  const { getTexts, setExternalList } = AppContainer.useContainer();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const cancelRef = React.useRef();

  const upsertFacebookPageDrawer = useDisclosure();

  const query = useMemo(() => queryString.parse(location.search.substr(1)), [
    location.search,
  ]);

  const { control, errors, handleSubmit, reset } = useForm();

  const [selectedFacebookPageParams, setSelectedFacebookPageParams] = useState({
    platform,
  });

  const removeFacebookPageAlert = useDisclosure();
  const [
    removeFacebookPageIsLoading,
    setRemoveFacebookPageIsLoading,
  ] = useState(false);

  const onRemoveFacebookPage = async () => {
    if (selectedFacebookPageParams?.page?.id)
      try {
        setRemoveFacebookPageIsLoading(true);
        const { data } = await api.post({
          url: "/api/admin/setting/externalPlatform/facebook/page/delete",
          body: {
            id: selectedFacebookPageParams.platform.id,
            pageId: selectedFacebookPageParams.page.id,
          },
        });
        setExternalList(data);
        removeFacebookPageAlert.onClose();
        toast({
          title: getTexts("FacebookPageRemoved"),
          status: "success",
        });
      } catch (err) {
      } finally {
        setRemoveFacebookPageIsLoading(false);
      }
  };

  const [upsertFacebookIsLoading, setUpsertFacebookIsLoading] = useState(false);

  const onUpsertFacebook = useCallback(
    async (data, callback = () => {}) => {
      try {
        setUpsertFacebookIsLoading(true);
        await api.post({
          url: `/api/admin/setting/externalPlatform/facebook/${
            platform?.id ? "update" : "create"
          }`,
          body: {
            id: platform?.id ?? undefined,
            ...data,
          },
        });
        reset({ appSecret: "" });
        toast({
          title: `${
            platform?.id
              ? getTexts("FacebookAppSecretUpdated")
              : getTexts("FacebookConnectionCreated")
          }`,
          status: "success",
        });
        callback();
      } catch (error) {
        // console.log(error);
      } finally {
        setUpsertFacebookIsLoading(false);
      }
    },
    [platform?.id, getTexts, reset, toast]
  );

  const facebookSetupSectionSwitch = page => {
    switch (page) {
      case "1":
        return (
          <FacebookSetupStep
            title={getTexts("FacebookSetupS1Title")}
            previousBtnIsVisible={false}
            cancelBtnOnClick={() =>
              history.push({
                ...location,
                search: "",
              })
            }
            cancelBtnText={getTexts("Cancel")}
            cancelBtnIcon={null}
            nextBtnIsLoading={upsertFacebookIsLoading}
            nextBtnOnClick={handleSubmit(data =>
              onUpsertFacebook(data, () =>
                history.push({
                  ...location,
                  search: "facebookSetup=2",
                })
              )
            )}
            skipBtnIsVisible={platform?.id ? true : false}
            skipBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=2",
              })
            }
            pageText="1/4"
          >
            <OrderedList styleType="lower-alpha" lineHeight={2} spacing={8}>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS1a1")}`}{" "}
                  <Link
                    isExternal
                    color="blue.500"
                    href="https://developers.facebook.com/apps/"
                  >
                    {`${getTexts("FacebookSetupS1a2")}`}
                  </Link>
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS1b1")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS1b2"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS1b3")}`}
                </Text>
                <PreviewImage src={FB_RegisterDeveloper} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS1c1")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS1c2"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS1c3")}`}
                </Text>
                <PreviewImage src={FB_CreateApp} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS1d1")} `}
                  <ImportantText>{`${getTexts(
                    "FacebookSetupS1d2"
                  )} `}</ImportantText>
                  <Icon as={MdArrowForward} />
                  <ImportantText>{` ${getTexts(
                    "FacebookSetupS1d3"
                  )} `}</ImportantText>
                  {`${getTexts("FacebookSetupS1d4")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS1d5"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS1d6")} `}
                  <ImportantText>{getTexts("AppSecret")}</ImportantText>
                </Text>
                <PreviewImage src={FB_AppSecret} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS1e1")} `}
                  <ImportantText>{getTexts("AppSecret")}</ImportantText>
                  {` ${getTexts("FacebookSetupS1e2")} `}
                  <ImportantAction>{getTexts("NextStep")}</ImportantAction>
                  {` ${getTexts("FacebookSetupS1e3")} `}
                </Text>
                <Box px={2} py={4} color="black">
                  <FormControl isRequired isInvalid={errors.appSecret?.message}>
                    <FormLabel htmlFor="name">
                      {getTexts("AppSecret")}
                    </FormLabel>
                    <Flex>
                      <Controller
                        as={<Input />}
                        name="appSecret"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: `${getTexts("PleaseInput")} ${getTexts(
                            "AppSecret"
                          )}`,
                        }}
                      />
                    </Flex>
                    <FormErrorMessage>
                      {errors.appSecret?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Text fontSize="sm" fontStyle="italic" color="gray.400">
                  {`${getTexts("FacebookSetupS1e4")} `}
                  <ImportantText>{getTexts("AppSecret")}</ImportantText>
                  {` ${getTexts("FacebookSetupS1e5")}`}
                </Text>
              </ListItem>
            </OrderedList>
          </FacebookSetupStep>
        );

      case "2":
        return (
          <FacebookSetupStep
            title={getTexts("FacebookSetupS2Title")}
            pageText="2/4"
            cancelBtnOnClick={() =>
              history.push({
                ...location,
                search: "",
              })
            }
            previousBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=1",
              })
            }
            nextBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=3",
              })
            }
          >
            <OrderedList styleType="lower-alpha" lineHeight={2} spacing={8}>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS2a1")} `}
                  <ImportantText>{`${getTexts(
                    "FacebookSetupS2a2"
                  )} `}</ImportantText>
                  <Icon as={MdArrowForward} />
                  <ImportantText>{` ${getTexts(
                    "FacebookSetupS2a3"
                  )} `}</ImportantText>
                  <Icon as={MdArrowForward} />
                  <ImportantText>{` ${getTexts(
                    "FacebookSetupS2a4"
                  )} `}</ImportantText>
                  {` ${getTexts("FacebookSetupS2a5")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS2a6"
                  )}`}</ImportantAction>
                </Text>
                <PreviewImage src={FB_CreateMessenger} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS2b1")} `}
                  <ImportantText>{`${getTexts(
                    "FacebookSetupS2b2"
                  )}`}</ImportantText>{" "}
                  {` ${getTexts("FacebookSetupS2b3")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS2b4"
                  )}`}</ImportantAction>
                </Text>
                <PreviewImage src={FB_MessengerWebhooks} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS2c1")} `}
                  <ImportantText>{getTexts("WebhookUrl")}</ImportantText>
                  {` ${getTexts("FacebookSetupS2c2")} `}
                  <ImportantText>{getTexts("VerifyToken")}</ImportantText>
                  {` ${getTexts("FacebookSetupS2c3")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS2c4"
                  )}`}</ImportantAction>
                </Text>
                <PreviewImage src={FB_MessengerAddCallbackUrl} />
                <Stack px={2} py={4} color="black" spacing={4} fontSize="md">
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("WebhookUrl")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Input
                          value={platform?.webhookUrl}
                          readOnly
                          placeholder="We can't find your Facebook Connection, Please setup your Facebook Application"
                        />
                        {platform?.webhookUrl && (
                          <CopyClipboardButton content={platform?.webhookUrl} />
                        )}
                      </Flex>
                    </Box>
                  </Stack>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("VerifyToken")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Input
                          value={platform?.verifyToken}
                          readOnly
                          placeholder="We can't find your Facebook Connection, Please setup your Facebook Application"
                        />
                        {platform?.verifyToken && (
                          <CopyClipboardButton
                            content={platform?.verifyToken}
                          />
                        )}
                      </Flex>
                    </Box>
                  </Stack>
                </Stack>
              </ListItem>
            </OrderedList>
          </FacebookSetupStep>
        );

      case "3":
        return (
          <FacebookSetupStep
            title={getTexts("FacebookSetupS3Title")}
            pageText="3/4"
            cancelBtnOnClick={() =>
              history.push({
                ...location,
                search: "",
              })
            }
            previousBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=2",
              })
            }
            nextBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=4",
              })
            }
          >
            <OrderedList styleType="lower-alpha" lineHeight={2} spacing={8}>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS3a1")} `}
                  <ImportantText>{`${getTexts(
                    "FacebookSetupS3a2"
                  )}`}</ImportantText>
                  {` ${getTexts("FacebookSetupS3a3")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS3a4"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS3a5")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS3a6"
                  )}`}</ImportantAction>{" "}
                  <Icon as={MdArrowForward} />{" "}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS3a7"
                  )}`}</ImportantAction>
                </Text>
                <PreviewImage src={FB_MessengerAddOrRemovePage} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS3b1")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS3b2"
                  )}`}</ImportantAction>
                </Text>
                <PreviewImage src={FB_MessengerAddSubscriptions} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS3c1")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS3c2"
                  )}`}</ImportantAction>
                </Text>
                <OrderedList pl={8} mb={4}>
                  <ListItem>messages</ListItem>
                  <ListItem>messaging_postbacks</ListItem>
                </OrderedList>
                <PreviewImage src={FB_MessengerEditSubscriptions} />
              </ListItem>
            </OrderedList>
          </FacebookSetupStep>
        );

      case "4":
        return (
          <FacebookSetupStep
            title={getTexts("FacebookSetupS4Title")}
            cancelBtnOnClick={() =>
              history.push({
                ...location,
                search: "",
              })
            }
            nextBtnProps={{ colorScheme: "green" }}
            nextBtnIcon={<MdCheck />}
            nextBtnText={getTexts("Complete")}
            pageText="4/4"
            previousBtnOnClick={() =>
              history.push({
                ...location,
                search: "facebookSetup=3",
              })
            }
            nextBtnOnClick={() =>
              history.push({
                ...location,
                search: "",
              })
            }
          >
            <OrderedList styleType="lower-alpha" lineHeight={2} spacing={8}>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS4a1")} `}
                  <ImportantText>{getTexts("AccessToken")}</ImportantText>
                  {` ${getTexts("FacebookSetupS4a2")} `}
                  <ImportantText>{getTexts("PageName")}</ImportantText>
                  {` ${getTexts("FacebookSetupS4a3")} `}
                  <ImportantText>{getTexts("PageId")}</ImportantText>
                  {` ${getTexts("FacebookSetupS4a4")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookSetupS4a5"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS4a6")} `}
                  <ImportantText>{getTexts("AccessToken")}</ImportantText>
                </Text>
                <PreviewImage src={FB_PageConnectGetDetail} />
              </ListItem>
              <ListItem>
                <Text>
                  {`${getTexts("FacebookSetupS4b1")} `}
                  <ImportantAction>{`${getTexts(
                    "FacebookPageConnect"
                  )}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS4b2")} `}
                  <ImportantText>{getTexts("PageName")}</ImportantText>
                  {` ${getTexts("FacebookSetupS4b3")} `}
                  <ImportantText>{getTexts("PageId")}</ImportantText>
                  {` ${getTexts("FacebookSetupS4b4")} `}
                  <ImportantText>{getTexts("AccessToken")}</ImportantText>
                  {`${getTexts("FacebookSetupS4b5")} `}
                  <ImportantAction>{`${getTexts("Create")}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS4b6")} `}
                </Text>

                <Flex
                  lineHeight="1.5"
                  color="black"
                  alignItems="flex-end"
                  mt={8}
                >
                  <Flex w="100%" minW={0} flex={1}>
                    <Heading size="lg">{getTexts("FacebookPage")}</Heading>
                  </Flex>
                  <Button
                    colorScheme="primary"
                    onClick={() => {
                      setSelectedFacebookPageParams({ platform });
                      upsertFacebookPageDrawer.onOpen();
                    }}
                  >
                    {getTexts("FacebookPageConnect")}
                  </Button>
                </Flex>
                {platform?.pages?.length ? (
                  platform?.pages?.map((page, i) => (
                    <Box
                      lineHeight="1.5"
                      color="black"
                      as={Card}
                      key={i}
                      py={4}
                      cursor="pointer"
                      onClick={() => {
                        setSelectedFacebookPageParams(_ => ({ ..._, page }));
                        upsertFacebookPageDrawer.onOpen();
                      }}
                    >
                      <Box pos="relative">
                        <IconButton
                          pos="absolute"
                          top="0px"
                          right="0px"
                          icon={<MdClose />}
                          variant="ghost"
                          size="sm"
                          fontSize="lg"
                          isRound
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedFacebookPageParams(_ => ({
                              ..._,
                              page,
                            }));
                            removeFacebookPageAlert.onOpen();
                          }}
                        />
                      </Box>
                      <Stack flex={1} minW={0} w="100%" spacing={1}>
                        <Text w="100%" fontWeight="bold">
                          {page?.name}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {`${getTexts("ResponseWindowHours")}: ${
                            page?.responseWindowHours
                          }`}
                        </Text>
                        <Box flex={1}>
                          {page?.tags?.map(({ name, tag, description }) => (
                            <Tag
                              colorScheme="secondary"
                              mr={2}
                              size="sm"
                              key={tag}
                            >
                              <Tooltip label={description}>{name}</Tooltip>
                            </Tag>
                          ))}
                        </Box>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Card>
                    <Center>
                      <Text fontSize="xl" color="gray.400">
                        {getTexts("FacebookPageListEmpty")}
                      </Text>
                    </Center>
                  </Card>
                )}
              </ListItem>
              <ListItem>
                <Text>
                  {` ${getTexts("FacebookSetupS4c1")} `}
                  <ImportantAction>{`${getTexts("Complete")}`}</ImportantAction>
                  {` ${getTexts("FacebookSetupS4c2")} `}
                </Text>
              </ListItem>
            </OrderedList>
          </FacebookSetupStep>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {upsertFacebookPageDrawer.isOpen && (
        <UpsertFacebookPageDrawer
          isOpen={upsertFacebookPageDrawer.isOpen}
          onClose={upsertFacebookPageDrawer.onClose}
          params={selectedFacebookPageParams}
        />
      )}
      <AlertModal
        isOpen={removeFacebookPageAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeFacebookPageAlert.onClose}
        isLoading={removeFacebookPageIsLoading}
        onSubmit={onRemoveFacebookPage}
        title={getTexts("FacebookPageRemove")}
      />

      <Box maxH="100%">
        {platform?.id
          ? facebookSetupSectionSwitch(query?.facebookSetup)
          : facebookSetupSectionSwitch("1")}
      </Box>
    </>
  );
};

export default FacebookSetup;
