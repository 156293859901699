import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";

const UpsertSanukerDrawer = ({ isOpen, onClose, platform }) => {
  const toast = useToast();
  const { getTexts, setExternalList } = AppContainer.useContainer();

  const isEdit = platform?.id ? true : false;

  const [upsertSanukerIsLoading, setUpsertSanukerIsLoading] = useState(false);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      organizationId: platform?.organizationId,
      projectGChannelId: platform?.projectGChannelId,
    },
  });

  const onSubmit = async data => {
    try {
      setUpsertSanukerIsLoading(true);
      const { data: resData } = await api.post({
        url: "/api/admin/setting/externalPlatform/sanuker/update",
        body: {
          id: platform.id,
          ...data,
        },
      });
      setExternalList(resData);
      toast({
        title: isEdit ? getTexts("SanukerUpdated") : getTexts("SanukerCreated"),
        status: "success",
      });
      onClose();
    } catch (err) {
    } finally {
      setUpsertSanukerIsLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEdit ? getTexts("SanukerUpdate") : getTexts("SanukerCreate")}
          </DrawerHeader>

          <DrawerBody>
            <List spacing={8}>
              <ListItem>
                <FormControl
                  // isRequired
                  isInvalid={errors.organizationId?.message}
                >
                  <FormLabel htmlFor="organizationId">
                    {getTexts("OrganizationId")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="organizationId"
                    defaultValue=""
                    // rules={{
                    //   required: `${getTexts("PleaseInput")} ${getTexts(
                    //     "OrganizationId"
                    //   )}`,
                    // }}
                  />
                  <FormErrorMessage>
                    {errors.organizationId?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl
                  // isRequired
                  isInvalid={errors.projectGChannelId?.message}
                >
                  <FormLabel htmlFor="projectGChannelId">
                    {getTexts("ProjectGChannelId")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="projectGChannelId"
                    defaultValue=""
                    // rules={{
                    //   required: `${getTexts("PleaseInput")} ${getTexts(
                    //     "ProjectGChannelId"
                    //   )}`,
                    // }}
                  />
                  <FormErrorMessage>
                    {errors.projectGChannelId?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
            </List>
          </DrawerBody>

          <DrawerFooter>
            <Flex mt={8} w="100%">
              <Box flex={1} minW={0} w="100%"></Box>
              <Stack direction="row">
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {getTexts("Cancel")}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={upsertSanukerIsLoading}
                >
                  {isEdit ? getTexts("Update") : getTexts("Create")}
                </Button>
              </Stack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UpsertSanukerDrawer;
