import { Flex, Text, IconButton, Box } from "@chakra-ui/react";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { humanifyDate } from "@projectg/utils/utils/helpers";
import { useGetLastMessageDisplay } from "../../utils/hooks";
import { useMemo } from "react";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";
const Recent = () => {
  const history = useHistory();
  const { hasUnread, conversations } = MessengerContainer.useContainer();
  const getLastMessageDisplay = useGetLastMessageDisplay();
  const { session } = AppContainer.useContainer();

  const filteredConversations = useMemo(() => {
    return Object.values(conversations).sort((a, b) =>
      a.orderTimstamp < b.orderTimstamp ? 1 : -1
    );
  }, [conversations]);

  return (
    <Flex w="100%" h="100%" flexDir="column">
      <Flex
        h={150}
        w="100%"
        position="relative"
        flexDir="column"
        justifyContent="flex-end"
      >
        <Flex px={2} alignItems="center" color="primary._200">
          <IconButton
            icon={<MdArrowBack />}
            variant="ghost"
            zIndex={400}
            fontSize="2xl"
            colorScheme="primary"
            color="primary._200"
            onClick={() => history.push("/")}
          />
          <Text fontSize="2xl" fontWeight="bold" fontFamily="Roboto Slab" p={2}>
            最近對話
          </Text>
          <Box flex={1} minW={0} w="100%" />
        </Flex>
      </Flex>
      <Flex
        flex={1}
        minH={0}
        h="100%"
        alignItems="stretch"
        justifyContent="stretch"
        overflow="auto"
        flexDirection="column"
      >
        {filteredConversations.length === 0 ? (
          <Box
            m={2}
            color="#999"
            alignSelf="center"
            justify="center"
            textAlign="center"
          >
            沒有相關對話
          </Box>
        ) : (
          filteredConversations.map((conversation, index) => {
            const id = conversation?.id;
            return (
              <Link key={id} to={`/conversation/${id}`}>
                <Box
                  _hover={{
                    bg: "#f1f1f1"
                  }}
                  bg={"transparent"}
                  position="relative"
                >
                  {/* <Flex
                    h="100%"
                    w="100%"
                    position="absolute"
                    bg="rgba(255,255,255,0.7)"
                    {...(matched && { display: "none" })}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {conversation?._status && (
                      <Text fontWeight="bold">
                        此對話狀態已更改為
                        {
                          statusOptions.find(
                            o => o?.value === conversation?.status
                          )?.label
                        }
                      </Text>
                    )}

                    {conversation?._assignedAgent &&
                      (conversation?.assignedAgent?.length > 0 ? (
                        <Text fontWeight="bold">此對話已分派予其他代理</Text>
                      ) : (
                        <Text fontWeight="bold">此對話已改為未分派</Text>
                      ))}
                    {conversation?._team &&
                      (conversation?.team?.length > 0 ? (
                        <Text fontWeight="bold">此對話已分派予其他團隊</Text>
                      ) : (
                        <Text fontWeight="bold">此對話沒有分派至任何團隊</Text>
                      ))}
                  </Flex> */}
                  <Flex
                    cursor="pointer"
                    alignSelf="stretch"
                    p={4}
                    flexDirection="column"
                    key={id}
                  >
                    <Flex align="center">
                      <Box
                        mr={2}
                        bg={conversation?.online ? "primary.300" : "gray.300"}
                        borderRadius="50%"
                        w="6px"
                        h="6px"
                      />
                      <Flex flex={1} align="center">
                        <Text
                          fontWeight="bold"
                          color="gray.700"
                          mr={1}
                          fontSize="md"
                          fontFamily="Roboto"
                        >
                          {conversation?.name}
                        </Text>
                      </Flex>
                      <Box color="#bbb" fontSize="80%">
                        {humanifyDate(conversation.orderTimestamp, "zh")}
                      </Box>
                    </Flex>
                    <Flex mr={1} mt={1} align="center">
                      {getLastMessageDisplay(
                        conversation,
                        session?.user?.userId
                      )}
                      {hasUnread(conversation, session?.user?.userId) && (
                        <Box
                          bg="secondary.500"
                          borderRadius="50%"
                          w="6px"
                          h="6px"
                        />
                      )}
                    </Flex>
                  </Flex>
                </Box>
              </Link>
            );
          })
        )}
      </Flex>
    </Flex>
  );
};

export default Recent;
