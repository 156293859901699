import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Link,
  Divider
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import logo from "@projectg/utils/assets/logo.png";
import api from "@projectg/utils/utils/api";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../utils/helpers";
import Langs from "../../components/LanguagesPicker";
import AppContainer from "@projectg/utils/store/app";

const RESENT_REQUIRED_SEC = 60;

const ForgetPassword = () => {
  const { getTexts } = AppContainer.useContainer();
  const [error, setError] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [eMail, setEMail] = useState("");

  //timer
  const timerRef = useRef(null);
  const [remainSec, setRemainSec] = useState(null);
  const isFirstSend = remainSec === null;

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  //email post api
  const onAccept = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();
      setAccepting(true);
      setError(false);
      try {
        if (!validateEmail(eMail)) {
          throw new Error(getTexts("Incorrect_email_format"));
        }
        const res = await api.post({
          url: `/api/forgetPassword/request`,
          body: {
            email: eMail
          }
        });
        if (res.timestamp) {
          timerRef.current = setInterval(() => {
            setAccepting(false);
            setRemainSec(s => {
              if (s === 1) clearInterval(timerRef.current);
              return !s ? RESENT_REQUIRED_SEC : s - 1;
            });
          }, 1000);
          toast({
            title: getTexts("forgetPassword_email_sent"),
            description: getTexts("forgetPassword_check_your_email"),
            status: "info",
            duration: 3500,
            isClosable: true
          });
        }
      } catch (error) {
        setAccepting(false);
        setError(error);
      }
    },
    [eMail, getTexts, toast]
  );

  return (
    <Box as="form" onSubmit={onAccept} h="100%" position="relative">
      <svg
        viewBox="0 0 450 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%", position: "absolute" }}
      >
        <path
          d="M213.19,0.00 C152.69,70.06 270.03,70.06 202.98,150.00 L500.00,150.00 L500.00,0.00 Z"
          style={{ stroke: "none", fill: "#FFE0A1" }}
        ></path>
      </svg>
      <div style={{ top: "8px", right: "8px", position: "absolute" }}>
        <Langs />
      </div>
      <Flex
        h="100%"
        w="100%"
        position="absolute"
        zIndex={1}
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Flex
          flexDir="column"
          alignItems="stretch"
          justifyContent="center"
          maxW={400}
          w="100%"
          px={12}
          py={6}
          boxShadow="md"
          minH="60%"
          bg="white"
        >
          <Image mx="auto" src={logo} w={100} />
          <Text
            mt={3}
            as="h2"
            fontWeight="bold"
            fontSize="2xl"
            alignSelf="center"
            fontFamily="Roboto Slab"
          >
            {getTexts("ResetPassword")}
          </Text>
          <>
            <Text mt={12} fontSize="lg" alignSelf="center" textAlign="center">
              {getTexts("forgetPassword_fill_in_email")}
              <br />
              {getTexts("forgetPassword_link_will_be_sent_to_email")}
            </Text>
            <FormControl mt={6}>
              <FormLabel>{getTexts("Email")}</FormLabel>
              <Input
                fontSize="2l"
                mb={-1}
                focusBorderColor="primary"
                w="100%"
                value={eMail}
                onChange={({ target: { value } }) => {
                  setEMail(value);
                }}
              />
            </FormControl>
            <Text mt={12} color="error">
              {error?.message}
            </Text>
            <Button
              mt={3}
              size="lg"
              fontSize="xl"
              colorScheme="secondary"
              variant="solid"
              isLoading={accepting}
              htmltype="submit"
              type="submit"
              isDisabled={remainSec > 0}
            >
              {remainSec
                ? `${getTexts(
                    "forgetPassword_please_wait_for"
                  )}${remainSec}${getTexts("Second")}`
                : isFirstSend
                ? getTexts("Submit")
                : getTexts("ReSubmit")}
            </Button>
            <Divider />
            <Link onClick={() => history.push("/")}>
              {getTexts("reuturn_to_main_page")}
            </Link>
          </>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ForgetPassword;
