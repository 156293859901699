import { keyframes } from "@emotion/react";
import { FaDesktop, FaFacebook, FaGlobe, FaWhatsapp } from "react-icons/fa";

export const fadeOutAndIn = keyframes`
    from,  to {
        opacity: 0;
    }

    20%, 80% {
        opacity: 1;
    }
`;
export const Sources = {
  facebook: {
    icon: FaFacebook,
    name: "Facebook",
    color: "#3b5998",
  },
  whatsapp: {
    icon: FaWhatsapp,
    name: "Whatsapp",
    color: "#25d366",
  },
  sanuker: {
    icon: FaWhatsapp,
    name: "Sanuker",
    color: "#25d366",
  },
  api: {
    icon: FaGlobe,
    name: "API",
    color: "secondary.300",
  },
  web: {
    icon: FaDesktop,
    name: "Web",
    color: "secondary.300",
  },
};

// https://internal.talkbox.io/docs/#b/projectGAgentPermission.pdf
export const PermissionGroups = {
  all: "all",
  console: "console",
};

export const permissionGroupsOptions = [
  { value: PermissionGroups.all, label: "PermissionGroupAll" },
  {
    value: PermissionGroups.console,
    label: "PermissionGroupConsole",
  },
];

export const Permission = {
  report: "report",
  system: "system",
  manageSettings: "manageSettings",
  manageUser: "manageUser",
  ownConversations: "ownConversations",
  allConversations: "allConversations",
};

export const permissionOptions = [
  { value: Permission.report, label: "ViewReport" },
  { value: Permission.system, label: "UseSystemApi" },
  { value: Permission.manageSettings, label: "ManageSettings" },
  { value: Permission.manageUser, label: "Manager" },
  {
    value: Permission.ownConversations,
    label: "ViewTheConversationAboutMe",
  },
  {
    value: Permission.allConversations,
    label: "ViewAllConversations",
  },
];
