import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Sources } from "../utils/constants";
import api from "@projectg/utils/utils/api";
import { FaChevronRight, FaUsers } from "react-icons/fa";
import { BiAlarm, BiAlarmSnooze, BiPlus, BiSupport } from "react-icons/bi";
import { fetchAssetToObjectUrl, removeIf } from "@projectg/utils/utils/helpers";
import {
  MdCheck,
  MdGraphicEq,
  MdPause,
  MdPlayArrow,
  MdContentCopy,
} from "react-icons/md";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { emailRegex } from "@projectg/utils/utils/constants";
import MessengerContainer from "@projectg/utils/store/messenger";
import { autoSize, getSnoozeMessage } from "../utils/helpers";
import AppContainer from "@projectg/utils/store/app";
import dayjs from "dayjs";

export const SourceTag = forwardRef(
  ({ source: { type = "web", name } = {}, nameVisible = false }, ref) => {
    const {
      icon: Icon,
      name: defaultName,
      color = "primary.500",
    } = Sources?.[type] ?? Sources.web;
    return (
      <Tooltip label={name ?? defaultName}>
        <Flex>
          <TagLeftIcon as={Icon} color={color} fontSize="lg" />
          {nameVisible && (
            <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {defaultName}
            </Text>
          )}
        </Flex>
      </Tooltip>
    );
  }
);

export const LiveChatIndicator = forwardRef(
  (
    { livechat = false, editable = false, nameVisible = false, ...props },
    ref
  ) => {
    const { getTexts } = AppContainer.useContainer();
    return (
      <Tooltip
        label={
          !livechat
            ? getTexts("AutomaticConversation")
            : getTexts("PersonalConversation")
        }
      >
        {nameVisible ? (
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            size="sm"
            color={!livechat ? "gray.500" : "primary.800"}
            variant="link"
            colorScheme={!livechat ? "primary" : "gray"}
            isActive={livechat}
            leftIcon={<BiSupport />}
            fontSize="md"
            {...props}
          >
            {nameVisible && (
              <Text
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {!livechat
                  ? getTexts("AutomaticConversation")
                  : getTexts("PersonalConversation")}
              </Text>
            )}
          </Button>
        ) : (
          <Flex>
            <TagLeftIcon
              as={BiSupport}
              color={!livechat ? "gray.300" : "primary.800"}
              fontSize="lg"
              {...props}
            />
          </Flex>
        )}
      </Tooltip>
    );
  }
);

export const SnoozeIndicator = forwardRef(
  ({ snooze = 0, editable = false, ...props }, ref) => {
    if (!snooze) return null;
    const snoozeIsBefore = dayjs(snooze).isBefore(dayjs());

    return (
      <Tooltip label={getSnoozeMessage(snooze)}>
        <Flex>
          <TagLeftIcon
            as={BiAlarm}
            color={snoozeIsBefore ? "red.800" : "primary.800"}
            fontSize="lg"
            {...props}
          />
        </Flex>
      </Tooltip>
    );
  }
);

export const BadgeIndicator = ({
  enable = false,
  onColor = "secondary.500",
  offColor = "transparent",
  onLabel = undefined,
  offLabel = undefined,
  size = "7px",
  ...props
}) => {
  return (
    <Tooltip
      label={enable ? onLabel : offLabel}
      hidden={!(enable ? onLabel : offLabel)}
    >
      <Box p={2}>
        <Box
          {...props}
          bg={enable ? onColor : offColor}
          borderRadius="50%"
          w={size}
          h={size}
        />
      </Box>
    </Tooltip>
  );
};

export const Section = ({
  title,
  description,
  children,
  onExpand,
  isExpanded,
  ...props
}) => (
  <Box as={Box} my={4} boxShadow="md" borderRadius={8} {...props}>
    <Box p={8} py={4} onClick={onExpand} cursor="pointer">
      <Heading fontSize="xl">{title}</Heading>
      <Text fontSize="md" mt={1} color="gray.500">
        {description}
      </Text>
    </Box>
    <Collapse in={isExpanded}>
      <Box p={8}>{children}</Box>
    </Collapse>
  </Box>
);

export const TeamPicker = ({ isMultple = true, value, onChange, ...props }) => {
  const [teams, setTeams] = useState(null);
  const loading = teams === null;
  const { getTexts } = AppContainer.useContainer();
  useEffect(() => {
    (async () => {
      try {
        const { data: teams } = await api.get({
          url: "/api/admin/team",
          params: {
            limit: 0,
          },
        });
        setTeams(teams);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor="pointer" {...props}>
          <Box>
            {!value || value?.length === 0 ? (
              <Button variant="link" size="sm" colorScheme="primary">
                {getTexts("ChooseATeam")}
              </Button>
            ) : !isMultple ? (
              teams[value]?.name
            ) : (
              value?.map(id => (
                <Tag ml={2} key={id} variant="subtle" colorScheme="primary">
                  <TagLeftIcon as={FaUsers} />
                  <Text>
                    {(teams ?? [])?.find(({ id: _id }) => id === _id)?.name ||
                      `${getTexts("NonexistingTeam")} ${id}`}
                  </Text>
                </Tag>
              ))
            )}
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent zIndex={20} p={4} placement="bottom-start">
        <Stack maxH={300}>
          <Text color="gray.500" fontSize="sm">
            {getTexts("Team")}
          </Text>
          {loading ? (
            <Spinner mx="auto" color="primary.500" />
          ) : teams.length === 0 ? (
            <Text mx="auto" color="gray.500">
              {getTexts("NoTeam")}
            </Text>
          ) : (
            <Box overflow="auto" flex={1}>
              {teams.map(({ id, name, selected = value.includes(id) }) => (
                <Box
                  cursor="pointer"
                  alignItems="center"
                  as={Flex}
                  {...(selected && { bg: "gray:50" })}
                  _hover={{ bg: "gray.50" }}
                  p={2}
                  fontSize="md"
                  borderBottom="1px solid #eee"
                  onClick={() =>
                    onChange(
                      selected ? removeIf(value, t => t === id) : [...value, id]
                    )
                  }
                  key={id}
                  w="100%"
                >
                  <Text flex={1} minW={0} w="100%">
                    {name}
                  </Text>
                  {selected && <MdCheck ml={2} color="green" />}
                </Box>
              ))}
            </Box>
          )}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

export const AudioPlayer = ({ url, color, ...props }) => {
  const [blob, setBlob] = useState(null);
  useEffect(() => {
    (async () => {
      const blob = await fetchAssetToObjectUrl(url);
      if (blob) {
        setBlob(blob);
      }
    })();
  }, [url]);

  const audioRef = useRef(null);
  const playingTimerRef = useRef(null);
  const [timePlayed, setTimePlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);

  return (
    <Flex alignItems="center" py={2} px={2} w={200} {...props}>
      <Box fontSize="xl">
        <IconButton
          isLoading={!blob}
          variant="ghost"
          fontSize="lg"
          colorScheme="gray"
          size="sm"
          icon={playing ? <MdPause /> : <MdPlayArrow />}
          onClick={() => {
            if (!playing) {
              audioRef.current.play();
              setPlaying(true);
            } else {
              audioRef.current.pause();
              setPlaying(false);
            }
          }}
        />
      </Box>

      <Box
        as="audio"
        maxW={0}
        maxH={0}
        ref={audioRef}
        src={blob}
        controls={true}
        onLoadedMetadata={() => setDuration(audioRef.current.duration)}
        onPlay={() => {
          playingTimerRef.current = setInterval(() => {
            setTimePlayed(audioRef?.current?.currentTime);
          }, 100);
        }}
        onPause={() => {
          setPlaying(false);
          clearInterval(playingTimerRef.current);
        }}
        onEnded={() => {
          setPlaying(false);
          clearInterval(playingTimerRef.current);
        }}
      />
      <Slider
        flex={1}
        mx={4}
        value={timePlayed}
        max={duration}
        min={0}
        onChange={t => {
          audioRef.current.currentTime = t;
          setTimePlayed(t);
        }}
      >
        <SliderTrack bg="gray.300">
          <SliderFilledTrack bg="gray.400" />
        </SliderTrack>
        <SliderThumb boxSize={4}>
          <Box color="gray.300" as={MdGraphicEq} />
        </SliderThumb>
      </Slider>
      <Box color={color} pr={2} fontWeight="bold">{`${parseInt(
        (timePlayed || duration) / 60
      )}:${parseInt((timePlayed || duration) % 60)
        .toString()
        .padStart(2, "0")}`}</Box>
    </Flex>
  );
};

export const TemplateInputList = ({ conversationId, onChange, ...props }) => {
  const { getTexts, externalList } = AppContainer.useContainer();
  const { conversations, customers } = MessengerContainer.useContainer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalParams, setModalParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, errors } = useForm();

  const externalPlatformLastSent = useMemo(() => {
    const lastSent =
      customers[conversations[conversationId]?.customer[0]?.id]
        ?.externalPlatformLastSent;
    return lastSent && lastSent.length ? lastSent[0] : null;
  }, [conversationId, conversations, customers]);

  const templateList = (() => {
    if (externalList && externalPlatformLastSent)
      for (let platform of externalList) {
        if (platform.id === externalPlatformLastSent.platformId) {
          if (platform.type === "sanuker") {
            for (let channel of platform.channels) {
              if (channel.id === externalPlatformLastSent.channelId)
                return channel?.templates ?? [];
            }
          }
          if (platform.type === "facebook") {
            for (let page of platform.pages) {
              if (page.id === externalPlatformLastSent.channelId)
                return page?.tags ?? [];
            }
          }
        }
      }
    else return [];
  })();

  const onCloseModal = () => {
    onClose();
    setModalParams(null);
  };

  const onSubmit = async data => {
    try {
      const template = {
        name: modalParams.name,
        description: modalParams.description,
        key: modalParams.key ?? modalParams.tag,
      };
      let templateParams = {};
      let displayParams = [];
      switch (externalPlatformLastSent?.platformType) {
        case "whatsapp":
          templateParams = Object.keys(data).reduce((acc, key) => {
            const param = modalParams?.params[key];
            acc[`${param.component}/${param.index}`] = data[key];
            return acc;
          }, {});
          displayParams = Object.keys(data).reduce((acc, key, i) => {
            const param = modalParams?.params[key];
            acc[i] = { ...param, value: data[key] };
            return acc;
          }, []);
          break;
        case "facebook":
          templateParams = {
            ...data,
          };
          displayParams = [
            {
              name: "input",
              value: data["1"],
            },
          ];
          break;
        default:
      }
      // console.log("meta", {
      //   ...template,
      //   params: templateParams,
      //   displayParams,
      // });
      onCloseModal();
      onChange({
        ...template,
        params: templateParams,
        displayParams,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderInputs = useCallback(() => {
    switch (externalPlatformLastSent?.platformType) {
      case "whatsapp":
        return (
          modalParams?.params && (
            <Stack mt={4} spacing={4}>
              {modalParams?.params?.map((param, i) => (
                <FormControl key={i} isInvalid={errors[`${i}`]}>
                  <FormLabel fontWeight="bold">{param.name}</FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name={`${i}`}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                  <FormHelperText>{param.description}</FormHelperText>
                  <FormErrorMessage>
                    {`${getTexts("PleaseInput")} ${param.name}`}
                  </FormErrorMessage>
                </FormControl>
              ))}
            </Stack>
          )
        );
      case "facebook":
        return (
          <Box mt={4}>
            <FormControl>
              <Controller
                as={<Input />}
                control={control}
                name={`1`}
                defaultValue=""
              />
            </FormControl>
          </Box>
        );
      default:
        return null;
    }
  }, [
    control,
    errors,
    externalPlatformLastSent,
    getTexts,
    modalParams?.params,
  ]);

  return externalList && externalPlatformLastSent ? (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalParams?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{modalParams?.description}</Box>
            {renderInputs()}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseModal}>
              {getTexts("Cancel")}
            </Button>
            <Button
              colorScheme="primary"
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                handleSubmit(onSubmit, () => setIsLoading(false))();
              }}
            >
              {getTexts("Submit")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack p={4} {...props}>
        <Text color="gray.500" fontSize="sm">
          {getTexts("TemplateMessage")} ({templateList?.length ?? 0})
        </Text>
        {templateList?.length ? (
          <Box overflow="auto" flex={1}>
            {templateList?.map(template => (
              <Box
                cursor="pointer"
                alignItems="center"
                as={Flex}
                _hover={{ bg: "gray.50" }}
                p={2}
                fontSize="md"
                borderBottom="1px solid #eee"
                onClick={() => {
                  setModalParams(template);
                  onOpen();
                }}
                key={template?.key || template?.tag}
                w="100%"
              >
                <Tooltip placement="top" label={template?.description}>
                  <Text flex={1} minW={0} w="100%">
                    {template?.name}
                  </Text>
                </Tooltip>
              </Box>
            ))}
          </Box>
        ) : (
          <Text mx="auto" color="gray.500">
            {getTexts("NoTemplateMessage")}
          </Text>
        )}
      </Stack>
    </>
  ) : null;
};

export const InlineInputList = ({ value, onChange, internal, ...props }) => {
  const { getTexts } = AppContainer.useContainer();
  const [inlineInputs, setInlineInputs] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: _inputs } = await api.get({
          url: "/api/admin/inlineInput",
          params: {
            limit: 0,
          },
        });
        setInlineInputs(_inputs.filter(_ => _.internal === internal));
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [internal]);
  return (
    <Stack p={4} {...props}>
      <Text color="gray.500" fontSize="sm">
        {getTexts("InteractiveInformation")} ({inlineInputs?.length ?? 0})
      </Text>
      {loading ? (
        <Spinner mx="auto" color="primary.500" />
      ) : inlineInputs.length === 0 ? (
        <Text mx="auto" color="gray.500">
          {getTexts("NoInteractiveInformation")}
        </Text>
      ) : (
        <Box overflow="auto" flex={1}>
          {inlineInputs.map(inlineInput => (
            <Box
              cursor="pointer"
              alignItems="center"
              as={Flex}
              _hover={{ bg: "gray.50" }}
              p={2}
              fontSize="md"
              borderBottom="1px solid #eee"
              onClick={() => onChange(inlineInput)}
              key={inlineInput?.id}
              w="100%"
            >
              <Text flex={1} minW={0} w="100%">
                {inlineInput?.name}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </Stack>
  );
};

export const InlineInputItem = ({
  sdk = false,
  conversationId,
  message: m,
  colorScheme,
  item: { title, inputId, type, canEdit = true, value },
  isReadOnly = false,
}) => {
  const { reset, control, register, handleSubmit, errors } = useForm({});
  const { upsertMessage } = MessengerContainer.useContainer();
  const isDisabled = useMemo(() => {
    if (!canEdit) return true;
    if (!sdk && !m?.internal) return true;
    if (isReadOnly) return true;

    return false;
  }, [canEdit, isReadOnly, m?.internal, sdk]);

  const { getTexts } = AppContainer.useContainer();

  useEffect(() => {
    reset({
      value,
    });
  }, [reset, value]);

  return (
    <Box mt={2} p={2} bg="white" border={1} key={inputId}>
      <Box
        as="form"
        onSubmit={handleSubmit(async ({ value, inputId, messageId }) => {
          try {
            const { data: _m } = await api.post({
              url: "/api/message/inlineInput/update",
              body: { value, inputId, messageId },
            });
            upsertMessage(conversationId, _m?.id, _m);
          } catch (error) {
            console.error(error);
          }
          return false;
        })}
      >
        <Input type="hidden" ref={register} name="inputId" value={inputId} />
        <Input type="hidden" ref={register} name="messageId" value={m?.id} />
        {(() => {
          switch (type) {
            case "rating":
              return (
                <FormControl isInvalid={errors?.value}>
                  <FormLabel>
                    {title && (
                      <Text
                        mt={2}
                        px={2}
                        color={`${colorScheme}.500`}
                        whiteSpace="pre-line"
                      >
                        {title}
                      </Text>
                    )}
                  </FormLabel>
                  <Box px={6} mt={4}>
                    <Controller
                      isDisabled={isDisabled}
                      name="value"
                      control={control}
                      defaultValue={value ? Number(value) : null}
                      render={({ onChange, value, onBlur, name }) => {
                        if (!sdk && !value && !m?.internal) {
                          return (
                            <Box
                              my={2}
                              textAlign="center"
                              color={`${colorScheme}.300`}
                            >
                              {getTexts("NotYetAnswered")}
                            </Box>
                          );
                        }
                        return (
                          <Slider
                            isDisabled={isDisabled}
                            name={name}
                            onBlur={onBlur}
                            value={value ?? 1}
                            max={5}
                            min={1}
                            step={1}
                            onChange={onChange}
                          >
                            <SliderTrack bg={`${colorScheme}.100`}>
                              <SliderFilledTrack bg={`${colorScheme}.200`} />
                            </SliderTrack>
                            <SliderThumb boxSize={6} bg={`${colorScheme}.300`}>
                              <Box
                                color={`${colorScheme}._500`}
                                fontFamily="Roboto Slab"
                                fontWeight="bold"
                              >
                                {value}
                              </Box>
                            </SliderThumb>
                          </Slider>
                        );
                      }}
                    ></Controller>
                  </Box>

                  {!isDisabled && (
                    <Flex w="100%" my={2} justifyContent="center">
                      <Button
                        type="submit"
                        variant="outline"
                        // isHover={true}
                        size="sm"
                        colorScheme={colorScheme}
                      >
                        {getTexts("SubmitRating")}
                      </Button>
                    </Flex>
                  )}
                </FormControl>
              );

            case "date":
            case "anniversary":
            case "text":
            case "longtext":
            case "phone":
            case "url":
            case "email":
              return (
                <FormControl isInvalid={errors?.value}>
                  <FormLabel>
                    {title && (
                      <Text
                        mt={2}
                        px={2}
                        color={`${colorScheme}.500`}
                        whiteSpace="pre-line"
                      >
                        {title}
                      </Text>
                    )}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      // autoFocus
                      name="value"
                      focusBorderColor={`${colorScheme}.500`}
                      errorBorderColor="red.500"
                      isInvalid={errors?.value}
                      ref={register({
                        required: getTexts("RequiredField"),
                        ...(type === "email" && {
                          pattern: {
                            value: emailRegex,
                            message: getTexts("PleaseEnterAValidEmail"),
                          },
                        }),
                      })}
                      placeholder={title}
                      defaultValue={value}
                      maxW={300}
                      isDisabled={isDisabled}
                      w="100%"
                    ></Input>
                    <InputRightElement>
                      <IconButton
                        type="submit"
                        size="sm"
                        variant="ghost"
                        colorScheme={colorScheme}
                        icon={<FaChevronRight />}
                        fontSize="lg"
                        isDisabled={isDisabled}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage color="red.500">
                    {errors?.value?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            default:
          }
        })()}
      </Box>
    </Box>
  );
};

export const InlineInputMessage = ({
  conversationId: id,
  colorScheme,
  bg,
  color,
  sdk,
  message: m,
  clearCellMeasurerCache = () => {},
  isReadOnly = false,
}) => {
  const { input: inputs = [], title, description } = m?.meta?.inlineInput;

  // const inputs = useMemo(() => {
  //   clearCellMeasurerCache();
  //   return input || [];
  // }, [clearCellMeasurerCache, input]);

  useEffect(() => {
    clearCellMeasurerCache();
  }, [clearCellMeasurerCache]);

  return (
    <Box p={2} borderRadius={4} bg={bg} color={color} w="100%">
      {title && (
        <Text
          mt={2}
          px={2}
          fontSize="lg"
          fontWeight="bold"
          color={`${colorScheme}.800`}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text px={2} color="#666" whiteSpace="pre-line">
          {description}
        </Text>
      )}
      {inputs.map(input => (
        <InlineInputItem
          conversationId={id}
          key={input.inputId}
          message={m}
          item={input}
          sdk={sdk}
          colorScheme={colorScheme}
          isReadOnly={isReadOnly}
        />
      ))}
    </Box>
  );
};

export const InlineInputBuilder = ({ id, bg, color, onRefresh }) => {
  const [upserting, setUpserting] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { appConfig, getTextByLang, getTexts } = AppContainer.useContainer();

  const initialValues = useMemo(
    () => ({
      name: "",
      title: "",
      description: "",
      input: [],
    }),
    []
  );

  const { handleSubmit, register, reset, control, errors } = useForm({
    defaultValues: initialValues,
  });

  const { fields: inputs, append } = useFieldArray({
    control,
    name: "input",
  });
  const builderRef = useRef(null);
  const autoSizeRef = useRef(false);
  const toast = useToast();

  useEffect(() => {
    if (!autoSizeRef.current) return;
    const elems = builderRef.current.getElementsByTagName("textarea");
    [...(elems ?? [])].forEach(x => {
      autoSize(x);
    });
    autoSizeRef.current = false;
  });

  const availableInputOptions = useMemo(
    () => [
      {
        label: getTexts("InquiryName"),
        ___localType: "updateField_name",
        params: {
          type: "text",
          action: {
            type: "updateField",
            field: "name",
          },
        },
      },
      {
        label: getTexts("InquiryEmail"),
        ___localType: "updateField_email",
        params: {
          type: "email",
          action: {
            type: "updateField",
            field: "email",
          },
        },
      },
      {
        label: getTexts("InquirySubtitle"),
        ___localType: "updateField_subtitle",
        params: {
          type: "text",
          action: {
            type: "updateField",
            field: "subtitle",
          },
        },
      },
      ...(appConfig?.customerFields ?? []).map(({ id, name, type }) => ({
        label: `${getTexts("InquiryField")}: ${getTextByLang(name)}`,
        ___localType: `updateCustomField_${id}`,
        params: {
          type: type,
          action: {
            type: "updateField",
            field: "customField",
            customField: id,
          },
        },
      })),
      {
        label: getTexts("InquiryCustomerSatisfaction"),
        ___localType: "updateRating",
        params: {
          type: "rating",
          action: {
            type: "updateRating",
          },
        },
      },
      {
        label: getTexts("InquiryCustomerOpinions"),
        ___localType: "updateComment",
        params: {
          type: "text",
          action: {
            type: "updateComment",
          },
        },
      },
    ],
    [appConfig?.customerFields, getTextByLang, getTexts]
  );

  const onRemove = useCallback(async () => {
    try {
      setRemoving(true);
      await api.post({
        url: `/api/admin/inlineInput/delete`,
        body: {
          id,
        },
      });

      onRefresh();
      toast({
        title: getTexts("ThisTemplateHasBeenDeleted"),
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: getTexts("CannotDeleteThisTemplate"),
        status: "error",
        duration: 2000,
      });
    } finally {
      setRemoving(false);
    }
  }, [getTexts, id, onRefresh, toast]);

  const onSubmit = useCallback(
    async ({ automation, ...values }) => {
      const params = {
        ...values,
        automation: automation?.type ? automation : "",
        input: (values?.input ?? []).map(
          ({ inputId, title, ___localType }) => ({
            inputId,
            title,
            ...(availableInputOptions?.find(
              ({ ___localType: value }) => value === ___localType
            )?.params ?? {}),
          })
        ),
      };
      if (id) {
        try {
          setUpserting(true);
          await api.post({
            url: `/api/admin/inlineInput/update`,
            body: {
              id,
              ...params,
            },
          });

          onRefresh();
          toast({
            title: getTexts("ThisTemplateHasBeenUpdated"),
            status: "success",
            duration: 2000,
          });
        } catch (error) {
          toast({
            title: getTexts("UnableToUpdateThisTemplate"),
            description: getTexts(
              "PleaseCheckIfThereAreFieldsThatsYetToFilled"
            ),
            status: "error",
            duration: 2000,
          });
        } finally {
          setUpserting(false);
        }
      } else {
        try {
          setUpserting(true);
          await api.post({
            url: `/api/admin/inlineInput/create`,
            body: {
              ...params,
            },
          });

          onRefresh();
          toast({
            title: getTexts("ThisTemplateHasBeenAdded"),
            status: "success",
            duration: 2000,
          });
        } catch (error) {
          toast({
            title: getTexts("UnableToSubmit"),
            description: getTexts(
              "PleaseCheckIfThereAreFieldsThatsYetToFilled"
            ),
            status: "error",
            duration: 2000,
          });
        } finally {
          setUpserting(false);
        }
      }
    },
    [availableInputOptions, getTexts, id, onRefresh, toast]
  );

  useEffect(() => {
    (async () => {
      if (!id) {
        reset(initialValues);
        return;
      }
      const { data: inlineInput } = await api.get({
        url: `/api/admin/inlineInput/${id}`,
      });

      reset(initialValues);
      reset({
        automation: {
          type: "",
        },
        ...inlineInput,
        input: (inlineInput?.input ?? []).map(
          ({ title, action: { type, field, customField }, inputId }) => ({
            title,
            id: inputId,
            inputId,
            ___localType: customField
              ? `updateCustomField_${customField}`
              : field
              ? `updateField_${field}`
              : type,
          })
        ),
      });
      autoSizeRef.current = true;
    })();
  }, [id, initialValues, reset]);

  return (
    <Stack
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      alignItems="stretch"
    >
      <FormControl mt={2}>
        <FormLabel>{getTexts("Name")}</FormLabel>
        <Input
          flex={1}
          minW={0}
          w="100%"
          ref={register}
          name="name"
          bg="transparent"
        />
      </FormControl>
      <FormControl mt={2}>
        <FormLabel>{getTexts("Description")}</FormLabel>
        <Textarea
          flex={1}
          minW={0}
          w="100%"
          ref={register}
          name="name"
          bg="transparent"
        />
      </FormControl>
      <FormControl mt={2}>
        <FormLabel>{getTexts("StartingMethod")}</FormLabel>
        <Select ref={register} name="automation.type">
          <option value="closeConversation">
            {getTexts("SendThisMessageWhenTheConversationIsClosed")}
          </option>
          <option value="newConversation">
            {getTexts("WhenThereIsANewConversationSendThisMessage")}
          </option>
          <option value="">{getTexts("SendManually")}</option>
        </Select>
      </FormControl>
      <FormControl mt={2}>
        <FormLabel>{getTexts("Counterpart")}</FormLabel>
        <Select ref={register} name="internal">
          <option value={false}>{getTexts("Customers")}</option>
          <option value={true}>{getTexts("InternalCommunication")}</option>
        </Select>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>{getTexts("Information")}</FormLabel>
        <Box flex={1} minW={0} w="100%">
          <Box
            mx="auto"
            ref={builderRef}
            bg={bg}
            p={2}
            color={color}
            borderRadius={4}
            overflowX="auto"
            w={300}
          >
            <Input
              ref={register}
              name="title"
              _hover={{ bg: "primary.50" }}
              variant="unstyled"
              placeholder={getTexts("FillInTheTitle")}
              p={2}
              fontSize="lg"
              fontWeight="bold"
              color="primary.800"
              bg="transparent"
            />
            <Textarea
              minH="auto"
              rows={1}
              resize="none"
              ref={register}
              _hover={{ bg: "primary.50" }}
              variant="unstyled"
              name="description"
              placeholder={getTexts("FillInTheDescription")}
              px={2}
              color="#666"
              whiteSpace="pre-line"
              bg="transparent"
              onChange={({ target }) => autoSize(target)}
            />
            {inputs.map(({ id, inputId, title, ___localType }, index) => (
              <Box mt={2} p={2} bg="white" border={1} key={id}>
                <Input
                  type="hidden"
                  ref={register({})}
                  name={`input[${index}].inputId`}
                  defaultValue={inputId ?? id}
                />
                <Box>
                  <FormControl>
                    <FormLabel>
                      <Input
                        ref={register({})}
                        name={`input[${index}].title`}
                        variant="unstyled"
                        placeholder={getTexts("FillInTheQuestion")}
                        defaultValue={title}
                        mt={2}
                        px={2}
                        color="primary.500"
                        whiteSpace="pre-line"
                        bg="transparent"
                      />
                    </FormLabel>

                    <Select
                      ref={register()}
                      name={`input[${index}].___localType`}
                      defaultValue={___localType}
                    >
                      {availableInputOptions.map(({ ___localType, label }) => (
                        <option key={___localType} value={___localType}>
                          {label}
                        </option>
                      ))}
                    </Select>

                    <FormErrorMessage color="red.500">
                      {errors?.value?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            ))}
            <Button
              mt={2}
              w="100%"
              variant="link"
              leftIcon={<BiPlus />}
              onClick={append}
            >
              {getTexts("AddAnswer")}
            </Button>
          </Box>
        </Box>
      </FormControl>
      <Flex justifyContent="flex-end" mt={8}>
        <Button
          variant="ghost"
          colorScheme="red"
          onClick={onRemove}
          isLoading={removing}
        >
          {getTexts("Remove")}
        </Button>
        <Button
          type="submit"
          isLoading={upserting}
          variant="solid"
          colorScheme="primary"
        >
          {getTexts("Save")}
        </Button>
      </Flex>
    </Stack>
  );
};

export const CopyClipboardButton = ({ content }) => {
  const { getTexts } = AppContainer.useContainer();
  const [hasCopied, setHasCopied] = useState(false);

  const label = hasCopied
    ? getTexts("CopiedToClipboard")
    : getTexts("CopyToClipboard");

  const onCopy = content => {
    navigator.clipboard.writeText(content);
    setHasCopied(true);
  };

  return navigator?.clipboard ? (
    <Tooltip
      hasArrow
      closeOnClick={false}
      label={label}
      onClose={() => setHasCopied(false)}
      placement="top"
    >
      <IconButton
        ml={4}
        aria-label={label}
        onClick={() => onCopy(content)}
        icon={hasCopied ? <MdCheck /> : <MdContentCopy />}
      />
    </Tooltip>
  ) : null;
};

export const AlertModal = ({
  isOpen,
  cancelRef,
  onClose,
  isLoading,
  onSubmit,
  title,
}) => {
  const { getTexts } = AppContainer.useContainer();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{getTexts("DeleteAlert")}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {getTexts("Cancel")}
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              onClick={onSubmit}
              ml={3}
            >
              {getTexts("Confirm")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
