import React from "react";
import AppContainer from "../store/app";
import MessengerContainer from "../store/messenger";
import {
  Box,
  Text,
  Flex,
  Spinner,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FaRobot } from "react-icons/fa";
import {
  MessageMetaFileSubTypes,
  MessageMetaTypes,
} from "@projectg/utils/utils/constants";
import { getBoundedImageSize } from "@projectg/utils/utils/helpers";
import AsyncImage from "./AsyncImage";
import MessageModal from "./MessageModal";

const ReplyMessage = ({ replyMeta, sdk, onImgLoad, ...props }) => {
  const {
    appConfig,
    getTextByLang,
    session,
    getTexts,
  } = AppContainer.useContainer();
  const { agents, customers } = MessengerContainer.useContainer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const outgoing =
    sdk || replyMeta?.internal
      ? replyMeta?.senderId === session?.user?.userId
      : replyMeta?.senderType !== "customer";

  const bg = replyMeta?.internal
    ? "secondary.100"
    : outgoing
    ? "primary.100"
    : "gray.50";

  const color = replyMeta?.internal
    ? "secondary._100"
    : outgoing
    ? "primary._100"
    : "black";

  const colorScheme = !replyMeta?.internal ? "primary" : "secondary";

  const sender = (message => {
    if (!message) {
      return <Text display="inline">{getTexts("Users")}</Text>;
    }
    const { senderType, senderId } = message;
    switch (senderType) {
      case "agent":
        return (
          <Text as="span" display="inline">
            {agents[senderId]?.displayName ?? getTexts("Agents")}
          </Text>
        );
      case "customer":
        return (
          <Text as="span" display="inline">
            {customers[senderId]?.displayName ?? getTexts("Customers")}
          </Text>
        );
      case "autoMessage":
        return (
          <Flex as="span" align="center">
            <Text as="span" display="inline-block" mr={1}>
              <FaRobot />
            </Text>
            <Text as="span" d="inline">
              {sdk
                ? getTextByLang(
                    appConfig?.landingPageDescriptio,
                    `${getTexts("ChatBot")}`
                  )
                : `${getTexts("ChatBot")}`}
            </Text>
          </Flex>
        );
      case "system":
        return (
          <Text as="span" display="inline" color="secondary">
            {getTexts("System")}
          </Text>
        );
      default:
    }
  })(replyMeta);

  const replyContent = (() => {
    switch (replyMeta?.meta?.type) {
      case MessageMetaTypes.TEXT:
        return (
          <Box>
            <Text noOfLines={1}>{replyMeta?.meta?.content}</Text>
          </Box>
        );

      case MessageMetaTypes.VOICE:
        return getTexts("SentAnAudio");

      case MessageMetaTypes.ELEMENT:
        return (
          <Box>
            <Box fontSize="md" fontWeight="bold" color="#666">
              <Text noOfLines={1}>
                {replyMeta?.meta?.element.elements[0].title}
              </Text>
            </Box>
            <Box color="#666">
              <Text noOfLines={1}>
                {replyMeta?.meta?.element.elements[0].description}
              </Text>
            </Box>
          </Box>
        );

      case MessageMetaTypes.FILE:
        const file = replyMeta?.meta.file;
        switch (replyMeta?.meta.subtype) {
          case MessageMetaFileSubTypes.IMAGE: {
            const isLocalImage = file instanceof Blob;
            const size = getBoundedImageSize(
              // file?.previewDimension?.width,
              // file?.previewDimension?.height,
              75,
              75,
              75,
              75
            );
            return (
              <Flex alignItems="flex-end">
                <AsyncImage
                  {...(!isLocalImage
                    ? {
                        srcType: "url",
                        src: file.fileUrl,
                      }
                    : {
                        srcType: "file",
                        src: file,
                      })}
                  fallback={
                    <Flex {...size} align="center" justify="center">
                      <Spinner />
                    </Flex>
                  }
                >
                  {({ src }) => (
                    <Box bg={bg} h="auto" w={`min(100%, ${size.width}px)`}>
                      <Image
                        fallback={<Text>{getTexts("SentAnImage")}</Text>}
                        borderRadius={4}
                        src={src}
                        name={file.name || "image"}
                        boxShadow="sm"
                        onLoad={onImgLoad}
                      />
                    </Box>
                  )}
                </AsyncImage>
              </Flex>
            );
          }
          default:
            const type = (type => {
              if (type?.match(/(pdf)/)) {
                return "attachment";
              } else if (type?.match(/(ogg)/)) {
                return "audio";
              } else if (type?.match(/(mp4)/)) {
                return "audio";
              }
            })(file?.contentType);

            switch (type) {
              case "audio":
                return getTexts("SentAnAudio");
              default:
                return getTexts("SentAFile");
            }
        }

      case MessageMetaTypes.INLINE_INPUT:
        return (
          <Box>
            <Box fontSize="md" fontWeight="bold" color="#666">
              <Text noOfLines={1}>{replyMeta?.meta?.inlineInput.title}</Text>
            </Box>
            <Box color="#666">
              <Text noOfLines={1}>
                {replyMeta?.meta?.inlineInput.description}
              </Text>
            </Box>
          </Box>
        );

      case MessageMetaTypes.TEMPLATE_MESSAGE:
        return (
          <Box>
            <Box fontSize="md" fontWeight="bold" color="#666">
              <Text noOfLines={1}>{getTexts("TemplateMessage")}</Text>
            </Box>
            <Box color="#666">
              <Text noOfLines={1}>{replyMeta?.meta?.template?.name}</Text>
            </Box>
          </Box>
        );
      default:
        return getTexts("SentAMessage");
    }
  })();

  return (
    <>
      <MessageModal
        isOpen={isOpen}
        onClose={onClose}
        sdk={sdk}
        messageDetail={{ m: replyMeta }}
      />

      <Box
        color={color}
        cursor="pointer"
        transition="all 0.2s"
        _hover={{ bg: "rgba(0,0,0,0.05)" }}
        userSelect="none"
        onClick={onOpen}
        {...props}
      >
        <Box borderLeft="4px" borderColor={`${colorScheme}.700`} p={1} pl={2}>
          <Text
            fontSize="sm"
            fontWeight="bold"
            fontFamily="Roboto Slab"
            color={`${colorScheme}.700`}
            mb={1}
          >
            {sender}
          </Text>
          <Box fontSize="sm">{replyContent}</Box>
        </Box>
      </Box>
    </>
  );
};

export default ReplyMessage;
