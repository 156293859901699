import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Global } from "@emotion/react";
import { darken } from "polished";
import {
  ChakraProvider,
  CSSReset,
  extendTheme,
  useForceUpdate,
} from "@chakra-ui/react";

import Login from "./login";
import Main from "./main";
import Invitation from "./invitation";
import ResetPassword from "./reset-password";
import ForgetPassword from "./forget-password";
import { getPalette } from "@projectg/utils/utils/helpers";
import ConsoleContainer from "../../store/console";
import AppContainer from "@projectg/utils/store/app";

const AnonymousRoute = props => {
  const { session } = AppContainer.useContainer();
  const location = useLocation();

  if (session) {
    return <Redirect to={location?.state?.referer ?? "/"} />;
  } else {
    return <Route {...props} />;
  }
};

const AuthenticatedRoute = props => {
  const { session } = AppContainer.useContainer();
  const location = useLocation();

  if (!session) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            referer: location,
          },
        }}
      />
    );
  } else {
    return <Route {...props} />;
  }
};

const App = () => {
  const { currentLangKey } = AppContainer.useContainer();
  const forceUpdate = useForceUpdate();
  const theme = extendTheme({
    colors: {
      primary: getPalette("#6d78a5"),
      secondary: getPalette("#fd8800"),
      error: darken(0.2, "#EC4E45"),
    },
    styles: {
      global: props => ({
        "*": {
          outline: "none",
        },
        button: {
          boxShadow: "none",
        },
        "html, body, #root": {
          minWidth: 1024,
          height: "100%",
          width: "100%",
          margin: 0,
          padding: 0,
          fontSize: 14,
          fontSmooth: "always",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          fontFamily: `Roboto `,
          overflow: "hidden",
          bg: "transparent",
        },
      }),
    },
  });
  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLangKey]);
  return (
    <ChakraProvider theme={theme}>
      <ConsoleContainer.Provider>
        <Switch>
          <AnonymousRoute exact path="/login" component={Login} />
          <Route exact path="/invitation/:token" component={Invitation} />
          <Route exact path="/forget" component={ForgetPassword} />
          <Route exact path="/account/:token/reset" component={ResetPassword} />
          <AuthenticatedRoute path="/" component={Main} />
          <Redirect to="/" />
        </Switch>
      </ConsoleContainer.Provider>
    </ChakraProvider>
  );
};

export default App;
