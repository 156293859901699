import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  IconButton,
  Image,
  Spinner,
  extendTheme,
} from "@chakra-ui/react";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import api, {
  setApiKey,
  setBaseUrl,
  setClientType,
  setToken,
} from "@projectg/utils/utils/api";
import { LangKey, patterns } from "@projectg/utils/utils/constants";
import { getPalette } from "@projectg/utils/utils/helpers";
import { useQueue, useSocket } from "@projectg/utils/utils/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { MdChatBubbleOutline, MdClose } from "react-icons/md";
import { MemoryRouter, Route, Switch } from "react-router-dom";

import SdkContainer from "../../store/sdk";
import ConversationWrapper from "./conversation";
import Home from "./home";
import Recent from "./recent";

const SdkApp = ({ preview: _preview = null }) => {
  const {
    appConfig: _appConfig,
    session,
    setAppConfig,
    setSession,
    setSelectedLangKey,
  } = AppContainer.useContainer();
  const {
    preview,
    setPreview,
    workspaceVisible,
    setWorkspaceVisible,
    sdkVisible,
    setSdkVisible,
  } = SdkContainer.useContainer();
  const appConfig = preview?.appConfig ?? _appConfig;

  const { upsertConversation, upsertAgent } = MessengerContainer.useContainer();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mode, setMode] = useState("desktop");

  const ready = !!session;

  // const cookieAvailability = getCookieAvailability();

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setPreview(_preview);
    });
  }, [_preview, setPreview, setWorkspaceVisible]);

  const setIframeStyle = useCallback(options => {
    window.parent.postMessage(
      {
        name: "setFrame",
        params: { key: "main-sdk", options },
      },
      "*"
    );
  }, []);

  const updateLanguage = lang => {
    switch (lang) {
      case LangKey.EN:
      case LangKey.ZH:
      case LangKey.ZH_CN:
        setSelectedLangKey(lang);
        break;
      default:
        throw new Error("Language is not valid");
    }
  };

  const [dispatchAction] = useQueue(async ({ name, options }) => {
    switch (name) {
      case "init": {
        // check whether cookie enabled
        // if (!cookieAvailability) return;

        const {
          lang = LangKey.ZH,
          refId,
          apiKey = "",
          domain = "",
          fullscreen = "",
        } = options;

        const apiLang = {
          [LangKey.EN]: "en_US",
          [LangKey.ZH]: "zh_HK",
          [LangKey.ZH_CN]: "zh_CN",
        };

        try {
          if (preview) {
            setAppConfig(preview?.appConfig);
            return;
          }
          if (!apiKey) return;
          setBaseUrl(domain);
          setClientType(21);
          setApiKey(apiKey);

          const params = {
            getAuthToken: true,
            lang: apiLang[lang] ?? apiLang[LangKey.ZH],
            version: "0.0.1",
            appVersion: "0.0.1",
            type: "customer",
            deviceName: "Browser",
          };

          if (refId) {
            params.refId = refId;
          } else {
            console.log("no param refId", params);
            // const refId = (() => {
            //   try {
            //     return JSON.parse(getCookie(`grick-sdk-${apiKey}`))?.refId;
            //   } catch {
            //     return null;
            //   }
            // })();

            // if (refId) {
            //   params.refId = refId;
            // }
          }

          const { data: session } = await api.post({
            url: "/api/init",
            body: params,
          });

          const {
            token,
            user: { refId: _refId },
          } = session;
          if (!refId && _refId) {
            window.parent.postMessage(
              {
                name: "set-cookie",
                params: {
                  key: `grick-sdk-${apiKey}`,
                  value: _refId,
                },
              },
              "*"
            );
          }

          setToken(token);
          setSession({ ...session, apiKey });

          const { data: appConfig } = await api.get({
            url: "/api/application",
          });

          const { data: conversations } = await api.get({
            url: "/api/conversation",
            params: {
              status: "all",
            },
          });

          const { data: agents } = await api.get({
            url: "/api/agent/available",
            params: {
              size: 0,
            },
          });

          if (fullscreen === "true") {
            setWorkspaceVisible(true);
            setMode("mobile");
            setIsFullscreen(true);
          }

          unstable_batchedUpdates(() => {
            conversations.forEach(c => upsertConversation(c?.id, c));
            agents.forEach(u => upsertAgent(u?.userId, u));
            setAppConfig(appConfig);
            updateLanguage(lang);
          });
        } catch (error) {
          // removeCookie(`grick-sdk-${apiKey}`);
        }
        break;
      }

      case "update": {
        try {
          const fields = options.fields;
          api.post({
            url: "/api/me/update",
            body: {
              fields,
            },
          });
          setSession(_ => ({ ..._, user: { ..._.user, fields } }));
        } catch (error) {
          console.error(error);
        }

        break;
      }

      case "show": {
        setWorkspaceVisible(true);
        break;
      }

      case "hide": {
        setWorkspaceVisible(false);
        break;
      }

      case "showSDK": {
        setSdkVisible(true);
        window.parent.postMessage(
          { name: "sdkVisible", options: { value: true } },
          "*"
        );
        break;
      }

      case "hideSDK": {
        setSdkVisible(false);
        window.parent.postMessage(
          { name: "sdkVisible", options: { value: false } },
          "*"
        );
        break;
      }

      case "set-mode": {
        setMode(options.mode);
        break;
      }

      case "set-language": {
        if (!options?.lang) throw new Error("Please provide a language");
        updateLanguage(options.lang);
        break;
      }

      default:
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    if (params.get("apiKey") && params.get("domain")) {
      dispatchAction({
        name: "init",
        options: {
          apiKey: params.get("apiKey"),
          domain: params.get("domain"),
          refId: params.get("refId") ?? undefined,
          lang: params.get("lang") ?? undefined,
          fullscreen: params.get("fullscreen") ?? undefined,
        },
      });
    }
  }, [dispatchAction]);

  useEffect(() => {
    const handleMessage = ({ data }) => {
      if (data?.name) {
        dispatchAction(data);
      }
    };
    if (!preview) {
      window.addEventListener("message", handleMessage, "*");
      window.parent.postMessage({ name: "sdk-ready" }, "*");
    }
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatchAction, preview]);

  useSocket({ clientType: 21 });

  const _theme = extendTheme({
    colors: {
      primary: getPalette(appConfig?.themeColorPrimary ?? "#6d78a5"),
    },
    styles: {
      global: props => ({
        "*": {
          outline: "none",
        },
        button: {
          boxShadow: "none",
        },
        "html, body, #root": {
          height: "100%",
          width: "100%",
          margin: 0,
          padding: 0,
          fontSize: 14,
          fontSmooth: "always",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          fontFamily: `Roboto `,
          overflow: "hidden",
          bg: "transparent",
        },
      }),
    },
  });

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const style = (() => {
      if (!ready) {
        return { width: "0px", height: "0px" };
      } else if (workspaceVisible) {
        if (mode !== "mobile") {
          return {
            maxWidth: "400px",
            maxHeight: "700px",
            width: "100%",
            height: "100%",
          };
        } else {
          return { width: "100%", height: "100%" };
        }
      } else {
        return {
          maxWidth: "auto",
          maxHeight: "auto",
          width: "100px",
          height: "100px",
        };
      }
    })();
    setIframeStyle(style);
  }, [mode, ready, setIframeStyle, workspaceVisible]);

  return (
    <ChakraProvider w="100%" h="100%" theme={_theme}>
      {
        // !cookieAvailability ? (
        //   <Alert status="warning" variant="left-accent">
        //     <Image mx={2} w={12} src={logo} />
        //     <Text my={0} fontFamily="Roboto Slab">
        //       Your browser currently does not support Cookies. Please enable it in
        //       order to use our customer service.{" "}
        //       <Button
        //         variant="link"
        //         textDecor="underline"
        //         colorScheme="red"
        //         onClick={() => setIframeStyle({ display: "none " })}
        //       >
        //         Close
        //       </Button>
        //     </Text>
        //   </Alert>
        // ) :
        sdkVisible && (
          <Flex w="100%" h="100%" p={4} position="relative">
            <Flex
              justifyContent="flex-end"
              bottom={0}
              right={0}
              position="absolute"
              p={8}
            >
              <Button
                icon={<MdChatBubbleOutline />}
                size="lg"
                w={16}
                h={16}
                borderRadius="0"
                bg="transparent"
                color="primary._500"
                onClick={() => setWorkspaceVisible(v => !v)}
                opacity={1}
                {...(!ready && {
                  opacity: 0,
                  visibility: "hidden",
                  transition: "all form .3s ease-in",
                })}
                position="relative"
                p={0}
                _focus={{ boxShadow: "none" }}
                _hover={{
                  bg: "transparent",
                }}
                _active={{
                  bg: "transparent",
                }}
              >
                <AsyncImage
                  src={appConfig?.conversationButtonIcon}
                  loading={
                    <Flex align="center" justify="center">
                      <Spinner />
                    </Flex>
                  }
                  empty={
                    <Center
                      fontSize="3xl"
                      w="100%"
                      h="100%"
                      borderRadius="full"
                      bg="primary.500"
                      color="primary._500"
                    >
                      <MdChatBubbleOutline />
                    </Center>
                  }
                >
                  {({ src }) => <Image src={src} w="100%" h="100%" />}
                </AsyncImage>
              </Button>
            </Flex>
            <Flex
              w="100%"
              h="100%"
              bottom={0}
              right={0}
              position="absolute"
              visibility={workspaceVisible ? undefined : "hidden"}
              {...(mode !== "mobile" && { p: 4 })}
              transform={`translateX(${
                !ready || !workspaceVisible ? 100 : 0
              }%)`}
              {...(workspaceVisible && {
                transition: "transform .3s ease-in",
              })}
            >
              <Box
                w="100%"
                h="100%"
                boxShadow="lg"
                borderStyle="solid"
                borderWidth={1}
                borderColor="gray.100"
                borderRadius={4}
                overflow="auto"
                position="relative"
                bg="white"
              >
                <Box
                  w="100%"
                  h={150}
                  position="relative"
                  backgroundColor="primary.200"
                  backgroundImage={`url("${patterns.diagram()}")`}
                >
                  {!isFullscreen && (
                    <IconButton
                      alignSelf="flex-end"
                      position="absolute"
                      icon={<MdClose />}
                      variant="ghost"
                      top={4}
                      right={4}
                      zIndex={400}
                      color="black"
                      fontSize="2xl"
                      colorScheme="primary"
                      onClick={() => setWorkspaceVisible(false)}
                    />
                  )}
                </Box>
                <Box top={0} left={0} w="100%" h="100%" position="absolute">
                  <MemoryRouter>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/recent" component={Recent} />
                      <Route
                        exact
                        path="/conversation/:id"
                        component={ConversationWrapper}
                      />
                    </Switch>
                  </MemoryRouter>
                </Box>
              </Box>
            </Flex>
          </Flex>
        )
      }
    </ChakraProvider>
  );
};

const Sdk = props => (
  <SdkContainer.Provider>
    <SdkApp {...props} />
  </SdkContainer.Provider>
);
export default Sdk;
