import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Image,
  Input,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import api from "@projectg/utils/utils/api";
import {
  emailRegex,
  patterns,
  urlRegex,
} from "@projectg/utils/utils/constants";
import {
  border,
  getCanvasBlob,
  getCanvasFromImage,
  getCroppedCanvas,
  getImageFromFile,
  getResizedCanvas,
  humanifyDate,
  permitted,
  removeIf,
} from "@projectg/utils/utils/helpers";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Controller, useForm } from "react-hook-form";
import { MdChatBubbleOutline, MdDelete, MdPerson } from "react-icons/md";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";

import {
  AlertModal,
  BadgeIndicator,
  SourceTag,
} from "../../components/components";
import { DatePickerInput } from "../../components/DatePicker";
import {
  PermissionGroups,
  permissionGroupsOptions,
  permissionOptions,
} from "../../utils/constants";
import { useGetLastMessageDisplay } from "../../utils/hooks";

const Agent = () => {
  const { id } = useParams();
  const history = useHistory();
  const toast = useToast();

  const deleteAgentModal = useDisclosure();

  const {
    hasUnread,
    agents,
    conversations,
    upsertAgent,
    upsertConversation,
    deleteAgent,
  } = MessengerContainer.useContainer();

  const getLastMessageDisplay = useGetLastMessageDisplay();
  const { session, getTextByLang, appConfig, getTexts, currentLangKey } =
    AppContainer.useContainer();
  const [, setUpdatingAgent] = useState(false);
  const { reset, register, trigger, errors, control } = useForm({});
  const agent = useMemo(() => agents?.[id], [agents, id]);

  const cancelRef = useRef();
  const uploadInputRef = useRef(null);

  const currEditRef = useRef();
  const updateAgent = useCallback(
    async updater => {
      console.log("updater", updater);
      try {
        setUpdatingAgent(true);
        upsertAgent(id, updater);
        await api.post({
          url: "/api/admin/agent/update",
          body: {
            userId: id,
            ...updater,
          },
        });
        setUpdatingAgent(false);
      } catch (error) {
        console.error(error);
      }
    },
    [id, upsertAgent]
  );

  const [isDeleteingAgent, setIsDeleteingAgent] = useState(false);
  const onDeleteAgent = useCallback(async () => {
    try {
      setIsDeleteingAgent(true);
      deleteAgent(id);
      await api.post({
        url: "/api/admin/agent/delete",
        body: {
          userId: id,
        },
      });
      deleteAgentModal.onClose();
      history.push("/agents");
      setIsDeleteingAgent(false);
    } catch (error) {
      console.error(error);
    }
  }, [deleteAgent, deleteAgentModal, history, id]);

  const updateAgentPermission = useCallback(
    async ({ key, value }) => {
      if (key === PermissionGroups.all) {
        updateAgent({ permissions: value ? [PermissionGroups.all] : [] });
        return;
      }
      if (key === PermissionGroups.console) {
        updateAgent({ permissions: value ? [PermissionGroups.console] : [] });
        return;
      }

      updateAgent({
        permissions: value
          ? [...(agent?.permissions ?? []), key]
          : removeIf(agent?.permissions ?? [], p => p === key),
      });
    },
    [agent, updateAgent]
  );

  useEffect(() => {
    reset({ agent });
  }, [agent, reset]);

  useEffect(() => {
    (async () => {
      try {
        const { data: agents } = await api.get({
          url: "/api/admin/agent/get",
          params: {
            userId: id,
            fields: "createDate,conversations,permissions,lastActivity",
          },
        });

        const { data: conversations } = await api.get({
          url: `/api/conversation/get`,
          params: {
            id: agents
              ?.map(c => c?.conversations)
              ?.flat()
              .join(","),
          },
        });

        unstable_batchedUpdates(() => {
          agents.forEach(c => upsertAgent(c?.userId, c));
          conversations.forEach(c => upsertConversation(c?.id, c));
        });
      } catch (error) {
        toast({
          title: getTexts(
            "UnableToInviteThisPersonPleaseCheckThatTheEmailIsInTheAgentList"
          ),
          status: "error",
          duration: 2500,
          isClosable: false,
        });
        history.push("/agents");
      }
    })();
  }, [history, id, reset, toast, upsertConversation, upsertAgent, getTexts]);

  const relatedConversations = Object.values(conversations).filter(c =>
    c?.agent?.find(x => x?.id === id)
  );

  if (!id) {
    return <Redirect to="/agents" />;
  }

  const onUpdateProfilePic = file => {
    updateAgent({
      profilePic: file,
    });
  };

  console.log("agent?.permissions", agent?.permissions);

  return (
    <>
      <AlertModal
        isOpen={deleteAgentModal.isOpen}
        isLoading={isDeleteingAgent}
        onClose={deleteAgentModal.onClose}
        cancelRef={cancelRef}
        onSubmit={onDeleteAgent}
        title={getTexts("DeleteAgent")}
      />

      <Input
        type="file"
        d="none"
        ref={uploadInputRef}
        onChange={async e => {
          try {
            const file = e.target.files?.[0] ?? null;
            const img = await getImageFromFile(file);
            const canvas = await getCanvasFromImage(img);

            let cropped = canvas;

            if (img.width > img.height) {
              const size = img.height;
              cropped = getCroppedCanvas(canvas, {
                width: size,
                height: size,
                x: (img.width - size) / 2,
                y: 0,
              });
            }

            if (img.height > img.width) {
              const size = img.width;
              cropped = getCroppedCanvas(canvas, {
                width: size,
                height: size,
                x: 0,
                y: (img.height - size) / 2,
              });
            }

            const resized = getResizedCanvas(cropped, {
              width: 250,
              height: 250,
            });

            const blob = await getCanvasBlob(resized);
            uploadInputRef.current.onChange(blob);
            uploadInputRef.current.value = "";
          } catch (error) {
            console.error(error);
          }
          return false;
        }}
      />

      <Flex w="100%" h="100%" flexDir="column" p={4} overflow="auto">
        <Box colorScheme="primary" mx="auto" maxW={1024} w="100%">
          <Flex
            zIndex={1}
            flexDir="column"
            w="100%"
            px={8}
            mt={4}
            minHeight={120}
            backgroundImage={`url("${patterns.diagram()}")`}
          >
            <Flex px={8}>
              {agent?.profilePic ? (
                <Box w={32} h={32} position="relative">
                  <Box
                    p={1}
                    position="absolute"
                    borderRadius="50%"
                    transition="all 0.4s"
                    cursor="pointer"
                    _hover={{ bg: "rgba(0,0,0,0.15)" }}
                    zIndex={1}
                    w="100%"
                    h="100%"
                    onClick={() => {
                      uploadInputRef.current.onChange = onUpdateProfilePic;
                      uploadInputRef.current.click();
                    }}
                  />
                  <AsyncImage
                    src={agent?.profilePic}
                    loading={
                      <Flex align="center" justify="center">
                        <Spinner />
                      </Flex>
                    }
                    empty={
                      <Box fontSize="3xl">
                        <MdChatBubbleOutline />
                      </Box>
                    }
                  >
                    {({ src }) => (
                      <Image borderRadius="50%" w="100%" h="100%" src={src} />
                    )}
                  </AsyncImage>
                </Box>
              ) : (
                <IconButton
                  icon={<MdPerson />}
                  isRound
                  borderRadius="50%"
                  bg="primary.300"
                  color="white"
                  fontSize="128px"
                  p={1}
                  w={32}
                  h={32}
                  _focus={{ boxShadow: "none" }}
                  position="relative"
                  onClick={() => {
                    uploadInputRef.current.onChange = onUpdateProfilePic;
                    uploadInputRef.current.click();
                  }}
                />
              )}

              <Stack ml={8} spacing={2}>
                <Flex alignItems="center">
                  <BadgeIndicator
                    ml={-1}
                    enable={agent?.online}
                    onColor="#2aa92a"
                    offColor="gray.300"
                    onLabel={getTexts("Online")}
                    offLabel={getTexts("Offline")}
                  />
                  <Heading
                    fontFamily="Roboto Slab"
                    flex={1}
                    minW={0}
                    w="100%"
                    as="h2"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {agent?.displayName}
                  </Heading>
                </Flex>
                <Stack mt={2} spacing={2}>
                  <FormControl
                    d="flex"
                    alignItems="center"
                    color="primary.700"
                    fontSize="sm"
                  >
                    <Text w={100}>{getTexts("LastActivityOn")}</Text>
                    <Text ml={2}>
                      {dayjs(agent?.lastActivity).format(
                        currentLangKey === "en"
                          ? "YYYY-MM-DD HH:mm"
                          : "YYYY年MM月DD日 HH:mm"
                      )}
                    </Text>
                  </FormControl>
                  <FormControl
                    d="flex"
                    alignItems="center"
                    color="primary.700"
                    fontSize="sm"
                  >
                    <Text w={100}>{getTexts("EstablishedIn")}</Text>
                    <Text ml={2}>
                      {dayjs(agent?.createDate).format(
                        currentLangKey === "en"
                          ? "YYYY-MM-DD HH:mm"
                          : "YYYY年MM月DD日 HH:mm"
                      )}
                    </Text>
                  </FormControl>
                </Stack>
                <Flex mt={2}>
                  <Button
                    leftIcon={<MdDelete />}
                    mr={2}
                    size="sm"
                    colorScheme="red"
                    variant="outline"
                    onClick={deleteAgentModal.onOpen}
                  >
                    {getTexts("DeleteAgent")}
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </Flex>
          <Flex w="100%" mt={8}>
            <Box w={300} p={4}>
              <Accordion allowMultiple={true} defaultIndex={[0, 1]}>
                <AccordionItem boxShadow="md" p={2} border={0}>
                  <AccordionButton fontWeight="bold" p={2} {...border()}>
                    {getTexts("Profile")}
                  </AccordionButton>
                  <AccordionPanel mx={-2}>
                    {(appConfig?.agentFields ?? [])?.map(
                      ({ id, name, type }) => (
                        <FormControl key={id} d="flex">
                          <Tooltip
                            bg="error"
                            isOpen={
                              !!errors?.agent?.fields?.[id]?.value?.message
                            }
                            placement="left"
                            label={errors?.agent?.fields?.[id]?.value?.message}
                          >
                            <Text
                              mt={1}
                              mr={1}
                              fontSize="md"
                              color="#999"
                              fontWeight="bold"
                            >
                              {getTextByLang(name)}
                            </Text>
                          </Tooltip>
                          <Box flex={1}>
                            {(() => {
                              switch (type) {
                                case "date":
                                case "anniversary":
                                  return (
                                    <Box pl={30}>
                                      <Controller
                                        control={control}
                                        name={`agent.fields.${id}.value`}
                                        render={({
                                          onChange,
                                          value,
                                          name,
                                          ref,
                                        }) => (
                                          <DatePickerInput
                                            showSelectableDays={false}
                                            name={name}
                                            placeholder={getTexts("SelectDate")}
                                            size="sm"
                                            format={
                                              type === "anniversary"
                                                ? "MM-DD"
                                                : "YYYY-MM-DD"
                                            }
                                            value={value ? dayjs(value) : null}
                                            onChange={(date, dateString) =>
                                              onChange(dateString)
                                            }
                                            ref={ref}
                                            onFocus={e =>
                                              (currEditRef.current = e.target)
                                            }
                                            onBlur={async () => {
                                              const result = await trigger(
                                                `agent.fields.${id}.value`
                                              );
                                              if (result) {
                                                updateAgent({
                                                  fields: {
                                                    ...agent?.fields,
                                                    [id]: {
                                                      value:
                                                        currEditRef.current
                                                          .value,
                                                    },
                                                  },
                                                });
                                                currEditRef.current = null;
                                              } else {
                                                currEditRef.current.focus();
                                              }
                                            }}
                                            onClear={(date, dateString) =>
                                              updateAgent({
                                                fields: {
                                                  ...agent?.fields,
                                                  [id]: {
                                                    value: dateString,
                                                  },
                                                },
                                              })
                                            }
                                          />
                                        )}
                                      />
                                    </Box>
                                  );

                                default:
                                  return (
                                    <Input
                                      name={`agent.fields.${id}.value`}
                                      px={1}
                                      placeholder={getTexts("Unfilled")}
                                      fontSize="md"
                                      size="sm"
                                      _placeholder={{ textAlign: "right" }}
                                      _hover={{
                                        borderColor: "transparent",
                                        bg: "gray.50",
                                      }}
                                      textAlign="right"
                                      focusBorderColor="transparent"
                                      borderColor="transparent"
                                      ref={register({
                                        pattern: (() => {
                                          switch (type) {
                                            case "email":
                                              return {
                                                value: emailRegex,
                                                message: getTexts(
                                                  "PleaseEnterAValidEmail"
                                                ),
                                              };
                                            case "url":
                                              return {
                                                value: urlRegex,
                                                message: getTexts(
                                                  "PleaseEnterAValidUrl"
                                                ),
                                              };
                                            case "text":
                                            case "longText":
                                            case "phone":
                                            case "address":
                                            case "anniversary":
                                            case "date":
                                              return {};
                                            default:
                                          }
                                        })(),
                                      })}
                                      onFocus={e =>
                                        (currEditRef.current = e.target)
                                      }
                                      onBlur={async () => {
                                        const result = await trigger(
                                          `agent.fields.${id}.value`
                                        );
                                        if (result) {
                                          updateAgent({
                                            fields: {
                                              ...agent?.fields,
                                              [id]: {
                                                value:
                                                  currEditRef.current.value,
                                              },
                                            },
                                          });
                                          currEditRef.current = null;
                                        } else {
                                          currEditRef.current.focus();
                                        }
                                      }}
                                    />
                                  );
                              }
                            })()}
                          </Box>
                        </FormControl>
                      )
                    )}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem boxShadow="md" p={2} mt={4} border={0}>
                  <AccordionButton fontWeight="bold" p={2} b {...border()}>
                    {getTexts("Permissions")}
                  </AccordionButton>
                  <AccordionPanel mx={-2}>
                    {session?.user?.userId === id && (
                      <Alert status="warning">
                        <AlertIcon />
                        {getTexts(
                          "IfYouChangeYourPermissionsYouMayNotBeAbleToUseSomeFunctions"
                        )}
                      </Alert>
                    )}

                    {permissionGroupsOptions.map(
                      ({ value: permission, label }) => {
                        return (
                          <Box key={permission} mt={1}>
                            <Checkbox
                              colorScheme="primary"
                              name="permission"
                              key={permission}
                              value={permission}
                              isChecked={(agent?.permissions ?? []).includes(
                                permission
                              )}
                              onChange={e =>
                                updateAgentPermission({
                                  key: permission,
                                  value: e.target.checked,
                                })
                              }
                            >
                              {getTexts(label)}
                            </Checkbox>
                          </Box>
                        );
                      }
                    )}
                    <Box pl={6}>
                      {permissionOptions.map(({ value: permission, label }) => {
                        return (
                          <Box key={permission} mt={1}>
                            <Checkbox
                              colorScheme="primary"
                              name="permission"
                              key={permission}
                              value={permission}
                              isChecked={permitted(
                                agent?.permissions,
                                permission
                              )}
                              onChange={e =>
                                updateAgentPermission({
                                  key: permission,
                                  value: e.target.checked,
                                })
                              }
                              isDisabled={
                                (agent?.permissions ?? []).includes(
                                  PermissionGroups.all
                                ) ||
                                (agent?.permissions ?? []).includes(
                                  PermissionGroups.console
                                )
                              }
                            >
                              {getTexts(label)}
                            </Checkbox>
                          </Box>
                        );
                      })}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
            <Tabs flex={1} minW={0} w="100%" mt={4}>
              <TabList
                borderBottomWidth={1}
                borderStyle="solid"
                borderColor="#eee"
              >
                <Tab>
                  {getTexts("Conversations")} ({agent?.conversations?.length})
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel py={8}>
                  {relatedConversations.length === 0 ? (
                    <Box
                      m={2}
                      color="#999"
                      alignSelf="center"
                      justify="center"
                      textAlign="center"
                    >
                      {getTexts("NoRelatedConversations")}
                    </Box>
                  ) : (
                    relatedConversations.map(
                      ({ id, ...conversation }, index) => {
                        return (
                          <Box
                            mb={4}
                            boxShadow="sm"
                            borderRadius="sm"
                            key={id}
                            _hover={{ bg: "gray.50" }}
                          >
                            <Link to={`/conversation/${id}`}>
                              <Flex
                                cursor="pointer"
                                alignSelf="stretch"
                                p={4}
                                flexDirection="column"
                                key={id}
                              >
                                <Flex align="center">
                                  <BadgeIndicator
                                    ml={-2}
                                    enable={conversation?.online}
                                    onColor="#2aa92a"
                                    offColor="gray.300"
                                    onLabel={getTexts("Online")}
                                    offLabel={getTexts("Offline")}
                                  />
                                  <Flex flex={1} align="center">
                                    <Text
                                      fontWeight="bold"
                                      color="gray.700"
                                      mr={1}
                                      fontSize="md"
                                      fontFamily="Roboto"
                                    >
                                      {conversation?.name}
                                    </Text>
                                    <SourceTag source={conversation?.source} />
                                  </Flex>
                                  <Box color="#bbb" fontSize="80%">
                                    {humanifyDate(
                                      conversation.orderTimestamp,
                                      currentLangKey
                                    )}
                                  </Box>
                                </Flex>
                                <Flex mr={1} mt={1} align="center">
                                  {getLastMessageDisplay(
                                    conversation,
                                    session?.user?.userId
                                  )}

                                  <BadgeIndicator
                                    ml={-2}
                                    enable={hasUnread(
                                      conversation,
                                      session?.user?.userId
                                    )}
                                    onColor="secondary.500"
                                    offColor="transparent"
                                    onLabel={getTexts("Unread")}
                                  />
                                </Flex>
                              </Flex>
                            </Link>
                          </Box>
                        );
                      }
                    )
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Agent;
