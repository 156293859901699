import { useRef, useEffect, useCallback, useState, useMemo } from "react";

import { delayFor, isMyIssue } from "./helpers";
import logo from "../assets/logo.png";
import SocketContainer, { ConnectionStatusTypes } from "../store/socket";
import api, { getHeader } from "./api";
import { MessageDataTypes, MessageMetaTypes } from "./constants";
import MessengerContainer from "../store/messenger";
import AppContainer from "../store/app";
import { useGetMessageContent } from "../../console-app/src/utils/hooks";

export const useScrollToBottom = ref => {
  return useCallback(
    (pixelFromBottom = 0) => {
      const element = ref.current;
      if (element) {
        element.scrollTop =
          element.scrollHeight - pixelFromBottom - element.clientHeight;
      }
    },
    [ref]
  );
};

export const useGetPixelToBottom = ref => {
  return useCallback(() => {
    const element = ref.current;
    if (element) {
      return element.scrollHeight - element.scrollTop - element.clientHeight;
    } else {
      return null;
    }
  }, [ref]);
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useQueue = process => {
  const [_queue, _setQueue] = useState([]);
  const [_idle, _setIdle] = useState(true);

  const processNextPacket = useCallback(
    async _queue => {
      const nextPacket = _queue[0];
      _setIdle(false);
      await process(nextPacket);
      _setQueue(([removed, ...queue]) => queue);
      _setIdle(true);
    },
    [_setQueue, process]
  );

  useEffect(() => {
    if (_idle && _queue.length > 0) {
      processNextPacket(_queue);
    }
  }, [_idle, _queue, processNextPacket]);

  const append = useCallback(
    packet => {
      _setQueue(queue => [...queue, packet]);
    },
    [_setQueue]
  );

  return [append];
};

export const useMemoRef = value => {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};

export const useLocalStorage = () => {
  const set = useCallback((name, value) => {
    if (!window.localStorage) {
      console.error("No localStorage");
    }
    window.localStorage.setItem(name, JSON.stringify(value));
  }, []);

  const get = useCallback(name => {
    if (!window.localStorage) {
      console.error("No localStorage");
    }
    try {
      return JSON.parse(window.localStorage.getItem(name));
    } catch (error) {
      return undefined;
    }
  }, []);

  const remove = useCallback(name => {
    if (!window.localStorage) {
      console.error("No localStorage");
    }
    window.localStorage.removeItem(name);
  }, []);

  return useMemo(
    () => ({
      set,
      get,
      remove,
    }),
    [get, remove, set]
  );
};

export const useIsMounted = () => {
  const mountRef = useRef(false);
  useEffect(() => {
    mountRef.current = true;
  }, []);
  return mountRef.current;
};

export const useWindowSize = () => {
  const isClient = useMemo(() => typeof window === "object", []);

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return windowSize;
};

export const useSocket = ({ clientType }) => {
  /* socket -start */
  const { session } = AppContainer.useContainer();
  const {
    init: connect,
    destroy: disconnect,
    connectionStatus: socketStatus,
    subscribe,
  } = SocketContainer.useContainer();

  const {
    upsertConversation,
    upsertCustomer,
    upsertAgent,
    upsertMessage,
    setInboxCount,
    conversations,
    customers,
    agents,
  } = MessengerContainer.useContainer();

  const conversationsRef = useMemoRef(conversations);
  const customersRef = useMemoRef(customers);
  const agentsRef = useMemoRef(agents);

  const { isIdleRef, activeConversationRef } = AppContainer.useContainer();

  const getMessageContent = useGetMessageContent();

  const [appendToTypingEndQueue] = useQueue(packet => {
    const { timestamp = new Date().valueOf(), senderId, senderType } = packet;
    const duration = packet?.meta?.status?.time ?? 3000;
    upsertConversation(
      packet?.conversationId,
      c => ({
        ...c,
        [senderType]: c?.[senderType]?.map(u =>
          u?.id === senderId
            ? {
                id: senderId,
                ___isTyping:
                  !u?.___isTyping || timestamp + duration >= u?.___isTyping
                    ? false
                    : u?.___isTyping,
              }
            : u
        ),
      }),
      false
    ); // upsert = false
  });

  const handleProvisions = useCallback(
    async event => {
      try {
        if (!event.data) {
          throw new Error("No provisions");
        }

        if (event.data === "pong") {
          return;
        }

        const packet = JSON.parse(event.data);
        console.log("[packet]", packet);

        switch (packet.dataType) {
          case MessageDataTypes.REALTIME_PROVISIONING:
            const {
              meta: {
                conversationCount,
                conversation: conversations = [],
                customer: customers = [],
                agent: agents = [],
                message: messages = {},
              },
            } = packet;

            // handle Count Updates
            if (conversationCount) {
              setInboxCount(inboxCount => {
                const _reduceCount = (oldCounts, updates) => {
                  return Object.entries(updates).reduce(
                    (reducedCount, [name, newCount]) => ({
                      ...reducedCount,
                      [name]:
                        typeof newCount === "object"
                          ? _reduceCount(reducedCount[name] || {}, newCount)
                          : reducedCount[name] + newCount,
                    }),
                    oldCounts
                  );
                };
                return _reduceCount(inboxCount, packet.meta.conversationCount);
              });
            }

            // handle Conversation Updates
            (conversations ?? []).forEach(c => upsertConversation(c?.id, c));
            (customers ?? []).forEach(c => upsertCustomer(c?.userId, c));
            (agents ?? []).forEach(u => upsertAgent(u?.userId, u));
            (Object.entries(messages) ?? []).forEach(([cId, ms]) =>
              ms.forEach(m => upsertMessage(cId, m?.id, m))
            );

            break;

          case MessageDataTypes.MESSAGE_DISCRETE_SYNC:
            switch (packet.meta.type) {
              case MessageMetaTypes.STATUS: {
                const {
                  timestamp = new Date().valueOf(),
                  senderId,
                  senderType,
                } = packet;
                const duration = packet?.meta?.status?.time ?? 3000;
                upsertConversation(
                  packet?.conversationId,
                  {
                    [senderType]: [
                      { id: senderId, ___isTyping: timestamp + duration },
                    ],
                  },
                  false
                ); // upsert = false

                await delayFor(duration);
                appendToTypingEndQueue(packet);
                break;
              }
              default:
            }
            break;

          case MessageDataTypes.MESSAGE_STREAM:
          case MessageDataTypes.MESSAGE_DISCRETE:
            const m = packet;
            if (m) {
              upsertMessage(m?.conversationId, m?.localId ?? m?.id, m, true);

              // skip notification if not sent by me
              if (
                session?.user?.userId === m?.senderId &&
                m?.senderType === session?.user?.type
              ) {
                return;
              }

              // push notification

              const conversation = await (async () => {
                const conversation =
                  conversationsRef.current?.[m?.conversationId];
                if (conversation) return conversation;
                const { data: conversations } = await api.get({
                  url: `/api/conversation/get`,
                  params: { id: m?.conversationId },
                });
                return conversations?.[0];
              })();

              if (
                // [sdk/console] conversation not found
                !conversation ||
                // [console]: agent receives issues unassigned to me
                (session?.user?.type === "agent" &&
                  !isMyIssue(conversation, session)) ||
                // [sdk/console]: actively in the conversation view
                (!isIdleRef.current &&
                  activeConversationRef.current === m?.conversationId) ||
                // [console]: agent receives auto message
                (session?.user?.type === "agent" &&
                  m?.senderType === "autoMessage")
              ) {
                return;
              }

              let sender = await (async m => {
                switch (m?.senderType) {
                  case "customer": {
                    const customer = customersRef.current?.[m?.senderId];
                    if (customer) return customer;
                    const { data: _customers } = await api.get({
                      url: "/api/customer/get",
                      params: {
                        userId: [m?.senderId],
                        fields: "createDate,conversations,tags,notes",
                      },
                    });
                    return _customers?.[0];
                  }
                  case "autoMessage":
                  case "agent": {
                    const agent = agentsRef.current?.[m?.senderId];
                    if (agent) return agent;
                    const { data: _agents } = await api.get({
                      url: "/api/agent/get",
                      params: {
                        userId: [m?.senderId],
                      },
                    });
                    return _agents?.[0];
                  }
                  default: {
                    return { displayName: "系統信息" };
                  }
                }
              })();

              if (conversation && sender && "Notification" in window) {
                new Notification(conversation?.name, {
                  body: getMessageContent(m),
                  icon: logo,
                });
              }
            }
            break;

          default:
        }
      } catch (error) {
        console.error("Failed to handle provisioning event", event, error);
      }
    },
    [
      activeConversationRef,
      agentsRef,
      appendToTypingEndQueue,
      conversationsRef,
      customersRef,
      getMessageContent,
      isIdleRef,
      session,
      setInboxCount,
      upsertAgent,
      upsertConversation,
      upsertCustomer,
      upsertMessage,
    ]
  );

  const socketStatusRef = useRef(socketStatus);
  useEffect(() => {
    socketStatusRef.current = socketStatus;
  }, [socketStatus]);
  const initSocket = useCallback(async () => {
    if (socketStatusRef.current === ConnectionStatusTypes.SUCCESS) {
      disconnect();
    }
    const status = await connect({
      udId: session?.user?.udId,
      credential: {
        token: session?.token,
        ...(getHeader()?.["g-apiKey"] && { apiKey: getHeader()?.["g-apiKey"] }),
      },
      clientType,
    });
    if (status) {
      subscribe("handleProvisions", event => {
        handleProvisions(event);
      });
    }
    // }
  }, [connect, disconnect, handleProvisions, session, subscribe, clientType]);

  useEffect(() => {
    const onReconnect = () => {
      if (
        [
          ConnectionStatusTypes.PENDING_RETRY,
          ConnectionStatusTypes.ERROR,
        ].includes(socketStatusRef.current)
      ) {
        initSocket();
      }
    };

    window.addEventListener("focus", onReconnect);
    return () => {
      window.removeEventListener("focus", onReconnect);
    };
  });

  useEffect(() => {
    try {
      initSocket();
    } catch (error) {
      disconnect();
      console.error(`socket error: ${error}`);
    }
    return disconnect;
  }, [disconnect, initSocket]);
  /* socket -end */

  return initSocket;
};
