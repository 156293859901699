import React, { useState } from "react";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

const UpsertFacebookModal = ({
  title,
  isOpen,
  onSuccess = () => {},
  onClose = () => {},
  facebookId: id,
}) => {
  const { getTexts } = AppContainer.useContainer();
  const toast = useToast();
  const { control, errors, handleSubmit } = useForm();
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async data => {
    try {
      setIsFetching(true);
      const { data: res } = await api.post({
        url: `/api/admin/setting/externalPlatform/facebook/${
          id ? "update" : "create"
        }`,
        body: {
          id: id ?? undefined,
          ...data,
        },
      });
      toast({
        title: getTexts("FacebookAppSecretUpdated"),
        status: "success",
      });
      onSuccess(res);
      onClose();
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.appSecret?.message}>
            <FormLabel htmlFor="name">{getTexts("AppSecret")}</FormLabel>
            <Controller
              as={<Input />}
              name="appSecret"
              control={control}
              defaultValue=""
              rules={{
                required: `${getTexts("PleaseInput")} ${getTexts("AppSecret")}`,
              }}
            />
            <FormErrorMessage>{errors.appSecret?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            colorScheme="primary"
            mr={3}
            onClick={onClose}
          >
            {getTexts("Cancel")}
          </Button>
          <Button
            isLoading={isFetching}
            colorScheme="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {id ? getTexts("Update") : getTexts("Create")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpsertFacebookModal;
