import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-hk";

import {
  LangKey,
  MessageDataTypes,
  MessageMetaFileSubTypes,
  MessageMetaTypes,
} from "@projectg/utils/utils/constants";
import coloringPalette from "coloring-palette";
import dayjs from "dayjs";

import {
  Permission,
  PermissionGroups,
} from "../../console-app/src/utils/constants";
import api from "./api";
import { StatusTypes } from "./constants";

export const mergeBoolean = (
  old = null,
  patches = null,
  defaultValue = false
) => {
  return patches !== null ? patches : old !== null ? old : defaultValue;
};

export const mergeNumber = (
  old = null,
  patches = null,
  defaultValue = false
) => {
  return patches !== null ? patches : old !== null ? old : defaultValue;
};

export const appendClasses = (original = "", classNames = {}) => {
  return Object.entries(classNames).reduce((tmp, [className, condition]) => {
    return tmp + (condition ? ` ${className}` : "");
  }, original);
};

export const isUnassigned = conversation => {
  return !conversation.assignedAgent || conversation.assignedAgent.length === 0;
};

export const isMyIssue = (conversation, session) => {
  return (
    (conversation?.assignedAgent ?? [])
      .map(x => x.id)
      .indexOf(session.user.userId) >= 0
  );
};

export const mergeCustomer = (old = {}, patches = {}) => {
  return {
    ...old,
    ...patches,
    username: patches.username || old.username || "",
    displayName: patches.displayName || old.displayName || "",
    email: patches.email || old.email || "",
    subtitle:
      (patches.subtitle !== undefined ? patches.subtitle : old.subtitle) || "",
    bio: (patches.bio !== undefined ? patches.bio : old.bio) || "",
    fields: patches.fields || old.fields || {},
    tags: patches.tags || old.tags || [],
    notes: patches.notes || old.notes || [],
    generatedName: mergeBoolean(
      old.generatedName,
      patches.generatedName,
      false
    ),
    online: mergeBoolean(old.online, patches.online, false),
  };
};

export const mergeFields = (fieldScheme = [], _fields) =>
  fieldScheme.reduce(
    (fields, { id, type }) => ({
      ...fields,
      [id]: { value: "", ..._fields?.[id] },
    }),
    {}
  );

export const mergeMessage = (old = {}, patches) => ({ ...old, ...patches });

export const mergeConversation = (old = {}, patches) => {
  const _mergeUserState = (_old, _patches) => {
    let updated = [];
    if (_old && _old.length > 0) {
      updated = [..._old];
    }
    if (_patches && _patches.length > 0) {
      _patches.forEach(patch => {
        const index = updated.findIndex(row => row.id === patch.id);
        if (index >= 0) {
          updated.splice(index, 1, { ...updated?.[index], ...patch });
        } else {
          updated.push(patch);
        }
      });
    }
    return updated;
  };
  return {
    id: old.id || patches.id,
    name: patches.name || old.name || `Conversation ${patches.id.slice(0, 7)}`,
    profilePic: patches.profilePic || old.profilePic || "",
    subtitle: patches.subtitle || old.subtitle || "",
    status: patches.status || old.status || StatusTypes.OPEN,
    ...((patches._status || old._status) && {
      _status: patches._status || old._status,
    }),
    team: patches.team || old.team || [],
    ...((patches._team || old._team) && { _team: patches._team || old._team }),
    customer: _mergeUserState(old.customer, patches.customer), // ANITA: conversation replace state issue
    agent: _mergeUserState(old.agent, patches.agent), // ANITA: conversation replace state issue
    assignedAgent: patches.assignedAgent || old.assignedAgent || [],
    ...((patches._assignedAgent || old._assignedAgent) && {
      _assignedAgent: patches._assignedAgent || old._assignedAgent,
    }),
    agentStatus: patches.agentStatus || old.agentStatus,
    messages: patches.messages || old.messages || {},
    meta: patches.meta || old.meta || {},
    tag: patches.tag || old.tag || [],

    hidden: mergeBoolean(old.hidden, patches.hidden, false),
    mentioned: mergeBoolean(old.mentioned, patches.mentioned, false),
    snooze: mergeNumber(old.snooze, patches.snooze, 0),
    source: patches.source || old.source || "",
    lastUpdateTimestamp: Math.max(
      patches.lastUpdateTimestamp || 0,
      old.lastUpdateTimestamp || 0
    ),
    orderTimestamp: Math.max(
      patches.orderTimestamp || 0,
      old.orderTimestamp || 0
    ),
    agentOnly: mergeBoolean(old.agentOnly, patches.agentOnly, false),
    lastRead: patches.lastRead || old.lastRead || null,
    online: mergeBoolean(old.online, patches.online, false),
    livechat: mergeBoolean(old.livechat, patches.livechat, false),
    customerRating: patches.customerRating || old.customerRating || {},
    agentRating: patches.agentRating || old.agentRating || {},
  };
};

export const humanifyDate = (
  timestamp,
  lang = "en",
  showTimeFromNow = true
) => {
  let yesterdayText = "Yesterday";
  let todayText = "Today";

  const isToday = require("dayjs/plugin/isToday");
  dayjs.extend(isToday);
  const isYesterday = require("dayjs/plugin/isYesterday");
  dayjs.extend(isYesterday);

  switch (lang) {
    case LangKey.ZH:
      yesterdayText = "昨天";
      todayText = "今天";
      break;
    case LangKey.ZH_CN:
      yesterdayText = "昨天";
      todayText = "今天";
      break;
    case LangKey.EN:
    default:
  }

  const m = dayjs(timestamp);
  const now = dayjs();

  const today = m.isToday();
  const yesterday = m.isYesterday();

  // const withInAnHour = now.diff(m, "hour") === 0;
  // const withInWeek = now.diff(m, "day") < 7;
  const withInMonth = now.diff(m, "month") === 0;
  // const withInAYear = now.diff(m, "year") === 0;

  if (showTimeFromNow) {
    if (withInMonth) return m.fromNow();
  }
  if (today) return todayText;
  if (yesterday) return yesterdayText;

  return m.format("YYYY/MM/DD");
};

export const generateSelfId = () => parseInt(Math.random() * 1000);

export const interpolate = (s, obj) => {
  return s.replace(/[$]{([^}]+)}/g, function (_, path) {
    const properties = path.split(".");
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  });
};

export const getImageFromFile = file => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    const blob = URL.createObjectURL(file);
    img.src = blob;
    img.onload = () => resolve(img);
    img.onerror = () =>
      reject({
        msgCode: 1,
        message: "failed to load image",
      });
  });
};

export const getDataURLFromLocalFile = file => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr.result);
    fr.readAsDataURL(file);
  });
};

export const getImageFromDataURL = dataUrl => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = dataUrl;
    img.onload = () => resolve(img);
    img.onerror = () =>
      reject({
        msgCode: 1,
        message: "failed to load image",
      });
  });
};

export const getImageFromCanvas = canvas => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = canvas.toDataURL({
      quality: 1,
    });
    img.onload = () => resolve(img);
    img.onerror = () =>
      reject({
        msgCode: 1,
        message: "failed to convert to image",
      });
  });
};

export const getBoundedImageSize = (
  width,
  height,
  boundedWidth,
  boundedHeight
) => {
  const MAX_WIDTH = boundedWidth;
  const MAX_HEIGHT = boundedHeight;
  const MAX_RATIO = MAX_WIDTH / MAX_HEIGHT;
  const ratio = width / height;

  if (width > MAX_WIDTH && height > MAX_HEIGHT) {
    if (ratio < MAX_RATIO) {
      // height 100%;
      height = MAX_HEIGHT;
      width = MAX_HEIGHT * ratio;
    } else {
      // width 100%
      width = MAX_WIDTH;
      height = MAX_WIDTH / ratio;
    }
  } else if (width > MAX_WIDTH) {
    // width 100%
    width = MAX_WIDTH;
    height = MAX_WIDTH / ratio;
  } else if (height > MAX_HEIGHT) {
    // height 100%;
    height = MAX_HEIGHT;
    width = MAX_HEIGHT * ratio;
  }
  return { width, height };
};

export const getCanvasFromImage = img => {
  const imgCanvas = document.createElement("canvas"),
    imgCtx = imgCanvas.getContext("2d");
  imgCanvas.height = img.height;
  imgCanvas.width = img.width;

  imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

  return imgCanvas;
};

export const getResizedCanvas = (imgCanvas, { width, height }) => {
  const resultCanvas = document.createElement("canvas"),
    resultCtx = resultCanvas.getContext("2d");

  resultCanvas.height = height;
  resultCanvas.width = width;

  // step 3, resize to final size
  resultCtx.drawImage(
    imgCanvas,
    0,
    0,
    imgCanvas.width,
    imgCanvas.height,
    0,
    0,
    resultCanvas.width,
    resultCanvas.height
  );

  return resultCanvas;
};

export const getCroppedCanvas = (imgCanvas, { x, y, width, height }) => {
  const canvas = document.createElement("canvas");
  const scaleX = (imgCanvas.naturalWidth || imgCanvas.width) / imgCanvas.width;
  const scaleY =
    (imgCanvas.naturalHeight || imgCanvas.height) / imgCanvas.height;
  canvas.width = width * scaleX;
  canvas.height = height * scaleY;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    imgCanvas,
    x * scaleX,
    y * scaleY,
    width * scaleX,
    height * scaleY,
    0,
    0,
    width * scaleX,
    height * scaleY
  );

  return canvas;
};

export const getCanvasBlob = canvas => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
};

export const downloadBlob = (blob, filename = null) => {
  const a = document.createElement("a");
  const blobURL = (window.URL || window.webkitURL).createObjectURL(blob);
  if (filename) {
    a.download = filename;
  }
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadBlobUrl = (blobURL, filename = null) => {
  const a = document.createElement("a");
  if (filename) {
    a.download = filename;
  }
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const fetchAssetToObjectUrl = async url => {
  const response = await api.get(
    {
      url,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      responseType: "blob",
    },
    {
      getResponse: true,
    }
  );

  return (window.URL || window.webkitURL).createObjectURL(response.data);
};

// export const getCookieAvailability = () => {
//   try {
//     if (navigator.cookieEnabled) {
//       return true;
//     }
//     document.cookie = "cookietest=1";
//     var cookiesEnabled = document.cookie.indexOf("cookietest=") !== -1;
//     document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
//     return cookiesEnabled;
//   } catch (e) {
//     return false;
//   }
// };

/* Select */
export const getSelectTheme =
  ({ theme }) =>
  _ => ({
    ..._,
    borderRadius: 0,
    borderColor: "transparent",
    colors: {
      primary: theme.colors.primary[100],
      primary25: theme.colors.primary[50],
      primary50: theme.colors.primary[100],
      neutral0: "white",
    },
  });
export const getSelectStyle = ({ theme }) => ({
  dropdownIndicator: () => ({ display: "none " }),
  multiValue: _ => ({
    ..._,
    background: theme.colors.primary[100],
    fontWeight: "bold",
  }),
  singleValue: _ => ({
    ..._,
    fontWeight: "bold",
  }),
  option: _ => ({
    ..._,
    color: "#333",
    cursor: "pointer",
    margin: 0,
  }),
  container: (_, state) => ({
    ..._,
    width: "100%",
    padding: 0,
    borderColor: theme.colors.primary[100],
    color: state.hasValue ? theme.colors.primary[700] : "#999",
  }),
  control: _ => ({
    ..._,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    minHeight: 35,
    boxShadow: "none",
    background: "transparent",
    cursor: "pointer",
  }),
});

export const mergeSearchParams = ({
  status = "all",
  filter = "all",
  team = null,
  customerId = null,
  agentId = null,
  tag = null,
  customerTag = null,
  beforeTimestamp = null,
  afterTimestamp = null,
  showOnline = false,
} = {}) => ({
  status,
  filter,
  team,
  customerId,
  agentId,
  tag,
  customerTag,
  beforeTimestamp,
  afterTimestamp,
  showOnline,
});

export const createTextMessage = (
  conversationId,
  senderType,
  senderId,
  textMessage,
  internal,
  replyMeta,
  selfId = generateSelfId(),
  localTimestamp = new Date().valueOf()
) => {
  const reply = replyMeta?.id
    ? {
        reply: { ...replyMeta },
      }
    : {};

  return {
    id: selfId,
    selfId: selfId,
    timestamp: localTimestamp,
    senderId,
    senderType: senderType,
    conversationId: conversationId,
    dataType: MessageDataTypes.MESSAGE_DISCRETE,
    internal: internal,
    meta: {
      type: MessageMetaTypes.TEXT,
      content: textMessage,
      ...reply,
    },
    pushContent: "tb_text",
  };
};

export const createFileMessage = async (
  conversationId,
  senderId,
  file,
  internal,
  replyMeta,
  selfId = generateSelfId(),
  localTimestamp = new Date().valueOf()
) => {
  const img = await (async _file => {
    try {
      return await getImageFromFile(_file);
    } catch (error) {
      return null;
    }
  })(file);
  if (img) {
    file.previewDimension = {
      width: img.width,
      height: img.height,
    };
  }
  const reply = replyMeta?.id
    ? {
        reply: { ...replyMeta },
      }
    : {};

  return {
    id: selfId,
    selfId: selfId,
    timestamp: localTimestamp,
    senderId: senderId,
    conversationId: conversationId,
    dataType: MessageDataTypes.MESSAGE_DISCRETE,
    internal: internal,
    meta: {
      type: MessageMetaTypes.FILE,
      subtype: img
        ? MessageMetaFileSubTypes.IMAGE
        : MessageMetaFileSubTypes.FILE,
      file: file,
      ...reply,
    },
    pushContent: img ? "tb_image" : "tb_file",
  };
};

export const createTemplateMessage = (
  conversationId,
  senderType,
  senderId,
  internal,
  template,
  selfId = generateSelfId(),
  localTimestamp = new Date().valueOf()
) => {
  return {
    id: selfId,
    selfId: selfId,
    timestamp: localTimestamp,
    senderId,
    senderType: senderType,
    conversationId: conversationId,
    dataType: MessageDataTypes.MESSAGE_DISCRETE,
    internal: internal,
    meta: {
      type: MessageMetaTypes.TEMPLATE_MESSAGE,
      template: template,
    },
    pushContent: "tb_text",
  };
};

export const getPalette = color => {
  const palette = Object.entries(coloringPalette(color)).reduce(
    (o, [key, { color, contrastText }]) => ({
      ...o,
      [key]: color,
      [`_${key}`]: contrastText,
    }),
    {}
  );
  return {
    ...palette,
    20: palette[50],
  };
};

export const removeIf = (arr, cond) =>
  arr.filter((item, index) =>
    typeof cond === "function" ? !cond(item) : cond !== index
  );

export const updateIf = (arr, cond, updater) =>
  arr.map((item, index) =>
    !(typeof cond === "function" ? cond(item) : cond === index)
      ? item
      : typeof updater === "function"
      ? updater(item)
      : updater
  );
export const upsertIf = (arr, cond, updater) => {
  let found = false;
  const _arr = arr.map((item, index) => {
    found = typeof cond === "function" ? cond(item) : cond === index;
    return !(typeof cond === "function" ? cond(item) : cond === index)
      ? item
      : typeof updater === "function"
      ? updater(item)
      : updater;
  });
  if (!found) {
    _arr.push(typeof updater === "function" ? updater(null) : updater);
  }

  return _arr;
};

// Logic from https://internal.talkbox.io/docs/#b/projectGAgentPermission.pdf
export const permitted = (userPermissions = [], requiredPermission) => {
  if (!requiredPermission) return false;
  if (userPermissions.includes(PermissionGroups.all)) return true;

  if (
    userPermissions.includes(PermissionGroups.console) &&
    requiredPermission !== Permission.system
  )
    return true;

  return userPermissions.includes(requiredPermission);
};

export const delayFor = ms => new Promise(r => setTimeout(r, ms));

export const throttle = (func, timeFrame) => {
  var lastTime = 0;
  return function () {
    var now = new Date();
    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

export const border = (
  borderWidth = "0 0 1px 0",
  borderStyle = "solid",
  borderColor = "gray.100"
) => ({
  borderWidth,
  borderStyle,
  borderColor,
});

// export const setCookie = (name, value, days, options) => {
//   var expires = "";
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie = name + "=" + (value || "") + expires + "; path=/" + options;
// };
// export const getCookie = name => {
//   var nameEQ = name + "=";
//   var ca = document.cookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === " ") c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// };
// export const removeCookie = name => {
//   document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
// };
