import React, { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Button,
  IconButton,
  Center,
  TagLeftIcon,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdClose, MdDelete } from "react-icons/md";
import { useHistory } from "react-router";

import Card from "../card";
import UpsertSanukerTemplateDrawer from "./upsertSanukerTemplateDrawer";
import UpsertSanukerParamDrawer from "./upsertSanukerParamDrawer";

import { Sources } from "../../../../utils/constants";
import AppContainer from "@projectg/utils/store/app";
import api from "@projectg/utils/utils/api";
import { AlertModal } from "../../../../components/components";

const TemplateDetail = ({ id, channelId, templateKey }) => {
  const {
    getTexts,
    externalList,
    setExternalList,
  } = AppContainer.useContainer();
  const toast = useToast();
  const history = useHistory();

  const cancelRef = React.useRef();

  const platform = useMemo(
    () => externalList?.find(platform => platform.id === id),
    [externalList, id]
  );

  const channel = useMemo(
    () => platform?.channels?.find(channel => channel.id === channelId),
    [channelId, platform]
  );

  const template = useMemo(
    () => channel?.templates?.find(template => template.key === templateKey),
    [channel?.templates, templateKey]
  );

  const removeSanukerTemplateAlert = useDisclosure();
  const [
    removeSanukerTemplateIsLoading,
    setRemoveSanukerTemplateIsLoading,
  ] = useState(false);
  const onRemoveSanukerTemplate = async () => {
    try {
      setRemoveSanukerTemplateIsLoading(true);
      const newTemplatesArray = channel?.templates?.filter(
        item => item?.key !== template?.key
      );
      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...channel,
          id,
          channelId,
          templates: newTemplatesArray,
        },
      });
      setExternalList(resData);
      toast({
        title: getTexts("SanukerTemplateRemoved"),
        status: "success",
      });
      history.push(`/externalPlatform/${id}/${channelId}`);
      removeSanukerTemplateAlert.onClose();
    } catch (err) {
    } finally {
      setRemoveSanukerTemplateIsLoading(false);
    }
  };

  const upsertSanukerTemplateDrawer = useDisclosure();

  const upsertSanukerParamDrawer = useDisclosure();
  const [selectedSanukerParamParams, setSelectedSanukerParamParams] = useState(
    {}
  );

  const removeSanukerParamAlert = useDisclosure();
  const [
    removeSanukerParamIsLoading,
    setRemoveSanukerParamIsLoading,
  ] = useState(false);
  const onRemoveSanukerParam = async () => {
    try {
      setRemoveSanukerParamIsLoading(true);

      let newTemplatesArray = [
        ...(JSON.parse(JSON.stringify(channel?.templates)) ?? []),
      ];
      const templateIndex = newTemplatesArray.findIndex(
        _ => _.key === template?.key
      );

      const newParamsArray = newTemplatesArray[templateIndex]?.params?.filter(
        (_, i) => i !== selectedSanukerParamParams?.paramIndex
      );

      newTemplatesArray[templateIndex].params = newParamsArray;

      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...channel,
          id,
          channelId,
          templates: newTemplatesArray,
        },
      });
      setExternalList(resData);
      toast({
        title: getTexts("SanukerParamRemoved"),
        status: "success",
      });
      removeSanukerParamAlert.onClose();
    } catch (err) {
    } finally {
      setRemoveSanukerParamIsLoading(false);
    }
  };

  const { icon: IconSrc, name: defaultName, color = "primary.500" } =
    Sources?.[platform?.type] ?? Sources.web;

  return externalList ? (
    platform && channel && template ? (
      <>
        <AlertModal
          isOpen={removeSanukerTemplateAlert.isOpen}
          cancelRef={cancelRef}
          onClose={removeSanukerTemplateAlert.onClose}
          isLoading={removeSanukerTemplateIsLoading}
          onSubmit={onRemoveSanukerTemplate}
          title={getTexts("SanukerTemplateRemove")}
        />

        <AlertModal
          isOpen={removeSanukerParamAlert.isOpen}
          cancelRef={cancelRef}
          onClose={removeSanukerParamAlert.onClose}
          isLoading={removeSanukerParamIsLoading}
          onSubmit={onRemoveSanukerParam}
          title={getTexts("SanukerParamRemove")}
        />

        {upsertSanukerTemplateDrawer.isOpen && (
          <UpsertSanukerTemplateDrawer
            isOpen={upsertSanukerTemplateDrawer.isOpen}
            onClose={upsertSanukerTemplateDrawer.onClose}
            params={{ platform, channel, template }}
          />
        )}

        {upsertSanukerParamDrawer.isOpen && (
          <UpsertSanukerParamDrawer
            isOpen={upsertSanukerParamDrawer.isOpen}
            onClose={upsertSanukerParamDrawer.onClose}
            params={{
              platform,
              channel,
              template,
              ...selectedSanukerParamParams,
            }}
          />
        )}

        <Flex overflow="auto" flexDir="column" w="100%" h="100%">
          <Box w="100%" minW={768} maxW={1280} mx="auto" zIndex={50} p={8}>
            <Flex alignItems="baseline">
              <IconButton
                onClick={() =>
                  history.push(`/externalPlatform/${id}/${channelId}`)
                }
                variant="ghost"
                isRound
                fontSize="3xl"
                icon={<MdArrowBack />}
              />
              <Flex flexDir="column" ml={4} mb={4}>
                <Heading size="xl" fontFamily="Roboto Slab">
                  {template.name}
                </Heading>
                <Text color="gray.500">{`${platform.id} > ${channel.name} > ${template.name}`}</Text>
              </Flex>
            </Flex>

            <Box mx={14} mb={14}>
              <Flex alignItems="center" mb={4}>
                <TagLeftIcon as={IconSrc} color={color} fontSize="lg" />
                <Text fontSize="lg" mt={1} color="gray.500">
                  {defaultName}
                </Text>
              </Flex>

              <Flex alignItems="flex-end">
                <Flex w="100%" minW={0} flex={1}>
                  <Heading size="lg">
                    {getTexts("SanukerTemplateDetail")}
                  </Heading>
                </Flex>
                <Stack direction="row" spacing={4}>
                  <Button
                    leftIcon={<MdDelete />}
                    colorScheme="red"
                    onClick={removeSanukerTemplateAlert.onOpen}
                    isLoading={removeSanukerTemplateIsLoading}
                  >
                    {getTexts("SanukerTemplateRemove")}
                  </Button>
                  <Button
                    colorScheme="primary"
                    onClick={upsertSanukerTemplateDrawer.onOpen}
                  >
                    {getTexts("Edit")}
                  </Button>
                </Stack>
              </Flex>

              <Box as={Card}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("TemplateKey")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">{template?.key}</Text>
                      </Flex>
                    </Box>
                  </Stack>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("TemplateName")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">{template?.name}</Text>
                      </Flex>
                    </Box>
                  </Stack>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("TemplateDescription")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">{template?.description}</Text>
                      </Flex>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              <Flex alignItems="flex-end" mt={8}>
                <Flex w="100%" minW={0} flex={1}>
                  <Heading size="lg">{getTexts("SanukerParams")}</Heading>
                </Flex>
                <Button
                  colorScheme="primary"
                  onClick={() => {
                    setSelectedSanukerParamParams({});
                    upsertSanukerParamDrawer.onOpen();
                  }}
                >
                  {getTexts("SanukerParamCreate")}
                </Button>
              </Flex>
              {template?.params?.length ? (
                template?.params?.map((param, i) => (
                  <Box
                    as={Card}
                    key={i}
                    py={4}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedSanukerParamParams(_ => ({
                        ..._,
                        param,
                        paramIndex: i,
                      }));
                      upsertSanukerParamDrawer.onOpen();
                    }}
                  >
                    <Box pos="relative">
                      <IconButton
                        pos="absolute"
                        top="0px"
                        right="0px"
                        icon={<MdClose />}
                        variant="ghost"
                        size="sm"
                        fontSize="lg"
                        isRound
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedSanukerParamParams(_ => ({
                            ..._,
                            param,
                            paramIndex: i,
                          }));
                          removeSanukerParamAlert.onOpen();
                        }}
                      />
                    </Box>
                    <Stack flex={1} minW={0} w="100%" spacing={1}>
                      <Text w="100%" fontWeight="bold">
                        {param?.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {`${getTexts("ParamDescription")}: ${
                          param?.description
                        }`}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {`${getTexts("ParamComponent")}: ${param?.component}`}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {`${getTexts("ParamIndex")}: ${param?.index}`}
                      </Text>
                    </Stack>
                  </Box>
                ))
              ) : (
                <Card>
                  <Center>
                    <Text fontSize="xl" color="gray.400">
                      {getTexts("ListEmpty")}
                    </Text>
                  </Center>
                </Card>
              )}
            </Box>
          </Box>
        </Flex>
      </>
    ) : (
      <Box p={8}>
        <Heading>{getTexts("PageNotFound")}</Heading>
      </Box>
    )
  ) : (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  );
};

export default TemplateDetail;
