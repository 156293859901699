import React, { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Input,
  Button,
  useDisclosure,
  useToast,
  IconButton,
  Tag,
  Tooltip,
  Center,
} from "@chakra-ui/react";
import queryString from "querystring";

import Card from "../card";

import UpsertFacebookSecretModal from "./upsertFacebookSecretModal";
import UpsertFacebookPageDrawer from "./upsertFacebookPageDrawer";
import FacebookSetup from "./facebookSetup";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import {
  CopyClipboardButton,
  AlertModal,
} from "../../../../components/components";
import { MdClose, MdDelete } from "react-icons/md";
import { useHistory, useLocation } from "react-router";

const FacebookDetail = ({ platform }) => {
  const { getTexts, setExternalList } = AppContainer.useContainer();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => {
    return queryString.parse(location.search.substr(1));
  }, [location.search]);

  const cancelRef = React.useRef();

  const [selectedFacebookPageParams, setSelectedFacebookPageParams] = useState({
    platform,
  });

  const removeFacebookAlert = useDisclosure();
  const [removeFacebookIsLoading, setRemoveFacebookIsLoading] = useState(false);

  const upsertFacebookSecretModal = useDisclosure();

  const removeFacebookPageAlert = useDisclosure();
  const [
    removeFacebookPageIsLoading,
    setRemoveFacebookPageIsLoading,
  ] = useState(false);

  const upsertFacebookPageDrawer = useDisclosure();

  const onRemoveFacebook = async () => {
    try {
      setRemoveFacebookIsLoading(true);
      const { data } = await api.post({
        url: "/api/admin/setting/externalPlatform/facebook/delete",
        body: {
          id: platform.id,
        },
      });
      setExternalList(data);
      removeFacebookAlert.onClose();
      toast({
        title: getTexts("FacebookConnectionRemoved"),
        status: "success",
      });
      history.replace("/externalPlatform");
    } catch (error) {
    } finally {
      setRemoveFacebookIsLoading(false);
    }
  };

  const onRemoveFacebookPage = async () => {
    if (selectedFacebookPageParams?.page?.id)
      try {
        setRemoveFacebookPageIsLoading(true);
        const { data } = await api.post({
          url: "/api/admin/setting/externalPlatform/facebook/page/delete",
          body: {
            id: platform.id,
            pageId: selectedFacebookPageParams.page.id,
          },
        });
        setExternalList(data);
        removeFacebookPageAlert.onClose();
        toast({
          title: getTexts("FacebookPageRemoved"),
          status: "success",
        });
      } catch (err) {
      } finally {
        setRemoveFacebookPageIsLoading(false);
      }
  };

  return query?.facebookSetup ? (
    <FacebookSetup platform={platform} />
  ) : (
    <>
      <AlertModal
        isOpen={removeFacebookAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeFacebookAlert.onClose}
        isLoading={removeFacebookIsLoading}
        onSubmit={onRemoveFacebook}
        title={getTexts("FacebookConnectionRemove")}
      />

      <AlertModal
        isOpen={removeFacebookPageAlert.isOpen}
        cancelRef={cancelRef}
        onClose={removeFacebookPageAlert.onClose}
        isLoading={removeFacebookPageIsLoading}
        onSubmit={onRemoveFacebookPage}
        title={getTexts("FacebookPageRemove")}
      />

      <UpsertFacebookSecretModal
        title={getTexts("FacebookAppSecretUpdate")}
        isOpen={upsertFacebookSecretModal.isOpen}
        onClose={() => {
          upsertFacebookSecretModal.onClose();
        }}
        facebookId={platform.id}
      />

      {upsertFacebookPageDrawer.isOpen && (
        <UpsertFacebookPageDrawer
          isOpen={upsertFacebookPageDrawer.isOpen}
          onClose={upsertFacebookPageDrawer.onClose}
          params={selectedFacebookPageParams}
        />
      )}

      <Flex alignItems="flex-end">
        <Flex w="100%" minW={0} flex={1}>
          <Heading size="lg">{getTexts("ExternalPlatformDetail")}</Heading>
        </Flex>

        <Stack direction="row" spacing={4}>
          <Button
            leftIcon={<MdDelete />}
            colorScheme="red"
            onClick={removeFacebookAlert.onOpen}
            isLoading={removeFacebookIsLoading}
          >
            {getTexts("FacebookConnectionRemove")}
          </Button>
          <Button onClick={upsertFacebookSecretModal.onOpen}>
            {getTexts("FacebookAppSecretUpdate")}
          </Button>
          <Button
            colorScheme="primary"
            onClick={() =>
              history.push({
                search: "facebookSetup=1",
              })
            }
          >
            {getTexts("SetupWizard")}
          </Button>
        </Stack>
      </Flex>

      <Box as={Card}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Text w="100%" fontWeight="bold">
              {getTexts("WebhookUrl")}
            </Text>
            <Box fontSize="sm" color="gray.500">
              <Flex alignItems="center">
                <Input
                  value={platform?.webhookUrl}
                  readOnly
                  placeholder="We can't find your Facebook Connection, Please setup your Facebook Application"
                />
                {platform?.webhookUrl && (
                  <CopyClipboardButton content={platform?.webhookUrl} />
                )}
              </Flex>
            </Box>
          </Stack>
          <Stack spacing={2}>
            <Text w="100%" fontWeight="bold">
              {getTexts("VerifyToken")}
            </Text>
            <Box fontSize="sm" color="gray.500">
              <Flex alignItems="center">
                <Input
                  value={platform?.verifyToken}
                  readOnly
                  placeholder="We can't find your Facebook Connection, Please setup your Facebook Application"
                />
                {platform?.verifyToken && (
                  <CopyClipboardButton content={platform?.verifyToken} />
                )}
              </Flex>
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Flex alignItems="flex-end" mt={8}>
        <Flex w="100%" minW={0} flex={1}>
          <Heading size="lg">{getTexts("FacebookPage")}</Heading>
        </Flex>
        <Button
          colorScheme="primary"
          onClick={() => {
            setSelectedFacebookPageParams({ platform });
            upsertFacebookPageDrawer.onOpen();
          }}
        >
          {getTexts("FacebookPageConnect")}
        </Button>
      </Flex>
      {platform?.pages?.length ? (
        platform?.pages?.map((page, i) => (
          <Box
            as={Card}
            key={i}
            py={4}
            cursor="pointer"
            onClick={() => {
              setSelectedFacebookPageParams(_ => ({ ..._, page }));
              upsertFacebookPageDrawer.onOpen();
            }}
          >
            <Box pos="relative">
              <IconButton
                pos="absolute"
                top="0px"
                right="0px"
                icon={<MdClose />}
                variant="ghost"
                size="sm"
                fontSize="lg"
                isRound
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedFacebookPageParams(_ => ({ ..._, page }));
                  removeFacebookPageAlert.onOpen();
                }}
              />
            </Box>
            <Stack flex={1} minW={0} w="100%" spacing={1}>
              <Text w="100%" fontWeight="bold">
                {page?.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {`${getTexts("PageId")}: ${page?.id}`}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {`${getTexts("ResponseWindowHours")}: ${
                  page?.responseWindowHours
                }`}
              </Text>
              <Box flex={1}>
                {page?.tags?.map(({ name, tag, description }) => (
                  <Tag colorScheme="secondary" mr={2} size="sm" key={tag}>
                    <Tooltip label={description}>{name}</Tooltip>
                  </Tag>
                ))}
              </Box>
            </Stack>
          </Box>
        ))
      ) : (
        <Card>
          <Center>
            <Text fontSize="xl" color="gray.400">
              {getTexts("FacebookPageListEmpty")}
            </Text>
          </Center>
        </Card>
      )}
    </>
  );
};

export default FacebookDetail;
