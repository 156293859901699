import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { MdAdd, MdClose } from "react-icons/md";
import api from "@projectg/utils/utils/api";
import { unstable_batchedUpdates } from "react-dom";
import {
  InlineInputBuilder,
  Section,
  TeamPicker,
} from "../../components/components";
import { FaTrash } from "react-icons/fa";
import { removeIf, upsertIf } from "@projectg/utils/utils/helpers";
import MessengerContainer from "@projectg/utils/store/messenger";
import AppContainer from "@projectg/utils/store/app";
import { useIsMounted } from "@projectg/utils/utils/hooks";
import { LangKey } from "@projectg/utils/utils/constants";

const InlineInputSection = ({ ...props }) => {
  const [selected, setSelected] = useState(null);
  const [inlineInputs, setInlineInputs] = useState(null);
  const [loading, setLoading] = useState(true);
  const mounted = useIsMounted();
  const { getTexts } = AppContainer.useContainer();
  const fetchInlineInputs = useCallback(async () => {
    try {
      setLoading(true);
      const { data: _inputs } = await api.get({
        url: "/api/admin/inlineInput",
        params: {
          size: 0,
        },
      });
      setInlineInputs(_inputs);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchInlineInputs();
  }, [fetchInlineInputs]);
  return loading && !mounted ? (
    <Flex>
      <Spinner mx="auto" color="primary.500" />
    </Flex>
  ) : (
    <Flex {...props}>
      <Box w={300} px={2}>
        {loading ? (
          <Spinner mx="auto" color="primary.500" />
        ) : (
          <>
            <Stack {...props}>
              {inlineInputs.length === 0 ? (
                <Text mx="auto" color="gray.500">
                  {getTexts("NoInteractiveInformation")}
                </Text>
              ) : (
                <Box overflow="auto" flex={1}>
                  {inlineInputs.map(inlineInput => (
                    <Box
                      cursor="pointer"
                      alignItems="center"
                      as={Flex}
                      _hover={{ bg: "gray.50" }}
                      p={2}
                      fontSize="md"
                      borderBottom="1px solid #eee"
                      onClick={() => setSelected(inlineInput)}
                      key={inlineInput?.id}
                      w="100%"
                    >
                      <Text flex={1} minW={0} w="100%">
                        {inlineInput?.name}
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
            </Stack>
            <Flex textAlign="right" w="100%">
              <Button
                mx="auto"
                variant="link"
                colorScheme="primary"
                leftIcon={<MdAdd />}
                onClick={() => setSelected(null)}
              >
                {getTexts("AddTemplate")}
              </Button>
            </Flex>
          </>
        )}
      </Box>
      <Box flex={1} minW={0} w="100%">
        <InlineInputBuilder
          id={selected?.id}
          bg="primary.100"
          color="primary._100"
          onRefresh={fetchInlineInputs}
        />
      </Box>
    </Flex>
  );
};

const Configuration = () => {
  const [rules, setRules] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdating] = useState(false);
  const { handleSubmit, register, reset, control } = useForm({});

  const toast = useToast();
  const { setAppConfig, getTexts } = AppContainer.useContainer();

  const onSubmit = useCallback(
    async appConfig => {
      try {
        setUpdating(true);
        const { data: _appConfig } = await api.post({
          url: "/api/admin/setting/update",
          body: appConfig,
        });
        unstable_batchedUpdates(() => {
          setAppConfig(_appConfig);
          setUpdating(false);
          toast({
            title: getTexts("UpdateSucceed"),
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        });
      } catch (error) {
        toast({
          title: getTexts("UpdateFail"),
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      }
    },
    [getTexts, setAppConfig, toast]
  );

  const {
    fields: customerFields,
    append: appendCustomerField,
    remove: removeCustomerField,
  } = useFieldArray({
    control,
    name: "customerFields",
  });

  const {
    fields: agentFields,
    append: appendAgentField,
    remove: removeAgentField,
  } = useFieldArray({
    control,
    name: "agentFields",
  });

  const {
    setConversationTags,
    setCustomerTags,
    upsertTeam,
    conversationTags,
    customerTags,
  } = MessengerContainer.useContainer();

  const originalAppConfig = useRef(null);
  useEffect(() => {
    (async () => {
      try {
        const { data: appConfig } = await api.get({
          url: "/api/admin/setting",
        });

        const { data: rules } = await api.get({
          url: "/api/admin/setting/rule",
        });

        const { data: conversationTags } = await api.get({
          url: "/api/tag/conversation",
        });
        const { data: customerTags } = await api.get({
          url: "/api/tag/customer",
        });
        unstable_batchedUpdates(() => {
          reset(appConfig);
          setRules(rules);
          setConversationTags(conversationTags);
          setCustomerTags(customerTags);
          originalAppConfig.current = appConfig;
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reset, setConversationTags, setCustomerTags, upsertTeam]);

  const [expandedKey, setExpandedKey] = useState(null);

  const sectionFooter = (
    <Box w="100%" mt={4} textAlign="right">
      <Button
        mt={4}
        variant="link"
        colorScheme="primary"
        size="lg"
        fontSize="xl"
        isDisabled={updating}
        onClick={() => {
          setExpandedKey(null);
          reset(originalAppConfig.current);
        }}
      >
        {getTexts("Cancel")}
      </Button>
      <Button
        ml={4}
        mt={4}
        colorScheme="primary"
        size="lg"
        fontSize="xl"
        type="submit"
        isLoading={updating}
      >
        {getTexts("Apply")}
      </Button>
    </Box>
  );

  const onRuleUpdate = useCallback(async ({ teams, tag: _tag }) => {
    try {
      let _rules = null;
      setRules(_ => {
        _rules = upsertIf(
          _,
          ({ type, tag }) =>
            type === "conversationTagAssignRule" && tag === _tag,
          rule => ({
            type: rule?.type ?? "conversationTagAssignRule",
            tag: rule?.tag ?? _tag,
            team: teams,
          })
        );
        return _rules;
      });

      try {
        await api.post({
          url: "/api/admin/setting/rule/update",
          body: { rules: _rules },
        });
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    } catch (error) {}
  }, []);

  return fetching ? (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  ) : (
    <Flex position="relative" w="100%" h="100%">
      <Box p={8} overflow="auto" w="100%">
        <Box maxW={768} mx="auto">
          <Section
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            title={getTexts("CustomerFields")}
            description={getTexts("customerFields_description")}
            isExpanded={expandedKey === "customerFields"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "customerFields" ? null : "customerFields"
              )
            }
          >
            <FormControl>
              {(customerFields ?? []).map(({ id, type, name }, index) => {
                return (
                  <Box key={id} boxShadow="sm" p={4} mt={4}>
                    <Flex alignItems="center">
                      <Text fontWeight="bold">{`${getTexts("Field")} ${
                        index + 1
                      }`}</Text>
                      <Input
                        mx={4}
                        w={180}
                        ref={register()}
                        isReadOnly={true}
                        flex={1}
                        colorScheme="primary"
                        variant="unstyled"
                        name={`customerFields[${index}].id`}
                        defaultValue={id}
                      />
                      <Select
                        ref={register()}
                        w={100}
                        mx={2}
                        colorScheme="primary"
                        focusBorderColor="primary.500"
                        name={`customerFields[${index}].type`}
                        defaultValue={type}
                      >
                        {[
                          { value: "text", label: getTexts("Text") },
                          { value: "phone", label: getTexts("Phone") },
                          { value: "email", label: getTexts("Email") },
                          { value: "url", label: getTexts("Url") },
                          { value: "date", label: getTexts("Date") },
                          {
                            value: "anniversary",
                            label: getTexts("Anniversary"),
                          },
                        ].map(({ label, value }) => {
                          return (
                            <option value={value} key={value}>
                              {label}
                            </option>
                          );
                        })}
                      </Select>{" "}
                      <IconButton
                        fontSize="xl"
                        variant="ghost"
                        icon={<MdClose />}
                        onClick={() => removeCustomerField(index)}
                      />
                    </Flex>
                    <FormControl mt={4}>
                      {[
                        { value: LangKey.EN, label: "English" },
                        { value: LangKey.ZH, label: "繁體中文" },
                        { value: LangKey.ZH_CN, label: "簡體中文" },
                      ].map(({ value, label }, _index) => (
                        <FormControl d="flex" mt={2} key={value}>
                          <FormLabel
                            mt={2}
                            mr={4}
                            w={150}
                            fontSize="md"
                            color="gray.500"
                          >
                            {label}
                          </FormLabel>
                          <Input
                            variant="flushed"
                            flex={1}
                            focusBorderColor="primary.500"
                            ref={register()}
                            name={`customerFields[${index}].name.${value}`}
                            defaultValue={name?.[value]}
                          />
                        </FormControl>
                      ))}
                    </FormControl>
                  </Box>
                );
              })}

              <Flex w="100%" justifyContent="flex-end">
                <Button
                  mt={4}
                  leftIcon={<MdAdd />}
                  variant="link"
                  colorScheme="primary"
                  size="md"
                  onClick={() => appendCustomerField()}
                >
                  {getTexts("add_field")}
                </Button>
              </Flex>
            </FormControl>
            {sectionFooter}
          </Section>

          <Section
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            title={getTexts("AgentFields")}
            description={getTexts("agentFields_description")}
            isExpanded={expandedKey === "agentFields"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "agentFields" ? null : "agentFields"
              )
            }
          >
            <FormControl>
              {(agentFields ?? []).map(({ id, type, name }, index) => {
                return (
                  <Box key={id} boxShadow="sm" p={4} mt={4}>
                    <Flex alignItems="center">
                      <Text fontWeight="bold">{`${getTexts("Field")} ${
                        index + 1
                      }`}</Text>
                      <Input
                        mx={4}
                        w={180}
                        ref={register()}
                        isReadOnly={true}
                        flex={1}
                        colorScheme="primary"
                        variant="unstyled"
                        name={`agentFields[${index}].id`}
                        defaultValue={id}
                      />
                      <Select
                        ref={register()}
                        w={100}
                        mx={2}
                        colorScheme="primary"
                        focusBorderColor="primary.500"
                        name={`agentFields[${index}].type`}
                        defaultValue={type}
                      >
                        {[
                          { value: "text", label: getTexts("Text") },
                          { value: "phone", label: getTexts("Phone") },
                          { value: "email", label: getTexts("Email") },
                          { value: "url", label: getTexts("Url") },
                          { value: "date", label: getTexts("Date") },
                          {
                            value: "anniversary",
                            label: getTexts("Anniversary"),
                          },
                        ].map(({ label, value }) => {
                          return (
                            <option value={value} key={value}>
                              {label}
                            </option>
                          );
                        })}
                      </Select>
                      <IconButton
                        fontSize="xl"
                        variant="ghost"
                        icon={<MdClose />}
                        onClick={() => removeAgentField(index)}
                      />
                    </Flex>
                    <FormControl mt={4}>
                      {[
                        { value: LangKey.EN, label: "English" },
                        { value: LangKey.ZH, label: "繁體中文" },
                        { value: LangKey.ZH_CN, label: "簡體中文" },
                      ].map(({ value, label }, _index) => (
                        <FormControl d="flex" mt={2} key={value}>
                          <FormLabel
                            mt={2}
                            mr={4}
                            w={150}
                            fontSize="md"
                            color="gray.500"
                          >
                            {label}
                          </FormLabel>
                          <Input
                            variant="flushed"
                            flex={1}
                            focusBorderColor="primary.500"
                            ref={register()}
                            name={`agentFields[${index}].name.${value}`}
                            defaultValue={name?.[value]}
                          />
                        </FormControl>
                      ))}
                    </FormControl>
                  </Box>
                );
              })}

              <Flex w="100%" justifyContent="flex-end">
                <Button
                  mt={4}
                  leftIcon={<MdAdd />}
                  variant="link"
                  colorScheme="primary"
                  size="md"
                  onClick={() => appendAgentField()}
                >
                  {getTexts("add_field")}
                </Button>
              </Flex>
            </FormControl>
            {sectionFooter}
          </Section>

          <Section
            title={getTexts("ConversationTags")}
            description={getTexts("conversationTags_description")}
            isExpanded={expandedKey === "conversationTags"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "conversationTags" ? null : "conversationTags"
              )
            }
          >
            {(conversationTags ?? []).map(tag => {
              return (
                <Box
                  as={Flex}
                  alignItems="center"
                  key={tag}
                  _hover={{ bg: "gray.50", "> *": { visibility: "visible" } }}
                  borderBottom="1px solid #eee"
                >
                  <Box p={4}>
                    <Text w="100%" fontSize="lg">
                      {tag}
                    </Text>
                    <Flex mt={2} alignItems="center">
                      <Text color="gray.500" fontSize="sm">
                        {getTexts("automatically_assigned_to")}
                      </Text>
                      <TeamPicker
                        ml={2}
                        flex={1}
                        minW={0}
                        w="100%"
                        value={
                          rules?.find(
                            ({ type, tag: _tag }) =>
                              type === "conversationTagAssignRule" &&
                              tag === _tag
                          )?.team ?? []
                        }
                        onChange={teams => onRuleUpdate({ tag, teams })}
                      />
                    </Flex>
                  </Box>
                  <Box flex={1} minW={0} w="100%" />

                  <IconButton
                    onClick={async () => {
                      try {
                        await api.post({
                          url: "/api/tag/conversation/delete",
                          body: {
                            tag,
                          },
                        });
                        setConversationTags(_ =>
                          removeIf(_, _tag => _tag === tag)
                        );
                        return false;
                      } catch (error) {
                        console.error(error);
                        return false;
                      }
                    }}
                    icon={<FaTrash />}
                    variant="ghost"
                    colorScheme="red"
                  />
                </Box>
              );
            })}

            <InputGroup
              mt={4}
              size="lg"
              w="100%"
              as="form"
              onSubmit={async e => {
                e.stopPropagation();
                e.preventDefault();
                try {
                  const { data: tags } = await api.post({
                    url: "/api/tag/conversation/create",
                    body: {
                      tag: e.target.tagInput.value,
                    },
                  });
                  setConversationTags(tags);
                  e.target.tagInput.value = "";
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              }}
            >
              <Input
                focusBorderColor="primary.500"
                name="tagInput"
                placeholder={getTexts("NewTag")}
                variant="outline"
              />
              <InputRightElement w={16}>
                <Button
                  size="lg"
                  type="submit"
                  colorScheme="primary"
                  variant="link"
                >
                  {getTexts("Add")}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Section>

          <Section
            title={getTexts("DefaultCustomerTags")}
            description={getTexts("defaultCustomerTags_description")}
            isExpanded={expandedKey === "customerTags"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "customerTags" ? null : "customerTags"
              )
            }
          >
            {(customerTags ?? []).map(tag => {
              return (
                <Box
                  as={Flex}
                  alignItems="center"
                  key={tag}
                  _hover={{ bg: "gray.50", "> *": { visibility: "visible" } }}
                  borderBottom="1px solid #eee"
                >
                  <Text p={4}>{tag}</Text>
                  <Box flex={1} minW={0} w="100%" />
                  <IconButton
                    onClick={async () => {
                      try {
                        await api.post({
                          url: "/api/tag/customer/delete",
                          body: {
                            tag,
                          },
                        });
                        setCustomerTags(_ => removeIf(_, _tag => _tag === tag));
                        return false;
                      } catch (error) {
                        console.error(error);
                        return false;
                      }
                    }}
                    icon={<FaTrash />}
                    variant="ghost"
                    colorScheme="red"
                  />
                </Box>
              );
            })}

            <InputGroup
              mt={4}
              size="lg"
              w="100%"
              as="form"
              onSubmit={async e => {
                e.stopPropagation();
                e.preventDefault();
                try {
                  const { data: tags } = await api.post({
                    url: "/api/tag/customer/create",
                    body: {
                      tag: e.target.tagInput.value,
                    },
                  });
                  setCustomerTags(tags);
                  e.target.tagInput.value = "";
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              }}
            >
              <Input
                focusBorderColor="primary.500"
                name="tagInput"
                placeholder={getTexts("NewTag")}
                variant="outline"
              />
              <InputRightElement w={16}>
                <Button
                  size="lg"
                  type="submit"
                  colorScheme="primary"
                  variant="link"
                >
                  {getTexts("Add")}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Section>

          <Section
            title={getTexts("InlineInput")}
            description={getTexts("inlineInput_description")}
            isExpanded={expandedKey === "inlineInput"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "inlineInput" ? null : "inlineInput"
              )
            }
          >
            <InlineInputSection />
          </Section>
        </Box>
      </Box>
    </Flex>
  );
};

export default Configuration;
