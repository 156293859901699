import React, { useCallback, useEffect, useState } from "react";
import {
  Select,
  Box,
  Stack,
  FormLabel,
  FormControl,
  Heading,
  Button,
  Divider,
  Checkbox,
  Text,
  Flex,
  Tooltip,
  FormErrorMessage,
  PopoverTrigger,
  PopoverContent,
  Popover,
  Input,
  Tag
} from "@chakra-ui/react";
import { Controller, get, useForm } from "react-hook-form";
import DatePickerInput from "../../components/DatePicker";
import api from "@projectg/utils/utils/api";
import dayjs from "dayjs";
import { downloadBlob, removeIf } from "@projectg/utils/utils/helpers";
import {
  MdAttachFile,
  MdFileDownload,
  MdKeyboardVoice,
  MdRefresh
} from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { unstable_batchedUpdates } from "react-dom";
import AppContainer from "@projectg/utils/store/app";

const Report = () => {
  const [downloading, setDownloading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    watch,
    errors,
    getValues,
    formState,
    trigger
  } = useForm({
    mode: "onChange"
  });

  const [exportList, setExportList] = useState([]);
  const [exportKey, setExportKey] = useState(null);
  const [fetching, setFetching] = useState(true);
  const { getTexts } = AppContainer.useContainer();

  const fetchMessageExportList = useCallback(async () => {
    try {
      setFetching(true);
      const { data: exportList } = await api.get({
        url: "/api/admin/report/message/export"
      });
      const { data: setting } = await api.get({
        url: "/api/admin/setting"
      });
      unstable_batchedUpdates(() => {
        setExportKey(setting?.exportKey);
        setExportList(exportList);
        setFetching(false);
      });
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);
  const watched = watch();

  const onExport = useCallback(
    async ({ reportType, from, to, includeFile, includeVoice }) => {
      try {
        setDownloading(true);
        switch (reportType) {
          case "conversation": {
            const blob = await api.get({
              url: "/api/admin/report/conversation/download",
              params: {
                from: dayjs(from).format("YYYY-MM-DD"),
                to: dayjs(to).format("YYYY-MM-DD")
              },
              responseType: "blob"
            });
            if (blob) {
              downloadBlob(blob);
            }
            break;
          }
          case "conversation_agent": {
            const blob = await api.get({
              url: "/api/admin/report/conversation/agent/download",
              params: {
                from: dayjs(from).format("YYYY-MM-DD"),
                to: dayjs(to).format("YYYY-MM-DD")
              },
              responseType: "blob"
            });
            if (blob) {
              downloadBlob(blob);
            }
            break;
          }
          case "message_history":
            await api.post({
              url: "/api/admin/report/message/export/request",
              body: {
                from,
                to,
                includeFile,
                includeVoice
              }
            });
            fetchMessageExportList();
            break;
          default:
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDownloading(false);
      }
    },
    [fetchMessageExportList]
  );

  const onExportKeyRevoke = useCallback(async () => {
    try {
      const { data: setting } = await api.post({
        url: "/api/admin/setting/revokeMessageExportKey"
      });
      setExportKey(setting?.exportKey);
    } catch (error) {}
  }, []);

  const onDownload = useCallback(async id => {
    try {
      const { data: urls } = await api.post({
        url: "/api/admin/report/message/export/download",
        body: {
          id
        }
      });
      const blob = await api.get({
        url: urls?.[0],
        responseType: "blob"
      });
      if (blob) {
        downloadBlob(blob, `${id}.zip`);
      }
    } catch (error) {}
  }, []);
  const onRemove = useCallback(async id => {
    try {
      setExportList(_ => removeIf(_, item => item?.id === id));
      await api.post({
        url: "/api/admin/report/message/export/delete",
        body: {
          id
        }
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchMessageExportList();
  }, [fetchMessageExportList]);

  return (
    <Box height="100%" w="100%" overflow="auto">
      <Stack w="100%">
        <Box mx="auto" w="100%" maxW={768} p={8}>
          <Flex>
            <Heading
              flex={1}
              minW={0}
              w="100%"
              as="h2"
              fontFamily="Roboto Slab"
              mb={12}
            >
              {getTexts("Report")}
            </Heading>
            <Popover>
              <PopoverTrigger>
                <Button variant="ghost" colorScheme="primary">
                  {getTexts("ViewReportPassword")}
                </Button>
              </PopoverTrigger>
              <PopoverContent p={2}>
                <FormControl d="flex" alignItems="center">
                  <Input
                    focusBorderColor="primary.500"
                    flex={1}
                    minW={0}
                    w="100%"
                    readonly
                    value={exportKey}
                  />
                  <Button
                    icon={<MdRefresh />}
                    ml={1}
                    size="sm"
                    variant="ghost"
                    colorScheme="primary"
                    onClick={onExportKeyRevoke}
                  >
                    {getTexts("RevokeAndResendNewPassword")}
                  </Button>
                </FormControl>
              </PopoverContent>
            </Popover>
          </Flex>
          <Box as="form" onSubmit={handleSubmit(onExport)}>
            <FormControl
              d="flex"
              alignItems="center"
              isInvalid={errors?.reportType}
            >
              <FormLabel p={0}>{getTexts("ReportType")}</FormLabel>
              <Select
                flex={1}
                ml={8}
                maxW={300}
                defaultValue="conversation"
                ref={register({
                  required: getTexts("Required")
                })}
                name="reportType"
              >
                <option value="conversation">
                  {getTexts("ConversationReport")}
                </option>
                <option value="conversation_agent">
                  {getTexts("ConversationReportIncludingAgentInformation")}
                </option>
                <option value="message_history">
                  {getTexts("ConversationInformationRecord")}
                </option>
              </Select>
              <FormErrorMessage>{errors?.reportType?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              d="flex"
              alignItems="center"
              isInvalid={errors?.from}
            >
              <FormLabel p={0}>{getTexts("StartDate")}</FormLabel>
              <Controller
                defaultValue={dayjs().subtract(1, "month").format("YYYY-MM-DD")}
                control={control}
                rules={{
                  required: getTexts("Required"),
                  value: () => trigger("to")
                }}
                name="from"
                render={({ value, onBlur, name, onChange }) => {
                  return (
                    <DatePickerInput
                      ml={8}
                      flex={1}
                      maxW={300}
                      name={name}
                      onBlur={onBlur}
                      returnFormat="YYYY-MM-DD"
                      placeholder={getTexts("SelectDate")}
                      onChange={(_, obj) => onChange(obj ?? null)}
                      defaultValue={value}
                      variant="outline"
                      bg="transparent"
                      size="md"
                    />
                  );
                }}
              />
              <FormErrorMessage ml={4}>
                {errors?.from?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              d="flex"
              alignItems="center"
              isInvalid={errors?.to}
            >
              <FormLabel p={0}>{getTexts("EndDate")}</FormLabel>
              <Controller
                defaultValue={dayjs().format("YYYY-MM-DD")}
                control={control}
                rules={{
                  required: getTexts("Required"),
                  validate: {
                    invalidRange: value =>
                      dayjs(getValues("from"), "YYYY-MM-DD").isSameOrBefore(
                        dayjs(value)
                      ) || `${getTexts("IncorrectDateRange")}`,
                    maxRange: value =>
                      dayjs(value, "YYYY-MM-DD").diff(
                        dayjs(getValues("from"), "YYYY-MM-DD"),
                        "day"
                      ) <= 30 ||
                      `${getTexts("TheDateRangeCannotBeMoreThan30Days")}`
                  }
                }}
                name="to"
                render={({ value, onBlur, name, onChange }) => {
                  return (
                    <DatePickerInput
                      ml={8}
                      flex={1}
                      maxW={300}
                      name={name}
                      onBlur={onBlur}
                      returnFormat="YYYY-MM-DD"
                      placeholder={getTexts("SelectDate")}
                      onChange={(_, obj) => onChange(obj ?? null)}
                      defaultValue={value}
                      variant="outline"
                      bg="transparent"
                      size="md"
                    />
                  );
                }}
              />
              <FormErrorMessage ml={4}>{errors?.to?.message}</FormErrorMessage>
            </FormControl>
            {watched?.reportType === "message_history" && (
              <>
                <FormControl mt={4}>
                  <Checkbox
                    w="100%"
                    mt={4}
                    colorScheme="primary"
                    name="includeVoice"
                    ref={register}
                  >
                    {getTexts("TheExportedFileIncludesAllVoiceMessages")}
                  </Checkbox>
                  <Checkbox
                    w="100%"
                    mt={4}
                    colorScheme="primary"
                    name="includeFile"
                    ref={register}
                  >
                    {getTexts("TheExportedFileIncludesAllAttachments")}
                  </Checkbox>
                </FormControl>
              </>
            )}
            <FormControl w="100%" textAlign="right" mt={4}>
              <Button variant="ghost" colorScheme="primary" type="reset">
                {getTexts("ResetFilter")}
              </Button>
              <Button
                isDisabled={!formState.isValid}
                variant="solid"
                colorScheme="primary"
                type="submit"
                isLoading={downloading}
              >
                {getTexts("DownloadReport")}
              </Button>
            </FormControl>
          </Box>
          <Divider my={4} />
          <Box
            mt={8}
            {...(watched?.reportType !== "message_history" && { d: "none" })}
          >
            <Flex>
              <Box flex={1} minW={0} w="100%">
                <Heading as="h3" fontSize="lg">
                  {getTexts("PastDownloadHistory")}
                </Heading>
                <Text color="gray.500" mt={1}>
                  {getTexts(
                    "ItTakesTimeToDownloadConversationInformationRecordsAndTheSystemWillAutomaticallyBackUpTheRecordsThatHaveBeenDownloaded"
                  )}
                </Text>
              </Box>
              <Button
                lefticon={<MdRefresh />}
                onClick={fetchMessageExportList}
                isLoading={fetching}
                variant="ghost"
                colorScheme="secondary"
              >
                {getTexts("Reorganize")}
              </Button>
            </Flex>
            <Box mt={8}>
              {(exportList ?? [])
                .sort((a, b) => (a?.createDate > b?.createDate ? -1 : 1))
                .map(
                  (
                    { id, from, to, includeVoice, includeFile, status },
                    index,
                    _arr
                  ) => {
                    return (
                      <Box
                        key={id}
                        as={Flex}
                        _hover={{ bg: "gray.50" }}
                        alignItems="center"
                        p={2}
                      >
                        <Text w={12} p={2}>
                          #{_arr?.length - index}
                        </Text>
                        <Text p={2}>
                          {dayjs(from).format("YYYY-MM-DD")}
                          {` ${getTexts("To")} `}
                          {dayjs(to).format("YYYY-MM-DD")}
                        </Text>
                        <Tooltip
                          label={
                            includeVoice
                              ? getTexts("IncludingVoice")
                              : getTexts("DoesNotIncludeVoice")
                          }
                        >
                          <Box
                            p={2}
                            mx={-1}
                            color={includeVoice ? "secondary.500" : "gray.300"}
                          >
                            <MdKeyboardVoice />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          label={
                            includeFile
                              ? getTexts("IncludingAccessories")
                              : getTexts("DoesNotIncludeAccessories")
                          }
                        >
                          <Box
                            p={2}
                            mx={-1}
                            color={includeFile ? "secondary.500" : "gray.300"}
                          >
                            <MdAttachFile />
                          </Box>
                        </Tooltip>
                        <Box flex={1} minW={0} w="100%" />
                        {status === "pending" && (
                          <Tag colorScheme="secondary">
                            {getTexts("Scheduled")}
                          </Tag>
                        )}
                        <Button
                          isDisabled={status !== "success"}
                          lefticon={<MdFileDownload />}
                          colorScheme="primary"
                          variant="ghost"
                          onClick={() => onDownload(id)}
                        >
                          {getTexts("Export")}
                        </Button>
                        <Button
                          lefticon={<FaTrash />}
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => onRemove(id)}
                        >
                          {getTexts("Delete")}
                        </Button>
                      </Box>
                    );
                  }
                )}
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Report;
