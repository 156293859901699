import lamejs from "lamejs"; /* global lamejs */
import AudioDecoder from "./AudioDecoder";

//defien Math.log10 function if not exist
if (typeof Math.log10 != "function") {
  Math.log10 = function (varx) {
    return Math.log(varx) / Math.log(10);
  };
}

function convert(n) {
  var v = n < 0 ? n * 32768 : n * 32767; // convert in range [-32768, 32767]
  return Math.max(-32768, Math.min(32768, v)); // clamp
}

function f32Toi16(data) {
  var len = data.length,
    i = 0;
  var dataAsInt16Array = new Int16Array(len);

  while (i < len) {
    dataAsInt16Array[i] = convert(data[i++]);
  }
  return dataAsInt16Array;
}

function TeamworkVoiceDecoder(url, headers) {
  this.url = url;
  this._headers = headers;
  this.option = {
    opusDecoderArg: {
      sampleRate: 24000,
      channels: 1,
    },
    mp3DecoderArg: {
      sampleRate: 24000,
      channels: 1,
      kbps: 128,
      sourceBlockSize: 1152,
    },
    waveDecoderArg: {
      channels: 1,
      sampleRate: 24000,
      bytesPerSample: 2,
    },
  };

  this.mp3Encoder = new lamejs.Mp3Encoder(
    this.option.mp3DecoderArg.channels,
    this.option.mp3DecoderArg.sampleRate,
    this.option.mp3DecoderArg.kbps
  );

  this._decodedPosition = 0;
  this._dataBytes = null;
  this._pcmRaw = [];
  this._callback = null;
}

TeamworkVoiceDecoder.prototype._download = function (callback) {
  this._callback = callback;

  var opus_header_buf = new ArrayBuffer(19);
  var view8 = new Uint8Array(opus_header_buf);
  var view32 = new Uint32Array(opus_header_buf, 12, 1);
  var magic = "OpusHead";
  for (var i = 0; i < magic.length; ++i) view8[i] = magic.charCodeAt(i);
  view8[8] = 1;
  view8[9] = this.option.opusDecoderArg.channels;
  view8[10] = view8[11] = 0;
  view32[0] = this.option.opusDecoderArg.sampleRate;
  view8[16] = view8[17] = 0;
  view8[18] = 0;
  var headerPacket = [
    {
      data: opus_header_buf,
    },
  ];

  var decoder = (this.opusDecoder = new AudioDecoder());
  decoder.setup({}, headerPacket).then(
    info => {
      window.console.log("decoder.setup.reslove", info);

      var req = new XMLHttpRequest();
      req.onload = () => {
        this._dataBytes = new Uint8Array(req.response);
        this._decodeNext();
      };
      req.open("GET", this.url, true);

      if (this._headers) {
        // if (this._headers["g-token"]) {
        //   req.setRequestHeader("g-token", this._headers["g-token"]);
        // }
        if (this._headers["g-apiKey"]) {
          req.setRequestHeader("g-apiKey", this._headers["g-apiKey"]);
        }
      }
      req.responseType = "arraybuffer"; //for IE11, responseType must define on opened after
      try {
        req.send(null);
      } catch (err) {
        window.console.error(err);
      }
    },
    err => {
      window.console.error("decoder.setup.reject", err);
    }
  );
};

TeamworkVoiceDecoder.prototype._decodeNext = function () {
  var position = this._decodedPosition;
  var bytes = this._dataBytes;

  var headerLength = bytes[position] & 0xff;
  position += 1;
  if (headerLength == 0 || position + headerLength > bytes.length) {
    // console.log("_pcmRaw", this._pcmRaw);
    this._callback(this._pcmRaw);
    return;
  }

  var seq =
    (bytes[position] & 0xff) |
    ((bytes[position + 1] & 0xff) << 8) |
    ((bytes[position + 2] & 0xff) << 16) |
    ((bytes[position + 3] & 0xff) << 24);
  position += 4;

  var timestamp =
    (bytes[position] & 0xff) |
    ((bytes[position + 1] & 0xff) << 8) |
    ((bytes[position + 2] & 0xff) << 16) |
    ((bytes[position + 3] & 0xff) << 24);
  position += 4;

  var span =
    (bytes[position] & 0xff) |
    ((bytes[position + 1] & 0xff) << 8) |
    ((bytes[position + 2] & 0xff) << 16) |
    ((bytes[position + 3] & 0xff) << 24);
  position += 4;

  var dataLength =
    (bytes[position] & 0xff) |
    ((bytes[position + 1] & 0xff) << 8) |
    ((bytes[position + 2] & 0xff) << 16) |
    ((bytes[position + 3] & 0xff) << 24);
  position += 4;

  // console.log(seq, timestamp, span, dataLength);
  var arr = new Uint8Array(dataLength);
  for (var i = 0; i < dataLength; i++) {
    arr[i] = bytes[position + i];
    // console.log(bytes[position + i]);
  }
  // console.log(seq, arr.buffer);

  this.opusDecoder.decode({ data: arr.buffer }).then(decodedBuffer => {
    // console.log("decoder.decode.reslove", decodedBuffer);
    if (decodedBuffer.samples) {
      // console.log("decoder.decode.reslove", decodedBuffer.samples.buffer);
      var i16 = f32Toi16(decodedBuffer.samples);
      // console.log("seq", i16);
      for (var i = 0; i < i16.length; i++) {
        this._pcmRaw.push(i16[i]);
      }
    }
    this._decodeNext();
  });
  position += dataLength;
  this._decodedPosition = position;
};

TeamworkVoiceDecoder.prototype.toWave = function (callback) {
  var _option = this.option.waveDecoderArg;

  this._download(function (pcmRaw) {
    var wave_numFrames = pcmRaw.length;
    var wave_numChannels = _option.channels;
    var wave_sampleRate = _option.sampleRate;
    var wave_bytesPerSample = _option.bytesPerSample;
    var wave_blockAlign = wave_numChannels * _option.bytesPerSample;
    var wave_byteRate = wave_sampleRate * wave_blockAlign;
    var wave_dataSize = wave_numFrames * wave_blockAlign;

    var waveBytes = new Uint8Array(pcmRaw.length * 2 + 44);
    //chunk ID: "RIFF"
    waveBytes[0] = 82;
    waveBytes[1] = 73;
    waveBytes[2] = 70;
    waveBytes[3] = 70;

    //chunk size
    var wave_chunkSize = wave_dataSize + 36;
    waveBytes[4] = wave_chunkSize & 0xff;
    waveBytes[5] = (wave_chunkSize >> 8) & 0xff;
    waveBytes[6] = (wave_chunkSize >> 16) & 0xff;
    waveBytes[7] = (wave_chunkSize >> 24) & 0xff;

    //format: "WAVE"
    waveBytes[8] = 87;
    waveBytes[9] = 65;
    waveBytes[10] = 86;
    waveBytes[11] = 69;

    //Subchunk1ID: "fmt "
    waveBytes[12] = 102;
    waveBytes[13] = 109;
    waveBytes[14] = 116;
    waveBytes[15] = 32;

    //Subchunk1Size
    var wave_subchunk1Size = 16;
    waveBytes[16] = wave_subchunk1Size & 0xff;
    waveBytes[17] = (wave_subchunk1Size >> 8) & 0xff;
    waveBytes[18] = (wave_subchunk1Size >> 16) & 0xff;
    waveBytes[19] = (wave_subchunk1Size >> 24) & 0xff;

    //AudioFormat
    var wave_audioFormat = 1;
    waveBytes[50] = wave_audioFormat & 0xff;
    waveBytes[21] = (wave_audioFormat >> 8) & 0xff;

    //NumChannels
    waveBytes[22] = wave_numChannels & 0xff;
    waveBytes[23] = (wave_numChannels >> 8) & 0xff;

    //SampleRate
    waveBytes[24] = wave_sampleRate & 0xff;
    waveBytes[25] = (wave_sampleRate >> 8) & 0xff;
    waveBytes[26] = (wave_sampleRate >> 16) & 0xff;
    waveBytes[27] = (wave_sampleRate >> 24) & 0xff;

    //ByteRate
    waveBytes[28] = wave_byteRate & 0xff;
    waveBytes[29] = (wave_byteRate >> 8) & 0xff;
    waveBytes[30] = (wave_byteRate >> 16) & 0xff;
    waveBytes[31] = (wave_byteRate >> 24) & 0xff;

    //BlockAlign
    waveBytes[32] = wave_blockAlign & 0xff;
    waveBytes[33] = (wave_blockAlign >> 8) & 0xff;

    //BitsPerSample
    var bitsPerSample = wave_bytesPerSample * 8;
    waveBytes[34] = bitsPerSample & 0xff;
    waveBytes[35] = (bitsPerSample >> 8) & 0xff;

    //Subchunk2ID
    waveBytes[36] = 100;
    waveBytes[37] = 97;
    waveBytes[38] = 116;
    waveBytes[39] = 97;

    //Subchunk2Size
    waveBytes[40] = wave_dataSize & 0xff;
    waveBytes[41] = (wave_dataSize >> 8) & 0xff;
    waveBytes[42] = (wave_dataSize >> 16) & 0xff;
    waveBytes[43] = (wave_dataSize >> 24) & 0xff;

    var pcmInt16 = new Uint16Array(pcmRaw);
    for (var i = 0; i < pcmInt16.length; i++) {
      waveBytes[44 + i * 2] = pcmInt16[i] & 0xff;
      waveBytes[45 + i * 2] = pcmInt16[i] >> 8;
    }

    var blob = new Blob([waveBytes.buffer], { type: "audio/wav" });
    var url = URL.createObjectURL(blob);
    callback(url);
  });
};

TeamworkVoiceDecoder.prototype.toMp3 = function (callback) {
  var _mp3Encoder = this.mp3Encoder;
  var _option = this.option.mp3DecoderArg;
  this._download(function (pcmRaw) {
    var mp3_data = [];
    var source = new Int16Array(pcmRaw);
    var sourceBlockSize = _option.sourceBlockSize;
    for (var i = 0; i < source.length; i += sourceBlockSize) {
      var sourceChunk = source.subarray(i, i + sourceBlockSize);
      var mp3buf = _mp3Encoder.encodeBuffer(sourceChunk);

      if (mp3buf.length > 0) {
        mp3_data.push(mp3buf);
      }
    }
    mp3buf = _mp3Encoder.flush();
    if (mp3buf.length > 0) {
      // console.log("mp3buf", "end", mp3buf);
      mp3_data.push(mp3buf);
    }

    var blob = new Blob(mp3_data, { type: "audio/mp3" });
    var url = URL.createObjectURL(blob);
    callback(url);
  });
};

export default TeamworkVoiceDecoder;
