import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Code,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack,
  Tag,
  Text
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MdDelete,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked
} from "react-icons/md";
import { removeIf } from "@projectg/utils/utils/helpers";
import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";

const Section = ({
  title,
  description,
  children,
  onExpand,
  isExpanded,
  ...props
}) => (
  <Box my={4} boxShadow="md" borderRadius={8} {...props}>
    <Box p={8} py={4} onClick={onExpand} cursor="pointer">
      <Heading fontSize="xl">{title}</Heading>
      <Text fontSize="md" mt={1} color="gray.500">
        {description}
      </Text>
    </Box>
    <Collapse in={isExpanded}>
      <Box p={8}>{children}</Box>
    </Collapse>
  </Box>
);
const Developer = () => {
  const [fetching, setFetching] = useState(true);
  const [apiKeys, setApiKeys] = useState(null);
  const [apiTokens, setApiTokens] = useState(null);
  const [expandedKey, setExpandedKey] = useState(null);

  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [apiTokenPopoverVisible, setApiTokenPopoverVisible] = useState(false);
  const { getTexts } = AppContainer.useContainer();

  const permissionOptions = [
    { value: "report", label: getTexts("ViewReport") },
    { value: "system", label: getTexts("UseSystemApi") },
    { value: "manageSettings", label: getTexts("ManageSettings") },
    { value: "manageUser", label: getTexts("Manager") },
    {
      value: "ownConversations",
      label: getTexts("ViewTheConversationAboutMe")
    },
    { value: "allConversations", label: getTexts("ViewAllConversations") }
  ];

  useEffect(() => {
    (async () => {
      try {
        const { data: apiKeys } = await api.get({
          url: "/api/admin/setting/apiKey"
        });

        const { data: apiTokens } = await api.get({
          url: "/api/admin/apiToken"
        });
        setApiKeys(apiKeys);
        setApiTokens(apiTokens);
        setFetching(false);
      } catch (error) {}
    })();
  }, []);

  const onApiKeyDelete = useCallback(async apiKey => {
    try {
      setApiKeys(_ => removeIf(_, ({ key }) => key === apiKey?.key));
      await api.post({
        url: "/api/admin/setting/apiKey/delete",
        body: {
          key: apiKey?.key,
          secret: apiKey?.secret
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const apiKeyNameRef = useRef();
  const onApiKeyCreate = useCallback(async () => {
    const name = apiKeyNameRef.current?.value;
    if (!name) return;
    try {
      const { data: apiKey } = await api.post({
        url: "/api/admin/setting/apiKey/create",
        body: {
          name,
          permissions: ["sdk"]
        }
      });
      apiKeyNameRef.current.value = "";
      setApiKeys(_ => [..._, apiKey]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const apiTokenNameRef = useRef();
  const onApiTokenDelete = useCallback(async apiToken => {
    try {
      setApiTokens(_ => removeIf(_, ({ token }) => token === apiToken?.token));
      await api.post({
        url: "/api/admin/apiToken/delete",
        body: {
          token: apiToken?.token,
          secret: apiToken?.secret
        }
      });
      apiTokenNameRef.current.value = "";
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onApiTokenCreate = useCallback(async () => {
    const name = apiTokenNameRef?.current?.value;
    if (!name) return;
    try {
      setApiTokenPopoverVisible(false);
      const { data: apiToken } = await api.post({
        url: "/api/admin/apiToken/create",
        body: {
          name: name,
          permissions: selectedPermissions ?? []
        }
      });
      setApiTokens(_ => [..._, apiToken]);
    } catch (error) {
      console.error(error);
    }
  }, [selectedPermissions]);

  return fetching ? (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  ) : (
    <Flex position="relative" w="100%" h="100%">
      <Box p={8} overflow="auto" w="100%">
        <Box maxW={768} mx="auto">
          <Section
            title={getTexts("SdkInstallation")}
            description={getTexts("sdkInstallation_description")}
            isExpanded={expandedKey === "sdkInstallation"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "sdkInstallation" ? null : "sdkInstallation"
              )
            }
          >
            <List as={Box} styleType="decimal">
              <ListItem as={Flex}>
                <Text w={50}>1.</Text>
                <Box flex={1} minW={0} w="100%">
                  <Text>
                    {getTexts(
                      "ChooseOneOfTheApiKeysThatHaveBeenGeneratedBelow"
                    )}
                  </Text>
                  {(apiKeys ?? []).map((apiKey, index) => (
                    <Flex
                      key={apiKey.key}
                      mt={4}
                      p={4}
                      w="100%"
                      boxShadow="sm"
                      {...(selectedApiKey === apiKey && {
                        boxShadow: "sm",
                        bg: "gray.50"
                      })}
                      cursor="pointer"
                      onClick={() => setSelectedApiKey(apiKey)}
                      alignItems="center"
                    >
                      {selectedApiKey === apiKey ? (
                        <MdRadioButtonChecked fontSize="lg" />
                      ) : (
                        <MdRadioButtonUnchecked fontSize="lg" />
                      )}
                      <Stack flex={1} minW={0} w="100%" ml={4} spacing={1}>
                        <Text w="100%" fontWeight="bold">
                          {apiKey?.name ||
                            `${getTexts("UnnamedApiKey")} ${index + 1}`}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {apiKey?.key}
                        </Text>
                      </Stack>
                      <IconButton
                        ml={2}
                        fontSize="xl"
                        icon={<MdDelete />}
                        onClick={() => onApiKeyDelete(apiKey)}
                      />
                    </Flex>
                  ))}

                  <InputGroup mt={4}>
                    <Input
                      placeholder={getTexts("ApiKeyName")}
                      ref={apiKeyNameRef}
                    />
                    <InputRightElement mx={2}>
                      <Button
                        variant="link"
                        colorScheme="primary"
                        onClick={onApiKeyCreate}
                      >
                        {getTexts("Generate")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </ListItem>
              <ListItem mt={8} as={Flex}>
                <Text w={50}>2.</Text>
                <Box flex={1} minW={0} w="100%">
                  <Text>{`${getTexts(
                    "CopyTheFollowingCodeIntoYourBodyTag"
                  )}`}</Text>

                  {!selectedApiKey && (
                    <Alert mt={2} status="warning">
                      <AlertIcon />
                      {getTexts(
                        "YouNeedToSelectAnApiKeyToContinueTheInstallation"
                      )}
                    </Alert>
                  )}
                  <Code
                    mt={2}
                    p={4}
                    resize="none"
                    focusBorderColor="primary.500"
                    borderRadius={2}
                    boxShadow="sm"
                  >
                    {`<script>(function() { Greet = window.Greet || function () { (Greet.q = Greet.q || []).push(arguments); }; Greet('init', { domain: "${
                      window.location.protocol
                    }//${window.location.hostname}", apiKey: "${
                      selectedApiKey?.key ??
                      `<!-- ${getTexts("your_api_key")} -->`
                    }" }); })(); </script>
                    <script src="${window.location.protocol}//${
                      window.location.hostname
                    }/console/greet-sdk-v1.js"></script>
  `}
                  </Code>
                </Box>
              </ListItem>
              <ListItem mt={8} as={Flex}>
                <Text w={50}>3.</Text>
                <Box flex={1} minW={0} w="100%">
                  {getTexts("TheInstallationIsComplete")}
                </Box>
              </ListItem>
            </List>
          </Section>

          <Section
            as="form"
            title={getTexts("GrickApi")}
            description={getTexts("UseApiSuiteForBackEndDevelopment")}
            isExpanded={expandedKey === "GrickApi"}
            onExpand={() =>
              setExpandedKey(key => (key === "GrickApi" ? null : "GrickApi"))
            }
          >
            <List as={Box} styleType="decimal">
              <ListItem as={Flex}>
                <Text w={50}>1.</Text>
                <Box flex={1} minW={0} w="100%">
                  <Text>
                    {getTexts("UseTheApiTokenGeneratedBelowForDevelopment")}
                  </Text>
                  {(apiTokens ?? []).map((apiToken, index) => (
                    <Flex
                      key={apiToken.key}
                      mt={4}
                      p={4}
                      w="100%"
                      spacing={0}
                      alignItems="center"
                      boxShadow="sm"
                    >
                      <Stack flex={1} minW={0} w="100%" ml={4} spacing={1}>
                        <Text w="100%" fontWeight="bold">
                          {apiToken?.name ||
                            `${getTexts("UnnamedApiToken")} ${index + 1}`}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {apiToken?.token}
                        </Text>
                        <Box flex={1}>
                          {permissionOptions
                            .filter(
                              ({ value }) =>
                                value === "all" ||
                                (apiToken?.permissions ?? []).includes(value)
                            )
                            .map(({ label, value }) => (
                              <Tag
                                colorScheme="secondary"
                                mr={2}
                                size="sm"
                                key={value}
                              >
                                {label}
                              </Tag>
                            ))}
                        </Box>
                      </Stack>

                      <IconButton
                        ml={2}
                        fontSize="xl"
                        icon={<MdDelete />}
                        onClick={() => onApiTokenDelete(apiToken)}
                      />
                    </Flex>
                  ))}

                  <Popover placement="right" isOpen={apiTokenPopoverVisible}>
                    <PopoverTrigger>
                      <Button
                        mt={4}
                        variant="link"
                        colorScheme="primary"
                        onClick={() => setApiTokenPopoverVisible(v => !v)}
                      >
                        {getTexts("GenerateApiToken")}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      _focus={{ boxShadow: "none" }}
                      boxShadow="sm"
                    >
                      <PopoverBody p={4}>
                        <FormControl>
                          <FormLabel fontWeight="bold">
                            {getTexts("GrantPermissions")}
                          </FormLabel>
                          <CheckboxGroup
                            value={selectedPermissions}
                            onChange={setSelectedPermissions}
                          >
                            {permissionOptions.map(({ value, label }) => {
                              return (
                                <Box key={value} mt={1}>
                                  <Checkbox
                                    colorScheme="primary"
                                    name="permission"
                                    key={value}
                                    value={value}
                                  >
                                    {label}
                                  </Checkbox>
                                </Box>
                              );
                            })}
                          </CheckboxGroup>
                        </FormControl>
                        <InputGroup mt={4}>
                          <Input
                            placeholder={getTexts("ApiKeyName")}
                            ref={apiTokenNameRef}
                          />
                          <InputRightElement mx={2}>
                            <Button
                              onClick={onApiTokenCreate}
                              variant="link"
                              colorScheme="primary"
                              size="sm"
                            >
                              {getTexts("Generate")}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </ListItem>
              <ListItem mt={8} as={Flex}>
                <Text w={50}>2.</Text>
                <Box flex={1} minW={0} w="100%">
                  {getTexts("CheckOutOurDevelopmentFiles")}
                </Box>
              </ListItem>
            </List>
          </Section>
        </Box>
      </Box>
    </Flex>
  );
};

export default Developer;
