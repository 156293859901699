import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import logo from "@projectg/utils/assets/logoWithText.png";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import api from "@projectg/utils/utils/api";
import { humanifyDate } from "@projectg/utils/utils/helpers";
import dayjs from "dayjs";
import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import SdkContainer from "../../store/sdk";
import { useGetLastMessageDisplay } from "../../utils/hooks";
import { OfficeHourMessage } from "./components/OfficeHourMessage";

const Home = () => {
  const history = useHistory();

  const { hasUnread, conversations, upsertConversation, agents } =
    MessengerContainer.useContainer();
  const {
    session,
    appConfig: _appConfig,
    getTextByLang,
    getTexts,
  } = AppContainer.useContainer();
  const getLastMessageDisplay = useGetLastMessageDisplay();

  const { preview } = SdkContainer.useContainer();
  const appConfig = preview?.appConfig ?? _appConfig;

  const onCreate = useCallback(async () => {
    try {
      const { data: c } = await api.post({
        url: "/api/conversation/create",
      });
      upsertConversation(c.id, c);
      history.push(`/conversation/${c.id}`);
    } catch (error) {
      console.error(error);
    }
  }, [upsertConversation, history]);

  return (
    <>
      <Flex
        flexDir="column"
        justifyContent="flex-end"
        position="absolute"
        h={150}
        top={0}
        left={0}
        p={4}
        pb={8}
      >
        {appConfig?.landingPageLogo && (
          <Box>
            <AsyncImage
              src={appConfig?.landingPageLogo}
              fallback={
                <Flex align="center" justify="center">
                  <Spinner />
                </Flex>
              }
            >
              {({ src }) => <Image h={12} src={src} />}
            </AsyncImage>
          </Box>
        )}
        <Text
          py={2}
          fontFamily="Roboto Slab"
          mb={1}
          fontSize="lg"
          fontWeight="bold"
          color="primary._300"
        >
          {getTextByLang(appConfig?.landingPageDescription, "歡迎你！")}
        </Text>
      </Flex>
      <Stack w="100%" top={120} position="absolute" zIndex={1} px={4} pb={2}>
        {(appConfig?.moduleList ?? []).map(
          ({ module: type, ...module }, index) => {
            switch (type) {
              case "startConversation":
                const weekday = dayjs()
                  .locale("en")
                  .format("ddd")
                  .toLowerCase();
                const time = dayjs().format("HH:mm");
                const {
                  enable,
                  start = null,
                  end = null,
                } = appConfig?.officeHour?.[weekday] ?? {};
                const isOfficeHour =
                  !(enable === false) &&
                  (start || end) &&
                  (start ?? "00:00") <= time &&
                  time <= (end ?? "24:00");

                return (
                  <Stack
                    key={index}
                    boxShadow="md"
                    w="100%"
                    bg="white"
                    px={4}
                    py={8}
                    minH={16}
                    borderRadius={4}
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      {getTextByLang(module?.title, "你好！")}
                    </Text>
                    <Text>
                      {getTextByLang(
                        module?.description,
                        "按以下按鈕開始你與我們的對話，我們會有專人為你提供服務！"
                      )}
                    </Text>
                    <Flex my={2} px={1} alignSelf="center">
                      {Object.values(agents)
                        .slice(0, Math.min(5, Object.values(agents).length))
                        .map(u => {
                          return (
                            <Tooltip
                              key={u?.userId}
                              label={u?.displayName ?? "Staff"}
                              placement="top"
                            >
                              <Flex
                                key={u?.userId}
                                mx={-1}
                                w={12}
                                h={12}
                                borderRadius="50%"
                                bg="primary.500"
                                color="primary._500"
                                alignItems="center"
                                justifyContent="center"
                                boxShadow="md"
                                overflow="hidden"
                              >
                                {u?.profilePic ? (
                                  <Image src={u?.profilePic} />
                                ) : (
                                  <Text fontWeight="bole" fontSize="lg">
                                    {u?.displayName
                                      .split(" ")
                                      .map(x => x?.[0])
                                      .filter(_ => !!_)
                                      .join("")}
                                  </Text>
                                )}
                              </Flex>
                            </Tooltip>
                          );
                        })}
                    </Flex>
                    <Flex mt={2} w="100%">
                      {appConfig?.responseTimeType === "manual" && (
                        <Text d="inline" mx="auto">
                          {getTexts("AverageResponseTime")}
                          <Text
                            d="inline"
                            mx={1}
                            fontSize="2xl"
                            color="primary.500"
                            fontWeight="bold"
                          >
                            {isOfficeHour
                              ? appConfig?.officeHourResponseTime
                              : appConfig?.nonOfficeHourResponseTime}
                          </Text>
                          {getTexts("Minute")}
                        </Text>
                      )}
                    </Flex>

                    <Flex mt={1} w="100%">
                      <Button
                        borderRadius="3rem"
                        size="md"
                        mx="auto"
                        py={1}
                        boxShadow="md"
                        px={8}
                        colorScheme="primary"
                        variant="solid"
                        onClick={onCreate}
                        color="primary._500"
                      >
                        {getTexts("StartConversation")}
                      </Button>
                    </Flex>
                  </Stack>
                );
              case "officeHour":
                return (
                  <Flex
                    key={index}
                    boxShadow="md"
                    w="100%"
                    bg="white"
                    p={2}
                    minH={16}
                    borderRadius={4}
                    flexDir="column"
                  >
                    <Heading
                      px={2}
                      pt={4}
                      pb={2}
                      fontFamily="Roboto Slab"
                      fontSize="md"
                    >
                      {getTexts("OfficeHour")}
                    </Heading>
                    <Stack px={2} pb={2}>
                      <OfficeHourMessage
                        label={getTexts("Monday")}
                        officeHour={appConfig?.officeHour?.mon}
                      />
                      <OfficeHourMessage
                        label={getTexts("Tuesday")}
                        officeHour={appConfig?.officeHour?.tue}
                      />
                      <OfficeHourMessage
                        label={getTexts("Wednesday")}
                        officeHour={appConfig?.officeHour?.wed}
                      />
                      <OfficeHourMessage
                        label={getTexts("Thursday")}
                        officeHour={appConfig?.officeHour?.thu}
                      />
                      <OfficeHourMessage
                        label={getTexts("Friday")}
                        officeHour={appConfig?.officeHour?.fri}
                      />
                      <OfficeHourMessage
                        label={getTexts("Saturday")}
                        officeHour={appConfig?.officeHour?.sat}
                      />
                      <OfficeHourMessage
                        label={getTexts("Sunday")}
                        officeHour={appConfig?.officeHour?.sun}
                      />
                    </Stack>
                  </Flex>
                );
              // past conversation
              case "conversationList":
              default:
                return (
                  <Flex
                    key={index}
                    boxShadow="md"
                    w="100%"
                    bg="white"
                    p={2}
                    minH={16}
                    borderRadius={4}
                    flexDir="column"
                  >
                    <Heading
                      px={2}
                      pt={4}
                      pb={2}
                      fontFamily="Roboto Slab"
                      fontSize="md"
                    >
                      {getTexts("PastConversations")}
                    </Heading>
                    {Object.values(conversations)
                      .slice(
                        0,
                        Math.min(5, Object.values(conversations)?.length)
                      )
                      .map((c, index) => {
                        return (
                          <Link key={c?.id} to={`/conversation/${c?.id}`}>
                            <Box
                              _hover={{
                                bg: "primary.50",
                                color: "primary._50",
                              }}
                              position="relative"
                            >
                              <Flex
                                cursor="pointer"
                                alignSelf="stretch"
                                p={4}
                                flexDirection="column"
                                key={c?.id}
                              >
                                <Flex align="center">
                                  <Box
                                    mr={2}
                                    bg={c?.online ? "primary.300" : "gray.300"}
                                    borderRadius="50%"
                                    w="6px"
                                    h="6px"
                                  />
                                  <Flex flex={1} align="center">
                                    <Text
                                      fontWeight="bold"
                                      mr={1}
                                      fontSize="md"
                                      fontFamily="Roboto"
                                    >
                                      {c?.name}
                                    </Text>
                                  </Flex>
                                  <Box color="#bbb" fontSize="80%">
                                    {humanifyDate(c.orderTimestamp, "zh")}
                                  </Box>
                                </Flex>
                                <Flex mr={1} mt={1} align="center">
                                  {getLastMessageDisplay(
                                    c,
                                    session?.user?.userId
                                  )}
                                  {hasUnread(c, session?.user?.userId) && (
                                    <Box
                                      _hover={{
                                        bg: "primary._500",
                                      }}
                                      bg="primary.500"
                                      borderRadius="50%"
                                      w="6px"
                                      h="6px"
                                    />
                                  )}
                                </Flex>
                              </Flex>
                            </Box>
                          </Link>
                        );
                      })}
                    {Object.values(conversations)?.length > 5 && (
                      <Flex w="100%" justifyContent="center">
                        <Link to={`/recent`}>
                          <Button
                            size="md"
                            py={1}
                            px={8}
                            colorScheme="primary"
                            variant="link"
                          >
                            {getTextByLang(
                              appConfig?.conversationButtonText,
                              "更多對話"
                            )}
                          </Button>
                        </Link>
                      </Flex>
                    )}
                  </Flex>
                );
            }
          }
        )}
        <Flex flex={1} minH={0} h="100%" />
        <Flex mt={8} alignSelf="center" alignItems="center">
          <Text mr={1} color="gray.500" fontSize="sm">
            Powered by
          </Text>
          <Image src={logo} height={30} />
        </Flex>
      </Stack>
    </>
  );
};

export default Home;
