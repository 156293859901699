import React, { useEffect, useMemo } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  TagLeftIcon,
  Spinner,
  IconButton,
} from "@chakra-ui/react";

import FacebookDetail from "./facebook/facebookDetail";
import SanukerDetail from "./sanuker/sanukerDetail";

import { Sources } from "../../../utils/constants";
import AppContainer from "@projectg/utils/store/app";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router";

const ExternalPlatformDetail = ({ id }) => {
  const { getTexts, externalList } = AppContainer.useContainer();

  const history = useHistory();

  const platform = useMemo(
    () => externalList?.find(platform => platform.id === id),
    [externalList, id]
  );

  const { icon: Icon, name: defaultName, color = "primary.500" } =
    Sources?.[platform?.type] ?? Sources.web;

  const renderDetail = () => {
    switch (platform.type) {
      case "facebook":
        return <FacebookDetail platform={platform} />;
      case "sanuker":
        return <SanukerDetail platform={platform} />;
      default:
        return null;
    }
  };

  return externalList ? (
    platform ? (
      <Flex overflow="auto" flexDir="column" w="100%" h="100%">
        <Box w="100%" minW={768} maxW={1280} mx="auto" zIndex={50} p={8}>
          <Flex alignItems="baseline">
            <IconButton
              onClick={() => history.push("/externalPlatform")}
              variant="ghost"
              isRound
              fontSize="3xl"
              icon={<MdArrowBack />}
            />
            <Flex flexDir="column" ml={4} mb={4}>
              <Heading size="xl" fontFamily="Roboto Slab">
                {platform.id}
              </Heading>
            </Flex>
          </Flex>

          <Box mx={14} mb={14}>
            <Flex alignItems="center" mb={4}>
              <TagLeftIcon as={Icon} color={color} fontSize="lg" />
              <Text fontSize="lg" mt={1} color="gray.500">
                {defaultName}
              </Text>
            </Flex>

            {renderDetail()}
          </Box>
        </Box>
      </Flex>
    ) : (
      <Box p={8}>
        <Heading>{getTexts("PageNotFound")}</Heading>
      </Box>
    )
  ) : (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  );
};
export default ExternalPlatformDetail;
