import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";

const UpsertSanukerParamDrawer = ({ isOpen, onClose, params }) => {
  const toast = useToast();
  const { getTexts, setExternalList } = AppContainer.useContainer();

  const isEdit = params?.param?.name ? true : false;

  const [
    upsertSanukerParamIsLoading,
    setUpsertSanukerParamIsLoading,
  ] = useState(false);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...params?.param,
    },
  });

  const onSubmit = async data => {
    try {
      setUpsertSanukerParamIsLoading(true);

      let newTemplatesArray = [
        ...(JSON.parse(JSON.stringify(params?.channel?.templates)) ?? []),
      ];
      const templateIndex = newTemplatesArray.findIndex(
        template => template.key === params?.template?.key
      );

      let newParamsArray = [
        ...(newTemplatesArray[templateIndex]?.params ?? []),
      ];
      const paramIndex =
        params.paramIndex ??
        newParamsArray.findIndex(param => param.name === params?.param?.name);

      if (paramIndex === -1) newParamsArray = [...newParamsArray, { ...data }];
      else
        newParamsArray[paramIndex] = {
          ...data,
        };

      newTemplatesArray[templateIndex].params = newParamsArray;

      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...params?.channel,
          id: params?.platform?.id,
          channelId: params?.channel?.id,
          templates: newTemplatesArray,
        },
      });
      setExternalList(resData);
      toast({
        title: isEdit
          ? getTexts("SanukerParamUpdated")
          : getTexts("SanukerParamCreated"),
        status: "success",
      });
      onClose();
    } catch (err) {
    } finally {
      setUpsertSanukerParamIsLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEdit
              ? getTexts("SanukerParamUpdate")
              : getTexts("SanukerParamCreate")}
          </DrawerHeader>

          <DrawerBody>
            <List spacing={8}>
              <ListItem>
                <FormControl isRequired isInvalid={errors.name?.message}>
                  <FormLabel htmlFor="name">{getTexts("ParamName")}</FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "ParamName"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isRequired isInvalid={errors.description?.message}>
                  <FormLabel htmlFor="description">
                    {getTexts("ParamDescription")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "ParamDescription"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isRequired isInvalid={errors.component?.message}>
                  <FormLabel htmlFor="component">
                    {getTexts("ParamComponent")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="component"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "ParamComponent"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.component?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isRequired isInvalid={errors.index?.message}>
                  <FormLabel htmlFor="index">
                    {getTexts("ParamIndex")}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="index"
                    defaultValue=""
                    render={({ name, value, onChange }) => (
                      <NumberInput
                        name={name}
                        value={value}
                        onChange={v => onChange(Math.round(v))}
                        inputMode="numeric"
                        precision={0}
                        min={0}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "ParamIndex"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.index?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
            </List>
          </DrawerBody>

          <DrawerFooter>
            <Flex mt={8} w="100%">
              <Box flex={1} minW={0} w="100%"></Box>
              <Stack direction="row">
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {getTexts("Cancel")}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={upsertSanukerParamIsLoading}
                >
                  {isEdit ? getTexts("Update") : getTexts("Create")}
                </Button>
              </Stack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UpsertSanukerParamDrawer;
