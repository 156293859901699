import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { Sources } from "../../../utils/constants";
import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";
import { useHistory } from "react-router";
import { Controller, useForm } from "react-hook-form";
import Card from "./card";

const PlatformNameWithIcon = ({ type }) => {
  const { icon: Icon, name: defaultName, color = "primary.500" } =
    Sources?.[type] ?? Sources.web;

  return (
    <Flex alignItems="center">
      <TagLeftIcon as={Icon} color={color} fontSize="lg" />
      <Text fontSize="lg" mt={1} color="gray.500">
        {defaultName}
      </Text>
    </Flex>
  );
};

const CreatePlatformModal = ({ isOpen, onClose, fetchList }) => {
  const { getTexts } = AppContainer.useContainer();
  const toast = useToast();

  const { errors, control, handleSubmit } = useForm();

  const [createPlatformIsFetching, setCreatePlatformIsFetching] = useState(
    false
  );

  const onCreatePlatform = async data => {
    setCreatePlatformIsFetching(true);
    try {
      switch (data?.platform) {
        case "facebook":
          await api.post({
            url: `/api/admin/setting/externalPlatform/facebook/create`,
            body: {
              appSecret: "",
            },
          });
          break;
        case "sanuker":
          await api.post({
            url: `/api/admin/setting/externalPlatform/sanuker/create`,
          });
          break;
        default:
          return;
      }
      fetchList();
      toast({
        title: getTexts("ExternalPlatformCreated"),
        status: "success",
      });
      onClose();
    } catch (err) {
      // console.log(err)
    } finally {
      setCreatePlatformIsFetching(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getTexts("ExternalPlatformCreate")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.platform?.message}>
            <Controller
              as={
                <RadioGroup size="lg">
                  <Stack direction="column">
                    <Radio value="facebook">
                      <PlatformNameWithIcon type="facebook" />
                    </Radio>
                    <Radio value="sanuker">
                      <PlatformNameWithIcon type="sanuker" />
                    </Radio>
                  </Stack>
                </RadioGroup>
              }
              name="platform"
              control={control}
              defaultValue=""
              rules={{
                required: `${getTexts("PleaseSelect")} ${getTexts(
                  "ExternalPlatform"
                )}`,
              }}
            />

            <FormErrorMessage>{errors.platform?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            colorScheme="primary"
            mr={3}
            onClick={onClose}
          >
            {getTexts("Cancel")}
          </Button>
          <Button
            isLoading={createPlatformIsFetching}
            colorScheme="primary"
            onClick={handleSubmit(onCreatePlatform)}
          >
            {getTexts("Create")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Platform = ({ platform }) => {
  const history = useHistory();

  return (
    <Box
      my={4}
      boxShadow="md"
      borderRadius={8}
      onClick={() => history.push(`externalPlatform/${platform.id}`)}
    >
      <Box p={8} py={4} cursor="pointer">
        <Heading fontSize="2xl">{platform.id}</Heading>
        <PlatformNameWithIcon type={platform.type} />
      </Box>
    </Box>
  );
};

const ExternalPlatformList = () => {
  const {
    getTexts,
    externalList,
    setExternalList,
  } = AppContainer.useContainer();

  const [fetching, setFetching] = useState(true);

  const createPlatformDisclosure = useDisclosure();

  const fetchList = useCallback(async () => {
    setFetching(true);
    try {
      const { data } = await api.get({
        url: "/api/admin/setting/externalPlatform",
      });
      setExternalList(data);
      setFetching(false);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  }, [setExternalList]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return fetching ? (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  ) : (
    <>
      <CreatePlatformModal
        isOpen={createPlatformDisclosure.isOpen}
        onClose={createPlatformDisclosure.onClose}
        fetchList={fetchList}
      />

      <Flex position="relative" w="100%" h="100%">
        <Box p={8} overflow="auto" w="100%">
          <Box maxW={768} mx="auto">
            <Flex>
              <Box w="100%" minW={0} flex={1}>
                <Heading>{getTexts("ExternalPlatform")}</Heading>
              </Box>
              <Button
                colorScheme="primary"
                onClick={createPlatformDisclosure.onOpen}
              >
                {getTexts("Create")}
              </Button>
            </Flex>
            {externalList?.length ? (
              externalList?.map((platform, i) => (
                <Platform key={i} platform={platform} />
              ))
            ) : (
              <Card>
                <Center>
                  <Text fontSize="xl" color="gray.400">
                    {getTexts("ListEmpty")}
                  </Text>
                </Center>
              </Card>
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default ExternalPlatformList;
