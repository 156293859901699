import { Box, Spinner } from "@chakra-ui/react";
import AppContainer from "@projectg/utils/store/app";
import {
  fetchAssetToObjectUrl,
  getDataURLFromLocalFile,
} from "@projectg/utils/utils/helpers";
import React from "react";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";

const AsyncImage = ({
  src,
  srcType = src instanceof Blob ? "file" : "url",
  loading: loadingComponent = <Spinner />,
  empty,
  children,
}) => {
  const [imgData, setImgData] = useState(srcType === "dataURL" ? src : null);
  const [loading, setLoading] = useState(imgData === null);
  const { getTexts } = AppContainer.useContainer();

  const emptyElement = empty ?? (
    <Box fontSize="sm" color="#999">
      {getTexts("Empty")}
    </Box>
  );

  useEffect(() => {
    const fetchUrlImage = async () => {
      const objectUrl = await fetchAssetToObjectUrl(src);
      unstable_batchedUpdates(() => {
        setImgData(objectUrl);
        setLoading(false);
      });
    };
    const loadLocalFile = async () => {
      const dataURL = await getDataURLFromLocalFile(src);
      unstable_batchedUpdates(() => {
        setImgData(dataURL);
        setLoading(false);
      });
    };
    if (src) {
      if (srcType === "url") {
        fetchUrlImage();
      } else if (srcType === "file") {
        loadLocalFile();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [src, srcType]);
  if (loading) {
    return loadingComponent;
  } else if (src) {
    return children({ src: imgData });
  } else {
    return emptyElement;
  }
};

export default AsyncImage;
