import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";

import { MdLanguage } from "react-icons/md";
import AppContainer from "@projectg/utils/store/app";
import { LangKey } from "@projectg/utils/utils/constants";

const Langs = () => {
  const { setSelectedLangKey } = AppContainer.useContainer();
  return (
    <Menu zIndex={1000}>
      <MenuButton
        as={Button}
        position="absolute"
        zIndex={1000}
        right={0}
        color="primary"
      >
        <MdLanguage />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => setSelectedLangKey(LangKey.EN)}
          minH="30px"
          color="primary"
        >
          <span>English</span>
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedLangKey(LangKey.ZH)}
          minH="30px"
          color="primary"
        >
          <span>繁中</span>
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedLangKey(LangKey.ZH_CN)}
          minH="30px"
          color="primary"
        >
          <span>簡中</span>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default Langs;
