import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import MessengerContainer from "@projectg/utils/store/messenger";

import api from "@projectg/utils/utils/api";
import {
  getSelectStyle,
  getSelectTheme,
  removeIf,
  updateIf,
} from "@projectg/utils/utils/helpers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Controller, useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { MdGroupAdd, MdPerson } from "react-icons/md";
import Select from "react-select";
import { Section } from "../../components/components";
import AppContainer from "@projectg/utils/store/app";

const Teams = () => {
  const { getTexts } = AppContainer.useContainer();
  const { upsertTeam, upsertAgent, agents, deleteTeam } =
    MessengerContainer.useContainer();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: _teams } = await api.get({
          url: "/api/admin/team",
        });

        const { data: _agents } = await api.get({
          url: "/api/admin/agent",
          params: {
            size: 0,
            fields: "createDate",
          },
        });

        unstable_batchedUpdates(() => {
          _teams.forEach(t => upsertTeam(t.id, t));
          _agents.forEach(u => upsertAgent(u.id, u));
          setTeams(_teams);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [upsertAgent, upsertTeam]);

  const toast = useToast();
  const [expandedKey, setExpandedKey] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { register, errors, control, reset, handleSubmit, watch } = useForm({});
  const watched = watch();

  const onTeamDelete = useCallback(
    async id => {
      try {
        setDeleting(true);
        await api.post({
          url: "/api/admin/team/delete",
          body: {
            id,
          },
        });
        toast({
          title: getTexts("TeamDeletedSuccessfully"),
          status: "success",
          duration: 2500,
          isClosable: false,
        });
        setTeams(_ => removeIf(_, t => t?.id === id));
        deleteTeam(id);
        setExpandedKey(null);
      } catch (error) {
        console.error(error);
        toast({
          title: getTexts("FailedToDeleteTeam"),
          status: "error",
          duration: 2500,
          isClosable: false,
        });
        setDeleting(false);
      } finally {
        setDeleting(false);
      }
    },
    [deleteTeam, getTexts, toast]
  );
  const onSubmit = useCallback(
    async ({ id, ...params }) => {
      try {
        setUpdating(true);
        const { data: _team } = await (() => {
          switch (id) {
            case "add":
              return api.post({
                url: "/api/admin/team/create",
                body: {
                  ...params,
                },
              });
            default:
              return api.post({
                url: "/api/admin/team/update",
                body: {
                  id,
                  ...params,
                },
              });
          }
        })();
        toast({
          title: getTexts("StorageTeamSuccess"),
          status: "success",
          duration: 2500,
          isClosable: false,
        });
        setTeams(_ =>
          id === "add" ? [..._, _team] : updateIf(_, t => t?.id === id, _team)
        );
        upsertTeam(_team.id, _team);
        setExpandedKey(null);
      } catch (error) {
        toast({
          title: getTexts("FailedToModifyTeam"),
          status: "error",
          duration: 2500,
          isClosable: false,
        });
        console.error(error);
      } finally {
        setUpdating(false);
      }
    },
    [getTexts, toast, upsertTeam]
  );

  const theme = useTheme();
  const agentOptions = useMemo(
    () =>
      Object.values(agents).map(({ userId, displayName }) => ({
        value: userId,
        label: displayName,
      })),
    [agents]
  );

  const teamList = useMemo(
    () => [
      ...teams,
      { id: "add", name: getTexts("NewTeamName"), agent: [] },
      // ...(expandedKey !== "add"
      //   ? []
      //   : [{ id: "add", name: getTexts("NewTeamName"), agent: [] }]),
    ],
    [getTexts, teams]
  );

  useEffect(() => {
    reset(teamList?.find(t => t?.id === expandedKey));
  }, [expandedKey, reset, teamList]);

  return (
    <Flex flexDir="column" w="100%" h="100%" overflow="auto">
      <Box mx="auto" w="100%" maxW={768} zIndex={50} p={8}>
        <Heading as="h2" fontFamily="Roboto Slab" mb={16}>
          {getTexts("Team")}
        </Heading>
        {loading ? (
          <Spinner mx="auto" color="primary.500" />
        ) : (
          <>
            {(teamList ?? []).map(({ id, name, agent: agentIds = [] }) => (
              <Section
                boxShadow="md"
                _hover={{
                  opacity: 1,
                }}
                {...(expandedKey &&
                  expandedKey !== id && {
                    boxShadow: "none",
                    opacity: 0.2,
                  })}
                {...(id === "add" &&
                  expandedKey !== "add" && { display: "none" })}
                key={id}
                title={
                  expandedKey === id
                    ? watched?.name || getTexts("UnnamedTeam")
                    : name
                }
                description={`${
                  (expandedKey === id ? watched?.agent : agentIds)?.length
                } member${
                  (expandedKey === id ? watched?.agent : agentIds)?.length > 1
                    ? "s"
                    : ""
                }`}
                isExpanded={expandedKey === id}
                onExpand={() => {
                  setExpandedKey(key => (key === id ? null : id));
                }}
              >
                {expandedKey === id && (
                  <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                    <>
                      <Input type="hidden" ref={register} name="id" />
                      <FormControl isInvalid={errors?.name}>
                        <FormLabel>{getTexts("TeamName")}</FormLabel>
                        <Input
                          isInvalid={errors?.name}
                          variant="flushed"
                          focusBorderColor="primary.500"
                          errorBorderColor="red.500"
                          size="lg"
                          ref={register({
                            required: getTexts("Required"),
                            validate: {
                              duplicated: value =>
                                !teams.find(
                                  t => t?.name === value && t?.id !== id
                                ) || getTexts("TeamNameAlreadyExists"),
                            },
                          })}
                          name="name"
                          placeholder={getTexts("TeamName")}
                          defaultValue={name ?? ""}
                        />
                        <FormErrorMessage>
                          {errors?.name?.message}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl mt={8}>
                        <FormLabel>{getTexts("TeamMember")}</FormLabel>
                        <Controller
                          control={control}
                          name="agent"
                          render={({
                            value: agentIds = [],
                            onChange,
                            name,
                          }) => {
                            return (
                              <Box py={4}>
                                <Select
                                  isClearable={true}
                                  name={name}
                                  onChange={({ value: id }) =>
                                    onChange([...new Set([...agentIds, id])])
                                  }
                                  value={null}
                                  placeholder={getTexts("SelectAgents")}
                                  styles={getSelectStyle({ theme })}
                                  theme={getSelectTheme({ theme })}
                                  options={agentOptions.filter(
                                    ({ value }) => !agentIds.includes(value)
                                  )}
                                />
                                {(agentIds ?? []).map(userId => {
                                  const agent = agents[userId];
                                  return (
                                    <Box
                                      as={Flex}
                                      p={4}
                                      key={userId}
                                      _hover={{
                                        bg: "",
                                      }}
                                      alignItems="center"
                                    >
                                      <AsyncImage
                                        src={agent?.profilePic}
                                        srcType="dataURL"
                                        empty={
                                          <Box
                                            as={MdPerson}
                                            borderRadius="50%"
                                            bg="primary.300"
                                            color="white"
                                            fontSize="xl"
                                            p={1}
                                            w={34}
                                            h={34}
                                          />
                                        }
                                      >
                                        {({ src }) => (
                                          <Image
                                            w={34}
                                            h={34}
                                            alt={agent?.displayName}
                                            src={src}
                                            borderRadius="50%"
                                          />
                                        )}
                                      </AsyncImage>
                                      <Text
                                        ml={4}
                                        color="#333"
                                        fontWeight="bold"
                                        flex={1}
                                      >
                                        {agent?.displayName}
                                      </Text>
                                      <IconButton
                                        ml={4}
                                        size="sm"
                                        icon={<FaTrash />}
                                        variant="ghost"
                                        colorScheme="red"
                                        onClick={() =>
                                          onChange(
                                            removeIf(
                                              agentIds,
                                              id => id === agent?.userId
                                            )
                                          )
                                        }
                                      />
                                    </Box>
                                  );
                                })}
                              </Box>
                            );
                          }}
                        ></Controller>
                        <FormErrorMessage>
                          {errors?.name?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        d="flex"
                        alignItems="center"
                        textAlign="right"
                        w="100%"
                        mt={12}
                      >
                        <Button
                          type="button"
                          isLoading={deleting}
                          onClick={() => onTeamDelete(id)}
                          colorScheme="red"
                          variant="ghost"
                        >
                          {getTexts("Delete")}
                        </Button>
                        <Box flex={1} />
                        <Button
                          mr={2}
                          onClick={() => setExpandedKey(null)}
                          colorScheme="primary"
                          variant="ghost"
                        >
                          {getTexts("Cancel")}
                        </Button>
                        <Button
                          type="submit"
                          isLoading={updating}
                          colorScheme="primary"
                        >
                          {getTexts("Save")}
                        </Button>
                      </FormControl>
                    </>
                  </Box>
                )}
              </Section>
            ))}
            <Button
              lefticon={<MdGroupAdd />}
              variant="ghost"
              colorScheme="primary"
              onClick={() => setExpandedKey("add")}
            >
              {getTexts("NewTeam")}
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default Teams;
