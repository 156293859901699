import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Badge,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  useTheme,
} from "@chakra-ui/react";
import logo from "@projectg/utils/assets/logo.png";
import Conversation from "@projectg/utils/components/conversation";
import AppContainer from "@projectg/utils/store/app";
import MessengerContainer from "@projectg/utils/store/messenger";
import SocketContainer from "@projectg/utils/store/socket";
import api from "@projectg/utils/utils/api";
import { LangKey } from "@projectg/utils/utils/constants";
import {
  getSelectStyle,
  getSelectTheme,
  humanifyDate,
  mergeSearchParams,
  removeIf,
} from "@projectg/utils/utils/helpers";
import {
  useGetPixelToBottom,
  useMemoRef,
  usePrevious,
  useSocket,
} from "@projectg/utils/utils/hooks";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  FaCog,
  FaServer,
  FaSortAmountDown,
  FaSortAmountUp,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { MdChatBubble, MdFilterList } from "react-icons/md";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useCookie } from "react-use";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";

import {
  BadgeIndicator,
  LiveChatIndicator,
  SnoozeIndicator,
  SourceTag,
} from "../../components/components";
import DatePicker from "../../components/DatePicker";
import ConsoleContainer from "../../store/console";
import { useGetLastMessageDisplay } from "../../utils/hooks";
import Agent from "./agent";
import Agents from "./agents";
import Configuration from "./configuration";
import Customer from "./customer";
import Customers from "./customers";
import Customization from "./customization";
import Dashboard from "./dashboard";
import Detail from "./detail";
import Developer from "./developer";
import ExternalPlatformDetail from "./externalPlatform/detail";
import ExternalPlatformList from "./externalPlatform/list";
import SanukerChannelDetail from "./externalPlatform/sanuker/channelDetail";
import SanukerTemplateDetail from "./externalPlatform/sanuker/templateDetail";
import Report from "./report";
import Teams from "./teams";

const PAGE_SIZE = 20;

const ConversationComp = ({
  id,
  conversationId,
  matched,
  conversation,
  session,
  hasUnread,
  style = {},
}) => {
  const getLastMessageDisplay = useGetLastMessageDisplay();
  const { getTexts, currentLangKey } = AppContainer.useContainer();

  const statusOptions = [
    { value: "all", label: getTexts("All") },
    { value: "open", label: getTexts("Processing") },
    { value: "closed", label: getTexts("Finished") },
    // { value: "terminated", label: getTexts("Terminate") }
  ];

  return (
    <Link key={id} to={`/conversation/${id}`}>
      <Box
        css={style}
        _hover={{
          bg: conversationId === id ? "primary.50" : "#f1f1f1",
        }}
        bg={conversationId === id ? "primary.50" : "transparent"}
        position="relative"
      >
        <Flex
          h="100%"
          w="100%"
          position="absolute"
          bg="rgba(255,255,255,0.7)"
          {...(matched && { display: "none" })}
          alignItems="center"
          justifyContent="center"
        >
          {conversation?._status ? (
            <Text fontWeight="bold">
              {getTexts("TheStatusOfThisConversationHasChangedTo")}
              {
                statusOptions.find(o => o?.value === conversation?.status)
                  ?.label
              }
            </Text>
          ) : conversation?._assignedAgent &&
            conversation?.assignedAgent?.length > 0 ? (
            <Text fontWeight="bold">
              {getTexts("ThisConversationHasBeenAssignedToAnotherAgent")}
            </Text>
          ) : conversation?._team && conversation?.team?.length > 0 ? (
            <Text fontWeight="bold">
              {getTexts("ThisConversationHasBeenAssignedToAnotherTeam")}
            </Text>
          ) : (
            <Text fontWeight="bold">
              {getTexts("ThisConversationIsNotAssignedToAnyTeamOrAgent")}
            </Text>
          )}
        </Flex>
        <Flex
          cursor="pointer"
          alignSelf="stretch"
          p={4}
          flexDirection="column"
          key={id}
        >
          <Flex align="center">
            <BadgeIndicator
              ml={-2}
              enable={conversation?.online}
              onColor="#2aa92a"
              offColor="gray.300"
              onLabel={getTexts("Online")}
              offLabel={getTexts("Offline")}
            />
            <Flex flex={1} alignItems="center">
              <Tooltip label={conversation?.name}>
                <Text
                  fontWeight="bold"
                  color="gray.700"
                  mr={1}
                  fontSize="md"
                  fontFamily="Roboto"
                  noOfLines={1}
                  isTruncated
                  maxW="90px"
                >
                  {conversation?.name}
                </Text>
              </Tooltip>
              <SourceTag source={conversation?.source} />
              <LiveChatIndicator livechat={conversation?.livechat} />
              <SnoozeIndicator snooze={conversation?.snooze} />
            </Flex>
            <Box textAlign="right" color="#bbb" fontSize="80%">
              {humanifyDate(conversation.orderTimestamp, currentLangKey)}
            </Box>
          </Flex>
          <Flex mr={1} mt={1} align="center">
            {getLastMessageDisplay(conversation, session?.user?.userId)}

            <BadgeIndicator
              ml={-2}
              enable={hasUnread(conversation, session?.user?.userId)}
              onColor="secondary.500"
              offColor="transparent"
              onLabel={getTexts("Unread")}
            />
          </Flex>
        </Flex>
      </Box>
    </Link>
  );
};

const viewMatched = (view1, params) => {
  view1 = mergeSearchParams(view1);
  params = mergeSearchParams(params);
  const r =
    view1.status === params.status &&
    view1.filter === params.filter &&
    (params.filter === "me" || view1.agentId === params.agentId) &&
    (params.filter !== "team" || view1.team === params.team) &&
    view1.customerId === params.customerId &&
    view1.beforeTimestamp === params.beforeTimestamp &&
    view1.afterTimestamp === params.afterTimestamp;
  return r;
};

const InboxContainer = ({
  filterPanelIsOpen,
  filterPanelOnOpen,
  filterPanelOnToggle,
}) => {
  // const [filterPanelVisible, setFilterPanelVisible] = useState(false);

  const { id: conversationId } = useParams();

  const { appConfig, session } = AppContainer.useContainer();
  const { configuration, upsertConfiguration, getTexts } =
    AppContainer.useContainer();

  const {
    hasUnread,
    customerTags,
    conversationTags,
    inboxCount,
    agents,
    customers,
    teams,
    upsertCustomer,
    upsertConversation,
    searchConversations,
    isConversationMatched,
  } = MessengerContainer.useContainer();

  const [searching, setSearching] = useState(false);
  const viewNameRef = useRef("");
  const lastSearchAtRef = useRef(null);
  const { ___searchParams } = ConsoleContainer.useContainer();

  const customerTagOptions = customerTags?.map(tag => ({
    value: tag,
    label: tag,
  }));

  const conversationTagOptions = conversationTags?.map(tag => ({
    value: tag,
    label: tag,
  }));

  const { control, setValue, reset } = useForm({
    defaultValues: {
      status: "open",
      filter: "me",
      team: null,
      customerId: null,
      agentId: null,
      tag: null,
      customerTag: null,
      beforeTimestamp: null,
      afterTimestamp: null,
      showOnline: true,
      reverseOrder: false,
      ...___searchParams.current,
    },
  });

  const searchParams = useWatch({ control });

  useEffect(() => {
    ___searchParams.current = searchParams;
  }, [___searchParams, searchParams]);

  const [lastTimestamp, setLastTimestamp] = useState(null);

  const filteredConversations = useMemo(() => {
    return searchConversations(
      searchParams,
      lastTimestamp,
      session,
      lastSearchAtRef.current
    );
  }, [lastTimestamp, searchConversations, searchParams, session]);

  const beforeId = useMemo(
    () =>
      filteredConversations?.[filteredConversations?.length - 1]?.id ?? null,
    [filteredConversations]
  );
  const beforeIdRef = useMemoRef(beforeId);
  const [hasMore, setHasMore] = useState(false);

  const theme = useTheme();

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.searchParams) {
      reset(location?.state?.searchParams);
    }
  }, [location?.state?.searchParams, reset]);

  const defaultViews = useMemo(
    () => [
      {
        value: "me",
        label: getTexts("AssignedToMe"),
        count: inboxCount?.me,
        searchParams: mergeSearchParams({ status: "open", filter: "me" }),
      },
      {
        value: "unassigned",
        label: getTexts("Unassigned"),
        count: inboxCount?.unassigned,
        searchParams: mergeSearchParams({
          status: "open",
          filter: "unassigned",
        }),
      },
      {
        value: "snooze",
        label: getTexts("SnoozeTracking"),
        count: inboxCount?.snooze,
        searchParams: mergeSearchParams({ status: "open", filter: "snooze" }),
      },
      {
        value: "hidden",
        label: getTexts("HiddenTracking"),
        count: inboxCount?.hidden,
        searchParams: mergeSearchParams({ status: "open", filter: "hidden" }),
      },
      {
        value: "mention",
        label: getTexts("MentionedMe"),
        count: inboxCount?.mention,
        searchParams: mergeSearchParams({ status: "open", filter: "mention" }),
      },
    ],
    [inboxCount, getTexts]
  );

  const teamViews = useMemo(
    () =>
      Object.values(teams ?? {})
        .filter(({ agent }) => agent?.includes(session?.user?.userId))
        .map(({ id, name }) => ({
          value: `team-${id}`,
          label: name,
          count: inboxCount?.team?.[id],
          searchParams: mergeSearchParams({
            status: "open",
            filter: "team",
            team: id,
          }),
        })),
    [inboxCount, session, teams]
  );

  const combinedViews = useMemo(
    () => [
      {
        label: getTexts("DefaultViews"),
        options: defaultViews,
      },
      {
        label: getTexts("TeamViews"),
        options: teamViews,
      },
      {
        label: getTexts("ConversationCustomViews"),
        options: [
          ...(configuration?.["conversationCustomViews"] ?? []),
          {
            value: "custom",
            label: getTexts("conversationCustomViewsFiltering"),
            searchParams: mergeSearchParams(),
          },
        ],
      },
    ],
    [configuration, defaultViews, getTexts, teamViews]
  );

  const currentView = useMemo(() => {
    return combinedViews
      .map(x => x.options)
      .flat()
      .find(
        ({ value, searchParams: _searchParams }) =>
          value === "custom" || viewMatched(_searchParams, searchParams)
      );
  }, [combinedViews, searchParams]);

  const canDeleteCurrentView = useMemo(() => {
    return (configuration?.["conversationCustomViews"] ?? []).some(
      view => view === currentView
    );
  }, [configuration, currentView]);

  const prependCustomView = useCallback(
    async view => {
      try {
        let conversationCustomViews = [];
        upsertConfiguration("conversationCustomViews", _ => {
          conversationCustomViews = [view, ...(_ ?? [])];
          return conversationCustomViews;
        });
        await api.post({
          url: "/api/configuration/update",
          body: {
            setValue: { conversationCustomViews },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [upsertConfiguration]
  );

  const removeCustomView = useCallback(
    async viewValue => {
      try {
        let conversationCustomViews = [];
        upsertConfiguration("conversationCustomViews", _ => {
          conversationCustomViews = removeIf(
            _,
            view => view.value === viewValue
          );
          return conversationCustomViews;
        });
        await api.post({
          url: "/api/configuration/update",
          body: {
            setValue: { conversationCustomViews },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [upsertConfiguration]
  );

  const prevSearchParams = usePrevious(searchParams);
  const fetchConversation = useCallback(
    async ({ more = false } = { more: false }) => {
      if (!more && viewMatched(prevSearchParams, searchParams)) return;
      try {
        setSearching(true);
        const { data: conversations } = await api.get({
          url: "/api/conversation",
          params: {
            ...searchParams,
            showOnline: true,
            size: PAGE_SIZE,
            beforeId: more ? beforeIdRef.current : null,
          },
        });
        const { data: customers } = await api.get({
          url: "/api/admin/customer/get",
          params: {
            userId: conversations
              .reduce((_, c) => [..._, ...c.customer.map(c => c.id)], [])
              .join(","),
            fields: "createDate,conversations,tags,notes,lastActivity",
          },
        });

        unstable_batchedUpdates(() => {
          if (!more) {
            lastSearchAtRef.current = dayjs().valueOf();
          }
          customers.forEach(c => upsertCustomer(c?.userId, c));
          conversations.forEach(c => upsertConversation(c?.id, c));
          setLastTimestamp(
            conversations?.[conversations.length - 1]?.orderTimestamp
          );
          setHasMore(conversations.length === PAGE_SIZE);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSearching(false);
      }
    },
    [
      beforeIdRef,
      prevSearchParams,
      searchParams,
      upsertConversation,
      upsertCustomer,
    ]
  );

  useEffect(() => {
    fetchConversation({ more: false });
  }, [fetchConversation, upsertConversation, upsertCustomer]);

  const filterOptions = useMemo(
    () => [
      { value: "all", label: getTexts("AllConversations") },
      { value: "me", label: getTexts("AssignedToMe") },
      { value: "unassigned", label: getTexts("Unassigned") },
      { value: "snooze", label: getTexts("SnoozeTracking") },
      // { value: "hidden", label: getTexts("HiddenTracking") },
      { value: "mention", label: getTexts("MentionedMe") },
      { value: "team", label: getTexts("TeamViews") },
    ],
    [getTexts]
  );

  const agentOptions = useMemo(
    () =>
      Object.values(agents).map(({ userId, displayName }) => ({
        value: userId,
        label: displayName,
      })),
    [agents]
  );

  const teamOptions = useMemo(
    () =>
      Object.values(teams).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [teams]
  );

  // const scrollRef = useRef();
  // const getPixelToBottom = useGetPixelToBottom(scrollRef);
  // const offsetRef = useMemoRef(filteredConversations?.length ?? 0);
  const hasMoreRef = useMemoRef(hasMore);
  // useEffect(() => {
  //   const handler = e => {
  //     if (
  //       !searching &&
  //       getPixelToBottom() < 10 &&
  //       offsetRef.current > 0 &&
  //       hasMoreRef.current
  //     ) {
  //       fetchConversation({ more: true });
  //     }
  //   };
  //   const scrollView = scrollRef.current;
  //   if (scrollView) {
  //     scrollView.addEventListener("scroll", handler);
  //   }
  //   return () => {
  //     scrollView && scrollView.removeEventListener("scroll", handler);
  //   };
  // }, [fetchConversation, getPixelToBottom, hasMoreRef, offsetRef, searching]);
  const statusOptions = [
    { value: "all", label: getTexts("All") },
    { value: "open", label: getTexts("Processing") },
    { value: "closed", label: getTexts("Finished") },
    // { value: "terminated", label: getTexts("Terminate") }
  ];
  return (
    <>
      <Flex
        alignSelf="stretch"
        maxWidth={280}
        w="100%"
        flexDirection="column"
        borderRight="1px solid #eee"
      >
        <Box p={3}>
          <Flex align="center">
            <Flex flex={1} align="stretch" bg="secondaryLightVariant">
              <Select
                isSearchable={true}
                placeholder={getTexts("ViewConversation")}
                theme={getSelectTheme({ theme })}
                components={{
                  Option: props => {
                    const { innerProps, isDisabled, data, getStyles } = props;
                    return !isDisabled ? (
                      <Box
                        flex={1}
                        {...innerProps}
                        style={getStyles("option", props)}
                      >
                        <Flex align="center">
                          <Box>{data.label}</Box>
                          {data.count > 0 && (
                            <Badge ml={2} colorScheme="secondary">
                              {data.count}
                            </Badge>
                          )}
                        </Flex>
                      </Box>
                    ) : null;
                  },
                  SingleValue: props => {
                    const { innerProps, isDisabled, data, getStyles } = props;
                    return !isDisabled ? (
                      <Box
                        {...innerProps}
                        style={getStyles("singleValue", props)}
                      >
                        <Flex align="center">
                          <Box>{data.label}</Box>
                          {data.count > 0 && (
                            <Badge
                              ml={2}
                              fontSize="md"
                              px={1}
                              colorScheme="secondary"
                            >
                              {data.count}
                            </Badge>
                          )}
                        </Flex>
                      </Box>
                    ) : null;
                  },
                }}
                styles={{
                  ...getSelectStyle({ theme }),
                  control: _ => ({
                    ...getSelectStyle({ theme })?.control(_),
                    background: theme.colors.primary[50],
                  }),
                }}
                options={combinedViews}
                getOptionValue={option => option.value}
                value={currentView}
                onChange={({ value, searchParams }) => {
                  value === "custom" && filterPanelOnOpen();
                  Object.entries(searchParams).forEach(([key, value]) =>
                    setValue(key, value)
                  );
                }}
              />
            </Flex>

            <Controller
              name="reverseOrder"
              control={control}
              render={({ name, onChange, value }) => {
                return (
                  <Menu placement="bottom">
                    <MenuButton
                      as={IconButton}
                      colorScheme="primary"
                      boxShadow="none"
                      _focus={{
                        boxShadow: "none",
                      }}
                      alignSelf="center"
                      fontSize="lg"
                      icon={
                        searchParams?.reverseOrder ? (
                          <FaSortAmountDown />
                        ) : (
                          <FaSortAmountUp />
                        )
                      }
                      variant="ghost"
                    />
                    <MenuList>
                      <MenuOptionGroup
                        value={value ? "true" : "false"}
                        title={getTexts("UpdatedDate")}
                        type="radio"
                        name={name}
                        onChange={e => onChange(e === "true")}
                      >
                        <MenuItemOption value="false">
                          {getTexts("FromNewToOld")}
                        </MenuItemOption>
                        <MenuItemOption value="true">
                          {getTexts("FromOldToNew")}
                        </MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                );
              }}
            />
            <IconButton
              colorScheme="primary"
              variant="ghost"
              icon={<MdFilterList />}
              border={2}
              boxShadow="none"
              _focus={{
                boxShadow: "none",
              }}
              alignSelf="center"
              fontSize="2xl"
              onClick={filterPanelOnToggle}
            />
          </Flex>
        </Box>
        <Flex flexDirection="column" flex={1} overflow="auto">
          {!searching && filteredConversations.length === 0 ? (
            <Box
              m={2}
              color="#999"
              alignSelf="center"
              justify="center"
              textAlign="center"
            >
              {getTexts("NoRelatedConversations")}
            </Box>
          ) : (
            <InfiniteLoader
              isRowLoaded={({ index }) => !!filteredConversations[index]}
              loadMoreRows={() => fetchConversation({ more: true })}
              rowCount={
                hasMoreRef.current ? 9999 : filteredConversations.length
              }
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      onRowsRendered={onRowsRendered}
                      ref={registerChild}
                      height={height}
                      rowCount={
                        hasMoreRef.current
                          ? filteredConversations.length + 1
                          : filteredConversations.length
                      }
                      rowHeight={80}
                      rowRenderer={({ key, index, style }) => {
                        if (index === filteredConversations.length)
                          return searching ? (
                            <Box
                              key={key}
                              css={style}
                              m={2}
                              alignSelf="center"
                              justify="center"
                              textAlign="center"
                            >
                              <Spinner color="primary.400" />
                            </Box>
                          ) : hasMore ? (
                            <Box
                              key={key}
                              css={style}
                              alignSelf="center"
                              justify="center"
                              textAlign="center"
                            >
                              <Button
                                m={2}
                                onClick={() =>
                                  fetchConversation({ more: true })
                                }
                                variant="link"
                                colorScheme="secondary"
                                alignSelf="center"
                                justify="center"
                                textAlign="center"
                              >
                                {getTexts("LoadMore")}
                              </Button>
                            </Box>
                          ) : null;

                        const id = filteredConversations[index]?.id;
                        const matched = isConversationMatched(
                          filteredConversations[index],
                          searchParams,
                          lastTimestamp,
                          session,
                          dayjs().valueOf()
                        );

                        return (
                          <ConversationComp
                            id={id}
                            key={key}
                            conversation={filteredConversations[index]}
                            conversationId={conversationId}
                            matched={matched}
                            session={session}
                            hasUnread={hasUnread}
                            style={style}
                          />
                        );
                      }}
                      width={width}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
            // filteredConversations.map((conversation, index) => {
            //   const id = conversation?.id;
            //   const matched = isConversationMatched(
            //     conversation,
            //     searchParams,
            //     lastTimestamp,
            //     session,
            //     dayjs().valueOf()
            //   );
            //   return (
            //     <ConversationComp
            //       id={id}
            //       key={index}
            //       conversation={conversation}
            //       conversationId={conversationId}
            //       matched={matched}
            //       session={session}
            //       hasUnread={hasUnread}
            //     />
            //   );
            // })
          )}
          {/* {searching ? (
            <Box m={2} alignSelf="center" justify="center" textAlign="center">
              <Spinner color="primary.400" />
            </Box>
          ) : hasMore ? (
            <Button
              m={2}
              onClick={() => fetchConversation({ more: true })}
              variant="link"
              colorScheme="secondary"
              alignSelf="center"
              justify="center"
              textAlign="center"
            >
              {getTexts("LoadMore")}
            </Button>
          ) : null} */}
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        maxW={240}
        minW={240}
        w={240}
        borderRight="1px solid #eee"
        {...(!filterPanelIsOpen && { display: "none !important" })}
      >
        <Flex alignItems="center" p={4}>
          <Text
            flex={1}
            minW={0}
            w="100%"
            fontFamily="Roboto Slab"
            fontSize="2xl"
            fontWeight="bold"
          >
            {getTexts("FilterConversation")}
          </Text>
          {canDeleteCurrentView && (
            <Button
              ml={1}
              _focus={{ boxShadow: "none" }}
              variant="ghost"
              colorScheme="red"
              onClick={() => removeCustomView(currentView.value)}
            >
              {getTexts("DeleteView")}
            </Button>
          )}
        </Flex>

        <Flex p={4} flex={1} overflow="auto" flexDirection="column">
          <FormControl align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("Classification")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="filter"
                render={({ onChange, onBlur, value, name }) => (
                  <Select
                    name={name}
                    onBlur={onBlur}
                    onChange={({ value }) => onChange(value)}
                    value={filterOptions?.find(o => o.value === value)}
                    placeholder={getTexts("Select")}
                    styles={getSelectStyle({ theme })}
                    theme={getSelectTheme({ theme })}
                    options={filterOptions}
                  />
                )}
              />
            </Box>
          </FormControl>

          <FormControl mt={8} align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("Status")}
            </FormLabel>
            <Controller
              control={control}
              name="status"
              render={({ onChange, onBlur, value, name }) => (
                <RadioGroup
                  as={Stack}
                  defaultValue={value}
                  onChange={onChange}
                  name={name}
                  flex={1}
                  value={value}
                >
                  {statusOptions.map(({ label, value }) => (
                    <Radio
                      key={value}
                      colorScheme="primary"
                      borderColor="#eee"
                      onBlur={onBlur}
                      value={value}
                    >
                      {label}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl
            mt={8}
            align="center"
            {...(searchParams.filter !== "team" && { display: "none" })}
          >
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("Team")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="team"
                render={({ onChange, value, name }) => (
                  <Select
                    isClearable={true}
                    name={name}
                    onChange={team => onChange(team?.value ?? null)}
                    value={teamOptions?.find(o => o.value === value)}
                    placeholder={getTexts("SelectTeams")}
                    styles={getSelectStyle({ theme })}
                    theme={getSelectTheme({ theme })}
                    options={teamOptions}
                  />
                )}
              />
            </Box>
          </FormControl>

          <FormControl
            mt={8}
            align="center"
            {...(["me", "unassigned"].includes(searchParams.filter) && {
              display: "none",
            })}
          >
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("Agents")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="agentId"
                render={({ onChange, value, name }) => (
                  <Select
                    isClearable={true}
                    name={name}
                    onChange={agent => onChange(agent?.value ?? null)}
                    value={agentOptions?.find(o => o.value === value)}
                    placeholder={getTexts("SelectAgents")}
                    styles={getSelectStyle({ theme })}
                    theme={getSelectTheme({ theme })}
                    options={agentOptions}
                  />
                )}
              />
            </Box>
          </FormControl>
          <FormControl mt={8} align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("Client")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="customerId"
                render={({ onChange, name, value }) => {
                  const defaultOptions = Object.values(customers)?.map(
                    ({ userId, displayName }) => ({
                      value: userId,
                      label: displayName,
                    })
                  );
                  return (
                    <AsyncSelect
                      isClearable={true}
                      name={name}
                      value={defaultOptions?.find(x => x.value === value)}
                      defaultOptions={defaultOptions}
                      loadOptions={value =>
                        new Promise(async r => {
                          const phoneFieldKey = appConfig?.customerFields
                            ?.filter(f => f?.type === "phone")
                            .map(f => f?.id);
                          const { data: cs } = await api.post({
                            url: "/api/admin/customer",
                            body: {
                              criteria: JSON.stringify([
                                `displayName contains "${value}"`,
                                `email contains "${value}"`,
                                ...phoneFieldKey?.map(
                                  id => `fields.${id} starts_with "${value}"`
                                ),
                              ]),
                              operand: "or",
                            },
                          });
                          r(
                            [...(cs ?? [])]?.map(({ userId, displayName }) => ({
                              value: userId,
                              label: displayName,
                            }))
                          );
                        })
                      }
                      onChange={customer => onChange(customer?.value ?? null)}
                      placeholder={getTexts("CustomerNameOrPhone")}
                      styles={getSelectStyle({ theme })}
                      theme={getSelectTheme({ theme })}
                    />
                  );
                }}
              />
            </Box>
          </FormControl>

          <FormControl mt={8} align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("TagsConversation")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="tag"
                render={({ onChange, name, value }) => (
                  <Select
                    isClearable={true}
                    name={name}
                    placeholder={getTexts("SelectTags")}
                    onChange={_ => onChange(_?.value ?? null)}
                    styles={getSelectStyle({ theme })}
                    theme={getSelectTheme({ theme })}
                    options={customerTagOptions}
                    value={customerTagOptions.find(x => x.value === value)}
                  ></Select>
                )}
              />
            </Box>
          </FormControl>
          <FormControl mt={8} align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("TagsCustomer")}
            </FormLabel>
            <Box flex={1}>
              <Controller
                control={control}
                name="customerTag"
                render={({ onChange, name, value }) => (
                  <Select
                    isClearable={true}
                    name={name}
                    placeholder={getTexts("SelectTags")}
                    onChange={_ => onChange(_?.value ?? null)}
                    styles={getSelectStyle({ theme })}
                    theme={getSelectTheme({ theme })}
                    options={conversationTagOptions}
                    value={conversationTagOptions.find(x => x.value === value)}
                  ></Select>
                )}
              />
            </Box>
          </FormControl>
          <FormControl mt={8} align="center">
            <FormLabel color="#666" mb={1} fontWeight="bold">
              {getTexts("UpdatedDate")}
            </FormLabel>
            <Flex w="100%" flex={1} alignItems="center">
              <Text mr={2}>{getTexts("From")}</Text>
              <Flex flex={1} align="center">
                <Controller
                  control={control}
                  name="afterTimestamp"
                  render={({ onChange, onBlur, value, name }) => (
                    <DatePicker
                      name={name}
                      onBlur={onBlur}
                      onChange={date => onChange(date ? date.valueOf() : null)}
                      defaultValue={value}
                      colorScheme="primary"
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Flex mt={2} w="100%" flex={1} alignItems="center">
              <Text mr={2}>{getTexts("To")}</Text>
              <Flex flex={1} align="center">
                <Controller
                  control={control}
                  name="beforeTimestamp"
                  render={({ onChange, onBlur, value, name }) => (
                    <DatePicker
                      name={name}
                      onBlur={onBlur}
                      onChange={date => onChange(date ? date.valueOf() : null)}
                      defaultValue={value}
                      colorScheme="primary"
                    />
                  )}
                />
              </Flex>
            </Flex>
          </FormControl>
        </Flex>
        {currentView.value === "custom" && (
          <Flex p={2} align="stretch" justify="stretch">
            <Flex
              px={2}
              borderRadius={4}
              borderColor="secondary.300"
              borderWidth={1}
              w="100%"
            >
              <Input
                flex={1}
                px={2}
                variant="flushed"
                border="none"
                placeholder={getTexts("AddAFilterForViewName")}
                ref={viewNameRef}
                isDisabled={currentView.value !== "custom"}
              />
              <Button
                ml={1}
                _focus={{ boxShadow: "none" }}
                variant="link"
                colorScheme="secondary"
                isDisabled={currentView.value !== "custom"}
                onClick={() =>
                  prependCustomView({
                    value: `customView-${viewNameRef.current.value}`,
                    label: viewNameRef.current.value,
                    count: inboxCount?.mention,
                    searchParams,
                  })
                }
              >
                {getTexts("Save")}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

const ConversationContainer = () => (
  <Flex flex={1} minW={0} w="100%">
    <Switch>
      <Route exact path="/conversation" component={Dashboard} />
      <Route
        exact
        path="/conversation/:id?"
        render={({ match }) => {
          return <Conversation id={match?.params?.id} sdk={false} />;
        }}
      />
    </Switch>
  </Flex>
);

const DetailContainer = ({ filterPanelIsOpen }) => (
  <Box
    w={[
      filterPanelIsOpen ? "0px" : "auto",
      filterPanelIsOpen ? "0px" : "auto",
      filterPanelIsOpen ? "0px" : "auto",
      filterPanelIsOpen ? "0px" : "auto",
      "auto",
    ]}
  >
    <Route
      exact
      path="/conversation/:id?"
      render={({ match }) => (
        <Detail conversationId={match?.params?.id} customerId={null} />
      )}
    />
  </Box>
);

const Inbox = () => {
  const { filterPanelIsOpen, filterPanelOnOpen, filterPanelOnToggle } =
    MessengerContainer.useContainer();

  return (
    <Flex minH={0} flex={1} h="100%" justify="stretch" align="stretch">
      <InboxContainer
        filterPanelIsOpen={filterPanelIsOpen}
        filterPanelOnOpen={filterPanelOnOpen}
        filterPanelOnToggle={filterPanelOnToggle}
      />
      <ConversationContainer />
      <DetailContainer filterPanelIsOpen={filterPanelIsOpen} />
    </Flex>
  );
};

const Main = () => {
  const { connectionStatus: socketStatus } = SocketContainer.useContainer();
  const {
    currentLangKey,
    getTexts,
    hasReportPermission,
    hasManageSettingsPermission,
    hasManageUserPermission,
    resetApp,
    session,
    setAppConfig,
    setExternalList,
    setSelectedLangKey,
    upsertConfiguration,
  } = AppContainer.useContainer();
  const {
    clear: resetMessenger,
    setInboxCount,
    setCustomerTags,
    setConversationTags,
    upsertAgent,
    upsertTeam,
  } = MessengerContainer.useContainer();

  const history = useHistory();
  const connect = useSocket({ clientType: 26 });

  const [, , deleteCookie] = useCookie("grick-console-auth");

  const [modalSelectedLang, setModalSelectedLang] = useState(null);

  // useEffect(() => {
  //   window.Greet("onShowSDK", () => {
  //     setSdkVisible(true);
  //   });
  //   window.Greet("onHideSDK", () => {
  //     setSdkVisible(false);
  //   });
  // }, []);

  const onLogout = useCallback(async () => {
    deleteCookie();

    await api.post({
      url: "/api/logout",
    });

    unstable_batchedUpdates(() => {
      // setToken(null);
      resetMessenger();
      resetApp();
    });
  }, [resetApp, resetMessenger]);

  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: customerTags = [] } = await api.get({
          url: "/api/tag/customer",
        });
        const { data: conversationTags = [] } = await api.get({
          url: "/api/tag/conversation",
        });
        const { data: config } = await api.get({
          url: "/api/application",
        });
        const { data: configuration } = await api.get({
          url: "/api/configuration",
        });
        const { data: inboxCount } = await api.get({
          url: "/api/conversation/counts",
        });
        const { data: agents } = await api.get({
          url: "/api/admin/agent",
          params: {
            size: 0,
            fields: "createDate",
          },
        });
        const { data: teams } = await api.get({
          url: "/api/admin/team",
          params: {
            size: 0,
          },
        });
        const { data: externalPlatform } = await api.get({
          url: "/api/admin/setting/externalPlatform",
        });

        unstable_batchedUpdates(() => {
          setCustomerTags(customerTags);
          setConversationTags(conversationTags);
          setAppConfig(config);
          Object.entries(configuration).forEach(([key, value]) =>
            upsertConfiguration(key, value)
          );
          setInboxCount(inboxCount);
          agents.forEach(agent => upsertAgent(agent?.userId, agent));
          teams.forEach(team => upsertTeam(team?.id, team));
          setExternalList(externalPlatform);
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [
    setAppConfig,
    setConversationTags,
    setCustomerTags,
    setExternalList,
    setInboxCount,
    upsertAgent,
    upsertConfiguration,
    upsertTeam,
  ]);

  const {
    isOpen: switchLangModalIsOpen,
    onOpen: switchLangModalOnOpen,
    onClose: switchLangModalOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (currentLangKey && !modalSelectedLang) {
      setModalSelectedLang(currentLangKey);
    }
  }, [currentLangKey, modalSelectedLang]);

  return (
    <Flex spacing={0} h="100%" w="100%">
      <Stack
        spacing={0}
        overflow="hidden"
        w={42}
        boxShadow="lg"
        align="center"
        zIndex={1000}
        my={2}
      >
        <Image
          cursor="pointer"
          w={30}
          my={2}
          src={logo}
          onClick={() => history.push("/conversation")}
        />

        <Tooltip label={getTexts("Conversations")} placement="right">
          <IconButton
            mt={8}
            icon={<MdChatBubble />}
            variant="ghost"
            fontSize="xl"
            color="gray.500"
            onClick={() => history.push("/conversation")}
            _focus={{ boxShadow: "none" }}
          ></IconButton>
        </Tooltip>

        <Menu key="users" placement="right-start">
          <Tooltip label={getTexts("User")} placement="right">
            <MenuButton
              as={IconButton}
              key="setting"
              fontSize="xl"
              icon={<FaUsers />}
              variant="ghost"
              color="gray.500"
              _focus={{ boxShadow: "none" }}
            />
          </Tooltip>
          <Portal>
            <MenuList>
              {[
                {
                  key: "User",
                  label: getTexts("User"),
                  items: [
                    {
                      key: "customers",
                      label: getTexts("Customers"),
                      path: "/customers",
                      isDisabled: !hasManageUserPermission,
                    },
                    {
                      key: "agents",
                      label: getTexts("Agents"),
                      path: "/agents",
                      isDisabled: !hasManageUserPermission,
                    },
                    {
                      key: "teams",
                      label: getTexts("Team"),
                      path: "/teams",
                      isDisabled: !hasManageUserPermission,
                    },
                  ],
                },
              ].map(({ label, key, items }, index) => {
                return (
                  <Box key={index}>
                    {index > 0 && <MenuDivider key={`${key}_divider`} />}
                    <MenuGroup key={key} title={label}>
                      {(items ?? []).map(
                        ({ label, key, onClick, path, isDisabled }) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                setSelectedKey(key);
                                onClick && onClick();
                                path && history.push(path);
                              }}
                              {...(key === selectedKey && {
                                fontWeight: "bold",
                                bg: "secondary.100",
                              })}
                              key={key}
                              isDisabled={isDisabled}
                            >
                              {label}
                            </MenuItem>
                          );
                        }
                      )}
                    </MenuGroup>
                  </Box>
                );
              })}
            </MenuList>
          </Portal>
        </Menu>

        <Menu key="setting" placement="right-start">
          <Tooltip label={getTexts("Setting")} placement="right">
            <MenuButton
              as={IconButton}
              key="setting"
              fontSize="xl"
              icon={<FaCog />}
              variant="ghost"
              color="gray.500"
              _focus={{ boxShadow: "none" }}
            />
          </Tooltip>
          <Portal>
            <MenuList>
              {[
                {
                  key: "settings",
                  label: getTexts("Setting"),
                  items: [
                    {
                      key: "customization",
                      label: getTexts("SDKCustomization"),
                      path: "/customization",
                      isDisabled: !hasManageSettingsPermission,
                    },
                    {
                      key: "configuration",
                      label: getTexts("Configuration"),
                      path: "/configuration",
                      isDisabled: !hasManageSettingsPermission,
                    },
                    {
                      key: "externalPlatform",
                      label: getTexts("ExternalPlatform"),
                      path: "/externalPlatform",
                      isDisabled: !hasManageSettingsPermission,
                    },
                    {
                      key: "developer",
                      label: getTexts("Developer"),
                      path: "/developer",
                      isDisabled: !hasManageSettingsPermission,
                    },
                    {
                      key: "report",
                      label: getTexts("Report"),
                      path: "/report",
                      isDisabled: !hasReportPermission,
                    },
                  ],
                },
              ].map(({ label, key, items }, index) => {
                return (
                  <Box key={key}>
                    {index > 0 && <MenuDivider />}
                    <MenuGroup title={label}>
                      {(items ?? []).map(
                        ({ label, key, onClick, path, isDisabled }) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                setSelectedKey(key);
                                onClick && onClick();
                                path && history.push(path);
                              }}
                              isDisabled={isDisabled}
                              {...(key === selectedKey && {
                                fontWeight: "bold",
                                bg: "secondary.100",
                              })}
                              key={key}
                            >
                              {label}
                            </MenuItem>
                          );
                        }
                      )}
                    </MenuGroup>
                  </Box>
                );
              })}
            </MenuList>
          </Portal>
        </Menu>
        <Box flex={1} />
        {/* <Tooltip
          placement="right"
          label={!sdkVisible ? getTexts("ShowSDK") : getTexts("HideSDK")}
        >
          <Box mt={1} px={2}>
            <IconButton
              mt={1}
              borderRadius={4}
              variant="ghost"
              colorScheme="primary"
              isActive={sdkVisible}
              onClick={() => window.Greet(!sdkVisible ? "showSDK" : "hideSDK")}
              icon={<BiSupport />}
              mx={1}
            />
          </Box>
        </Tooltip> */}
        <Tooltip
          placement="right"
          label={(status => {
            switch (status) {
              case "error":
                return getTexts("ServerConnectionFailed");
              case "processing":
                return getTexts("ServerConnecting");
              case "success":
                return getTexts("ServerConnectionIsSuccessful");
              case "pending_retry":
              default:
                return getTexts("ServerNotConnected");
            }
          })(socketStatus)}
        >
          <Box mt={1} px={2}>
            <IconButton
              mt={1}
              borderRadius={4}
              onClick={connect}
              isLoading={socketStatus === "processing"}
              {...(socketStatus === "success" && { colorScheme: "green" })}
              {...(["error", "pending_retry"].includes(socketStatus) && {
                colorScheme: "red",
              })}
              icon={<FaServer />}
              mx={1}
            />
          </Box>
        </Tooltip>
        <Box mt={1} px={2}>
          <Menu placement="right">
            {session?.user?.profilePic ? (
              <MenuButton px={1} mt={2} borderRadius={4}>
                <Image src={session?.user?.profilePic}></Image>
              </MenuButton>
            ) : (
              <MenuButton
                px={1}
                mt={1}
                as={IconButton}
                icon={<FaUser />}
                borderRadius={4}
                colorScheme="primary"
                border="1px solid #eee"
              />
            )}
            <Portal>
              <MenuList>
                {[
                  {
                    key: "account",
                    label: session?.user?.displayName,
                    items: [
                      // {
                      //   key: "conversations",
                      //   label: getTexts("Conversations"),
                      //   path: "/conversation"
                      // },
                      {
                        key: "langSwitch",
                        label: getTexts("SwitchLanguage"),
                        onClick: switchLangModalOnOpen,
                      },
                      {
                        key: "logout",
                        label: getTexts("Logout"),
                        onClick: onLogout,
                      },
                    ],
                  },
                ].map(({ label, key, items }, index) => {
                  return (
                    <Box key={key}>
                      <Modal
                        isCentered
                        isOpen={switchLangModalIsOpen}
                        onClose={switchLangModalOnClose}
                        size="xs"
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {getTexts("SwitchLanguage")}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <RadioGroup
                              value={modalSelectedLang}
                              onChange={v => {
                                setModalSelectedLang(v);
                              }}
                            >
                              <Stack direction="column">
                                <Radio
                                  colorScheme="secondary"
                                  value={LangKey.EN}
                                >
                                  English
                                </Radio>
                                <Radio
                                  colorScheme="secondary"
                                  value={LangKey.ZH}
                                >
                                  繁體中文
                                </Radio>
                                <Radio
                                  colorScheme="secondary"
                                  value={LangKey.ZH_CN}
                                >
                                  简体中文
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="secondary"
                              mr={3}
                              onClick={() => {
                                setSelectedLangKey(modalSelectedLang);
                                switchLangModalOnClose();
                              }}
                            >
                              {getTexts("Confirm")}
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      {index > 0 && <MenuDivider />}
                      <MenuGroup key={key} title={label}>
                        {(items ?? []).map(({ label, key, onClick, path }) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                setSelectedKey(key);
                                onClick && onClick();
                                path && history.push(path);
                              }}
                              // {...(key === selectedKey && {
                              //   fontWeight: "bold",
                              //   bg: "secondary.100"
                              // })}
                              key={key}
                            >
                              {label}
                            </MenuItem>
                          );
                        })}
                      </MenuGroup>
                    </Box>
                  );
                })}
              </MenuList>
            </Portal>
          </Menu>
        </Box>
      </Stack>
      <Box bg="#fefefe" w="100%" h="100%" minW={0} flex={1}>
        <Switch>
          {/* Route for First Button  */}
          <Route exact path="/conversation/:id?" component={Inbox} />

          {/* Route for Second Button  */}
          <Route exact path="/customers">
            {hasManageUserPermission ? <Customers /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/customers/:id" component={Customer} />
          <Route exact path="/agents">
            {hasManageUserPermission ? <Agents /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/agents/:id">
            {hasManageUserPermission ? <Agent /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/teams" component={Teams} />

          {/* Route for Third Button  */}
          <Route path="/customization" component={Customization}>
            {hasManageSettingsPermission ? (
              <Customization />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/configuration">
            {hasManageSettingsPermission ? (
              <Configuration />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact path="/externalPlatform">
            {hasManageSettingsPermission ? (
              <ExternalPlatformList />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route
            exact
            path="/externalPlatform/:id"
            render={({ match }) => {
              if (!hasManageSettingsPermission) return <Redirect to="/" />;
              return <ExternalPlatformDetail id={match?.params?.id} />;
            }}
          />
          <Route
            exact
            path="/externalPlatform/:id/:channelId"
            render={({ match }) => {
              if (!hasManageSettingsPermission) return <Redirect to="/" />;
              return (
                <SanukerChannelDetail
                  id={match?.params?.id}
                  channelId={match?.params?.channelId}
                />
              );
            }}
          />
          <Route
            exact
            path="/externalPlatform/:id/:channelId/:templateKey"
            render={({ match }) => {
              if (!hasManageSettingsPermission) return <Redirect to="/" />;
              return (
                <SanukerTemplateDetail
                  id={match?.params?.id}
                  channelId={match?.params?.channelId}
                  templateKey={match?.params?.templateKey}
                />
              );
            }}
          />
          <Route exact path="/developer" component={Developer}>
            {hasManageSettingsPermission ? <Developer /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/report" component={Report}>
            {hasReportPermission ? <Report /> : <Redirect to="/" />}
          </Route>
          <Redirect to="/conversation" />
        </Switch>
      </Box>
    </Flex>
  );
};

export default Main;
