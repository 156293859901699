import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import api from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";

const platformTypeOptions = [
  { value: "whatsapp", label: "Whatsapp" },
  { value: "facebook", label: "Facebook" },
];

const UpsertSanukerChannelDrawer = ({ isOpen, onClose, params }) => {
  const toast = useToast();
  const { getTexts, setExternalList } = AppContainer.useContainer();

  const isEdit = params?.channel?.id ? true : false;

  const [
    upsertSanukerChannelIsLoading,
    setUpsertSanukerChannelIsLoading,
  ] = useState(false);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      name: params?.channel?.name,
      platformType: platformTypeOptions?.find(
        option => option.value === params?.channel?.platformType
      ),
      accountId: params?.channel?.accountId,
      namespace: params?.channel?.namespace,
      responseWindowHours: params?.channel?.responseWindowHours,
    },
  });

  console.log(params);
  const onSubmit = async data => {
    try {
      setUpsertSanukerChannelIsLoading(true);
      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...params?.channel,
          id: params?.platform?.id,
          channelId: params?.channel?.id,
          ...data,
          platformType: data?.platformType?.value,
        },
      });
      setExternalList(resData);
      toast({
        title: isEdit
          ? getTexts("SanukerChannelUpdated")
          : getTexts("SanukerChannelCreated"),
        status: "success",
      });
      onClose();
    } catch (err) {
    } finally {
      setUpsertSanukerChannelIsLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {isEdit
              ? getTexts("SanukerChannelUpdate")
              : getTexts("SanukerChannelCreate")}
          </DrawerHeader>

          <DrawerBody>
            <List spacing={8}>
              {!isEdit && (
                <ListItem>
                  <FormControl isRequired isInvalid={errors.channelId?.message}>
                    <FormLabel htmlFor="channelId">
                      {getTexts("ChannelId")}
                    </FormLabel>
                    <Controller
                      as={<Input />}
                      control={control}
                      name="channelId"
                      defaultValue=""
                      rules={{
                        required: `${getTexts("PleaseInput")} ${getTexts(
                          "ChannelId"
                        )}`,
                      }}
                    />
                    <FormErrorMessage>
                      {errors.channelId?.message}
                    </FormErrorMessage>
                  </FormControl>
                </ListItem>
              )}
              <ListItem>
                <FormControl isRequired isInvalid={errors.name?.message}>
                  <FormLabel htmlFor="name">
                    {getTexts("ChannelName")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "ChannelName"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl
                  isRequired
                  isInvalid={errors.platformType?.message}
                >
                  <FormLabel htmlFor="platformType">
                    {getTexts("PlatformType")}
                  </FormLabel>
                  <Controller
                    as={<Select options={platformTypeOptions} placeholder="" />}
                    control={control}
                    name="platformType"
                    defaultValue=""
                    rules={{
                      required: `${getTexts("PleaseInput")} ${getTexts(
                        "PlatformType"
                      )}`,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.platformType?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isInvalid={errors.accountId?.message}>
                  <FormLabel htmlFor="accountId">
                    {getTexts("AccountId")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="accountId"
                    defaultValue=""
                  />
                  <FormErrorMessage>
                    {errors.accountId?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isInvalid={errors.namespace?.message}>
                  <FormLabel htmlFor="namespace">
                    {getTexts("Namespace")}
                  </FormLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="namespace"
                    defaultValue=""
                  />
                  <FormErrorMessage>
                    {errors.namespace?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl isInvalid={errors.responseWindowHours?.message}>
                  <FormLabel htmlFor="responseWindowHours">
                    {getTexts("ResponseWindowHours")}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="responseWindowHours"
                    defaultValue={24}
                    render={({ name, value, onChange }) => (
                      <NumberInput
                        name={name}
                        value={value}
                        onChange={v => onChange(Math.round(v))}
                        inputMode="numeric"
                        precision={0}
                        min={0}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  <FormErrorMessage>
                    {errors.responseWindowHours?.message}
                  </FormErrorMessage>
                </FormControl>
              </ListItem>
            </List>
          </DrawerBody>

          <DrawerFooter>
            <Flex mt={8} w="100%">
              <Box flex={1} minW={0} w="100%"></Box>
              <Stack direction="row">
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {getTexts("Cancel")}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={upsertSanukerChannelIsLoading}
                >
                  {isEdit ? getTexts("Update") : getTexts("Create")}
                </Button>
              </Stack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UpsertSanukerChannelDrawer;
