import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  IconButton,
  Flex,
  Box,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import api from "../utils/api";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import Masonry from "react-responsive-masonry";

import MessengerContainer from "../store/messenger";
import AsyncImage from "./AsyncImage";
import PreviewImage from "./PreviewImage";
import {
  downloadBlobUrl,
  fetchAssetToObjectUrl,
  getBoundedImageSize,
} from "@projectg/utils/utils/helpers";
import { FaPlayCircle } from "react-icons/fa";
import MessageListItem from "./MessageListItem";
import TeamworkVoiceDecoder from "@projectg/utils/utils/voice/TeamworkVoiceDecoder";
import { getClientType, getHeader } from "@projectg/utils/utils/api";
import AppContainer from "@projectg/utils/store/app";

const cache = new CellMeasurerCache({
  fixedWidth: true,
});

const MediaLibraryDrawer = ({ isOpen, onClose, conversationId }) => {
  const inputRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [sortedResult, setSortedResult] = useState(undefined);
  const [totalMessage, setTotalMessage] = useState(undefined);
  const [tabIndex, setTabIndex] = useState(0);
  const { getTexts } = AppContainer.useContainer();

  const { upsertMessage } = MessengerContainer.useContainer();

  const fetchDataByTab = useCallback(async () => {
    const typeFilter = (() => {
      switch (tabIndex) {
        case 0:
          return [3, 2]; // img
        case 1:
          return [3, 1]; // file
        case 2:
          return [2, -1]; // voice
        default:
          return [-1, -1];
      }
    })();

    setIsSearching(true);
    try {
      const { data } = await api.get({
        url: `/api/conversation/${conversationId}/message`,
        params: {
          size: 50,
          msgTypeFilter: typeFilter[0] !== -1 ? typeFilter[0] : undefined,
          msgSubtypeFilter: typeFilter[1] !== -1 ? typeFilter[1] : undefined,
        },
      });
      if (data.message)
        setSortedResult(
          data.message.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
        );
      if (data.total) setTotalMessage(data.total);
    } catch (err) {
      console.log(err);
    }
    setIsSearching(false);
  }, [conversationId, tabIndex]);

  useEffect(() => {
    fetchDataByTab();
  }, [fetchDataByTab, tabIndex]);

  const onPlayButtonClick = useCallback(
    async m => {
      try {
        upsertMessage(conversationId, m.id, m => ({
          ...m,
          ___voiceFile: { downloading: true, blob: null },
        }));

        const blobMp3 = await (() =>
          new Promise(async resolve => {
            if (typeof window === "undefined") return;
            new TeamworkVoiceDecoder(
              `/api/message/download?messageId=${
                m.id
              }&clientType=${getClientType()}`,
              getHeader()
            ).toMp3(resolve);
          }))();

        upsertMessage(conversationId, m.id, m => ({
          ...m,
          ___voiceFile: { downloading: false, blob: blobMp3 },
        }));
      } catch (error) {
        upsertMessage(conversationId, m.id, m => ({
          ...m,
          ___voiceFile: { downloading: false, error: true, blob: null },
        }));
      }
    },
    [conversationId, upsertMessage]
  );

  return (
    <>
      <Drawer
        size="xl"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        returnFocusOnClose={false}
        initialFocusRef={inputRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{getTexts("MediaLibrary")}</DrawerHeader>

            <DrawerBody>
              <Tabs
                isLazy
                isFitted
                variant="enclosed"
                colorScheme="primary"
                index={tabIndex}
                onChange={i => setTabIndex(i)}
                h="95%"
                borderColor="gray.200"
              >
                <TabList color="gray.500" borderBottom="1px solid">
                  <Tab
                    _focus={{ boxShadow: 0 }}
                    _selected={{
                      bg: "gray.200",
                      border: "1px solid",
                      borderBottom: "0",
                      borderColor: "inherit",
                    }}
                    borderBottomColor="inherit"
                    fontWeight="bold"
                  >
                    {getTexts("Photo")}
                  </Tab>
                  <Tab
                    _focus={{ boxShadow: 0 }}
                    _selected={{
                      bg: "gray.200",
                      border: "1px solid",
                      borderBottom: "0",
                      borderColor: "inherit",
                    }}
                    borderBottomColor="inherit"
                    fontWeight="bold"
                  >
                    {getTexts("File")}
                  </Tab>
                  <Tab
                    _focus={{ boxShadow: 0 }}
                    _selected={{
                      bg: "gray.200",
                      border: "1px solid",
                      borderBottom: "0",
                      borderColor: "inherit",
                    }}
                    borderBottomColor="inherit"
                    fontWeight="bold"
                  >
                    {getTexts("Audio")}
                  </Tab>
                </TabList>
                {isSearching ? (
                  <Box
                    h="100%"
                    borderBottomRadius={4}
                    border="1px solid"
                    borderColor="inherit"
                    bg="gray.200"
                    textAlign="center"
                    pt={4}
                  >
                    <Spinner
                      emptyColor="gray.200"
                      color="primary.500"
                      size="xl"
                      thickness={4}
                    />
                  </Box>
                ) : (
                  <TabPanels
                    h="100%"
                    border="1px solid"
                    borderColor="inherit"
                    borderBottomRadius={4}
                    bg="gray.200"
                  >
                    <TabPanel w="100%" h="100%" p={4}>
                      <Box w="100%" h="100%" overflow="auto">
                        {sortedResult?.length ? (
                          <Masonry columnsCount={2} gutter="15px">
                            {sortedResult.map(m => {
                              const file = m.meta.file;
                              const isLocalImage = file instanceof Blob;
                              const size = getBoundedImageSize(
                                file?.previewDimension?.width,
                                file?.previewDimension?.height,
                                200,
                                250
                              );
                              return (
                                <Box key={m.id}>
                                  <AsyncImage
                                    {...(!isLocalImage
                                      ? {
                                          srcType: "url",
                                          src: file.fileUrl,
                                        }
                                      : {
                                          srcType: "file",
                                          src: file,
                                        })}
                                    fallback={
                                      <Flex
                                        {...size}
                                        align="center"
                                        justify="center"
                                      >
                                        <Spinner />
                                      </Flex>
                                    }
                                  >
                                    {({ src }) => (
                                      <PreviewImage
                                        src={src}
                                        file={file}
                                        size={size}
                                        message={m}
                                        h="100%"
                                        w="100%"
                                        d="flex"
                                        boxShadow="none"
                                        imgStyles={{
                                          fit: "cover",
                                          w: "100%",
                                          h: "100%",
                                          maxH: "400px",
                                        }}
                                      />
                                    )}
                                  </AsyncImage>
                                </Box>
                              );
                            })}
                          </Masonry>
                        ) : (
                          <Box textAlign="center" pt={4}>
                            {getTexts("NoPhotos")}
                          </Box>
                        )}
                      </Box>
                    </TabPanel>
                    <TabPanel h="100%" p={2}>
                      <Box h="100%" overflow="auto">
                        {sortedResult?.length ? (
                          <AutoSizer>
                            {({ height, width }) => (
                              <List
                                height={height}
                                width={width}
                                rowCount={totalMessage ?? 0}
                                rowHeight={cache.rowHeight}
                                rowRenderer={({
                                  key,
                                  index,
                                  style,
                                  parent,
                                }) => {
                                  const fileSizeText = (() => {
                                    if (
                                      sortedResult[index]?.meta?.file
                                        ?.fileSize /
                                        1024 /
                                        1024 >
                                      1000
                                    )
                                      return `${
                                        Math.round(
                                          (sortedResult[index]?.meta?.file
                                            ?.fileSize /
                                            1024 /
                                            1024 /
                                            1024) *
                                            100
                                        ) / 100
                                      } GB`;

                                    if (
                                      sortedResult[index]?.meta?.file
                                        ?.fileSize /
                                        1024 >
                                      1000
                                    )
                                      return `${
                                        Math.round(
                                          (sortedResult[index]?.meta?.file
                                            ?.fileSize /
                                            1024 /
                                            1024) *
                                            100
                                        ) / 100
                                      } MB`;

                                    return `${
                                      Math.round(
                                        (sortedResult[index]?.meta?.file
                                          ?.fileSize /
                                          1024) *
                                          100
                                      ) / 100
                                    } KB`;
                                  })();

                                  return (
                                    <CellMeasurer
                                      cache={cache}
                                      columnIndex={0}
                                      rowIndex={index}
                                      key={key}
                                      parent={parent}
                                    >
                                      <MessageListItem
                                        style={style}
                                        m={sortedResult[index]}
                                        onClick={async () => {
                                          const file =
                                            sortedResult[index].meta.file;
                                          if (file?.fileUrl) {
                                            const fileBlobUrl =
                                              await fetchAssetToObjectUrl(
                                                file?.fileUrl
                                              );
                                            downloadBlobUrl(
                                              fileBlobUrl,
                                              file?.name ?? null
                                            );
                                          }
                                        }}
                                      >
                                        <Flex>
                                          <Box width={0} minH="100%" flex={1}>
                                            {
                                              sortedResult[index]?.meta?.file
                                                ?.name
                                            }
                                          </Box>
                                          <Box color="gray.500">
                                            {fileSizeText}
                                          </Box>
                                        </Flex>
                                      </MessageListItem>
                                    </CellMeasurer>
                                  );
                                }}
                              />
                            )}
                          </AutoSizer>
                        ) : (
                          <Box textAlign="center" pt={4}>
                            {getTexts("NoFile")}
                          </Box>
                        )}
                      </Box>
                    </TabPanel>
                    <TabPanel p={2}>
                      <Box overflow="auto">
                        {sortedResult?.length ? (
                          <AutoSizer>
                            {({ height, width }) => (
                              <List
                                height={height}
                                width={width}
                                rowCount={totalMessage ?? 0}
                                rowHeight={cache.rowHeight}
                                rowRenderer={({
                                  key,
                                  index,
                                  style,
                                  parent,
                                }) => {
                                  const blob =
                                    sortedResult[index]?.___voiceFile?.blob;
                                  return (
                                    <CellMeasurer
                                      cache={cache}
                                      columnIndex={0}
                                      rowIndex={index}
                                      key={key}
                                      parent={parent}
                                    >
                                      <MessageListItem
                                        style={style}
                                        m={sortedResult[index]}
                                        onClick={() => {
                                          window.open(
                                            sortedResult[index].meta.file
                                              .fileUrl
                                          );
                                        }}
                                      >
                                        {blob ? (
                                          <audio src={blob} controls={true} />
                                        ) : (
                                          <IconButton
                                            icon={<FaPlayCircle />}
                                            onClick={() =>
                                              onPlayButtonClick(
                                                sortedResult[index]
                                              )
                                            }
                                          />
                                        )}
                                      </MessageListItem>
                                    </CellMeasurer>
                                  );
                                }}
                              />
                            )}
                          </AutoSizer>
                        ) : (
                          <Box textAlign="center" pt={4}>
                            {getTexts("NoAudio")}
                          </Box>
                        )}
                      </Box>
                    </TabPanel>
                  </TabPanels>
                )}
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default MediaLibraryDrawer;
