import React, { useState, useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Box,
  useDisclosure,
  Image
} from "@chakra-ui/react";
import { GoSearch } from "react-icons/go";
import { MdCancel } from "react-icons/md";
import { useForm } from "react-hook-form";
import api from "../utils/api";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache
} from "react-virtualized";
import MessageModal from "./MessageModal";
import MessageListItem from "./MessageListItem";
import NotFound from "../assets/image_not_found.svg";
import AppContainer from "@projectg/utils/store/app";

const cache = new CellMeasurerCache({
  fixedWidth: true
});

const SearchConversationDrawer = ({ isOpen, onClose, conversationId }) => {
  const inputRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [sortedSearchResult, setSortedSearchResult] = useState(undefined);
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose
  } = useDisclosure();
  const [messageDetail, setMessageDetail] = useState({ m: null });
  const { getTexts } = AppContainer.useContainer();

  const searchConversation = async values => {
    if (!isSearching) {
      setIsSearching(true);
      try {
        const { data } = await api.get({
          url: `/api/message/search`,
          params: { query: values.searchInput, conversationId }
        });
        cache.clearAll();
        setSortedSearchResult(
          data.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
        );
      } catch (err) {
        console.log(err);
      }
      setIsSearching(false);
    }
  };

  const { watch, errors, handleSubmit, setValue, register } = useForm({
    searchInput: ""
  });

  const watchValue = watch();

  return (
    <>
      <MessageModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        messageDetail={messageDetail}
      />
      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        returnFocusOnClose={false}
        initialFocusRef={inputRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{getTexts("SearchForConversation")}</DrawerHeader>

            <DrawerBody>
              <Flex h="100%" flexDir="column">
                <Box minH="7%">
                  <FormControl isInvalid={errors?.searchInput}>
                    <InputGroup>
                      <Input
                        ref={e => {
                          register(e, { minLength: 3, required: true });
                          inputRef.current = e;
                        }}
                        name="searchInput"
                        onChange={e => setValue("searchInput", e.target.value)}
                        value={watchValue?.searchInput || ""}
                        focusBorderColor="primary.400"
                        placeholder={getTexts(
                          "PleaseEnter3OrMoreWordsToSearch"
                        )}
                        onKeyPress={({ key }) => {
                          if (key === "Enter")
                            handleSubmit(searchConversation)();
                        }}
                      />
                      <InputRightElement
                        w="max-content"
                        pr={1}
                        children={
                          <Flex>
                            <IconButton
                              icon={<MdCancel />}
                              focusBorderColor="transparent"
                              _focus={{}}
                              _hover={{}}
                              _active={{}}
                              variant="ghost"
                              colorScheme="primary"
                              isRound
                              fontWeight="bold"
                              fontSize="md"
                              size="sm"
                              {...(!watchValue?.searchInput && {
                                display: "none"
                              })}
                              onClick={() => {
                                setValue("searchInput", "");
                                setSortedSearchResult(undefined);
                              }}
                            />
                            <IconButton
                              ml={1}
                              icon={<GoSearch />}
                              focusBorderColor="transparent"
                              _focus={{ boxShadow: "none" }}
                              variant="ghost"
                              colorScheme="primary"
                              fontWeight="bold"
                              fontSize="xl"
                              size="sm"
                              onClick={handleSubmit(searchConversation)}
                              isLoading={isSearching}
                            />
                          </Flex>
                        }
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {getTexts("PleaseEnter3OrMoreCharacters")}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box
                  bg="gray.100"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius={4}
                  p={2}
                  minH={0}
                  h="100%"
                  flex={1}
                  my={3}
                >
                  {sortedSearchResult !== undefined &&
                    (sortedSearchResult.length ? (
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            height={height}
                            width={width}
                            rowCount={sortedSearchResult.length ?? 0}
                            rowHeight={cache.rowHeight}
                            rowRenderer={({ key, index, style, parent }) => {
                              return (
                                <CellMeasurer
                                  cache={cache}
                                  columnIndex={0}
                                  rowIndex={index}
                                  key={key}
                                  parent={parent}
                                >
                                  <MessageListItem
                                    style={style}
                                    m={sortedSearchResult[index]}
                                    onClick={() => {
                                      setMessageDetail({
                                        m: sortedSearchResult[index]
                                      });
                                      modalOnOpen();
                                    }}
                                  >
                                    {sortedSearchResult[index]?.meta?.content}
                                  </MessageListItem>
                                </CellMeasurer>
                              );
                            }}
                          />
                        )}
                      </AutoSizer>
                    ) : (
                      <Flex
                        flexDir="column"
                        justify="center"
                        align="center"
                        color="gray.400"
                        m={8}
                        height="-webkit-fill-available"
                      >
                        <Image mb={4} width="75%" src={NotFound} />
                        {getTexts("NoRelatedConversationsFound")}
                      </Flex>
                    ))}
                </Box>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default SearchConversationDrawer;
