import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Stack,
  Text,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import AsyncImage from "@projectg/utils/components/AsyncImage";
import AppContainer from "@projectg/utils/store/app";
import api from "@projectg/utils/utils/api";
import { LangKey, patterns } from "@projectg/utils/utils/constants";
import {
  getCanvasBlob,
  getCanvasFromImage,
  getImageFromFile,
  getPalette,
  getResizedCanvas,
} from "@projectg/utils/utils/helpers";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CirclePicker } from "react-color";
import { unstable_batchedUpdates } from "react-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { MdChatBubbleOutline, MdClose } from "react-icons/md";

import { Section } from "../../components/components";
import { getModuleDefaultValue } from "../../utils/helpers";
import SdkApp from "../sdk/sdkApp";

const moduleOptions = [
  { value: "startConversation", label: "開始對話" },
  { value: "conversationList", label: "過往對話" },
  { value: "officeHour", label: "營業時間" },
];

const Customization = () => {
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdating] = useState(false);
  const theme = useTheme();
  const { handleSubmit, register, reset, watch, control, getValues, setValue } =
    useForm({
      defaultValues: {
        themeColorPrimary: theme.colors.primary[500],
        landingPageLogo: null,
        landingPageDescription: {
          zh: "歡迎你！",
          zh_cn: "歡迎你！",
          en: "Welcome to Grick!",
        },
        moduleList: [
          getModuleDefaultValue({ module: "startConversation" }),
          getModuleDefaultValue({ module: "conversationList" }),
        ],
      },
    });

  const toast = useToast();
  const { setAppConfig, getTexts } = AppContainer.useContainer();

  const onSubmit = useCallback(
    async appConfig => {
      try {
        console.log("appConfig", appConfig);
        setUpdating(true);
        const { data: _appConfig } = await api.post({
          url: "/api/admin/setting/update",
          body: appConfig,
        });
        unstable_batchedUpdates(() => {
          setAppConfig(_appConfig);
          setUpdating(false);
          toast({
            title: "修改成功",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        });
      } catch (error) {
        toast({
          title: "修改失敗",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      }
    },
    [setAppConfig, toast]
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "moduleList",
  });

  const moduleSelectedRef = useRef();

  const originalAppConfig = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: appConfig } = await api.get({
          url: "/api/admin/setting",
        });
        appConfig.conversationButtonRemove = false; // if conversationButtonRemove=undefined, backend return error
        reset(appConfig);
        originalAppConfig.current = appConfig;
        setFetching(false);
      } catch (error) {}
    })();
  }, [reset]);

  const watched = watch([
    "themeColorPrimary",
    "landingPageLogo",
    "landingPageDescription",
    "conversationButtonIcon",
    "moduleList",
    "responseTimeType",
    "officeHourResponseTime",
    "nonOfficeHourResponseTime",
    "officeHour",
  ]);
  const uploadInputRef = useRef(null);

  const palette = useMemo(
    () => getPalette(watched?.themeColorPrimary ?? "#fff"),
    [watched]
  );

  const [expandedKey, setExpandedKey] = useState(null);

  const sectionFooter = (
    <Box w="100%" mt={4} textAlign="right">
      <Button
        mt={4}
        variant="link"
        colorScheme="primary"
        size="lg"
        fontSize="xl"
        isDisabled={updating}
        onClick={() => {
          setExpandedKey(null);
          reset(originalAppConfig.current);
        }}
      >
        {getTexts("Cancel")}
      </Button>
      <Button
        ml={4}
        mt={4}
        colorScheme="primary"
        size="lg"
        fontSize="xl"
        type="submit"
        isLoading={updating}
      >
        {getTexts("Apply")}
      </Button>
    </Box>
  );

  return fetching ? (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  ) : (
    <Flex position="relative" w="100%" h="100%">
      <Box p={8} pr={420} overflow="auto" w="100%">
        <Box maxW={768} mx="auto">
          <Section
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            title={getTexts("Appearance")}
            description={getTexts("appearance_description")}
            isExpanded={expandedKey === "appearance"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "appearance" ? null : "appearance"
              )
            }
          >
            <FormControl d="flex">
              <FormLabel mt={2} mr={4} w={150} fontSize="lg">
                {getTexts("default_language")}
              </FormLabel>
              <Select flex={1} ref={register} name="defaultLang">
                <option value={LangKey.EN}>English</option>
                <option value={LangKey.ZH}>繁體中文</option>
                <option value={LangKey.ZH_CN}>簡體中文</option>
              </Select>
            </FormControl>
            <FormControl mt={12} d="flex">
              <FormLabel mt={2} mr={4} w={150} fontSize="lg">
                {getTexts("main_color")}
              </FormLabel>
              <Controller
                name="themeColorPrimary"
                control={control}
                render={({ value, onChange, name }) => {
                  return (
                    <CirclePicker
                      onChange={e => {
                        onChange(e.hex);
                      }}
                      value={value}
                      name={name}
                    />
                  );
                }}
              ></Controller>
            </FormControl>

            <Input type="hidden" {...register("conversationButtonRemove")} />

            <FormControl mt={12} d="flex">
              <Stack spacing={0}>
                <FormLabel mt={2} mr={4} w={150} fontSize="lg">
                  {getTexts("start_logo")}
                </FormLabel>
                <Button
                  w="fit-content"
                  variant="link"
                  onClick={() => {
                    setValue("conversationButtonIcon", null);
                    setValue("conversationButtonRemove", true);
                  }}
                >
                  {getTexts("reset_default_logo")}
                </Button>
              </Stack>
              <Controller
                name="conversationButtonIcon"
                control={control}
                render={({ value, onChange }) => {
                  return (
                    <HStack>
                      <Button
                        icon={<MdChatBubbleOutline />}
                        size="lg"
                        w={16}
                        h={16}
                        borderRadius="0"
                        bg="transparent"
                        _focus={{ boxShadow: "none" }}
                        position="relative"
                        p={0}
                        onClick={() => {
                          uploadInputRef.current.onChange = value => {
                            onChange(value);
                            setValue("conversationButtonRemove", false);
                          };
                          uploadInputRef.current.click();
                        }}
                      >
                        <AsyncImage
                          src={value}
                          loading={
                            <Flex align="center" justify="center">
                              <Spinner />
                            </Flex>
                          }
                          empty={
                            <Center
                              fontSize="3xl"
                              w="100%"
                              h="100%"
                              borderRadius="full"
                              bg={palette?.[500]}
                              color={palette?._500}
                            >
                              <MdChatBubbleOutline />
                            </Center>
                          }
                        >
                          {({ src }) => <Image src={src} w="100%" h="100%" />}
                        </AsyncImage>
                      </Button>
                    </HStack>
                  );
                }}
              ></Controller>
            </FormControl>
            {sectionFooter}
          </Section>

          <Section
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            title={getTexts("MainPage")}
            description={getTexts("mainPage_description")}
            isExpanded={expandedKey === "moduleList"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "moduleList" ? null : "moduleList"
              )
            }
          >
            <FormControl d="flex">
              <FormLabel mt={2} mr={4} w={150} fontSize="lg">
                {getTexts("company_logo")}
              </FormLabel>
              <Controller
                name="landingPageLogo"
                control={control}
                render={({ value, onChange, name }) => {
                  return (
                    <Button
                      p={4}
                      w="auto"
                      h="auto"
                      backgroundColor={palette?.[200]}
                      backgroundImage={`url("${patterns.diagram()}")`}
                      borderRadius={8}
                      boxShadow="md"
                      onClick={() => {
                        uploadInputRef.current.onChange = onChange;
                        uploadInputRef.current.click();
                      }}
                    >
                      <AsyncImage
                        src={value}
                        loading={
                          <Flex align="center" justify="center">
                            <Spinner />
                          </Flex>
                        }
                        empty={<Text>{getTexts("upload_image")}</Text>}
                      >
                        {({ src }) => <Image h={80} src={src} name={name} />}
                      </AsyncImage>
                    </Button>
                  );
                }}
              ></Controller>
            </FormControl>
            <FormControl mt={12}>
              <FormLabel fontSize="lg" w="100%">
                {getTexts("Title")}
              </FormLabel>
              {[
                { value: LangKey.EN, label: "English" },
                { value: LangKey.ZH, label: "繁體中文" },
                { value: LangKey.ZH_CN, label: "簡體中文" },
              ].map(({ value, label }, _index) => (
                <FormControl d="flex" mt={2} key={value}>
                  <FormLabel
                    mt={2}
                    mr={4}
                    w={150}
                    fontSize="md"
                    color="gray.500"
                  >
                    {label}
                  </FormLabel>
                  <Input
                    flex={1}
                    ref={register()}
                    name={`landingPageDescription.${value}`}
                  />
                </FormControl>
              ))}
            </FormControl>
            <FormControl w="100%">
              <Box flex={1}>
                {fields.map((field, index) => {
                  const { id, module: type } = field;
                  let content = null;
                  switch (field.module) {
                    case "startConversation": {
                      content = (
                        <FormControl>
                          <FormControl mt={6}>
                            <FormLabel fontSize="lg" w="100%">
                              {getTexts("Title")}
                            </FormLabel>
                            {[
                              { value: LangKey.EN, label: "English" },
                              { value: LangKey.ZH, label: "繁體中文" },
                              { value: LangKey.ZH_CN, label: "簡體中文" },
                            ].map(({ value, label }, _index) => (
                              <FormControl d="flex" mt={2} key={value}>
                                <FormLabel
                                  mt={2}
                                  mr={4}
                                  w={150}
                                  fontSize="md"
                                  color="gray.500"
                                >
                                  {label}
                                </FormLabel>
                                <Input
                                  flex={1}
                                  ref={register()}
                                  name={`moduleList[${index}].title.${value}`}
                                  defaultValue={field.title?.[value]}
                                />
                              </FormControl>
                            ))}
                          </FormControl>
                          <FormControl mt={12}>
                            <FormLabel fontSize="lg" w="100%">
                              {getTexts("Description")}
                            </FormLabel>
                            {[
                              { value: LangKey.EN, label: "English" },
                              { value: LangKey.ZH, label: "繁體中文" },
                              { value: LangKey.ZH_CN, label: "簡體中文" },
                            ].map(({ value, label }, _index) => (
                              <FormControl d="flex" mt={2} key={value}>
                                <FormLabel
                                  mt={2}
                                  mr={4}
                                  w={150}
                                  fontSize="md"
                                  color="gray.500"
                                >
                                  {label}
                                </FormLabel>
                                <Input
                                  flex={1}
                                  ref={register()}
                                  name={`moduleList[${index}].description.${value}`}
                                  defaultValue={field.description?.[value]}
                                />
                              </FormControl>
                            ))}
                          </FormControl>
                        </FormControl>
                      );
                      break;
                    }

                    case "conversationList": {
                      content = <></>;
                      break;
                    }

                    case "officeHour": {
                      content = <></>;
                      break;
                    }
                    default:
                      content = null;
                  }

                  return (
                    <Box
                      key={id}
                      mt={4}
                      border="1px solid #eee"
                      borderRadius={8}
                      p={4}
                      bg="gray.50"
                    >
                      <Flex w="100%" alignItems="center" fontSize="xl">
                        <Text flex={1} fontWeight="bold" color="priamry.500">
                          {index + 1}.{" "}
                          {
                            moduleOptions?.find(({ value }) => value === type)
                              ?.label
                          }
                        </Text>
                        <IconButton
                          fontSize="xl"
                          variant="ghost"
                          icon={<MdClose />}
                          onClick={() => remove(index)}
                        />
                      </Flex>
                      <Input
                        ref={register()}
                        type="hidden"
                        name={`moduleList[${index}].module`}
                        defaultValue={field.module}
                      />
                      {content}
                    </Box>
                  );
                })}

                <Flex mt={8}>
                  <Select
                    flex={1}
                    ref={moduleSelectedRef}
                    defaultValue="startConversation"
                  >
                    {moduleOptions.map(({ value, label }) => {
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      );
                    })}
                  </Select>
                  <Button
                    ml={2}
                    variant="outline"
                    colorScheme="primary"
                    onClick={() =>
                      append(
                        getModuleDefaultValue({
                          module: moduleSelectedRef.current.value,
                        })
                      )
                    }
                  >
                    {getTexts("Add")}
                  </Button>
                </Flex>
              </Box>
            </FormControl>
            {sectionFooter}
          </Section>
          <Section
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            title={getTexts("OfficeHour")}
            description={getTexts("officeHour_description")}
            isExpanded={expandedKey === "officeHour"}
            onExpand={() =>
              setExpandedKey(key =>
                key === "officeHour" ? null : "officeHour"
              )
            }
          >
            <FormControl>
              <FormLabel w="100%">{getTexts("set_your_office_hour")}</FormLabel>
              {[
                { value: "mon", label: getTexts("Monday") },
                { value: "tue", label: getTexts("Tuesday") },
                { value: "wed", label: getTexts("Wednesday") },
                { value: "thu", label: getTexts("Thursday") },
                { value: "fri", label: getTexts("Friday") },
                { value: "sat", label: getTexts("Saturday") },
                { value: "sun", label: getTexts("Sunday") },
              ].map(({ value, label }, index) => {
                return (
                  <Flex key={value} mt={4} alignItems="center">
                    <Checkbox
                      name={`officeHour.${value}.enable`}
                      ref={register()}
                      colorScheme="primary"
                      defaultIsChecked={getValues()?.officeHour?.[value]?.start}
                    >
                      {label}
                    </Checkbox>
                    <Select
                      mx={4}
                      w="120"
                      colorScheme="primary"
                      disabled={!watched?.officeHour?.[value]?.enable}
                      placeholder={getTexts("StartTime")}
                      ref={register()}
                      name={`officeHour.${value}.start`}
                    >
                      {[...new Array(48).keys()].map(i => {
                        const time = `${parseInt(i / 2)
                          .toString()
                          .padStart(2, "0")}:${i % 2 ? "30" : "00"}`;
                        return (
                          <option key={time} value={time}>
                            {time + (i < 24 ? " am" : " pm")}
                          </option>
                        );
                      })}
                    </Select>
                    {getTexts("To")}
                    <Select
                      mx={4}
                      w="120"
                      colorScheme="primary"
                      disabled={!watched?.officeHour?.[value]?.enable}
                      placeholder={getTexts("EndTime")}
                      ref={register()}
                      name={`officeHour.${value}.end`}
                    >
                      {[...new Array(48).keys()].map(i => {
                        const time = `${parseInt(i / 2)
                          .toString()
                          .padStart(2, "0")}:${i % 2 ? "30" : "00"}`;
                        if (time < watched?.officeHour?.[index]?.start)
                          return null;
                        return (
                          <option key={time} value={time}>
                            {time + (i < 24 ? " am" : " pm")}
                          </option>
                        );
                      })}
                    </Select>
                  </Flex>
                );
              })}
            </FormControl>
            <FormControl mt={12}>
              <FormLabel w="100%">{getTexts("SetResponseTime")}</FormLabel>
              <FormControl mt={4}>
                <Select
                  name="responseTimeType"
                  ref={register}
                  defaultValue="disable"
                >
                  {[
                    { value: "disable", label: getTexts("DoNotShow") },
                    { value: "dynamic", label: getTexts("DynamicTime") },
                    { value: "manual", label: getTexts("CustomTime") },
                  ].map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
            {watched?.responseTimeType === "manual" && (
              <FormControl ml={8} mt={4} d="flex" alignItems="center">
                <Text>{getTexts("DuringTheBusinessHour")}</Text>
                <Controller
                  control={control}
                  name="officeHourResponseTime"
                  as={
                    <NumberInput size="sm" mx={4} w={24}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  }
                />

                <Text>{getTexts("ReplyWithinMinutes")}</Text>
              </FormControl>
            )}
            {watched?.responseTimeType === "manual" && (
              <FormControl ml={8} mt={2} d="flex" alignItems="center">
                <Text>{getTexts("DuringNonBusinessHour")}</Text>
                <Controller
                  control={control}
                  name="nonOfficeHourResponseTime"
                  as={
                    <NumberInput size="sm" mx={4} w={24}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  }
                />
                <Text>{getTexts("ReplyWithinMinutesNonBusinessHour")}</Text>
              </FormControl>
            )}
            {sectionFooter}
          </Section>

          <Input
            type="file"
            d="none"
            ref={uploadInputRef}
            onChange={async e => {
              try {
                const boundedSize = {
                  height: 80,
                  width: 240,
                };
                const file = e.target.files?.[0] ?? null;
                const img = await getImageFromFile(file);
                const canvas = await getCanvasFromImage(img);
                const ratio = Math.min(
                  boundedSize.width / img.width,
                  boundedSize.height / img.height
                );
                const resized = getResizedCanvas(canvas, {
                  width: ratio * img.width,
                  height: ratio * img.height,
                });
                const blob = await getCanvasBlob(resized);
                uploadInputRef.current.onChange(blob);
                uploadInputRef.current.value = "";
              } catch (error) {
                console.error(error);
              }
              return false;
            }}
          />
        </Box>
      </Box>
      <Stack
        h="100%"
        maxW={420}
        w="100%"
        position="absolute"
        bottom={0}
        right={0}
      >
        <Box flex={1} minH={0} h="100%">
          <SdkApp preview={{ appConfig: watched }} />
        </Box>
      </Stack>
    </Flex>
  );
};

export default Customization;
