import AppContainer from "@projectg/utils/store/app";
import dayjs from "dayjs";
import SdkContainer from "../../../store/sdk";

export const useIsOfficeHour = () => {
  const { appConfig: _appConfig } = AppContainer.useContainer();
  const { preview } = SdkContainer.useContainer();
  const appConfig = preview?.appConfig ?? _appConfig;

  const weekday = dayjs().locale("en").format("ddd").toLowerCase();
  const time = dayjs().format("HH:mm");

  const {
    enable,
    start = null,
    end = null,
  } = appConfig?.officeHour?.[weekday] ?? {};

  const isOfficeHour =
    enable && (start || "00:00") <= time && time <= (end || "24:00");

  return isOfficeHour;
};
