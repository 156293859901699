import React, { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Button,
  IconButton,
  Tag,
  Tooltip,
  Center,
  TagLeftIcon,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdClose, MdDelete } from "react-icons/md";
import { useHistory } from "react-router";

import Card from "../card";
import UpsertSanukerChannelDrawer from "./upsertSanukerChannelDrawer";
import UpsertSanukerTemplateDrawer from "./upsertSanukerTemplateDrawer";

import { Sources } from "../../../../utils/constants";
import AppContainer from "@projectg/utils/store/app";
import api from "@projectg/utils/utils/api";
import { AlertModal } from "../../../../components/components";

const ChannelDetail = ({ id, channelId }) => {
  const {
    getTexts,
    externalList,
    setExternalList,
  } = AppContainer.useContainer();
  const toast = useToast();
  const history = useHistory();
  const cancelRef = React.useRef();

  const platform = useMemo(
    () => externalList?.find(platform => platform.id === id),
    [externalList, id]
  );

  const channel = useMemo(
    () => platform?.channels?.find(channel => channel.id === channelId),
    [channelId, platform]
  );

  const [selectedSanukerTemplate, setSelectedSanukerTemplate] = useState(null);

  const upsertSanukerChannelDrawer = useDisclosure();

  const removeSanukerChannelAlert = useDisclosure();
  const [
    removeSanukerChannelIsLoading,
    setRemoveSanukerChannelIsLoading,
  ] = useState(false);
  const onRemoveSanukerChannel = async () => {
    try {
      setRemoveSanukerChannelIsLoading(true);
      const { data } = await api.post({
        url: "/api/admin/setting/externalPlatform/sanuker/channel/delete",
        body: {
          id,
          channelId,
        },
      });
      setExternalList(data);
      removeSanukerChannelAlert.onClose();
      toast({
        title: getTexts("SanukerChannelRemoved"),
        status: "success",
      });
      history.push(`/externalPlatform/${id}`);
    } catch (error) {
    } finally {
      setRemoveSanukerChannelIsLoading(false);
    }
  };

  const upsertSanukerTemplateDrawer = useDisclosure();

  const removeSanukerTemplateAlert = useDisclosure();
  const [
    removeSanukerTemplateIsLoading,
    setRemoveSanukerTemplateIsLoading,
  ] = useState(false);
  const onRemoveSanukerTemplate = async () => {
    try {
      setRemoveSanukerTemplateIsLoading(true);
      const newTemplatesArray = channel?.templates?.filter(
        item => item?.key !== selectedSanukerTemplate?.key
      );
      const { data: resData } = await api.post({
        url:
          "/api/admin/setting/externalPlatform/sanuker/channel/updateOrCreate",
        body: {
          ...channel,
          id,
          channelId,
          templates: newTemplatesArray,
        },
      });
      setExternalList(resData);
      toast({
        title: getTexts("SanukerTemplateRemoved"),
        status: "success",
      });
      removeSanukerTemplateAlert.onClose();
    } catch (err) {
    } finally {
      setRemoveSanukerTemplateIsLoading(false);
    }
  };

  const { icon: Icon, name: defaultName, color = "primary.500" } =
    Sources?.[platform?.type] ?? Sources.web;

  return externalList ? (
    platform && channel ? (
      <>
        <AlertModal
          isOpen={removeSanukerChannelAlert.isOpen}
          cancelRef={cancelRef}
          onClose={removeSanukerChannelAlert.onClose}
          isLoading={removeSanukerChannelIsLoading}
          onSubmit={onRemoveSanukerChannel}
          title={getTexts("SanukerChannelRemove")}
        />

        <AlertModal
          isOpen={removeSanukerTemplateAlert.isOpen}
          cancelRef={cancelRef}
          onClose={removeSanukerTemplateAlert.onClose}
          isLoading={removeSanukerTemplateIsLoading}
          onSubmit={onRemoveSanukerTemplate}
          title={getTexts("SanukerTemplateRemove")}
        />

        {upsertSanukerChannelDrawer.isOpen && (
          <UpsertSanukerChannelDrawer
            isOpen={upsertSanukerChannelDrawer.isOpen}
            onClose={upsertSanukerChannelDrawer.onClose}
            params={{ platform, channel }}
          />
        )}

        {upsertSanukerTemplateDrawer.isOpen && (
          <UpsertSanukerTemplateDrawer
            isOpen={upsertSanukerTemplateDrawer.isOpen}
            onClose={upsertSanukerTemplateDrawer.onClose}
            params={{ platform, channel }}
          />
        )}

        <Flex overflow="auto" flexDir="column" w="100%" h="100%">
          <Box w="100%" minW={768} maxW={1280} mx="auto" zIndex={50} p={8}>
            <Flex alignItems="baseline">
              <IconButton
                onClick={() => history.push(`/externalPlatform/${id}`)}
                variant="ghost"
                isRound
                fontSize="3xl"
                icon={<MdArrowBack />}
              />
              <Flex flexDir="column" ml={4} mb={4}>
                <Heading size="xl" fontFamily="Roboto Slab">
                  {channel.name}
                </Heading>
                <Text color="gray.500">{`${platform.id} > ${channel.name}`}</Text>
              </Flex>
            </Flex>

            <Box mx={14} mb={14}>
              <Flex alignItems="center" mb={4}>
                <TagLeftIcon as={Icon} color={color} fontSize="lg" />
                <Text fontSize="lg" mt={1} color="gray.500">
                  {defaultName}
                </Text>
              </Flex>

              <Flex alignItems="flex-end">
                <Flex w="100%" minW={0} flex={1}>
                  <Heading size="lg">
                    {getTexts("SanukerChannelDetail")}
                  </Heading>
                </Flex>
                <Stack direction="row" spacing={4}>
                  <Button
                    leftIcon={<MdDelete />}
                    colorScheme="red"
                    onClick={removeSanukerChannelAlert.onOpen}
                    isLoading={removeSanukerChannelIsLoading}
                  >
                    {getTexts("SanukerChannelRemove")}
                  </Button>
                  <Button
                    colorScheme="primary"
                    onClick={upsertSanukerChannelDrawer.onOpen}
                  >
                    {getTexts("Edit")}
                  </Button>
                </Stack>
              </Flex>

              <Box as={Card}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("ChannelName")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">{channel?.name}</Text>
                      </Flex>
                    </Box>
                  </Stack>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("ChannelId")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">{channel?.id}</Text>
                      </Flex>
                    </Box>
                  </Stack>
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("PlatformType")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      {(() => {
                        const {
                          icon: Icon,
                          name: defaultName,
                          color = "primary.500",
                        } = Sources?.[channel?.platformType] ?? Sources.web;

                        return (
                          <Flex alignItems="center">
                            <TagLeftIcon
                              as={Icon}
                              color={color}
                              fontSize="lg"
                            />
                            <Text fontSize="lg" mt={1} color="gray.500">
                              {defaultName}
                            </Text>
                          </Flex>
                        );
                      })()}
                    </Box>
                  </Stack>
                  {channel?.accountId && (
                    <Stack spacing={2}>
                      <Text w="100%" fontWeight="bold">
                        {getTexts("AccountId")}
                      </Text>
                      <Box fontSize="sm" color="gray.500">
                        <Flex alignItems="center">
                          <Text fontSize="md">{channel?.accountId}</Text>
                        </Flex>
                      </Box>
                    </Stack>
                  )}
                  {channel?.namespace && (
                    <Stack spacing={2}>
                      <Text w="100%" fontWeight="bold">
                        {getTexts("Namespace")}
                      </Text>
                      <Box fontSize="sm" color="gray.500">
                        <Flex alignItems="center">
                          <Text fontSize="md">{channel?.namespace}</Text>
                        </Flex>
                      </Box>
                    </Stack>
                  )}
                  <Stack spacing={2}>
                    <Text w="100%" fontWeight="bold">
                      {getTexts("ResponseWindowHours")}
                    </Text>
                    <Box fontSize="sm" color="gray.500">
                      <Flex alignItems="center">
                        <Text fontSize="md">
                          {channel?.responseWindowHours}
                        </Text>
                      </Flex>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              <Flex alignItems="flex-end" mt={8}>
                <Flex w="100%" minW={0} flex={1}>
                  <Heading size="lg">{getTexts("SanukerTemplates")}</Heading>
                </Flex>
                <Button
                  colorScheme="primary"
                  onClick={() => {
                    upsertSanukerTemplateDrawer.onOpen();
                  }}
                >
                  {getTexts("SanukerTemplateCreate")}
                </Button>
              </Flex>
              {channel?.templates?.length ? (
                channel?.templates?.map((template, i) => (
                  <Box
                    as={Card}
                    key={i}
                    py={4}
                    cursor="pointer"
                    onClick={() =>
                      history.push(`${channel.id}/${template.key}`)
                    }
                  >
                    <Box pos="relative">
                      <IconButton
                        pos="absolute"
                        top="0px"
                        right="0px"
                        icon={<MdClose />}
                        variant="ghost"
                        size="sm"
                        fontSize="lg"
                        isRound
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedSanukerTemplate(_ => ({ ...template }));
                          removeSanukerTemplateAlert.onOpen();
                        }}
                      />
                    </Box>
                    <Stack flex={1} minW={0} w="100%" spacing={1}>
                      <Text w="100%" fontWeight="bold">
                        {template?.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {`${getTexts("TemplateKey")}: ${template?.key}`}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {`${getTexts("TemplateDescription")}: ${
                          template?.description
                        }`}
                      </Text>
                      <Box flex={1}>
                        {template?.params?.map(({ name, description }, i) => (
                          <Tag colorScheme="secondary" mr={2} size="sm" key={i}>
                            <Tooltip label={description}>{name}</Tooltip>
                          </Tag>
                        ))}
                      </Box>
                    </Stack>
                  </Box>
                ))
              ) : (
                <Card>
                  <Center>
                    <Text fontSize="xl" color="gray.400">
                      {getTexts("ListEmpty")}
                    </Text>
                  </Center>
                </Card>
              )}
            </Box>
          </Box>
        </Flex>
      </>
    ) : (
      <Box p={8}>
        <Heading>{getTexts("PageNotFound")}</Heading>
      </Box>
    )
  ) : (
    <Flex w="100%" h="100%">
      <Spinner color="primary.500" mx="auto" mt={12}></Spinner>
    </Flex>
  );
};

export default ChannelDetail;
