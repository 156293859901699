import {
  strongPasswordRegex,
  emailRegex,
} from "@projectg/utils/utils/constants";
import dayjs from "dayjs";
import AppContainer from "@projectg/utils/store/app";

/* Select */
export const getSelectTheme =
  ({ theme }) =>
  _ => ({
    ..._,
    borderRadius: 0,
    borderColor: "transparent",
    colors: {
      primary: theme.colors.primary[100],
      primary25: theme.colors.primary[50],
      primary50: theme.colors.primary[100],
      neutral0: "white",
    },
  });
export const getSelectStyle = ({ theme }) => ({
  dropdownIndicator: () => ({ display: "none " }),
  multiValue: _ => ({
    ..._,
    fontWeight: "bold",
  }),
  singleValue: _ => ({
    ..._,
    fontWeight: "bold",
  }),
  option: _ => ({
    ..._,
    color: "#333",
    cursor: "pointer",
    margin: 0,
  }),
  container: (_, state) => ({
    ..._,
    width: "100%",
    padding: 0,
    borderColor: theme.colors.primary[100],
    color: state.hasValue ? theme.colors.primary[700] : "#999",
  }),
  control: _ => ({
    ..._,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    minHeight: 35,
    boxShadow: "none",
    background: "transparent",
    cursor: "pointer",
  }),
});

export const validateEmail = email => {
  return emailRegex.test(String(email).toLowerCase());
};
export const validateStrongPassword = password => {
  return strongPasswordRegex.test(String(password));
};

export const getModuleDefaultValue = ({ module: type }) => {
  switch (type) {
    case "startConversation":
      return {
        module: type,
        title: {
          en: "Hi there!",
          zh: "你好",
          zh_cn: "你好",
        },
        description: {
          en: "Leave your message and we will contact you very soon.",
          zh: "留下您的信息，我們會儘快回應！",
          zh_cn: "留下您的信息，我们会尽快回应！",
        },
      };
    default:
      return {
        module: type,
      };
  }
};

export const autoSize = target => {
  target.style.height = "auto";
  const h = Math.max(target.scrollHeight, target.clientHeight);
  if (h > target.clientHeight) {
    target.style.height = h + "px";
  }
};

export const getSnoozeMessage = snooze => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getTexts, currentLangKey } = AppContainer.useContainer();
  const now = dayjs();
  const snoozeTime = dayjs(snooze);
  const snoozeIsBefore = snooze && snoozeTime.isBefore(now);

  const snoozeText = (() => {
    let formatter = "";

    const yearFormat = currentLangKey === "en" ? "YYYY/" : "YYYY年";
    const dateFormat = currentLangKey === "en" ? "MM/DD " : "MM月DD日 ";
    const timeFormat = currentLangKey === "en" ? "HH:mm" : "HH時mm分";

    if (!snoozeIsBefore) {
      if (!snoozeTime.isSame(now, "year")) formatter += yearFormat;
      if (!snoozeTime.isSame(now, "day")) formatter += dateFormat;
      formatter += timeFormat;
      return snoozeTime.format(formatter);
    }
    return snoozeTime.format(yearFormat + dateFormat + timeFormat);
  })();

  return !!snooze
    ? snoozeIsBefore
      ? `${getTexts("SnoozeTrackingDeprecated")} ${snoozeText}`
      : `${getTexts("SnoozeTrackingTill")} ${snoozeText}`
    : `${getTexts("SnoozeTracking")}`;
};
