import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const InternalSpliter = React.forwardRef(
  (
    {
      internalVisible = false,
      external = null,
      internal = null,
      style = {},
      ...props
    },
    ref
  ) => {
    return (
      <Flex css={style} ref={ref} {...props}>
        <Box w="55%" flex={1}>
          {external}
        </Box>
        <Box
          w="45%"
          bg="secondary.50"
          {...(!internalVisible && { display: "none" })}
        >
          {internal}{" "}
        </Box>
      </Flex>
    );
  }
);

export default InternalSpliter;
