import { createContainer } from "unstated-next";
import { useState, useCallback } from "react";
import { useQueue } from "../utils/hooks";
import {
  FilterTypes,
  MessageMetaFileSubTypes,
  MessageMetaTypes,
} from "../utils/constants";
import api from "../utils/api";
import { unstable_batchedUpdates } from "react-dom";
import {
  mergeConversation,
  mergeCustomer,
  mergeMessage,
} from "../utils/helpers";
import dayjs from "dayjs";
import { useDisclosure } from "@chakra-ui/react";

const MessengerContainer = createContainer(() => {
  const [customerTags, setCustomerTags] = useState([]);
  const [conversationTags, setConversationTags] = useState([]);
  const [customers, setCustomers] = useState({});
  const [agents, setAgents] = useState({});
  const [teams, setTeams] = useState({});
  const [conversations, setConversations] = useState({});
  const [messages, setMessages] = useState({});
  const [replyMeta, updateReplyMeta] = useState({});

  const [sendQueue] = useQueue(async localMessage => {
    switch (localMessage.meta.type) {
      case MessageMetaTypes.TEXT:
      case MessageMetaTypes.TEMPLATE_MESSAGE: {
        const { conversationId, selfId, dataType, internal } = localMessage;
        const {
          data: { id: mId },
        } = await api.post({
          url: `/api/message/send`,
          body: {
            conversationId,
            dataType,
            internal,
            localId: selfId,
            meta: localMessage.meta,
          },
        });
        unstable_batchedUpdates(() => {
          upsertMessage(conversationId, selfId, { id: mId, selfId: null });
        });
        break;
      }
      case MessageMetaTypes.FILE:
        const { conversationId, selfId, dataType, internal, pushContent } =
          localMessage;
        const { data: _message } = await api.post({
          url: `/api/message/file/send`,
          body: {
            conversationId,
            dataType,
            internal,
            localId: selfId,
            file: localMessage.meta.file,
            ...(localMessage.meta.subtype === MessageMetaFileSubTypes.IMAGE && {
              previewFile: localMessage.meta.file,
            }),
            pushContent,
            subtype: localMessage.meta.subtype,
          },
        });
        unstable_batchedUpdates(() => {
          const messageId = _message.id;
          const serverTimestamp = _message.timestamp;
          updateMessage(
            conversationId,
            selfId,
            message => {
              // console.log("delocalize", selfId, message);
              const newMessage = { ...message };
              newMessage.id = messageId;
              newMessage.meta = _message.meta;
              newMessage.timestamp = serverTimestamp;
              delete newMessage.selfId;
              delete newMessage.___unsent;
              // console.log("delocalize", selfId, message, newMessage);
              return newMessage;
            },
            { upsert: false }
          );
        });
        break;
      default:
    }
  });

  const [inboxCount, setInboxCount] = useState({
    me: 0,
    unassigned: 0,
    mention: 0,
    snooze: 0,
    team: {},
  });

  const upsertAgent = useCallback((id, updater) => {
    setAgents(_ => ({
      ..._,
      [id]:
        typeof updater === "function"
          ? updater(_?.[id] ?? null)
          : { ...(_?.[id] ?? {}), ...updater },
    }));
  }, []);

  const deleteAgent = useCallback(id => {
    setAgents(agents => {
      delete agents[id];
      return agents;
    });
  }, []);

  const upsertCustomer = useCallback((id, updater) => {
    setCustomers(_ => ({
      ..._,
      [id]:
        typeof updater === "function"
          ? updater(_?.[id] ?? null)
          : mergeCustomer(_?.[id] ?? {}, updater),
    }));
  }, []);

  const upsertTeam = useCallback((id, updater) => {
    setTeams(_ => ({
      ..._,
      [id]: typeof updater === "function" ? updater(_?.[id] ?? null) : updater,
    }));
  }, []);

  const deleteTeam = useCallback(
    id =>
      setTeams(_ => {
        const res = { ..._ };
        delete res[id];
        return res;
      }),
    []
  );

  const upsertConversation = useCallback((id, updater, upsert = true) => {
    setConversations(_ =>
      !upsert && !_?.[id]
        ? _
        : {
            ..._,
            [id]:
              typeof updater === "function"
                ? updater(_?.[id] ?? null)
                : mergeConversation(_?.[id] ?? {}, updater),
          }
    );
  }, []);

  const isConversationMatched = useCallback(
    (c, searchParams, lastTimestamp, session, lastSearchAt) => {
      const {
        filter,
        status,
        team,
        reverseOrder,
        afterTimestamp,
        beforeTimestamp,
        tag,
        customerTag,
        customerId,
        agentId,
      } = searchParams;
      const now = dayjs();
      if (!reverseOrder && lastTimestamp > c?.orderTimestamp) return false;
      if (reverseOrder && lastTimestamp > c?.orderTimestamp) return false;

      if (afterTimestamp && afterTimestamp < c?.orderTimestamp) return false;
      if (beforeTimestamp && beforeTimestamp < c?.orderTimestamp) return false;

      if (customerId && !c?.customer?.find(({ id }) => id === customerId))
        return false;

      if (
        agentId &&
        !c?.assignedAgent?.find(({ id }) => id === agentId) &&
        !(
          c?._assignedAgent?.value?.find(({ id }) => id === agentId) &&
          c?._assignedAgent?.timestamp > lastSearchAt
        )
      )
        return false;

      if (tag && !c?.tag.includes(tag)) return false;

      if (customerTag && !c?.agent?.[0]?.tag?.includes(customerTag))
        return false;

      if (
        status !== "all" &&
        c?.status !== status &&
        !(c?._status?.value === status && c?._status?.timestamp > lastSearchAt)
      )
        return false;

      if (
        filter === "snooze" &&
        (!c?.snooze || now.isAfter(c?.snooze)) &&
        !(c?._snooze?.value === status && c?._snooze?.timestamp > lastSearchAt)
      ) {
        return false;
      }

      if (
        filter === FilterTypes.ME &&
        !c?.assignedAgent?.find(({ id }) => id === session?.user?.userId) &&
        !(
          c?._assignedAgent?.value?.find(
            ({ id }) => id === session?.user?.userId
          ) && c?._assignedAgent?.timestamp > lastSearchAt
        )
      )
        return false;

      if (
        filter === FilterTypes.UNASSIGNED &&
        (c?.assignedAgent?.length ?? 0) > 0 &&
        !(
          c?._assignedAgent?.value?.length === 0 &&
          c?._assignedAgent?.timestamp > lastSearchAt
        )
      )
        return false;

      if (
        filter === FilterTypes.TEAM &&
        team &&
        (c?.team ?? []).indexOf(team) < 0 &&
        !(
          (c?._team?.value ?? []).indexOf(team) >= 0 &&
          c?._team?.timestamp > lastSearchAt
        )
      ) {
        return false;
      }
      return true;
    },
    []
  );

  const searchConversations = useCallback(
    (searchParams, lastTimestamp, session, lastSearchAt) => {
      return Object.values(conversations)
        .filter(c =>
          isConversationMatched(
            c,
            searchParams,
            lastTimestamp,
            session,
            lastSearchAt
          )
        )
        .sort((a, b) =>
          searchParams.reverseOrder
            ? a.orderTimestamp > b.orderTimestamp
              ? 1
              : -1
            : a.orderTimestamp < b.orderTimestamp
            ? 1
            : -1
        );
    },
    [conversations, isConversationMatched]
  );

  const upsertMessage = useCallback((cId, mId, updater, replace = false) => {
    setMessages(({ [cId]: { [mId]: m = null, ...ms } = {}, ...cs }) => ({
      ...cs,
      [cId]: {
        ...ms,
        ...(!updater?.deleted && {
          [updater?.id ?? m?.id]:
            typeof updater === "function"
              ? updater(m)
              : mergeMessage(replace ? null : m, updater),
        }),
      },
    }));
  }, []);

  const updateMessage = useCallback(
    (conversationId, messageId, updater, options = {}) => {
      setMessages(allMessages => ({
        ...allMessages,
        [conversationId]: {
          ...allMessages[conversationId],
          ...(!options.upsert && !allMessages[conversationId][messageId]
            ? {}
            : {
                [messageId]:
                  typeof updater === "function"
                    ? updater(allMessages[conversationId][messageId])
                    : updater,
              }),
        },
      }));
    },
    []
  );

  const getLastMessage = useCallback(
    conversation =>
      Object.values(messages?.[conversation.id] ?? []).sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      )?.[0] ??
      conversation?.messages?.last ??
      null,
    [messages]
  );

  const hasUnread = useCallback(
    (conversation, userId) => {
      const lastMessage = getLastMessage(conversation);
      if (!lastMessage) {
        return false;
      }
      if (lastMessage.senderId === userId) {
        return false;
      }
      if (conversation.lastRead) {
        return conversation.lastRead.id !== lastMessage.id;
      }

      const userState =
        (conversation?.customer ?? []).find(
          customer => customer.id === userId
        ) ?? (conversation?.agent ?? []).find(agent => agent.id === userId);

      if (userState && userState.read) {
        return userState.read.id !== lastMessage.id;
      }

      return true;
    },
    [getLastMessage]
  );

  const clear = useCallback(() => {
    setCustomers({});
    setAgents({});
    setTeams({});
    setConversations({});
    setMessages({});
    setCustomerTags([]);
    setConversationTags([]);
  }, []);

  const {
    isOpen: filterPanelIsOpen,
    onOpen: filterPanelOnOpen,
    onClose: filterPanelOnClose,
    onToggle: filterPanelOnToggle,
  } = useDisclosure();

  const setReplyMeta = useCallback(v => {
    const reply = v;
    if (reply?.meta?.reply?.meta?.reply) delete reply.meta.reply.meta.reply;
    updateReplyMeta(reply);
  }, []);

  const deleteMessage = useCallback((cId, mId) => {
    api.post({
      url: `/api/message/delete`,
      body: {
        messageId: mId,
      },
    });
    setMessages(({ [cId]: { [mId]: m = null, ...ms } = {}, ...cs }) => ({
      ...cs,
      [cId]: {
        ...ms,
      },
    }));
  }, []);

  return {
    customerTags,
    setCustomerTags,
    conversationTags,
    setConversationTags,
    customers,
    upsertCustomer,
    agents,
    upsertAgent,
    teams,
    upsertTeam,
    conversations,
    upsertConversation,
    searchConversations,
    isConversationMatched,
    getLastMessage,
    hasUnread,
    inboxCount,
    setInboxCount,

    messages,
    upsertMessage,
    deleteMessage,

    sendQueue,
    clear,

    filterPanelIsOpen,
    filterPanelOnOpen,
    filterPanelOnClose,
    filterPanelOnToggle,

    replyMeta,
    setReplyMeta,

    deleteAgent,
    deleteTeam,
  };
});

export default MessengerContainer;
