import { useState } from "react";
import { createContainer } from "unstated-next";

const ConsoleContainer = createContainer(() => {
  const ___searchParams = useState(null);

  return {
    ___searchParams
  };
});

export default ConsoleContainer;
